import _defineProperty from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/defineProperty.js";
var _objApi;
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 系统管理接口
 */
import request from "@/utils/request";
import getUrl from "@/utils/get-url";
var objApi = (_objApi = {
  // *************************角色管理****************************
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示数量
   * @param {*} data 参数
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/roles?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 3), {
      params: data
    });
  },
  /**
   * 删除角色
   * @param {*} id 当前角色的id
   */
  deleteRoles: function deleteRoles(id) {
    return request.delete(getUrl("/roles/".concat(id), 3));
  },
  /**
   * 新增角色
   */
  recentCreate: function recentCreate(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/roles/recentCreate", 3), data, {
      headers: headers
    });
  },
  /**
   * 查询角色
   * @param {*}
   */
  recentGetRole: function recentGetRole(id) {
    return request.get(getUrl("/roles/recentGetRole/".concat(id), 3));
  },
  /**
   * 修改角色
   * @param {*}
   */
  recentUpdate: function recentUpdate(data) {
    var headers = {
      showLoading: true
    };
    return request.put(getUrl("/roles/recentUpdate?roleId=".concat(data.roleId), 3), data, {
      headers: headers
    });
  },
  // *************************用户管理****************************
  /**
   * 用户列表
   * @param {*} data 参数
   */
  getUsersList: function getUsersList(data) {
    return request.get(getUrl("/users", 3), {
      params: data
    });
  },
  /**
   * 新增用户
   * @param {*} data 参数
   */
  createUsers: function createUsers(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/users", 3), data, {
      headers: headers
    });
  },
  /**
   * 查询用户
   * @param {*} userId
   */
  getUsersInfo: function getUsersInfo(userId) {
    return request.get(getUrl("/users/".concat(userId), 3));
  },
  /**
   * 修改用户
   * @param {*} userId
   * @param {*} data
   */
  updateUsers: function updateUsers(userId, data) {
    var headers = {
      showLoading: true
    };
    return request.put(getUrl("/users/".concat(userId), 3), data, {
      headers: headers
    });
  },
  /**
   * 删除用户
   * @param {*} userId 当前用户的userId
   */
  deleteUsers: function deleteUsers(userId) {
    return request.delete(getUrl("/users/".concat(userId), 3));
  },
  /**
   * 更新用户状态
   * @param {*} userId 当前用户的userId
   * @param {*} data
   */
  updateUsersStatus: function updateUsersStatus(userId, data) {
    var headers = {
      showLoading: true
    };
    return request.put(getUrl("/users/".concat(userId, "/status"), 3), data, {
      headers: headers
    });
  },
  /**
   * 获取角色下拉框
   * @param {*}
   */
  getRoleDropdownList: function getRoleDropdownList() {
    return request.get(getUrl("/roles/recentRoleAll", 3));
  },
  // *************************成员管理****************************
  /**
   * 根据企业ID获取部门树
   * @param {*} data 参数
   */
  getDepartmentTree: function getDepartmentTree(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomDepartment/departmentTree", 4), data, {
      headers: headers
    });
  },
  /**
   * 树形过滤成员
   * @param {*} data 参数
   */
  getWecomMemberList: function getWecomMemberList(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomMember/treeQuery", 4), data, {
      headers: headers
    });
  },
  /**
   * 授权
   * @param {*} data 参数
   */
  wecomMemberEmpower: function wecomMemberEmpower(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomMember/empower", 4), data, {
      headers: headers
    });
  },
  // *************************企业配置****************************
  /**
   * 分页查询企业配置
   * @param {*} data 参数
   */
  getWecomCorpConfigList: function getWecomCorpConfigList(data) {
    return request.get(getUrl("/wecomCorpConfig/search", 4), {
      params: data
    });
  },
  /**
   * 新增企业配置
   * @param {*} data 参数
   */
  createWecomCorpConfig: function createWecomCorpConfig(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomCorpConfig/create", 4), data, {
      headers: headers
    });
  },
  /**
   * 根据企业ID获取配置
   * @param {*} configId
   */
  getWecomCorpConfigInfo: function getWecomCorpConfigInfo(configId) {
    return request.get(getUrl("/wecomCorpConfig/get?configId=".concat(configId), 4));
  },
  /**
   * 更新企业微信配置
   * @param {*} configId
   * @param {*} data
   */
  updateWecomCorpConfig: function updateWecomCorpConfig(configId, data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomCorpConfig/update?configId=".concat(configId), 4), data, {
      headers: headers
    });
  },
  /**
   * 批量删除
   * @param {*} configIds
   */
  deleteWecomCorpConfig: function deleteWecomCorpConfig(configIds) {
    return request.post(getUrl("/wecomCorpConfig/batchDelete?configIds=".concat(configIds), 4));
  },
  /**
   * 启用/禁用企业配置
   * @param {*} configId
   */
  enabledWecomCorpConfig: function enabledWecomCorpConfig(configId) {
    return request.post(getUrl("/wecomCorpConfig/enabled?configId=".concat(configId), 4));
  },
  /**
   * 获取所有的企业配置
   */
  getAllWecomCorpConfigList: function getAllWecomCorpConfigList() {
    return request.get(getUrl("/wecomCorpConfig/all", 4));
  },
  /**
   * 创建企业管理员
   */
  createCorpAdminConfig: function createCorpAdminConfig(data) {
    return request.post(getUrl("/wecomCorpConfig/createCorpAdmin", 4), data);
  },
  /**
   * 创建企业管理员
   */
  confirmAuth: function confirmAuth(data) {
    return request.post(getUrl("/wecomCorpConfig/confirmAuth", 4), data);
  },
  /**
   * 企业微信网页授权登录
   * @param {*} data
   */
  webWecomAuth: function webWecomAuth(data) {
    return request.post(getUrl("/net/wecom/auth/web", 4), data);
  },
  /**
   * 域名获取企业配置
   * @param {*} data
   */
  getDomainWecomCorpConfig: function getDomainWecomCorpConfig(data) {
    return request.post(getUrl("/wecomCorpConfig/domain", 4), data);
  },
  /**
   * 扫码授权
   * @param {*} data
   */
  scanWecomAuth: function scanWecomAuth(data) {
    return request.post(getUrl("/wecom/auth/scan", 4), data);
  },
  /**
   * 扫码授权绑定手机号
   * @param {*} data
   */
  scanWecomAuthMobile: function scanWecomAuthMobile(data) {
    return request.post(getUrl("/wecom/auth/scan/mobile", 4), data);
  },
  // **********************************存储服务配置**********************************************
  /**
   * 列表
   * @param {*} data 内容
   */
  getStorageList: function getStorageList(data) {
    return request.get(getUrl("/objStorage/search", 7), {
      params: data
    });
  },
  /**
   * 新增数据
   * @param {*} data 内容
   */
  addData: function addData(data) {
    return request.post(getUrl("/objStorage/create", 7), data);
  },
  /**
   * 编辑数据
   * @param {*} data 内容
   */
  editData: function editData(id, data) {
    return request.put(getUrl("/objStorage/".concat(id), 7), data);
  },
  /**
   * 根据ID获取对象存储
   * @param {*} data 内容
   */
  getData: function getData(id) {
    return request.get(getUrl("/objStorage/".concat(id), 7));
  },
  /**
   * 删除对象存储
   * @param {*} data 内容
   */
  delData: function delData(id) {
    return request.delete(getUrl("/objStorage/".concat(id.toString()), 7));
  },
  /**
   * 设置默认对象存储
   * @param {*} data 内容
   */
  default: function _default(id) {
    return request.get(getUrl("/objStorage/default/".concat(id), 7));
  },
  /**
   * 启用对象存储
   * @param {*} data 内容
   */
  enable: function enable(id) {
    return request.get(getUrl("/objStorage/enable/".concat(id), 7));
  },
  /**
   * 禁用对象存储
   * @param {*} data 内容
   */
  disable: function disable(id) {
    return request.get(getUrl("/objStorage/disable/".concat(id), 7));
  },
  /**
   * 删除数据
   * @param {*} id
   */
  deleteData: function deleteData(id) {
    return request.delete(getUrl("/correct/policy/delete/".concat(id), 7));
  }
}, _defineProperty(_objApi, "getData", function getData(id) {
  return request.get(getUrl("/objStorage/get?objStorageId=".concat(id), 7));
}), _defineProperty(_objApi, "updateSecret", function updateSecret(id, data) {
  return request.post(getUrl("/objStorage/updateSecret?objStorageId=".concat(id), 7), data);
}), _defineProperty(_objApi, "getSensitiveWordList", function getSensitiveWordList(data) {
  return request.get(getUrl("/wecom/sensitiveWord/search", 4), {
    params: data
  });
}), _defineProperty(_objApi, "addSensitiveWord", function addSensitiveWord(data) {
  return request.post(getUrl("/wecom/sensitiveWord/create", 4), data);
}), _defineProperty(_objApi, "editSensitiveWord", function editSensitiveWord(id, data) {
  return request.post(getUrl("/wecom/sensitiveWord/update?configId=".concat(id), 4), data);
}), _defineProperty(_objApi, "getSensitiveWordInfo", function getSensitiveWordInfo(id) {
  return request.get(getUrl("/wecom/sensitiveWord/getDetails?configId=".concat(id), 4));
}), _defineProperty(_objApi, "deleteSensitiveWord", function deleteSensitiveWord(id) {
  return request.post(getUrl("/wecom/sensitiveWord/delete?configId=".concat(id), 4));
}), _objApi);
export default objApi;