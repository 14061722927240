import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "@/mixins/initHeight";
import dict from "@/mixins/dict";
import Pagination from "@/components/Pagination";
import StorageServiceDialog from "./dialog/storageServiceDialog";
import updateDialog from "./dialog/updateDialog";
import sysManageApi from "@/api/sysManage";
import { commonExport } from '@/utils/common';
export default {
  name: 'StorageServiceConfig',
  components: {
    Pagination: Pagination,
    StorageServiceDialog: StorageServiceDialog,
    updateDialog: updateDialog
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      opType: "",
      loading: false,
      showDialog: false,
      showUpdateDialog: false,
      itemId: null,
      currentId: "",
      categoryList: [{
        categoryCode: 'ALI',
        categoryName: '阿里云OSS'
      }, {
        categoryCode: 'TENCENT',
        categoryName: '腾讯云OSS'
      }, {
        categoryCode: 'MIN_IO',
        categoryName: 'MinIO OSS'
      }, {
        categoryCode: 'QINIU',
        categoryName: '七牛云OSS'
      }, {
        categoryCode: 'HUAWEI',
        categoryName: '华为云OBS'
      }]
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '存储服务配置',
        exportUrl: '/tp-storage-service/objStorage/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 是否默认
    changeDefault: function changeDefault(row, e) {
      var _this = this;
      if (!row.isDefault) {
        return;
      } else {
        this.$confirm('是否将该条数据设为默认?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          sysManageApi.default(row.objStorageId).then(function (res) {
            if (res.code == 200) {
              _this.$message({
                type: "success",
                message: res.msg
              });
            }
            _this.loadData();
          });
        }).catch(function () {
          _this.loadData();
        });
      }
    },
    // 是否启用
    changeEnable: function changeEnable(val) {
      var _this2 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981".concat(val.enabled ? '禁用' : '启用', "\u8BE5\u6570\u636E\u5417\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        var daa = val.enabled ? sysManageApi.disable(val.objStorageId) : sysManageApi.enable(val.objStorageId);
        daa.then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          ;
        });
      }).catch(function () {});
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 新增
    addItem: function addItem(type) {
      this.opType = type;
      this.showDialog = true;
      this.$forceUpdate();
    },
    updateKey: function updateKey(item) {
      this.showUpdateDialog = true;
      this.itemId = parseInt(item.objStorageId);
      this.$forceUpdate();
    },
    // 编辑
    editItem: function editItem(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.currentId = item.objStorageId.toString();
      this.$forceUpdate();
    },
    // 删除
    deleteItem: function deleteItem(item) {
      var _this3 = this;
      this.$confirm("您确定要删除该数据吗？", "温馨提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: "warning"
      }).then(function () {
        sysManageApi.delData(item.objStorageId).then(function (res) {
          if (res.code == 200) {
            _this3.$message({
              type: "success",
              message: res.msg
            });
            _this3.loadData();
          }
          ;
        }).catch(function (err) {});
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this4 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDialog = false;
      this.showUpdateDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      sysManageApi.getStorageList(data).then(function (res) {
        _this4.loading = false;
        if (res.code === 200) {
          _this4.tableData = res.data.list || [];
          _this4.total = res.data.page ? res.data.page.recordsTotal : 0;
        }
        ;
      }).catch(function (err) {
        _this4.loading = false;
      });
    }
  }
};