import _defineProperty from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/defineProperty.js";
var _computed;
export default {
  data: function data() {
    return {
      headerCollapse: false // 条件是否展开
    };
  },

  computed: (_computed = {
    tableHeightNMini: function tableHeightNMini() {
      return document.body.clientHeight - 310 + 'px'; //搜索框在侧边栏
    },
    tableHeightNS: function tableHeightNS() {
      return document.body.clientHeight - 210 + 'px'; //搜索框在侧边栏
    },
    tableHeightN: function tableHeightN() {
      return document.body.clientHeight - 215 + 'px'; //搜索框在侧边栏
    },
    tableHeightFD: function tableHeightFD() {
      return document.body.clientHeight - 218 + 'px'; //活动页列表及消息管理的高度
    },
    tableHeightJD: function tableHeightJD() {
      return document.body.clientHeight - 223 + 'px'; //活动页列表及消息管理的高度
    },
    tableHeightHD: function tableHeightHD() {
      return document.body.clientHeight - 235 + 'px'; //活动页列表及消息管理的高度
    },
    tableHeightH: function tableHeightH() {
      return document.body.clientHeight - 245 - 8 + 'px'; //增加一行按钮的高度
    },
    tableHeightJ: function tableHeightJ() {
      return document.body.clientHeight - 255 + 'px'; //增加一行按钮的高度(表头有搜索栏)
    },
    tableHeightSIXJ: function tableHeightSIXJ() {
      return document.body.clientHeight - 265 + 'px'; //增加一行按钮的高度(表头有搜索栏)
    }
  }, _defineProperty(_computed, "tableHeightSIXJ", function tableHeightSIXJ() {
    return document.body.clientHeight - 265 + 'px'; //增加一行按钮的高度(表头有搜索栏)
  }), _defineProperty(_computed, "tableHeightSJ", function tableHeightSJ() {
    return document.body.clientHeight - 270 + 'px'; //增加一行按钮的高度(表头有搜索栏)
  }), _defineProperty(_computed, "tableHeightSF", function tableHeightSF() {
    return document.body.clientHeight - 270 - 5 + 'px'; //增加一行按钮的高度
  }), _defineProperty(_computed, "tableHeightS", function tableHeightS() {
    return document.body.clientHeight - 270 - 8 + 'px'; //增加一行按钮的高度
  }), _defineProperty(_computed, "tableHeightSS", function tableHeightSS() {
    return document.body.clientHeight - 285 - 8 + 'px'; //增加一行按钮的高度
  }), _defineProperty(_computed, "tableHeightQ", function tableHeightQ() {
    return document.body.clientHeight - 280 + 'px'; //增加一行按钮、输入框的高度
  }), _defineProperty(_computed, "tableHeightP", function tableHeightP() {
    return document.body.clientHeight - 260 - 8 - 48 + 'px'; //增加一行按钮、输入框的高度
  }), _defineProperty(_computed, "tableHeightF", function tableHeightF() {
    return document.body.clientHeight - 232 + 'px'; //没有分页的高度
  }), _defineProperty(_computed, "tableHeightD", function tableHeightD() {
    return document.body.clientHeight - 179 + 'px'; //复合表头的高度
  }), _defineProperty(_computed, "tableHeightT", function tableHeightT() {
    return document.body.clientHeight - 440 + 'px'; //tabs下表格的高度
  }), _defineProperty(_computed, "tableHeightY", function tableHeightY() {
    return document.body.clientHeight - 450 + 'px'; //续保客户弹窗表格的高度
  }), _defineProperty(_computed, "tableHeightO", function tableHeightO() {
    return document.body.clientHeight - 175 + 'px'; //没有按钮的表格高度
  }), _defineProperty(_computed, "tableHeightL", function tableHeightL() {
    return document.body.clientHeight - 360 + 'px'; //对账详情的表格高度  对账单
  }), _defineProperty(_computed, "tableHeightZ", function tableHeightZ() {
    return document.body.clientHeight - 310 + 'px'; //对账详情的表格高度  结账单 
  }), _defineProperty(_computed, "containerHeight", function containerHeight() {
    return document.body.clientHeight - 85 + 'px'; //无表格的容器高度
  }), _defineProperty(_computed, "tableHeightE", function tableHeightE() {
    return document.body.clientHeight - 285 + 'px'; //增加一行按钮的高度
  }), _defineProperty(_computed, "tableHeightUC", function tableHeightUC() {
    return document.body.clientHeight - 530 + 'px'; // 用户中心——用户管理的高度
  }), _computed),
  methods: {
    collapse: function collapse() {
      this.headerCollapse = !this.headerCollapse;
    }
  },
  beforeRouteLeave: function beforeRouteLeave(to, form, next) {
    this.drawer = false;
    next();
  },
  activated: function activated() {
    // 获取快捷搜索区域
    var searchBox = document.getElementsByClassName('search-box');
    var height = searchBox[0] && searchBox[0].clientHeight;
    // 快捷搜索区域高度大于36px时，重新设置列表高度
    if (height > 36) {
      this.$nextTick(function () {
        var eltable = document.getElementsByClassName('el-table');
        if (eltable) {
          var _tableHeightJD = document.body.clientHeight - 223 - (height - 36) + 'px';
          eltable[0].style.height = _tableHeightJD;
        }
      });
    }
  }
};