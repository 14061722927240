var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "115px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业：", prop: "corpId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择企业",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.corpId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "corpId", $$v)
                                    },
                                    expression: "form.corpId",
                                  },
                                },
                                _vm._l(_vm.corpList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.corpName,
                                      value: item.corpId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "标签组名称：",
                                prop: "groupName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入标签组名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.groupName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "groupName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.groupName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排序：", prop: "orderBy" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入排序",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.orderBy = _vm.onInput(
                                      _vm.form.orderBy,
                                      0,
                                      9999,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.orderBy,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "orderBy",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.orderBy",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "must-star",
                              attrs: { label: "标签：" },
                            },
                            [
                              _c("div", [
                                _c(
                                  "table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { border: "1" },
                                  },
                                  [
                                    _c("colgroup", [
                                      _c("col", {
                                        staticStyle: { width: "40%" },
                                      }),
                                      _c("col", {
                                        staticStyle: { width: "40%" },
                                      }),
                                      _c("col"),
                                    ]),
                                    _c(
                                      "thead",
                                      {
                                        staticStyle: {
                                          "background-color": "#eeee",
                                        },
                                      },
                                      [
                                        _c(
                                          "tr",
                                          {
                                            staticStyle: {
                                              "font-size": "13px",
                                            },
                                          },
                                          [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("标签名")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("排序")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("操作")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.form.tags,
                                        function (item, index) {
                                          return _c("tr", { key: index }, [
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "40",
                                                        placeholder:
                                                          "请输入标签名",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value: item.name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "name",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression: "item.name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "11",
                                                        placeholder:
                                                          "请输入排序",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          item.orderBy =
                                                            _vm.onInput(
                                                              item.orderBy,
                                                              0,
                                                              9999,
                                                              0
                                                            )
                                                        },
                                                      },
                                                      model: {
                                                        value: item.orderBy,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "orderBy",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.orderBy",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("td", [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-circle-plus-outline",
                                                staticStyle: {
                                                  "font-size": "24px",
                                                  color: "#356ef6",
                                                },
                                                on: { click: _vm.addItem },
                                              }),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-remove-outline",
                                                staticStyle: {
                                                  "font-size": "24px",
                                                  color: "#ed6262",
                                                  "margin-left": "8px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteitem(index)
                                                  },
                                                },
                                              }),
                                            ]),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }