var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageContainerLoading,
          expression: "pageContainerLoading",
        },
      ],
      staticClass: "scanBox",
    },
    [
      _vm.orgInfo
        ? _c("div", [
            _vm.getInNow == 1
              ? _c("div", { staticClass: "orgDetails" }, [
                  _c(
                    "div",
                    { staticClass: "orgHeader" },
                    [
                      _c("div", [
                        _c("img", {
                          attrs: { src: _vm.qiyeweixinIcon, alt: "" },
                        }),
                        _c("div", { staticClass: "orgName" }, [
                          _c("div", [
                            _vm._v(_vm._s(_vm.orgInfo.corp.corpName)),
                          ]),
                          _c("div", [_vm._v(_vm._s(_vm.orgInfo.corp.corpId))]),
                        ]),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "getItNow",
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              _vm.getInNow = 2
                            },
                          },
                        },
                        [_vm._v("立即接入")]
                      ),
                    ],
                    1
                  ),
                  _c("div"),
                ])
              : _vm._e(),
            _vm.getInNow == 2
              ? _c("div", { staticClass: "accessContent" }, [
                  _c(
                    "div",
                    { staticClass: "stepsBox" },
                    [
                      _c(
                        "el-steps",
                        { attrs: { active: _vm.active, "align-center": "" } },
                        [
                          _c("el-step", { attrs: { title: "扫码授权代开发" } }),
                          _c("el-step", {
                            attrs: { title: "企业微信设置权限" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "scanAndFlow" }, [
                    _c("div", { staticClass: "erweima" }, [
                      _c("img", { attrs: { src: _vm.scanImg, alt: "" } }),
                      _c("div", [
                        _vm._v(
                          "如右图，点击【授权代开发】>【同意以上授权，并添加】"
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flow" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            src: _vm.step1Img,
                            "preview-src-list": _vm.srcList,
                          },
                        }),
                        _c("el-image", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            src: _vm.step2Img,
                            "preview-src-list": _vm.srcList,
                          },
                        }),
                        _c("el-image", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            src: _vm.step3Img,
                            "preview-src-list": _vm.srcList,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "bottom_button" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.completeSetup },
                        },
                        [_vm._v("完成设置")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.getInNow == 3
              ? _c("div", { staticClass: "accessContentOver" }, [
                  _c(
                    "div",
                    { staticClass: "finished" },
                    [
                      _c(
                        "el-steps",
                        {
                          attrs: {
                            active: _vm.factive,
                            "align-center": "",
                            "finish-status": "success",
                          },
                        },
                        [_c("el-step", { attrs: { title: "已完成" } })],
                        1
                      ),
                      _c("el-image", {
                        staticStyle: { width: "200px", "margin-top": "30px" },
                        attrs: {
                          src: _vm.step4Img,
                          "preview-src-list": _vm.finishList,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                height: "80vh",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
              },
            },
            [_vm._v(" 目前仅支持企微扫码授权角色 ")]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }