/**
 * 省市区维护接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  //  **********************************省市区维护************************************

  /**
  * 列表
  * @param {*} pageNum 当前页
  * @param {*} pageSize 每页显示条数
  * @param {*} data 内容
  */
  getDistrictsTreeList: function getDistrictsTreeList() {
    return request.get(getUrl("/districts/tree/all", 6));
  },
  /**
    * 新增地区
    * @param {*} data 内容
    */
  addDistricts: function addDistricts(data) {
    return request.post(getUrl("/districts", 6), data);
  },
  /**
   * 删除地区
   * @param {*} id
   */
  deleteDistricts: function deleteDistricts(id) {
    return request.delete(getUrl("/districts/".concat(id), 6));
  },
  /**
   * 获取区域对象（名称,类型）
   * @param {*} type
   */
  getProvincesAndCities: function getProvincesAndCities(type) {
    return request.get(getUrl("/districts/getByType?type=".concat(type), 6));
  },
  /**
   * 获取地区
   * @param {*} id
   */
  getDistricts: function getDistricts(id) {
    return request.get(getUrl("/districts/detail/".concat(id), 6));
  },
  /**
   * 更新地区
   * @param {*} id
   * @param {*} data
   */
  editDistricts: function editDistricts(id, data) {
    return request.put(getUrl("/districts/".concat(id), 6), data);
  },
  /**
   * 获取区域子列表(主键)
   * @param {*} id
   */
  getSubCities: function getSubCities(id) {
    return request.get(getUrl("/districts/getSubDistricts?districtId=".concat(id), 6));
  }
};
export default objApi;