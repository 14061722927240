var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "600px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "来源企业" } }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.form.sourceCorpName || "-")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "客户" } }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.form.externalUserName || "-")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "员工备注" } }, [
                            _c("div", [_vm._v(_vm._s(_vm.form.remark || "-"))]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入姓名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.externalUserRealName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "externalUserRealName",
                                      $$v
                                    )
                                  },
                                  expression: "form.externalUserRealName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "跟进分类" } }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.form.newTrackClassifyName || "-")
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "跟进状态" } }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.form.newTrackStatusDisplay || "-")
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "跟进内容" } }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.form.newTrackContent || "-")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择车型",
                                    filterable: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.modelName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "modelName", $$v)
                                    },
                                    expression: "form.modelName",
                                  },
                                },
                                _vm._l(_vm.modelList, function (item) {
                                  return _c("el-option", {
                                    key: item.itemCode,
                                    attrs: {
                                      label: item.itemName,
                                      value: item.itemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "购车日期" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  type: "date",
                                  placeholder: "请选择购车日期",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.vehiclePurchaseTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "vehiclePurchaseTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.vehiclePurchaseTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "支付方式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择支付方式",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.paymentType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "paymentType", $$v)
                                    },
                                    expression: "form.paymentType",
                                  },
                                },
                                _vm._l(_vm.paymentTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.itemCode,
                                    attrs: {
                                      label: item.itemName,
                                      value: item.itemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否营运车" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择是否营运车",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.isOperatingVehicles,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "isOperatingVehicles",
                                        $$v
                                      )
                                    },
                                    expression: "form.isOperatingVehicles",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: true },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: false },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "下单手机号" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入下单手机号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "phone", $$v)
                                  },
                                  expression: "form.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车牌号" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入车牌号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.plateNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "plateNo", $$v)
                                  },
                                  expression: "form.plateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "年龄段" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择年龄段",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.ageGroup,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "ageGroup", $$v)
                                    },
                                    expression: "form.ageGroup",
                                  },
                                },
                                _vm._l(_vm.ageGroupList, function (item) {
                                  return _c("el-option", {
                                    key: item.itemCode,
                                    attrs: {
                                      label: item.itemName,
                                      value: item.itemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车架号" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入车架号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.vin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vin", $$v)
                                  },
                                  expression: "form.vin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "购买城市",
                                prop: "paymentCityCode",
                              },
                            },
                            [
                              _c("el-cascader", {
                                ref: "address",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "请选择所在省市",
                                  options: _vm.regionOptions,
                                },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.form.paymentCityCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "paymentCityCode", $$v)
                                  },
                                  expression: "form.paymentCityCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户所属企业" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.form.corpFullName || "-")),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "性别" } }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.form.genderDisplay || "-")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "所属员工" } }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.form.memberName || "-")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "来源" } }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.form.addWayDisplay || "-")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "标签" } }, [
                            _vm.form.tagsList && _vm.form.tagsList.length != 0
                              ? _c("div", [_vm._v(_vm._s(_vm.item.tagName))])
                              : _c("div", [_vm._v(_vm._s("-"))]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "所在群聊" } }, [
                            _vm.form.groupChatList &&
                            _vm.form.groupChatList.length != 0
                              ? _c("div", [
                                  _vm._v(_vm._s(_vm.item.groupChatName)),
                                ])
                              : _c("div", [_vm._v(_vm._s("-"))]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "添加时间" } }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.form.addTime || "-")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "添加时长(天)" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.form.addTimeDays || "-")),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "10px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.saveClicked },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }