import _createForOfIteratorHelper from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import openPlatformRequest from '@/api/openPlatform';
import groupChatLabelApi from '@/api/groupChatLabel';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    dialogType: {
      type: String,
      default: {}
    },
    corpList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    labelId: {
      type: String,
      default: {}
    }
  },
  data: function data() {
    return {
      addForm: {
        groupChatId: [],
        //企业微信群聊id;
        groupChatTagId: [] //群聊标签外键id;
      },

      searchVal: '',
      tagSearchVal: '',
      checkList: [],
      groupChatLists: [],
      formLoading: false,
      formRules: {
        corpId: [{
          required: true,
          message: '请选择企业',
          trigger: 'change'
        }],
        groupChatId: [{
          required: true,
          message: '请选择群聊',
          trigger: 'change'
        }]
      },
      labelData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      allLabelData: [],
      allGroupChatLists: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.addForm.resetFields();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData(); //初始化数据
  },

  methods: {
    // 模糊匹配
    search: function search() {
      var allGroupChatsLists = _.cloneDeep(this.allGroupChatLists) || [];
      if (this.searchVal) {
        var gList = [];
        var _iterator = _createForOfIteratorHelper(allGroupChatsLists),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var tItem = _step.value;
            if (tItem.groupChatName.indexOf(this.searchVal) > -1) {
              gList.push(tItem);
            }
            ;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        ;
        this.groupChatLists = gList || allGroupChatsLists;
      } else {
        this.groupChatLists = allGroupChatsLists;
      }
    },
    // 模糊匹配
    tagSearch: function tagSearch() {
      var allLabelsData = _.cloneDeep(this.allLabelData) || [];
      if (this.tagSearchVal) {
        var labelList = [];
        var _iterator2 = _createForOfIteratorHelper(allLabelsData),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var lItem = _step2.value;
            if (lItem.label.indexOf(this.tagSearchVal) > -1) {
              labelList.push(lItem);
            }
            ;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
        ;
        this.labelData = labelList || allLabelsData;
      } else {
        this.labelData = allLabelsData;
      }
    },
    getGroupChatList: function getGroupChatList(val) {
      var _this = this;
      groupChatLabelApi.getGroupChatListByCorpId(val).then(function (res) {
        if (res.code == 200 && res.data) {
          _this.groupChatLists = res.data || [];
          _this.allGroupChatLists = res.data || [];
        }
      });
    },
    getGroupChatTagClassificationList: function getGroupChatTagClassificationList() {
      var _this2 = this;
      groupChatLabelApi.getGroupChatTagClassificationsList().then(function (res) {
        if (res.code == 200) {
          var resData = res.data || [];
          var tagData = [];
          var _iterator3 = _createForOfIteratorHelper(resData),
            _step3;
          try {
            for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
              var i = _step3.value;
              if (i.groupChatTagVOList && i.groupChatTagVOList.length != 0) {
                var _iterator4 = _createForOfIteratorHelper(i.groupChatTagVOList),
                  _step4;
                try {
                  for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                    var VOItem = _step4.value;
                    VOItem.id = VOItem.id;
                    VOItem.label = VOItem.tagName || '';
                  }
                } catch (err) {
                  _iterator4.e(err);
                } finally {
                  _iterator4.f();
                }
                ;
              }
              ;
              tagData.push({
                id: i.id,
                label: i.tagClassificationName || '',
                children: i.groupChatTagVOList || []
              });
            }
          } catch (err) {
            _iterator3.e(err);
          } finally {
            _iterator3.f();
          }
          ;
          _this2.labelData = tagData || [];
          _this2.allLabelData = tagData || [];
        }
      });
    },
    //初始化数据
    initData: function initData() {
      var _this3 = this;
      this.getGroupChatTagClassificationList();
      this.searchVal = '';
      if (this.dialogType == 'add') {
        this.addForm = {
          groupChatId: [],
          //企业微信群聊id;
          groupChatTagId: [] //群聊标签外键id;
        };
      } else {
        openPlatformRequest.getOneRouteGroup({
          id: this.labelId
        }).then(function (res) {
          if (res.code == 200) {
            _this3.addForm = res.data;
          }
        });
      }
    },
    //保存数据
    save: function save() {
      var _this4 = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          if (_this4.dialogType == 'add') {
            //新增
            var nodeIds = _this4.$refs.tree.getCheckedNodes(true, true) || [];
            // console.log('nodeIds', nodeIds)
            if (nodeIds.length == 0) {
              _this4.$message.warning('请至少选择一个标签');
              return;
            }
            ;
            var groupChatTagIdList = [];
            var _iterator5 = _createForOfIteratorHelper(nodeIds),
              _step5;
            try {
              for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
                var nItem = _step5.value;
                if (nItem.id) {
                  groupChatTagIdList.push(nItem.id);
                }
                ;
              }
            } catch (err) {
              _iterator5.e(err);
            } finally {
              _iterator5.f();
            }
            ;
            var data = {
              "groupChatIds": _this4.addForm.groupChatId || [],
              "groupChatTagIds": groupChatTagIdList || []
            };
            // console.log(data);
            // return
            groupChatLabelApi.createGroupChatTag(data).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this4.formLoading = false;
                _this4.$message.success(res.msg);
                _this4.closePage(false);
              }
              ;
            });
          }
        }
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$refs.addForm.resetFields();
      this.$emit('closePage', boolean);
    }
  }
};