/**
 * SOP模版
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * SOP模版列表(分页)
   */
  getSopTemplateSearch: function getSopTemplateSearch(data) {
    return request.get(getUrl("/marketing/sop/template/search", 4), {
      params: data
    });
  },
  /**
   * SOP模版列表(不分页)
   */
  getSopTemplateList: function getSopTemplateList(data) {
    return request.get(getUrl("/marketing/sop/template/list", 4), {
      params: data
    });
  },
  /**
   * SOP模版--详情
   */
  sopTemplateDetail: function sopTemplateDetail(id) {
    return request.get(getUrl("/marketing/sop/template/get?id=".concat(id), 4));
  },
  /**
   * SOP模版--创建
   */
  sopTemplateCreate: function sopTemplateCreate(data) {
    return request.post(getUrl("/marketing/sop/template/create", 4), data);
  },
  /**
   * SOP模版--删除
   */
  sopTemplateDelete: function sopTemplateDelete(id) {
    return request.post(getUrl("/marketing/sop/template/delete?id=".concat(id), 4));
  }
};
export default objApi;