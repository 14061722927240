var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { staticClass: "collapse-item-style", attrs: { name: "1" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    staticStyle: { "line-height": "25px" },
                  },
                  [_c("FormLabel", { attrs: { "form-label": "用户信息" } })],
                  1
                ),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 注册日期：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 注册时使用设备：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 注册时使用操作系统：- ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { staticClass: "collapse-item-style", attrs: { name: "2" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    staticStyle: { "line-height": "25px" },
                  },
                  [
                    _c("FormLabel", {
                      attrs: { "form-label": "APP使用行为信息" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 用户活跃度：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 访问系统高频时段：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 近30天活跃天数：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 最近一次访问时间：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 最近一次访问设备：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 最近一次使用操作系统：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 近一月联系在线客服：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 近一月发起投诉：- ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { staticClass: "collapse-item-style", attrs: { name: "3" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    staticStyle: { "line-height": "25px" },
                  },
                  [
                    _c("FormLabel", {
                      attrs: { "form-label": "产品购买行为信息" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 近一年车险报价：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 近一年车险投保：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 近一年购买保值换新：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 近一年购买整车延保：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 近一年购买轮胎保障：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 近一年购买维修补偿：- ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { staticClass: "collapse-item-style", attrs: { name: "4" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    staticStyle: { "line-height": "25px" },
                  },
                  [
                    _c("FormLabel", {
                      attrs: { "form-label": "服务核销行为信息" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 使用卡券次数：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 车险理赔次数：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 保值换新理赔次数：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 整车延保理赔次数：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 轮胎保障理赔次数：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 维修补偿理赔次数：- ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { staticClass: "collapse-item-style", attrs: { name: "5" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    staticStyle: { "line-height": "25px" },
                  },
                  [
                    _c("FormLabel", {
                      attrs: { "form-label": "活动参与行为信息" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 访问活动页面时间：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 活动页停留时长：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 引导成交额：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 访问活动渠道：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 活动引导成功成交单数：- ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { staticClass: "collapse-item-style", attrs: { name: "6" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    staticStyle: { "line-height": "25px" },
                  },
                  [
                    _c("FormLabel", {
                      attrs: { "form-label": "车辆使用行为信息" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 开车时段：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 开车时长：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 开车高频地区：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 开车原因：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 驾照类别：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 开车频次：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 车辆违章情况：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 碰撞事故：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 车辆维修情况：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 酒驾经历：- ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 疲劳驾驶：- ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }