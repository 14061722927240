var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/welcome" },
                },
                [
                  _c("h1", { staticClass: "sidebar-title" }, [
                    _c("img", {
                      staticClass: "sidebar-logo",
                      attrs: { src: require("@/assets/zxlogo/zxlogo.png") },
                    }),
                  ]),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/welcome" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "sidebar-logo-s",
                        attrs: { src: require("@/assets/zxlogo/zxlogo.png") },
                      }),
                      _c(
                        "span",
                        { staticStyle: { "font-size": "20px", color: "#fff" } },
                        [_vm._v(_vm._s(_vm.title))]
                      ),
                    ]
                  ),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }