//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Page404',
  computed: {
    message: function message() {
      return '文章下架了，可能是链接过期或参数错误...';
    }
  }
};