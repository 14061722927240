var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "素材类型：", prop: "materialGroup" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择素材类型",
                      },
                      model: {
                        value: _vm.form.materialGroup,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "materialGroup", $$v)
                        },
                        expression: "form.materialGroup",
                      },
                    },
                    _vm._l(_vm.materialGroups, function (item) {
                      return _c("el-option", {
                        key: item.itemCode,
                        attrs: { label: item.itemName, value: item.itemCode },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "文章类型：",
                    prop: "materialClassification",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择文章类型",
                      },
                      model: {
                        value: _vm.form.materialClassification,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "materialClassification", $$v)
                        },
                        expression: "form.materialClassification",
                      },
                    },
                    _vm._l(_vm.materialClassificationLists, function (item) {
                      return _c("el-option", {
                        key: item.itemCode,
                        attrs: { label: item.itemName, value: item.itemName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "文章标题：", prop: "materialTitle" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "40",
                          placeholder: "请输入文章标题",
                        },
                        model: {
                          value: _vm.form.materialTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "materialTitle", $$v)
                          },
                          expression: "form.materialTitle",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "文章摘要：",
                    prop: "materialBriefInformation",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          rows: "4",
                          "show-word-limit": "",
                          type: "textarea",
                          placeholder: "请输入文章摘要",
                        },
                        model: {
                          value: _vm.form.materialBriefInformation,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "materialBriefInformation", $$v)
                          },
                          expression: "form.materialBriefInformation",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "文章标签：", prop: "associatedLabels" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "small",
                            placeholder: "请选择标签",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.form.associatedLabels,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "associatedLabels", $$v)
                            },
                            expression: "form.associatedLabels",
                          },
                        },
                        _vm._l(_vm.associatedLabelList, function (item) {
                          return _c("el-option", {
                            key: item.labelId,
                            attrs: {
                              label: item.labelContent,
                              value: item.labelId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "flex-direction": "column",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            display: "flex",
                            "flex-wrap": "wrap",
                            "flex-direction": "row",
                            "justify-content": "space-between",
                            "align-items": "center",
                          },
                        },
                        [
                          _vm._v(" 全部标签： "),
                          _vm.inputVisible
                            ? _c("el-input", {
                                ref: "saveTagInput",
                                staticClass: "input-new-tag",
                                staticStyle: { width: "400px" },
                                attrs: {
                                  size: "small",
                                  clearable: "",
                                  placeholder: "请输入标签",
                                  maxlength: "40",
                                  "show-word-limit": "",
                                },
                                on: { blur: _vm.handleInputConfirm },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleInputConfirm($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.associatedLabelsInput,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "associatedLabelsInput",
                                      $$v
                                    )
                                  },
                                  expression: "form.associatedLabelsInput",
                                },
                              })
                            : _c(
                                "el-button",
                                {
                                  staticClass: "button-new-tag addButton",
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    plain: "",
                                  },
                                  on: { click: _vm.showInput },
                                },
                                [_vm._v("+ 新增标签")]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "el-link",
                        {
                          staticClass: "button-new-tag toggle",
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.isView = !_vm.isView
                            },
                          },
                        },
                        [
                          _vm.isView
                            ? _c("i", { staticClass: "el-icon-arrow-up" })
                            : _c("i", { staticClass: "el-icon-arrow-down" }),
                          _vm._v(
                            " " + _vm._s(_vm.isView ? "收起" : "展开") + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: { display: "flex", "flex-wrap": "wrap" },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: _vm.isView ? "show" : "hide",
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "wrap",
                              },
                            },
                            _vm._l(
                              _vm.associatedLabelList,
                              function (tag, index) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: tag.labelId,
                                    staticClass: "elTag",
                                    attrs: {
                                      size: "lag",
                                      type: "info",
                                      closable: "",
                                      "disable-transitions": false,
                                    },
                                    on: {
                                      close: function ($event) {
                                        return _vm.handleClose(tag, index)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(tag.labelContent) + " ")]
                                )
                              }
                            ),
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-form-item", { attrs: { label: "文章封面：" } }, [
                _vm.form.coverImage
                  ? _c(
                      "div",
                      { staticClass: "coverBox" },
                      [
                        _c("img", {
                          staticClass: "coverImg",
                          attrs: { src: _vm.form.coverImage, alt: "" },
                        }),
                        _c(
                          "el-upload",
                          {
                            staticClass: "coverDel",
                            attrs: {
                              limit: 2,
                              action: "",
                              multiple: "",
                              "show-file-list": false,
                              "http-request": function (val) {
                                return _vm.uploadFileReplace(val)
                              },
                              "before-upload": function (val) {
                                return _vm.beforeUploadReplace(val)
                              },
                              "file-list": _vm.fileList,
                              accept: _vm.uploadType,
                              "list-type": "text",
                            },
                          },
                          [_c("div", [_vm._v("更换封面")])]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              limit: 2,
                              action: "",
                              multiple: "",
                              "show-file-list": false,
                              "http-request": function (val) {
                                return _vm.uploadFile(val)
                              },
                              "before-upload": function (val) {
                                return _vm.beforeUpload(val)
                              },
                              "file-list": _vm.fileList,
                              accept: _vm.uploadType,
                              "list-type": "text",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-plus",
                              staticStyle: { "font-size": "24px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }