/**
 * 群标签管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // *************************群标签****************************
  /**
   * 标签分页查询
   * @param {*} data 参数
   */
  getGroupChatTagClassificationList: function getGroupChatTagClassificationList(data) {
    return request.get(getUrl("/groupChat/tag/classification/search", 4), {
      params: data
    });
  },
  /**
   * 标签不分页查询
   * @param {*} data 参数
   */
  getGroupChatTagClassificationsList: function getGroupChatTagClassificationsList(data) {
    return request.get(getUrl("/groupChat/tag/classification/list", 4), {
      params: data
    });
  },
  /**
   * 创建标签分类和标签
   * @param {*} data 参数
   */
  createGroupChatTagClassification: function createGroupChatTagClassification(data) {
    return request.post(getUrl("/groupChat/tag/classification/create", 4), data);
  },
  /**
   * 删除标签分类和标签
   * @param {*} data 参数
   */
  deleteGroupChatTagClassification: function deleteGroupChatTagClassification(id) {
    return request.post(getUrl("/groupChat/tag/classification/delete?id=".concat(id), 4));
  },
  /**
   * 获取标签分类和标签详情
   * @param {*} id 参数
   */
  getGroupChatTagClassificationDetail: function getGroupChatTagClassificationDetail(id) {
    return request.get(getUrl("/groupChat/tag/classification/get?id=".concat(id), 4));
  },
  /**
   * 更新标签分组
   * @param {*} id 参数
   */
  updateGroupChatTagClassification: function updateGroupChatTagClassification(id, data) {
    return request.post(getUrl("/groupChat/tag/classification/update?id=".concat(id), 4), data);
  },
  /**
   * 创建单个标签
   * @param {*} id 参数
   */
  createSingleGroupChatTag: function createSingleGroupChatTag(data) {
    return request.post(getUrl("/groupChat/tag/create", 4), data);
  },
  /**
   * 更新单个标签
   * @param {*} id 参数
   */
  updateSingleGroupChatTag: function updateSingleGroupChatTag(id, data) {
    return request.post(getUrl("/groupChat/tag/update?id=".concat(id), 4), data);
  },
  /**
   * 删除单个标签
   * @param {*} id 参数
   */
  deleteSingleGroupChatTag: function deleteSingleGroupChatTag(id) {
    return request.post(getUrl("/groupChat/tag/delete?id=".concat(id), 4));
  },
  //   *************************群打标签****************************
  /**
   * 通过企业id获取群聊列表
   * @param {*} data 参数
   */
  getGroupChatListByCorpId: function getGroupChatListByCorpId(corpId) {
    return request.get(getUrl("/wecomGroupchat/getGroupChatByCorpId?corpId=".concat(corpId), 4));
  },
  /**
   * 群标签分页查询
   * @param {*} data 参数
   */
  getGroupChatTagList: function getGroupChatTagList(data) {
    return request.get(getUrl("/groupChat/tag/search", 4), {
      params: data
    });
  },
  /**
   * 创建群标签
   * @param {*} data 参数
   */
  createGroupChatTag: function createGroupChatTag(data) {
    return request.post(getUrl("/groupChat/tag/detail/create", 4), data);
  },
  /**
   * 通过标签获取群列表(不分页)
   * @param {*} data 参数
   */
  getGroupByChatTagList: function getGroupByChatTagList(data) {
    return request.post(getUrl("/groupChat/tag/detail/groupChatTag/list/get", 4), data);
  },
  /**
   * 通过标签获取群列表(分页)
   * @param {*} data 参数
   */
  getGroupByChatTagSearch: function getGroupByChatTagSearch(data) {
    return request.get(getUrl("/groupChat/tag/detail/search", 4), {
      params: data
    });
  },
  /**
   * 通过标签获取群列表
   * @param {*} data 参数
   */
  batchDeleteGroupChatTag: function batchDeleteGroupChatTag(data) {
    return request.post(getUrl("/groupChat/tag/detail/batchDelete", 4), data);
  }
};
export default objApi;