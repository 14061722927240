import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import roleDialog from './dialog/roleDialog';
import sysManageApi from '@/api/sysManage';
import { commonExport } from '@/utils/common';
export default {
  name: 'RoleManage',
  components: {
    Pagination: Pagination,
    roleDialog: roleDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      form: {},
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      loading: false,
      fileList: [],
      showDialog: false,
      itemId: ''
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '角色管理',
        exportUrl: '/tp-core-service/roles',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 是否启用
    updateItem: function updateItem(item) {
      var _this = this;
      sysManageApi.recentUpdate(item).then(function (res) {
        if (res.code == RESPONSE_SUCCESS) {
          _this.$message({
            type: 'success',
            message: res.msg
          });
        }
        ;
      }).catch(function (err) {});
    },
    // 新增
    addRole: function addRole(type) {
      this.opType = type;
      this.showDialog = true;
    },
    // 编辑
    editRole: function editRole(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.itemId = item.roleId.toString();
      this.$forceUpdate();
    },
    // 删除
    deleteRole: function deleteRole(item) {
      var _this2 = this;
      this.$confirm('您确定要将该角色删除吗？', '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        sysManageApi.deleteRoles(item.roleId).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this2.$message({
              type: 'success',
              message: res.msg
            });
            _this2.loadData();
          }
          ;
        }).catch(function (err) {});
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      sysManageApi.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this3.loading = false;
        if (res.data) {
          _this3.tableData = res.data.list || [];
          _this3.total = res.data.page ? res.data.page.recordsTotal : 0;
        }
        ;
      });
    }
  }
};