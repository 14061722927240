//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "OtherChat",
  props: {
    time: {
      type: String,
      default: ""
    },
    avatar: {
      type: String,
      default: ""
    },
    con: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};