import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import axios from "axios";
import { Message, Notification } from "element-ui";
import store from "@/store";
import router from "@/router/modules";
import { showLoading, hideLoading } from "@/utils/globalLoading";
// 创建axios实例
var service = axios.create({
  // baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 3 * 60000 // 请求超时时间
});

var openPostRequest = true;
var configChange = null;

// request拦截器
service.interceptors.request.use(function (config) {
  var requestURL = config.url;
  var token = localStorage.getItem("token") || "";
  config.headers["authorization"] = token.replace(/"/g, "");
  config.headers["x-appid"] = "FXZZOjtm";
  var url = requestURL && requestURL.indexOf("?") !== -1 ? requestURL.split("?")[0] : requestURL;
  //防抖接口白名单
  var whiteList = ["".concat(process.env.VUE_APP_APIDOMAIN, "/tp-auth-service/captcha/getImage"), "".concat(process.env.VUE_APP_APIDOMAIN, "/biz-scrm-service/wecom/auth/scan"), "".concat(process.env.VUE_APP_APIDOMAIN, "/biz-scrm-service/wecomMember/treeQuery"), "".concat(process.env.VUE_APP_APIDOMAIN, "/biz-cms-service/knowledge/getByGuid"), "".concat(process.env.VUE_APP_APIDOMAIN, "/biz-scrm-service/wecomDepartment/departmentTree")];
  // if (config.method.toLowerCase() === 'get') {
  //     if (_.indexOf(whiteList, url)) {
  //         config.headers['authorization'] = null;
  //     }
  // }
  if (config.method.toLowerCase() === "post" || config.method.toLowerCase() === "put") {
    if (_.indexOf(whiteList, url) == -1 && config.headers.showLoading) {
      showLoading(config.headers && config.headers.loadingText || "");
      configChange = config;
    }
  }
  if (config.method.toLowerCase() === "post" && _.indexOf(whiteList, url) == -1 || config.method.toLowerCase() === "put") {
    if (openPostRequest) {
      openPostRequest = false;
      return config;
    } else {
      // axios.cancelToken();
    }
  } else {
    return config;
  }
}, function (error) {
  Promise.reject(error);
});
// response 拦截器
service.interceptors.response.use(function (response) {
  setTimeout(function () {
    if (response.config.method.toLowerCase() === "post" || response.config.method.toLowerCase() === "put") {
      openPostRequest = true;
    }
  }, 1000);
  // 关闭loading
  if (configChange) {
    hideLoading();
  }
  if (response) {
    var requestURL = response.config.url;
    if (requestURL && requestURL.indexOf("job/execTask") != -1) {
      if (response.data.code == 400) {
        Notification({
          type: "warning",
          title: "提示",
          message: response.data.msg,
          duration: 0
        });
        return;
      }
    }
  }
  // 网络请求响应
  if (response.status != RESPONSE_SUCCESS) {
    var errMsg = response.status == "400" ? response.msg : "发生未知的异常。重试后无效，请联系系统客服。";
    Message({
      dangerouslyUseHTMLString: true,
      message: errMsg,
      type: response.status == "500" ? "error" : "warning",
      duration: 5 * 1000,
      customClass: "zZindex"
    });
    return response;
  } else {
    var res = response.data;
    if (res.code == undefined) {
      // 文件下载，没有code
      return res;
    } else {
      // code 非'200'
      var requestUrl = response.request.responseURL;
      if (res.code != RESPONSE_SUCCESS && res.code != "401" && res.code != "403") {
        var _errMsg = res.msg;
        if (res.code == "500") {
          _errMsg = "发生未知的异常。重试后无效，请联系系统客服。";
        }
        if (requestUrl && requestUrl.indexOf("/insure/quote/getProposal") == -1 && requestUrl.indexOf("/insCorpApiConfig/getFunctionFeature") == -1) {
          Message({
            dangerouslyUseHTMLString: true,
            message: _errMsg,
            type: res.code == "500" ? "error" : "warning",
            duration: 5 * 1000,
            customClass: "zZindex"
          });
        }
      } else {
        if (res.code == "401" || res.code == "403") {
          console.log("requestUrl", requestUrl);
          console.log("requestUrl", requestUrl.indexOf("/knowledge/getKnowledge"));
          // if(){}
          if (requestUrl && requestUrl.indexOf("/knowledge/getKnowledge") !== -1) {
            router.replace({
              path: "/403"
            });
          } else {
            if (requestUrl && requestUrl.indexOf("/token/refresh") == -1 && requestUrl.indexOf("/suppliers/getByType") == -1) {
              Message({
                dangerouslyUseHTMLString: true,
                message: "您的登录状态已过期，请重新登录！",
                type: "warning",
                duration: 3 * 1000,
                customClass: "zZindex"
              });
            }
            store.dispatch("user/logout");
            store.dispatch("tagsView/delAllCachedViews", router);
            if (navigator.userAgent.indexOf("MicroMessenger") != -1) {
              if (navigator.userAgent.indexOf("wxwork") != -1) {
                // 企业微信
                console.log("企业微信");
                return;
              } else {
                // 微信
              }
            } else {
              // 非微信
              router.replace({
                path: "/login"
              });
            }
          }
        }
      }
      return res;
    }
  }
}, function (error) {
  setTimeout(function () {
    openPostRequest = true;
  }, 2100);
  // 关闭loading
  if (configChange) {
    hideLoading();
  }
  return Promise.reject(error);
});
export default service;