/**
 * 微信配置
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 
   * 获取程序类型为开放平台列表数据
   */
  getOpenPlatformList: function getOpenPlatformList() {
    return request.get(getUrl("/wechat/config/getOpenPlatformList", 4));
  },
  /**
   * 
   * 获取程序类型为开放平台列表数据
   */
  getConfigList: function getConfigList(data) {
    return request.get(getUrl("/wechat/config/search", 4), {
      params: data
    });
  },
  /**
   * 
   * 新增微信配置
   */
  createConfig: function createConfig(data) {
    return request.post(getUrl("/wechat/config/create", 4), data);
  }
};
export default objApi;