import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import messageApi from '@/api/messagePlatform';
export default {
  data: function data() {
    return {
      tableData: [],
      total: 0,
      visible: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.loadData();
    this.$Bus.$on('loadData', function (res) {
      _this.loadData();
    });
  },
  methods: {
    // 获取详情
    getDetail: function getDetail(item) {
      this.visible = false;
      this.$router.push({
        path: '/messagePlatform/msgDetailPage',
        query: {
          messageId: item.messageId
        }
      });
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      var params = {
        readed: false,
        pageNum: 1,
        pageSize: 99999,
        receiverUserId: this.userInfo.userId || null
      };
      messageApi.getMessageList(params).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data.list || [];
          // 总条数
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      });
    },
    // 查看全部
    viewDetail: function viewDetail() {
      this.visible = false;
      this.$router.push('/myMessageTable');
    }
  }
};