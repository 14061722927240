var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageContainerLoading,
              expression: "pageContainerLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 120px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "sopTemplateForm",
                        attrs: {
                          model: _vm.sopTemplateForm,
                          rules: _vm.formRules,
                          "label-width": "140px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.sopTitle,
                              callback: function ($$v) {
                                _vm.sopTitle = $$v
                              },
                              expression: "sopTitle",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "模版名称" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "模版名称：",
                                              prop: "sopTemplateName",
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "请输入模版名称",
                                                  trigger: "change",
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                maxlength: "40",
                                                placeholder: "请输入模版名称",
                                              },
                                              model: {
                                                value:
                                                  _vm.sopTemplateForm
                                                    .sopTemplateName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.sopTemplateForm,
                                                    "sopTemplateName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "sopTemplateForm.sopTemplateName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "消息内容：",
                                              prop: "content",
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "请输入消息内容",
                                                  trigger: "change",
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                maxlength: "200",
                                                rows: "4",
                                                "show-word-limit": "",
                                                type: "textarea",
                                                placeholder: "请输入消息内容",
                                              },
                                              model: {
                                                value:
                                                  _vm.sopTemplateForm.content,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.sopTemplateForm,
                                                    "content",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "sopTemplateForm.content",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeSetting,
                              callback: function ($$v) {
                                _vm.activeSetting = $$v
                              },
                              expression: "activeSetting",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "添加素材" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  _vm._l(
                                    _vm.sopTemplateForm
                                      .sopTemplateDetailSaveParams,
                                    function (taskItem, taskIndex) {
                                      return _c(
                                        "el-col",
                                        {
                                          key: taskIndex,
                                          staticClass: "colCard",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "素材类型：",
                                                        prop:
                                                          "sopTemplateDetailSaveParams" +
                                                          "[" +
                                                          taskIndex +
                                                          "]" +
                                                          ".materialType",
                                                        rules: [
                                                          {
                                                            required: true,
                                                            message:
                                                              "请选择素材类型",
                                                            trigger: "change",
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            clearable: "",
                                                            filterable: "",
                                                            placeholder:
                                                              "请选择素材类型",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeGetMaterialGroup(
                                                                $event,
                                                                taskIndex
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              taskItem.materialType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                taskItem,
                                                                "materialType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "taskItem.materialType",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.materialGroupList,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.itemCode,
                                                                attrs: {
                                                                  label:
                                                                    item.itemName,
                                                                  value:
                                                                    item.itemCode,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          taskItem.materialType == "ARTICLE"
                                            ? _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "消息类型：",
                                                            prop:
                                                              "sopTemplateDetailSaveParams" +
                                                              "[" +
                                                              taskIndex +
                                                              "]" +
                                                              ".msgType",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择素材类型",
                                                                trigger:
                                                                  "change",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                filterable: "",
                                                                placeholder:
                                                                  "请选择消息类型",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeMsgType(
                                                                      $event,
                                                                      taskIndex
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  taskItem.msgType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      taskItem,
                                                                      "msgType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "taskItem.msgType",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.articleTypeList,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.itemCode,
                                                                    attrs: {
                                                                      label:
                                                                        item.itemName,
                                                                      value:
                                                                        item.itemCode,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          taskItem.materialType == "FILE"
                                            ? _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "消息类型：",
                                                            prop:
                                                              "sopTemplateDetailSaveParams" +
                                                              "[" +
                                                              taskIndex +
                                                              "]" +
                                                              ".msgType",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择素材类型",
                                                                trigger:
                                                                  "change",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                filterable: "",
                                                                placeholder:
                                                                  "请选择消息类型",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeMsgType(
                                                                      $event,
                                                                      taskIndex
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  taskItem.msgType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      taskItem,
                                                                      "msgType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "taskItem.msgType",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.fileTypeList,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.itemCode,
                                                                    attrs: {
                                                                      label:
                                                                        item.itemName,
                                                                      value:
                                                                        item.itemCode,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.sopTemplateForm
                                            .sopTemplateDetailSaveParams[
                                            taskIndex
                                          ]["materialType"] == "ARTICLE"
                                            ? _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "素材列表：",
                                                            prop:
                                                              "sopTemplateDetailSaveParams" +
                                                              "[" +
                                                              taskIndex +
                                                              "]" +
                                                              ".materialId",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择素材类型",
                                                                trigger:
                                                                  "change",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                filterable: "",
                                                                placeholder:
                                                                  "请选择素材列表",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getMaterials(
                                                                      $event,
                                                                      taskIndex
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  taskItem.materialId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      taskItem,
                                                                      "materialId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "taskItem.materialId",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.materialsList,
                                                              function (
                                                                item,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: index,
                                                                    attrs: {
                                                                      label:
                                                                        item.materialTitle,
                                                                      value:
                                                                        item.materialId,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.sopTemplateForm
                                            .sopTemplateDetailSaveParams[
                                            taskIndex
                                          ]["materialType"] == "FILE"
                                            ? _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "选择文件：",
                                                            prop:
                                                              "sopTemplateDetailSaveParams" +
                                                              "[" +
                                                              taskIndex +
                                                              "]" +
                                                              ".storageUrl",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择文件",
                                                                trigger:
                                                                  "change",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                plain: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openImgListDialog(
                                                                      taskIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " 素材库选择"
                                                              ),
                                                            ]
                                                          ),
                                                          taskItem.storageUrl
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                    "margin-top":
                                                                      "10px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-image",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100px",
                                                                          height:
                                                                            "auto",
                                                                          "background-color":
                                                                            "#FFF",
                                                                        },
                                                                      attrs: {
                                                                        src: taskItem.storageUrl,
                                                                        "preview-src-list":
                                                                          [
                                                                            taskItem.storageUrl,
                                                                          ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    { attrs: { label: "" } },
                                                    [
                                                      taskIndex ==
                                                      _vm.sopTemplateForm
                                                        .sopTemplateDetailSaveParams
                                                        .length -
                                                        1
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                plain: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.addSopTemplateDetailSaveParamsItem,
                                                              },
                                                            },
                                                            [_vm._v(" 新增")]
                                                          )
                                                        : _vm._e(),
                                                      _vm.sopTemplateForm
                                                        .sopTemplateDetailSaveParams &&
                                                      _vm.sopTemplateForm
                                                        .sopTemplateDetailSaveParams
                                                        .length > 1
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "danger",
                                                                plain: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delTaskMaterialItem(
                                                                      taskIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" 删除")]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "footer_box",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "footer-btn" }, [
                _c(
                  "div",
                  { staticClass: "footer-btn-list" },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "mini" }, on: { click: _vm.closePage } },
                      [_vm._v("返回")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.saveClicked },
                      },
                      [_vm._v(" 保存编辑 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showImgListDialog,
            "close-on-click-modal": false,
            width: "1000px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImgListDialog = $event
            },
            handleClose: function ($event) {
              _vm.showImgListDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("图片素材库")]
              ),
            ]
          ),
          _c("ImgDialog", {
            attrs: { refresh: _vm.showImgListDialog },
            on: {
              closeDialog: _vm.closeDialog,
              confrimDialog: _vm.confrimDialog,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }