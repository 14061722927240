import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 数据交互平台接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // ******************************数据类型配置***************************
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  getDuipdataconfigList: function getDuipdataconfigList(pageNum, pageSize, data) {
    return request.get(getUrl("/duip/dataconfig/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 5), {
      params: data
    });
  },
  /**
   * 不分页列表
   */
  getAllDuipdataconfigList: function getAllDuipdataconfigList() {
    return request.get(getUrl("/duip/dataconfig/list", 5));
  },
  /**
   * 新增数据
   * @param {*} data 内容
   */
  addData: function addData(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/duip/dataconfig/create", 5), data, {
      headers: headers
    });
  },
  /**
   * 修改数据
   * @param {*} data 内容
   */
  updateData: function updateData(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/duip/dataconfig/update", 5), data, {
      headers: headers
    });
  },
  /**
   * 查看数据
   * @param {*} data 内容
   */
  getData: function getData(id) {
    return request.get(getUrl("/duip/dataconfig/get?id=".concat(id), 5));
  },
  /**
   * 删除数据
   * @param {*} id
   */
  deleteData: function deleteData(ids) {
    return request.delete(getUrl("/duip/dataconfig/batchDelete/".concat(ids.toString()), 5));
  },
  // ******************************外部数据源配置***************************
  /**
     * 列表
     * @param {*} pageNum 当前页
     * @param {*} pageSize 每页显示条数
     */
  getDuipoutputconfigList: function getDuipoutputconfigList(pageNum, pageSize, data) {
    return request.get(getUrl("/duip/external/config/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 5), {
      params: data
    });
  },
  /**
  * 新增数据
  * @param {*} data 内容
  */
  duipoutputconfigAddData: function duipoutputconfigAddData(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/duip/external/config/create", 5), data, {
      headers: headers
    });
  },
  /**
  * 修改数据
  * @param {*} data 内容
  */
  duipoutputconfigUpdateData: function duipoutputconfigUpdateData(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/duip/external/config/update", 5), data, {
      headers: headers
    });
  },
  /**
  * 查询数据
  * @param {*} data 内容
  */
  getDuipoutputconfigInfo: function getDuipoutputconfigInfo(id) {
    return request.get(getUrl("/duip/external/config/get?id=".concat(id), 5));
  },
  /**
  * 删除一条数据
  * @param {*} data 内容
  */
  deleteDuipoutputconfig: function deleteDuipoutputconfig(id) {
    return request.delete(getUrl("/duip/external/config/delete/".concat(id), 5));
  },
  /**
  * 删除多条数据
  * @param {*} data 内容
  */
  batchDeleteDuipoutputconfig: function batchDeleteDuipoutputconfig(ids) {
    return request.delete(getUrl("/duip/external/config/batchDelete/".concat(ids.toString()), 5));
  },
  /**
  * 推送策略列表
  * @param {*} data 内容
  */
  getPushstragegy: function getPushstragegy(ids) {
    return request.get(getUrl("/pushstragegy/listOutputPushStrategy", 5));
  },
  // ******************************外部接入配置***************************
  /**
     * 列表
     * @param {*} pageNum 当前页
     * @param {*} pageSize 每页显示条数
     */
  getInputconfigList: function getInputconfigList(pageNum, pageSize, data) {
    return request.get(getUrl("/duip/internal/config/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 5), {
      params: data
    });
  },
  /**
  * 删除
  * @param {*} ids
  */
  deleteInputconfig: function deleteInputconfig(ids) {
    return request.delete(getUrl("/duip/internal/config/batchDelete/".concat(ids.toString()), 5));
  },
  /**
  * 查询数据
  * @param {*} id
  */
  getInputconfigInfo: function getInputconfigInfo(id) {
    return request.get(getUrl("/duip/internal/config/get?id=".concat(id), 5));
  },
  /**
  * 新增数据
  * @param {*} data 内容
  */
  addInputconfig: function addInputconfig(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/duip/internal/config/create", 5), data, {
      headers: headers
    });
  },
  /**
  * 修改数据
  * @param {*} data 内容
  */
  updateInputconfig: function updateInputconfig(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/duip/internal/config/update", 5), data, {
      headers: headers
    });
  },
  /**
  * 模型转换器列表
  */
  getDataConverter: function getDataConverter() {
    return request.get(getUrl("/modelTranslate/list", 5));
  },
  /**
  * 推送数据列表
  */
  getPushData: function getPushData() {
    return request.get(getUrl("/duip/dataconfig/list", 5));
  },
  // ******************************外部交互监控***************************
  /**
     * 列表
     * @param {*} pageNum 当前页
     * @param {*} pageSize 每页显示条数
     */
  getDuipoutputrecordList: function getDuipoutputrecordList(pageNum, pageSize, data) {
    return request.get(getUrl("/duip/external/record/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 5), {
      params: data
    });
  },
  /**
  * 查询数据
  * @param {*} data 内容
  */
  getDuipoutputconfigData: function getDuipoutputconfigData(id) {
    return request.get(getUrl("/duip/external/record/get?id=".concat(id), 5));
  },
  /**
  * 重新推送
  * @param {*} data 内容
  */
  ReSendData: function ReSendData(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/duip/external/record/reSend", 5), data, {
      headers: headers
    });
  },
  /**
  * 查询推送日志
  * @param {*} data 内容
  */
  getDuipoutputrecordlog: function getDuipoutputrecordlog(pageNum, pageSize, data) {
    return request.get(getUrl("/duip/external/record/log/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 5), {
      params: data
    });
  },
  // ******************************内部数据源配置***************************
  /**
   * 列表
   * @param {*} data 参数
   */
  getDuipInternaConfiglist: function getDuipInternaConfiglist(data) {
    return request.get(getUrl("/duip/internal/config/search", 5), {
      params: data
    });
  },
  /**
  * 创建数据
  * @param {*} data
  */
  createtDuipInternaConfig: function createtDuipInternaConfig(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/duip/internal/config/create", 5), data, {
      headers: headers
    });
  },
  /**
  * 通过id查询一条
  * @param {*} data
  */
  getInternalConfigDetailById: function getInternalConfigDetailById(id) {
    return request.get(getUrl("/duip/internal/config/get?id=".concat(id), 5));
  },
  /**
  * 更新数据
  * @param {*} data
  */
  updataDuipInternaConfig: function updataDuipInternaConfig(id, data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/duip/internal/config/update?id=".concat(id), 5), data, {
      headers: headers
    });
  },
  /**
  * 删除数据
  * @param {*} data
  */
  deleteDuipInternaConfig: function deleteDuipInternaConfig(id) {
    return request.delete(getUrl("/duip/internal/config/delete/".concat(id), 5));
  },
  // ******************************内部交互监控***************************
  /**
  * 列表
  * @param {*} data 参数
  */
  getDuipInternaRecordList: function getDuipInternaRecordList(data) {
    return request.get(getUrl("/duip/internal/record/search", 5), {
      params: data
    });
  },
  /**
   * 查看详情
   * @param {*} id 参数
   */
  getRecordDetailById: function getRecordDetailById(id) {
    return request.get(getUrl("/duip/internal/record/get?id=".concat(id), 5));
  }
};
export default objApi;