var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-drawer",
            {
              attrs: {
                title: "",
                visible: _vm.drawer,
                modal: false,
                "append-to-body": true,
                size: "350px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.searchForm,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "素材类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择素材类型",
                                  },
                                  model: {
                                    value: _vm.searchForm.knowledgeType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "knowledgeType",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.knowledgeType",
                                  },
                                },
                                _vm._l(_vm.materialGroup, function (item) {
                                  return _c("el-option", {
                                    key: item.itemCode,
                                    attrs: {
                                      label: item.itemName,
                                      value: item.itemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "文章标题" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入文章标题",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "title", $$v)
                                  },
                                  expression: "searchForm.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "保险公司" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择保险公司",
                                    filterable: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "insCorpCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.insCorpCode",
                                  },
                                },
                                _vm._l(_vm.supplyers, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.shortName,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "searchBtnStyle" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  icon: "el-icon-refresh-left",
                                },
                                on: { click: _vm.resetSearch },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchData },
                              },
                              [_vm._v("查询")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "table-cls normal-btn-cls",
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "justify-content": "flex-start",
                    "align-items": "flex-start",
                  },
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticStyle: { width: "100%" },
                      attrs: {
                        model: _vm.searchForm,
                        "label-width": "110px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { label: "关联地区：" },
                                },
                                [
                                  _c("el-cascader", {
                                    ref: "address",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择所在省市",
                                      options: _vm.regionOptions,
                                    },
                                    on: { change: _vm.handleChange },
                                    model: {
                                      value: _vm.insCityCode,
                                      callback: function ($$v) {
                                        _vm.insCityCode = $$v
                                      },
                                      expression: "insCityCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { label: "保险公司：" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "请选择保险公司",
                                        filterable: "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.searchForm.insCorpCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "insCorpCode",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.insCorpCode",
                                      },
                                    },
                                    _vm._l(
                                      _vm.supplyers,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.shortName,
                                            value: item.code,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { label: "原始问题文本：" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "100%",
                                      display: "flex",
                                    },
                                    attrs: {
                                      maxlength: "300",
                                      rows: "3",
                                      size: "mini",
                                      "show-word-limit": "",
                                      type: "textarea",
                                      placeholder: "请输入原始问题文本",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.searchTxt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "searchTxt",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.searchTxt",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "justify-content": "flex-end",
                                "align-items": "center",
                              },
                              attrs: { span: 24 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { label: "" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        icon: "el-icon-refresh-left",
                                      },
                                      on: { click: _vm.resetSearch },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.searchData },
                                    },
                                    [_vm._v("验证")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightNMini,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "knowledgeTypeDisplay",
                      label: "素材类型",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "文章标题",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "examineStatusDisplay",
                      label: "审核状态",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "publishedDisplay",
                      label: "是否上架",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "insCorpName",
                      label: "保险公司",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cityName",
                      label: "关联地区",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "questionTypeDisplay",
                      label: "问题类型",
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "knowledgeUrl",
                      label: "文章链接",
                      "min-width": "420",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "80",
                      align: "center",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.viewDetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }