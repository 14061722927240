import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import {
//     client
// } from '@/utils/alioss.js'
import artilceApi from '@/api/article';
import storageRequest from '@/api/storage';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      formLoading: false,
      addForm: {
        mediaTypeId: '',
        fileName: '',
        storageUrl: ''
      },
      rules: {
        mediaTypeId: [{
          required: true,
          message: '请选择素材类型',
          trigger: 'change'
        }],
        fileName: [{
          required: true,
          message: '请输入文件名称',
          trigger: 'blur'
        }]
      },
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      classificationList: [],
      uploadType: '.jpg,.gif,.png,.jpeg,.mp4',
      fileList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 判断文件类型
    beforeUpload: function beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
      var fileNameLength = file.name.split('.' + testmsg)[0] && file.name.split('.' + testmsg)[0].length;
      var arr = ['jpg', 'gif', 'png', 'jpeg', 'mp4'];
      if (!arr.includes(testmsg)) {
        this.$message.warning("目前支持的类型有：[.jpg|.gif|.png|.jepg|.mp4]");
        return false;
      } else {
        var uploadFileSize = file.size / 1024 / 1024 > 2;
        if (uploadFileSize) {
          this.$message.warning("上传附件大小不超过2M");
          return false;
        }
        ;
        if (fileNameLength > 40) {
          this.$message.warning("文件名称过长，请控制在40个字符以内");
          return false;
        }
        ;
      }
      ;
    },
    // 自定义上传
    uploadFile: function uploadFile(file) {
      var _this = this;
      var formData = new FormData();
      formData.append('file', file.file);
      storageRequest.uploadLongTermFile(formData).then(function (res) {
        if (res.code === 200 && res.data) {
          _this.addForm.fileName = res.data.fileName || '';
          _this.addForm.storageUrl = res.data.fileUrl || '';
        }
        ;
      });
    },
    delFileList: function delFileList(row) {
      this.fileList = [];
      this.addForm.fileName = null;
      this.addForm.storageUrl = null;
      this.$forceUpdate();
    },
    allTypeList: function allTypeList() {
      var _this2 = this;
      artilceApi.typeList().then(function (res) {
        if (res.code == 200 && res.data) {
          _this2.classificationList = res.data || [];
        }
      });
    },
    // 初始化数据
    initData: function initData() {
      this.addForm = {
        mediaTypeId: '',
        fileName: '',
        storageUrl: ''
      };
      this.$refs['addForm'].clearValidate();
      this.$forceUpdate();
      this.allTypeList();
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (!_this3.addForm.storageUrl || _this3.addForm.storageUrl == '') {
            _this3.$message.warning("请上传文件");
            return;
          }
          ;
          _this3.$emit('createFileStorage', _this3.addForm);
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$refs.addForm.resetFields();
      this.$emit('closePage');
    }
  }
};