import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chatRequest from "@/api/chat";
import OtherChat from "./components/OtherChat";
import MyChat from "./components/MyChat";
import { dateFormat } from '@/utils/common';
export default {
  name: 'Chatgpt',
  components: {
    OtherChat: OtherChat,
    MyChat: MyChat
  },
  data: function data() {
    return {
      // 屏幕高度
      curHeight: 0,
      // 信息体
      messageList: [],
      // 输入框
      sendVal: ""
    };
  },
  created: function created() {
    this.initMessage();
    this.beforeMount(0);
  },
  methods: {
    initMessage: function initMessage() {
      var _this = this;
      var other = {
        avatar: "https://bucket-ans.oss-cn-hangzhou.aliyuncs.com/2.jpg",
        time: dateFormat('YYYY-mm-dd HH:MM', new Date()),
        con: "您好，专属客服小智竭诚为您服务，谢谢！",
        user: "other"
      };
      setTimeout(function () {
        _this.messageList.push(other);
      }, 1000);
    },
    // 发送消息
    send: function send() {
      var _this2 = this;
      if (!this.sendVal) return;
      var item = {
        avatar: require('@/assets/images/default_avatar.png'),
        time: dateFormat('YYYY-mm-dd HH:MM', new Date()),
        con: this.sendVal,
        user: "my"
      };
      this.messageList.push(item);
      this.$nextTick(function () {
        // 一定要用nextTick
        // console.log(this.$refs.list.scrollHeight);
        window.scrollTo(0, _this2.$refs.list.scrollHeight);
      });
      this.getAiContent(this.sendVal);
      this.sendVal = "";
    },
    // 获取回答信息
    getAiContent: function getAiContent(_sendVal) {
      var _this3 = this;
      var other = {
        avatar: "https://bucket-ans.oss-cn-hangzhou.aliyuncs.com/2.jpg",
        time: dateFormat('YYYY-mm-dd HH:MM', new Date()),
        con: '思考中，请耐心等待...',
        user: "other",
        isLoading: true
      };
      this.messageList.push(other);
      chatRequest.getAskQuestionResult({
        question: _sendVal
      }).then(function (res) {
        if (res.code == 200) {
          _this3.messageList.splice(_this3.messageList.length - 1, 1);
          other.isLoading = false;
          other.con = res.data || '很抱歉，我没听懂您在说什么';
          // setTimeout(() => {
          _this3.messageList.push(other);
          _this3.$nextTick(function () {
            // 一定要用nextTick
            window.scrollTo(0, _this3.$refs.list.scrollHeight);
          });
          // }, 2000);
        }
        ;
      });
    },
    // 获取屏幕高度
    beforeMount: function beforeMount(height) {
      var h = document.documentElement.clientHeight || document.body.clientHeight;
      this.curHeight = h - height; //减去页面上固定高度height
    }
  }
};