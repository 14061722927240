import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import artilceApi from '@/api/article';
import dictionary from '@/api/dictionary';
import { commonExport } from '@/utils/common';
import addDiadlog from './add';
import addTypeDiadlog from './addType';
export default {
  name: "Article",
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination,
    addDiadlog: addDiadlog,
    addTypeDiadlog: addTypeDiadlog
  },
  data: function data() {
    return {
      loading: false,
      preViewImg: false,
      previewUrl: '',
      srcList: [],
      searchForm: {
        mediaTypeId: ''
      },
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      drawer: false,
      timeoutForExport: null,
      classificationIndex: null,
      classificationList: [],
      showAddDialog: false,
      showAddTypeDialog: false
    };
  },
  created: function created() {
    this.allTypeList();
    this.loadData();
  },
  methods: {
    allTypeList: function allTypeList() {
      var _this = this;
      artilceApi.typeList().then(function (res) {
        if (res.code == 200 && res.data) {
          _this.classificationList = res.data || [];
        }
      });
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this2 = this;
      this.loading = true;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this2.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        fileName: "文章列表",
        exportUrl: '/biz-cms-service/material/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    addTypeClicked: function addTypeClicked() {
      this.showAddTypeDialog = true;
    },
    addClicked: function addClicked() {
      this.showAddDialog = true;
    },
    // 上传文件创建素材
    createFileStorage: function createFileStorage(val) {
      var _this3 = this;
      artilceApi.createStorage(val).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success("".concat(res.msg));
          _this3.showAddDialog = false;
          _this3.loadData();
        }
      });
      this.showAddDialog = false;
    },
    closeTypeData: function closeTypeData() {
      this.showAddTypeDialog = false;
    },
    closeLoadData: function closeLoadData() {
      this.showAddDialog = false;
    },
    // 预览
    previewFile: function previewFile(row) {
      this.preViewImg = true;
      this.previewUrl = row.storageUrl;
      this.srcList.push(row.storageUrl);
    },
    closePreViewImg: function closePreViewImg() {
      this.preViewImg = false;
      this.previewUrl = null;
      this.srcList = [];
    },
    // 删除
    delFile: function delFile(row) {
      var _this4 = this;
      this.$confirm('是否删除该条素材?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = [row.id];
        _this4.deleteFile(data);
      }).catch(function () {
        console.log('已取消删除');
      });
    },
    deleteFile: function deleteFile(data) {
      var _this5 = this;
      artilceApi.deleteStorage(data).then(function (res) {
        if (res.code === 200) {
          _this5.$message.success(res.msg);
          _this5.loadData();
        }
        ;
      });
    },
    refreshClicked: function refreshClicked() {
      this.loadData();
    },
    resetSearch: function resetSearch() {
      this.searchForm = {
        mediaTypeId: ''
      };
      this.$forceUpdate();
    },
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this6 = this;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      this.drawer = false;
      this.loading = true;
      artilceApi.storageSearch(data).then(function (res) {
        _this6.loading = false;
        if (res.code == 200) {
          var resList = res.data && res.data.list || [];
          _this6.tableData = resList;
          _this6.total = res.data && res.data.page && res.data.page.recordsTotal || 0;
        }
        ;
      }).catch(function (err) {
        _this6.loading = false;
      });
    }
  }
};