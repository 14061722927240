import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import customerManagementApi from '@/api/customerManagement';
import sysManageApi from '@/api/sysManage';
import dictionary from '@/api/dictionary';
import AddFollowDialog from './dialog/addFollowDialog';
import { commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'MyHighSeas',
  components: {
    Pagination: Pagination,
    AddFollowDialog: AddFollowDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        startDate: "".concat(getBeforeDate(30), " 00:00:00"),
        endDate: "".concat(getCurrentDate(), " 23:59:59"),
        trackPeople: null,
        addType: 'ALL'
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      opType: '',
      currentId: '',
      createdStartEndTime: [getBeforeDate(30), getCurrentDate()],
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      userList: [],
      addTypeList: [],
      showAddFollowDialog: false,
      multipleSelection: [],
      userClueIds: []
    };
  },
  created: function created() {
    this.getUserList();
    this.getAddTypeList();
    this.searchForm.trackPeople = this.userInfo.userId;
    this.loadData();
  },
  methods: {
    // 获取跟进添加描述
    getAddTypeList: function getAddTypeList() {
      var _this = this;
      dictionary.getDictItemsByCode('ADDTYPES').then(function (res) {
        if (res.code === 200) {
          _this.addTypeList = res.data || [];
        }
        ;
      });
    },
    // 获取用户列表数据
    getUserList: function getUserList() {
      var _this2 = this;
      var data = {
        pageNum: 1,
        pageSize: 9999
      };
      sysManageApi.getUsersList(data).then(function (res) {
        if (res.code === 200) {
          _this2.userList = res.data.list;
        }
        ;
      });
    },
    // 是否可选
    selectableFn: function selectableFn(row) {
      return !row.addFlag;
    },
    // tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      this.searchData();
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        startDate: "".concat(getBeforeDate(30), " 00:00:00"),
        endDate: "".concat(getCurrentDate(), " 23:59:59"),
        trackPeople: this.searchForm.trackPeople,
        addType: 'ALL'
      };
      this.createdStartEndTime = [getBeforeDate(30), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 添加微信
    addWechat: function addWechat(item) {
      this.userClueIds = [];
      if (!_.isEmpty(item)) {
        this.userClueIds.push(item.userClueId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          this.userClueIds = _.map(this.multipleSelection, 'userClueId');
        }
        ;
      }
      ;
      this.showAddFollowDialog = true;
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showAddFollowDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      customerManagementApi.getCostomerClueList(data).then(function (res) {
        _this3.loading = false;
        if (res.code === 200) {
          _this3.tableData = res.data.list;
          _this3.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '我的公海',
        exportUrl: '/biz-scrm-service/wechat/userClue/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    }
  }
};