/**
 * 通用接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 根据token获取用户信息
   */
  getUserInfo: function getUserInfo() {
    return request.get(getUrl("/wecomMember/me", 4));
  },
  /**
   * 获取当前用户的角色菜单
   */
  getUserRoleMenus: function getUserRoleMenus() {
    return request.get(getUrl("/roles/getUserRoleMenus/me", 3));
  },
  /**
   * 修改密码（图形验证码）
   * @param {*} 
   */
  changePasswordByCode: function changePasswordByCode(data) {
    return request.post(getUrl("/users/changePassword?account=".concat(data.account), 6), data);
  },
  /**
  * 获取系统日志地址
  */
  getConsoleUrl: function getConsoleUrl(refreshUrl) {
    return request.get(getUrl("/cloudLog/getConsoleUrl?refreshUrl=".concat(refreshUrl), 3));
  }
};
export default objApi;