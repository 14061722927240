import config from '../config/index';
export default function getUrl(url, domainType) {
  // domainType 默认为1，取apiDomain；可自定义其他域名
  var domain = '';
  if (domainType == 11) {
    // openai-core-service服务
    domain = config.apiDomainDev + '/' + config.openaiCoreService;
  } else if (domainType == 10) {
    // 站内消息服务
    domain = config.apiDomain + '/' + config.tpMessageService;
  } else if (domainType == 9) {
    // ans-offline-analysis-service服务
    domain = config.answebapiDomain + '/' + config.ansOfflineAnalysisService;
  } else if (domainType == 8) {
    // scrm-biz-cms服务
    domain = config.apiDomain + '/' + config.bizCmsService;
  } else if (domainType == 7) {
    // 存储服务
    domain = config.apiDomain + '/' + config.tpStorageService;
  } else if (domainType == 6) {
    // 资源服务
    domain = config.apiDomain + '/' + config.tpResourceService;
  } else if (domainType == 5) {
    // 数据处理服务
    domain = config.apiDomain + '/' + config.tpDataProcessService;
  } else if (domainType == 4) {
    // biz-scrm服务
    domain = config.apiDomain + '/' + config.bizScrmService;
  } else if (domainType == 3) {
    // 核心服务
    domain = config.apiDomain + '/' + config.tpCoreService;
  } else if (domainType == 2) {
    // 组织服务
    domain = config.apiDomain + '/' + config.tpOrgService;
  } else if (domainType == 1) {
    // 认证服务
    domain = config.apiDomain + '/' + config.tpAuthService;
  } else {
    // 其他
    domain = config.apiDomain;
  }
  return domain + url;
}