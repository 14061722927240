import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VI_CityAreaTree from '@/assets/json/VI_CityAreaTree';
import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import knowledgeApi from '@/api/knowledge';
// import dictionary from '@/api/dictionary'
import { getSupplyers, commonExport } from '@/utils/common';
export default {
  name: "Article",
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      loading: false,
      materialGroup: [{
        itemCode: 'ARTICLE',
        itemName: '文章'
      }, {
        itemCode: 'VIDEO',
        itemName: '视频'
      }],
      insCityCode: [],
      searchForm: {},
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      drawer: false,
      supplyers: [],
      timeoutForSetPublished: null,
      regionData: VI_CityAreaTree || [],
      regionOptions: []
    };
  },
  created: function created() {
    // this.loadData();
    this.getAddress();
    this.supplyers = getSupplyers();
  },
  methods: {
    //选择省/市/区
    handleChange: function handleChange(val) {
      if (val) {
        this.searchForm.provinceCode = val && val[0]; //省
        this.searchForm.cityCode = val && val[1]; //市
      }
      ;
    },
    // 省市区
    getAddress: function getAddress() {
      var partialAddress = [];
      this.regionData.forEach(function (item, index) {
        partialAddress.push({
          label: item.label,
          value: item.value,
          // children: []
          children: item.children ? [] : null
        });
        if (item.children) {
          item.children.forEach(function (eitem) {
            partialAddress[index].children.push({
              label: eitem.label == '市辖区' ? item.label : eitem.label,
              value: eitem.value
            });
          });
        }
      });
      this.regionOptions = partialAddress;
    },
    // 查询车辆防抖
    setPublished: function setPublished(row) {
      var _this = this;
      this.loading = true;
      if (this.timeoutForSetPublished) {
        clearTimeout(this.timeoutForSetPublished);
      }
      this.timeoutForSetPublished = setTimeout(function () {
        _this.setPublished1(row);
      }, 500);
    },
    setPublished1: function setPublished1(row) {
      var _this2 = this;
      knowledgeApi.publishedKnowledge(row.guid).then(function (res) {
        _this2.loadData();
      });
    },
    getMaterialGroup: function getMaterialGroup() {
      var _this3 = this;
      dictionary.getDictItemsByCode('MATERIALTYPES').then(function (res) {
        if (res.code === 200) {
          _this3.materialGroup = res.data || [];
        }
        ;
      });
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: "文章列表",
        exportUrl: '/biz-cms-service/material/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    addClicked: function addClicked() {
      this.$router.push({
        name: 'MaterialAutoInsuranceAdd',
        query: {
          tabName: '新增车险知识',
          type: 'add'
        }
      });
    },
    // 编辑
    editDetail: function editDetail(row) {
      this.$router.push({
        name: 'MaterialAutoInsuranceEdit',
        query: {
          guid: row.guid,
          tabName: "\u8F66\u9669\u77E5\u8BC6\u7F16\u8F91-".concat(row.knowledgeType, "-").concat(row.title),
          type: 'edit'
        }
      });
    },
    // 查看
    viewDetail: function viewDetail(row) {
      var newUrl = window.location.href.split('material')[0];
      var toUrl = "".concat(newUrl, "autoInsurance/detail?guid=").concat(row.guid);
      window.open(toUrl);
    },
    delArticle: function delArticle(row) {
      var _this4 = this;
      this.$confirm('是否删除该条素材?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        knowledgeApi.delKnowledge(row.guid).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.msg);
            _this4.loadData();
          }
          ;
        });
      }).catch(function () {});
    },
    refreshClicked: function refreshClicked() {
      this.loadData();
    },
    resetSearch: function resetSearch() {
      this.insCityCode = [];
      this.searchForm = {};
    },
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this5 = this;
      if (!this.searchForm.searchTxt) {
        this.$message.warning('请输入原始问题文本');
        return;
      }
      if (this.insCityCode) {
        this.searchForm.insCityCode = this.insCityCode[1];
      }
      var data = _objectSpread({}, this.searchForm);
      this.drawer = false;
      this.loading = true;
      knowledgeApi.checkedKnowledgeValid(data).then(function (res) {
        _this5.loading = false;
        if (res.code == 200) {
          var resList = res.data || [];
          _this5.tableData = resList;
          _this5.total = res.data && res.data.page && res.data.page.recordsTotal || 0;
        }
        ;
      }).catch(function (err) {
        _this5.loading = false;
      });
    }
  }
};