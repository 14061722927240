import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import openPlatformRequest from '@/api/openPlatform';
import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import RouteGroupDialog from './dialog/routeGroupDialog';
import { commonExport } from '@/utils/common';
export default {
  name: 'RoutingGroupManage',
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    RouteGroupDialog: RouteGroupDialog
  },
  data: function data() {
    return {
      DialogType: null,
      DialogId: null,
      drawer: false,
      loading: true,
      showAddDialog: false,
      routerTypeList: [{
        routerName: 'GET'
      }, {
        routerName: 'POST'
      }, {
        routerName: 'PUT'
      }, {
        routerName: 'DELETE'
      }],
      searchForm: {
        name: ''
      },
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      multipleSelection: []
    };
  },
  created: function created() {
    this.getRouteGroupList();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '路由组管理',
        exportUrl: '/tp-core-service/apiGroup/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 获取数据列表
    getRouteGroupList: function getRouteGroupList() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      var _this$listQuery = this.listQuery,
        pageNum = _this$listQuery.pageNum,
        pageSize = _this$listQuery.pageSize;
      this.searchForm.pageNum = pageNum;
      this.searchForm.pageSize = pageSize;
      openPlatformRequest.RouteGroupLists(this.searchForm).then(function (res) {
        if (res.code == 200) {
          _this.loading = false;
          _this.tableData = res.data && res.data.list ? res.data.list : [];
          _this.total = res.data.page.recordsTotal;
        }
        ;
      });
    },
    // 表格选中操作
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量删除
    batchDeletion: function batchDeletion() {
      var _this2 = this;
      this.$confirm('是否确认删除, 确认删除后不能恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this2.multipleSelection) {
          var ids = [];
          _this2.multipleSelection.map(function (item) {
            ids.push(item.apiGroupId);
          });
          _this2.deleteApi(ids);
        }
        ;
      }).catch(function () {});
    },
    // 重置
    resetSearch: function resetSearch() {
      this.searchForm = {
        name: ''
      };
      this.getRouteGroupList();
    },
    // 刷新
    refreshClicked: function refreshClicked() {
      this.getRouteGroupList();
    },
    // 查询
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.getRouteGroupList();
    },
    // 新增
    showAddDialogClicked: function showAddDialogClicked(type) {
      this.showAddDialog = true;
      this.DialogType = type;
    },
    // 编辑
    editConfig: function editConfig(type, row) {
      this.showAddDialog = true;
      this.DialogType = type;
      this.DialogId = row.apiGroupId.toString();
    },
    // 是否启用
    updateItem: function updateItem(row) {
      var _this3 = this;
      var data = {
        id: row.apiGroupId,
        enabled: row.enabled,
        name: row.name
      };
      openPlatformRequest.updateRouteGroup(data).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success(res.msg);
        }
        ;
        _this3.getRouteGroupList();
      });
    },
    // 删除二次确认
    deleteRoute: function deleteRoute(row) {
      var _this4 = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.deleteApi(row.apiGroupId);
      }).catch(function () {});
    },
    // 删除api
    deleteApi: function deleteApi(ids) {
      var _this5 = this;
      var data = {
        ids: ids.toString()
      };
      openPlatformRequest.deleteRouteGroup(data).then(function (res) {
        if (res.code == 200) {
          _this5.$message.success(res.msg);
        }
        ;
        _this5.getRouteGroupList();
      });
    },
    // 关闭弹窗
    loadData: function loadData() {
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showAddDialog = false;
      this.DialogType = null;
      if (isDialogNoRefresh) return;
      this.getRouteGroupList();
    }
  }
};