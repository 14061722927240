import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import customerManagementApi from '@/api/customerManagement';
import CustomerLabelDialog from './dialog/customerLabelDialog';
import LabelDialog from './dialog/labelDialog';
import sysManageApi from '@/api/sysManage';
export default {
  name: 'CustomerLabel',
  components: {
    CustomerLabelDialog: CustomerLabelDialog,
    LabelDialog: LabelDialog
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      tableData: [],
      loading: false,
      vehicleForm: {},
      vehicleFormLoading: false,
      netUserVehicleId: null,
      typeList: [],
      opType: '',
      currentId: '',
      showCustomerLabelDialog: false,
      showLabelDialog: false,
      corpList: []
    };
  },
  created: function created() {
    this.getCorpList();
    this.loadData();
  },
  methods: {
    // 获取企业列表
    getCorpList: function getCorpList() {
      var _this = this;
      sysManageApi.getAllWecomCorpConfigList().then(function (res) {
        if (res.code == 200) {
          _this.corpList = res.data || [];
        }
      });
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      // this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 新增
    add: function add(type) {
      this.opType = type;
      this.showCustomerLabelDialog = true;
    },
    // 编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.currentId = item.id.toString();
      this.showLabelDialog = true;
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showCustomerLabelDialog = false;
      this.showLabelDialog = false;
      if (isDialogNoRefresh) return;
      this.drawer = false;
      this.loading = true;
      customerManagementApi.getWecomtagsGroupList(this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data || [];
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 删除
    batchDelete: function batchDelete(item, isBatch) {
      var _this3 = this;
      var ids = [];
      if (!_.isEmpty(item) && !isBatch) {
        ids.push(item.groupId);
      } else {
        if (!_.isEmpty(item.groupList)) {
          ids = _.map(item.groupList, 'groupId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        var data = {
          corpId: item.corpId,
          groupIds: ids
        };
        customerManagementApi.deleteWecomtags(data).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      });
    }
  }
};