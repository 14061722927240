var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messagebox" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            trigger: "click",
            placement: "bottom-start",
            "visible-arrow": true,
            "popper-class": "messageTopBox",
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "msg-list" }, [
            _c("div", { staticClass: "header" }, [_vm._v(" 消息通知 ")]),
            !_vm._.isEmpty(_vm.tableData)
              ? _c(
                  "div",
                  { staticClass: "messageDataList" },
                  _vm._l(_vm.tableData, function (o, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.getDetail(o)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "msg-left" }, [
                          _c("div", [_vm._v(_vm._s(o.messageContent))]),
                          _c("div", { staticClass: "msg-date" }, [
                            _vm._v(_vm._s(o.createdTime)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  {
                    staticClass: "no-data",
                    staticStyle: {
                      "text-align": "center",
                      "line-height": "80px",
                      "padding-bottom": "40px",
                    },
                  },
                  [_vm._v(" 暂无消息 ")]
                ),
            _c(
              "div",
              {
                staticClass: "bottom fixedbottom",
                on: { click: _vm.viewDetail },
              },
              [_c("span", [_vm._v("查看全部")])]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "msg-box",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "消息",
                    placement: "bottom",
                    effect: "light",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _vm.total
                        ? _c(
                            "el-badge",
                            {
                              staticClass: "spot",
                              attrs: { value: _vm.total, type: "primary" },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-message-solid",
                                attrs: { size: "46px" },
                              }),
                            ]
                          )
                        : _c("i", {
                            staticClass: "el-icon-message-solid",
                            attrs: { size: "46px" },
                          }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }