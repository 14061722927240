import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import groupChatLabelApi from '@/api/groupChatLabel';
import { limitInput } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    corpList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dialogType: {
      type: String,
      default: ''
    },
    labelId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        corpId: '',
        tagClassificationName: ''
      },
      groupChatTagList: [{
        tagName: ""
      }],
      rules: {
        corpId: {
          required: true,
          message: "\u8BF7\u9009\u62E9\u4F01\u4E1A",
          trigger: "change"
        },
        tagClassificationName: {
          required: true,
          message: "\u8BF7\u8F93\u5165\u6807\u7B7E\u5206\u7EC4\u540D\u79F0",
          trigger: "blur"
        }
      },
      formLoading: false,
      groupChatTagDetail: {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 获取标签详情
    getGroupChatTagDetail: function getGroupChatTagDetail(id) {
      var _this = this;
      groupChatLabelApi.getGroupChatTagClassificationDetail(id).then(function (res) {
        if (res.code == 200 && res.data) {
          var resData = res.data || {};
          _this.form.corpId = resData.corpId || '';
          _this.form.tagClassificationName = resData.tagClassificationName || '';
          _this.groupChatTagList = resData.groupChatTagVOList || [];
          _this.$forceUpdate();
        }
      });
    },
    // 初始化数据
    initData: function initData() {
      this.formLoading = true;
      if (this.dialogType == 'edit' && this.labelId) {
        this.getGroupChatTagDetail(this.labelId);
      } else {
        this.form = {
          corpId: '',
          tagClassificationName: ''
        };
        this.groupChatTagList = [{
          tagName: ""
        }];
        this.$refs.detail.resetFields();
      }
      ;
      this.formLoading = false;
    },
    // 新增标签
    addGroupChatTag: function addGroupChatTag() {
      this.groupChatTagList.push({
        tagName: ""
      });
      this.$forceUpdate();
    },
    // 编辑标签
    editSaveTagsItem: function editSaveTagsItem(row) {
      var _this2 = this;
      if (row.id) {
        var data = {
          tagClassificationId: row.tagClassificationId,
          tagName: row.tagName || ""
        };
        groupChatLabelApi.updateSingleGroupChatTag(row.id, data).then(function (res) {
          if (res.code == 200) {
            _this2.getGroupChatTagDetail(row.tagClassificationId);
            _this2.$message({
              type: 'success',
              message: res.msg
            });
          }
          ;
        });
      } else {
        var _data = {
          tagClassificationId: this.labelId || null,
          tagName: row.tagName || ""
        };
        groupChatLabelApi.createSingleGroupChatTag(_data).then(function (res) {
          if (res.code == 200) {
            _this2.getGroupChatTagDetail(_this2.labelId);
            _this2.$message({
              type: 'success',
              message: res.msg
            });
          }
          ;
        });
      }
      this.$forceUpdate();
    },
    // 删除标签
    deleteTagsItem: function deleteTagsItem() {
      var _this3 = this;
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var index = arguments.length > 1 ? arguments[1] : undefined;
      this.$confirm('是否删除该条标签?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this3.dialogType == 'add') {
          _this3.groupChatTagList.splice(index, 1);
          _this3.$message({
            type: 'success',
            message: res.msg
          });
        } else {
          if (!row.id) {
            _this3.groupChatTagList.splice(index, 1);
            _this3.$message({
              type: 'success',
              message: res.msg
            });
          } else {
            groupChatLabelApi.deleteSingleGroupChatTag(row.id).then(function (res) {
              if (res.code == 200) {
                _this3.getGroupChatTagDetail(row.tagClassificationId);
                _this3.$message({
                  type: 'success',
                  message: res.msg
                });
              }
              ;
            });
          }
        }
        ;
      }).catch(function () {});
      this.$forceUpdate();
    },
    // 保存数据
    save: function save() {
      var _this4 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var hasEmptyName = _this4.groupChatTagList.some(function (obj) {
            return obj.tagName === '';
          });
          if (hasEmptyName) {
            _this4.$message.warning('请填写完整');
            return;
          }
          ;
          _this4.formLoading = true;
          var request = null;
          var data = {};
          if (_this4.dialogType == 'add') {
            data = {
              'corpId': _this4.form.corpId || '',
              'tagClassificationName': _this4.form.tagClassificationName || '',
              groupChatTagSaveParamList: _this4.groupChatTagList || []
            };
            request = groupChatLabelApi.createGroupChatTagClassification(data);
          } else {
            data = {
              'corpId': _this4.form.corpId || '',
              'tagClassificationName': _this4.form.tagClassificationName || ''
            };
            request = groupChatLabelApi.updateGroupChatTagClassification(_this4.labelId, data);
          }
          request.then(function (res) {
            _this4.formLoading = false;
            if (res.code === 200) {
              _this4.$message.success(res.msg);
              _this4.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this4.formLoading = false;
          });
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};