//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import customerManagementApi from '@/api/customerManagement';
import initHeight from '@/mixins/initHeight';
export default {
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel
  },
  mixins: [initHeight],
  data: function data() {
    return {
      formLoading: false,
      activeNames: ['1'],
      form: {
        groupChatMemberList: []
      },
      groupChatId: '',
      tabActiveName: 'MEMBERINFO'
    };
  },
  watch: {
    '$route': {
      handler: function handler(val) {
        this.initData();
      },
      immediate: true
    }
  },
  computed: {
    // 计算表格高度
    tableHeight: function tableHeight() {
      return document.body.clientHeight - 380 + 'px';
    }
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.groupChatId = this.$route.query.groupChatId || '';
      if (this.groupChatId) this.getDeatils();
    },
    // 获取详情数据
    getDeatils: function getDeatils() {
      var _this = this;
      this.formLoading = true;
      customerManagementApi.getCostomerGroupInfo(this.groupChatId).then(function (res) {
        _this.formLoading = false;
        if (res.code === 200) {
          _this.form = res.data;
        }
        ;
      }).catch(function (err) {
        _this.formLoading = false;
      });
    },
    // 关闭
    closePage: function closePage() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    }
  }
};