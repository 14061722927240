var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-drawer",
            {
              attrs: {
                title: "",
                visible: _vm.drawer,
                modal: false,
                "append-to-body": true,
                size: "360px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.searchForm,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "素材类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择素材类型",
                                  },
                                  model: {
                                    value: _vm.searchForm.knowledgeType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "knowledgeType",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.knowledgeType",
                                  },
                                },
                                _vm._l(_vm.materialGroup, function (item) {
                                  return _c("el-option", {
                                    key: item.itemCode,
                                    attrs: {
                                      label: item.itemName,
                                      value: item.itemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "文章标题" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入文章标题",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "title", $$v)
                                  },
                                  expression: "searchForm.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "文章质量" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择文章质量",
                                    filterable: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.knowledgeAppraise,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "knowledgeAppraise",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.knowledgeAppraise",
                                  },
                                },
                                _vm._l(
                                  _vm.knowledgeAppraiseLists,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "保险公司" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择保险公司",
                                    filterable: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "insCorpCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.insCorpCode",
                                  },
                                },
                                _vm._l(_vm.supplyers, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.shortName,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "关联地区" } },
                            [
                              _c("el-cascader", {
                                ref: "address",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "请选择所在省市",
                                  options: _vm.regionOptions,
                                },
                                on: { change: _vm.getCityCode },
                                model: {
                                  value: _vm.insCityCode,
                                  callback: function ($$v) {
                                    _vm.insCityCode = $$v
                                  },
                                  expression: "insCityCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "问题类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择问题类型",
                                    filterable: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.questionType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "questionType",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.questionType",
                                  },
                                },
                                _vm._l(
                                  _vm.questionOptions,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.description,
                                        value: item.code,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "审核状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择审核状态",
                                  },
                                  model: {
                                    value: _vm.searchForm.examineStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "examineStatus",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.examineStatus",
                                  },
                                },
                                _vm._l(_vm.examineStatusList, function (item) {
                                  return _c("el-option", {
                                    key: item.itemCode,
                                    attrs: {
                                      label: item.itemName,
                                      value: item.itemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否上架" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择是否上架",
                                  },
                                  model: {
                                    value: _vm.searchForm.published,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "published", $$v)
                                    },
                                    expression: "searchForm.published",
                                  },
                                },
                                _vm._l(_vm.publishedList, function (item) {
                                  return _c("el-option", {
                                    key: item.itemCode,
                                    attrs: {
                                      label: item.itemName,
                                      value: item.itemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建人" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择创建人",
                                  },
                                  model: {
                                    value: _vm.searchForm.creatorBy,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "creatorBy", $$v)
                                    },
                                    expression: "searchForm.creatorBy",
                                  },
                                },
                                _vm._l(_vm.userList, function (item) {
                                  return _c("el-option", {
                                    key: item.userId,
                                    attrs: {
                                      label: item.userName,
                                      value: item.userId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建时间" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "start-placeholder": "起期",
                                  "end-placeholder": "止期",
                                  type: "daterange",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "unlink-panels": "",
                                  "range-separator": "——",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.dateChange(
                                      "createdTimeStart",
                                      "createdTimeEnd",
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.createdStartEndTime,
                                  callback: function ($$v) {
                                    _vm.createdStartEndTime = $$v
                                  },
                                  expression: "createdStartEndTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "searchBtnStyle" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  icon: "el-icon-refresh-left",
                                },
                                on: { click: _vm.resetSearch },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchData },
                              },
                              [_vm._v("查询")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "table-cls normal-btn-cls",
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticClass: "search-box",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("文章标题：")]),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "40",
                          size: "mini",
                          placeholder: "请输入文章标题",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "title", $$v)
                          },
                          expression: "searchForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("保险公司：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: {
                            placeholder: "请选择保险公司",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.insCorpCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "insCorpCode", $$v)
                            },
                            expression: "searchForm.insCorpCode",
                          },
                        },
                        _vm._l(_vm.supplyers, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.shortName, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("审核状态：")]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "mini",
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择审核状态",
                          },
                          model: {
                            value: _vm.searchForm.examineStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "examineStatus", $$v)
                            },
                            expression: "searchForm.examineStatus",
                          },
                        },
                        _vm._l(_vm.examineStatusList, function (item) {
                          return _c("el-option", {
                            key: item.itemCode,
                            attrs: {
                              label: item.itemName,
                              value: item.itemCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("是否上架：")]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "mini",
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择是否上架",
                          },
                          model: {
                            value: _vm.searchForm.published,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "published", $$v)
                            },
                            expression: "searchForm.published",
                          },
                        },
                        _vm._l(_vm.publishedList, function (item) {
                          return _c("el-option", {
                            key: item.itemCode,
                            attrs: {
                              label: item.itemName,
                              value: item.itemCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh-left" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchData },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.refreshClicked },
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.addClicked },
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.exportFn },
                        },
                        [_vm._v("导出")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = true
                            },
                          },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightJD,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "knowledgeTypeDisplay",
                      label: "素材类型",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "文章标题",
                      "min-width": "360",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "insCorpName",
                      label: "保险公司",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "examineStatusDisplay",
                      label: "审核状态",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "knowledgeAppraiseDisplay",
                      label: "文章质量",
                      width: "90",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cityName",
                      label: "关联地区",
                      width: "90",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "questionTypeDisplay",
                      label: "问题类型",
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "publishedDisplay",
                      label: "是否上架",
                      width: "90",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                disabled: scope.row.examineStatus !== "KEE02",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.setPublished(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.published,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "published", $$v)
                                },
                                expression: "scope.row.published",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creatorBy",
                      label: "创建人",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "modifierBy",
                      label: "修改人",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "examineBy",
                      label: "审核人",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "examineTime",
                      label: "审核时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "examineRemark",
                      label: "审核备注",
                      "min-width": "300",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdTime",
                      label: "创建时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "modifiedTime",
                      label: "更新时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "200",
                      align: "left",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.viewDetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            scope.row.examineStatus !== "KEE02"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.editDetail(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            scope.row.examineStatus === "KEE02" &&
                            scope.row.published === false
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.editDetail(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            scope.row.examineStatus !== "KEE02"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "danger",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delArticle(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.pageNum,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.loadData()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }