import _defineProperty from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sysManageApi from '@/api/sysManage';
import Rules from "@/utils/rules";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      var usernamereg = /^[a-z0-9]+$/i;
      if (!value) {
        callback(new Error('请输入用户名'));
      } else if (value.length < 6) {
        callback(new Error("请输入6-32位"));
      } else if (!usernamereg.test(value)) {
        callback(new Error("用户名必须使用字母、数字、或字母和数字组合"));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      var passwordreg = /^(((?=.*[0-9])(?=.*[a-zA-Z])|(?=.*[0-9])(?=.*[^\s0-9a-zA-Z])|(?=.*[a-zA-Z])(?=.*[^\s0-9a-zA-Z]))[^\s]+)$/;
      if (value.length < 8) {
        callback(new Error('请输入8-16位'));
      } else if (!passwordreg.test(value)) {
        callback(new Error('密码必须包含数字、字母、特殊字符中至少两种'));
      } else {
        callback();
      }
    };
    return {
      form: _defineProperty({
        account: '',
        birthday: '',
        bizType: 'PLATFORM',
        email: '',
        expiredDate: '',
        password: '',
        phone: '',
        remark: '',
        roleId: [],
        sex: 'MALE',
        tenantId: 0,
        userName: ''
      }, "tenantId", '100001'),
      rules: {
        account: {
          required: true,
          trigger: 'blur',
          validator: validateUsername
        },
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        userName: {
          required: true,
          message: '请输入姓名',
          trigger: "blur"
        },
        phone: [{
          required: true,
          message: "请输入联系电话",
          trigger: "blur"
        }, {
          pattern: Rules["MOBILE_RULE"].value,
          message: "联系电话格式有误",
          trigger: ["blur", "change"]
        }],
        roleId: {
          required: true,
          message: '请选择系统角色',
          trigger: "change"
        }
      },
      formLoading: false,
      roleIdList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = _defineProperty({
        account: '',
        birthday: '',
        bizType: 'PLATFORM',
        email: '',
        expiredDate: '',
        password: '',
        phone: '',
        remark: '',
        roleId: [],
        sex: 'MALE',
        tenantId: 0,
        userName: ''
      }, "tenantId", '100001');
      this.roleIdList = [];
      sysManageApi.getRoleDropdownList().then(function (res) {
        if (res.code === 200) {
          _this.roleIdList = res.data || [];
        }
        ;
      });
      if (this.opType === "edit") {
        this.formLoading = true;
        sysManageApi.getUsersInfo(this.currentId).then(function (res) {
          _this.formLoading = false;
          if (res.code === 200) {
            _this.form = res.data;
            _this.$set(_this.form, 'roleId', _this.form.roleIds);
          }
          ;
        }).catch(function (err) {
          _this.formLoading = false;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          var req = _this2.opType === 'add' ? sysManageApi.createUsers(_this2.form) : sysManageApi.updateUsers(_this2.currentId, _this2.form);
          req.then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};