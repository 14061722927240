import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//

import tinymce from 'tinymce/tinymce';
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/themes/silver';
import 'tinymce/icons/default/icons';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/save';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/media';
import 'tinymce/skins/ui/oxide/skin.css';
// import {
//     client,
//     headers,
//     getFileNameUUID
// } from '@/utils/alioss.js'
export default {
  name: 'MyTiny',
  components: {
    Editor: Editor
  },
  props: {
    htmlContent: {
      type: String,
      default: ''
    },
    materialId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var that = this;
    return {
      articleContent: that.htmlContent,
      isInit: false,
      //是否初始化
      editorInit: {
        language_url: '/tinymce/zh-CN.js',
        //指定中文包
        language: 'zh_CN',
        //中文
        skin_url: '/tinymce/skins/ui/oxide',
        //编辑器皮肤，
        height: 500,
        //高度
        browser_spellcheck: false,
        // 拼写检查
        branding: false,
        // 去水印
        elementpath: false,
        // 禁用编辑器底部的状态栏
        statusbar: false,
        // 隐藏编辑器底部的状态栏
        paste_data_images: false,
        // 允许粘贴图像
        placeholder: "在这里输入文字",
        menubar: false,
        // menubar: 'file edit subscript',
        plugins: 'advlist image lists preview table link media',
        //字符串方式
        toolbar: ['undo redo removeformat newdocument | fontselect | fontsizeselect | forecolor backcolor | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | blockquote | subscript superscript | image | link media | table tabledelete'],
        font_formats: "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;新罗马字体=TimeNewRoman;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px',
        // images_upload_handler: (blobInfo, succFun, failFun) => {
        //     let file = blobInfo.blob(); //转化为易于理解的file对象
        //     let year = new Date().getFullYear();
        //     let month = new Date().getMonth() + 1;
        //     let day = new Date().getDate();
        //     let fileName = `image/${year}-${month}-${day}/${Date.parse(new Date())}` + '.jpg';
        //     client.put(fileName, file).then((res) => {
        //         let coverUrl = res.res.requestUrls[0];
        //         succFun(coverUrl)
        //     })
        // },
        file_picker_types: 'file image media',
        // file_picker_callback: (callback, value, meta) => {
        //     //文件分类
        //     let filetype = '.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4';
        //     if (meta.filetype === "media") {
        //         // 模拟上传本地视频
        //         let input = document.createElement("input");
        //         input.setAttribute("type", "file");
        //         input.setAttribute("accept", ".mp4");
        //         input.onchange = function () {
        //             let file = this.files[0];
        //             let year = new Date().getFullYear();
        //             let month = new Date().getMonth() + 1;
        //             let day = new Date().getDate();
        //             let fileName = `video/${year}-${month}-${day}/${Date.parse(new Date())}` + '.mp4';
        //             client.put(fileName, file).then((res) => {
        //                 let viderSrc = res.res.requestUrls[0]
        //                 callback(viderSrc)
        //             })
        //         };
        //         input.click();
        //     }
        // },
        setup: function setup(editor) {
          // 初次化编辑器
          editor.on('init', function () {
            that.isInit = true; //告知是初始化\
            if (that.htmlContent) {
              editor.setContent(that.htmlContent);
              that.isInit = false;
            }
            that.$forceUpdate();
            editor.on('input change undo redo', function (res) {
              //此处设置为false很好解决光标前置问题
              that.isInit = false;
            });
          });
        }
      }
    };
  },
  watch: {
    materialId: function materialId(newVal) {
      if (newVal) {
        // 文章编辑切换时每次初始化
        this.isInit = true;
      }
    },
    htmlContent: function htmlContent(newValue) {
      if (newValue) {
        console.log('是否初始化isInit', this.isInit);
        if (this.isInit) {
          this.isInit = false;
          this.$nextTick(function () {
            if (tinymce.get('tinymce')) {
              var editor = tinymce.get('tinymce');
              editor.setContent(newValue);
            }
          });
        }
      }
    },
    articleContent: function articleContent(newValue) {
      this.$emit("setInput", newValue);
    }
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      tinymce.init({
        selector: '#tinymce',
        inline: true
      });
    });
  },
  methods: {
    transContent: function transContent(str) {
      if (!str) return str;
      var arr = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"'
      };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arr[t];
      });
    }
  }
};