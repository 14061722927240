import _interopRequireWildcard from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import Layout from "@/layout";
import appMain from "@/layout/components/AppMain";
export var constantRoutes = [{
  path: "/redirect",
  component: Layout,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/auth",
  name: 'auth',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/login/auth"));
    });
  },
  hidden: true
}, {
  path: "/knowledgeAuditorLogin",
  name: "knowledgeAuditorLogin",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/knowledgeAuditor/login"));
    });
  },
  hidden: true
}, {
  path: "/knowledgeAuditorList",
  name: "knowledgeAuditorList",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/knowledgeAuditor/list"));
    });
  },
  hidden: true
}, {
  path: "/knowledgeAuditorDetail",
  name: "knowledgeAuditorDetail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/knowledgeAuditor/index"));
    });
  },
  hidden: true
}, {
  path: "/qwAuthRedirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/login/qwAuthRedirect"));
    });
  },
  hidden: true
}, {
  path: "/article/detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/material/article/detail"));
    });
  },
  hidden: true
}, {
  path: "/insuranceKnowledge",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/material/autoInsurance/knowledge"));
    });
  },
  hidden: true
}, {
  path: "/403",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/material/autoInsurance/403"));
    });
  },
  hidden: true
}, {
  path: "/autoInsurance/detail",
  name: "MaterialAutoInsuranceDetail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/material/autoInsurance/detail"));
    });
  },
  hidden: true
}, {
  path: "/chat",
  name: "Chat",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/chat"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/",
  component: Layout,
  meta: {
    title: "工作台",
    icon: "workbench"
  },
  redirect: "/welcome",
  children: [{
    path: "/welcome",
    name: "Welcome",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/dashboard/index"));
      });
    },
    meta: {
      title: "首页",
      icon: "el-icon-s-home",
      affix: false,
      keepAlive: true
    }
  }, {
    path: "/myExportLog",
    name: "MyExportLog",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/operationMonitor/myExportLog"));
      });
    },
    meta: {
      title: "我的导出",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/myMessageTable",
    name: "MyMessageTable",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sysManage/messagePlatform/messageTable"));
      });
    },
    meta: {
      title: "我的消息",
      icon: "list",
      keepAlive: true
    }
  }]
}, {
  path: "/customerManagement",
  component: Layout,
  redirect: "/customerManagement/enterpriseManagement",
  meta: {
    title: "客户管理",
    icon: "peoples"
  },
  children: [{
    path: "/customerManagement/customerHighSeas",
    name: "CustomerHighSeas",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/customerHighSeas"));
      });
    },
    meta: {
      title: "客户公海",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/customerManagement/myHighSeas",
    name: "MyHighSeas",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/myHighSeas"));
      });
    },
    meta: {
      title: "我的公海",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/customerManagement/enterpriseManagement",
    name: "EnterpriseManagement",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/enterpriseManagement"));
      });
    },
    meta: {
      title: "企业管理",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/customerManagement/customerList",
    name: "CustomerList",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/customerList"));
      });
    },
    meta: {
      title: "客户列表",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/customerManagement/myCustomer",
    name: "MyCustomer",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/myCustomer"));
      });
    },
    meta: {
      title: "我的客户",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/customerManagement/customerDetail",
    name: "CustomerDetail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/customerList/detail"));
      });
    },
    meta: {
      title: "客户详情",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/customerManagement/customerBase",
    name: "CustomerBase",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/customerBase"));
      });
    },
    meta: {
      title: "客户群",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/customerManagement/customerBaseDetail",
    name: "CustomerBaseDetail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/customerBase/detail"));
      });
    },
    meta: {
      title: "客户群详情",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/customerManagement/customerLabel",
    name: "CustomerLabel",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/customerLabel"));
      });
    },
    meta: {
      title: "客户标签",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/customerManagement/groupLabelManage",
    name: "GroupLabelManage",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/groupLabel/tagManage"));
      });
    },
    meta: {
      title: "群标签管理",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/customerManagement/groupChatTagList",
    name: "GroupChatTagList",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/groupLabel/groupChatTagList"));
      });
    },
    meta: {
      title: "群标签列表",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/customerManagement/trackList",
    name: "trackList",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/trackList/index"));
      });
    },
    meta: {
      title: "跟进明细",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/customerManagement/transactionRecords",
    name: "TransactionRecords",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/customerManagement/transactionRecords"));
      });
    },
    meta: {
      title: "成交记录",
      icon: "list",
      keepAlive: true
    }
  }]
}, {
  path: "/material/article",
  component: Layout,
  redirect: "/material/article",
  meta: {
    title: "素材管理",
    icon: "tree-table"
  },
  children: [{
    path: "/material/article",
    name: "MaterialArticle",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/material/article/index"));
      });
    },
    meta: {
      title: "文章列表",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/material/article/add",
    name: "MaterialAddArticle",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/material/article/add"));
      });
    },
    meta: {
      title: "新增文章",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/material/article/edit",
    name: "MaterialEditArticle",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/material/article/edit"));
      });
    },
    meta: {
      title: "文章编辑",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/material/picture",
    name: "MaterialPicture",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/material/picture/index"));
      });
    },
    meta: {
      title: "图片素材库",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/material/autoInsurance",
    name: "MaterialAutoInsurance",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/material/autoInsurance/index"));
      });
    },
    meta: {
      title: "车险知识",
      icon: "list",
      keepAlive: true
    }
    // hidden:true,
  }, {
    path: "/material/autoInsurance/add",
    name: "MaterialAutoInsuranceAdd",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/material/autoInsurance/add"));
      });
    },
    meta: {
      title: "新增车险知识",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/material/autoInsurance/edit",
    name: "MaterialAutoInsuranceEdit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/material/autoInsurance/edit"));
      });
    },
    meta: {
      title: "编辑车险知识",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/material/autoInsurance/auditor",
    name: "MaterialAutoInsuranceAuditor",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/material/autoInsurance/auditor"));
      });
    },
    meta: {
      title: "车险知识审核列表",
      icon: "list",
      keepAlive: true
    }
    // hidden:true,
  }, {
    path: "/material/autoInsurance/checkKeyWords",
    name: "MaterialAutoInsuranceCheckKeyWords",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/material/autoInsurance/checkKeyWords"));
      });
    },
    meta: {
      title: "车险知识关键词验证",
      icon: "list",
      keepAlive: true
    }
    // hidden:true,
  }]
}, {
  path: "/questionnaire",
  component: Layout,
  redirect: "/questionnaire",
  meta: {
    title: "问卷管理",
    icon: "odometer"
  },
  children: [{
    path: "/questionnaire",
    name: "questionnaire",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/questionnaire/index"));
      });
    },
    meta: {
      title: "问卷填写情况",
      icon: "list",
      keepAlive: true
    }
  }]
}, {
  path: "/marketingManage",
  component: Layout,
  redirect: "/marketingManage/index",
  meta: {
    title: "营销管理",
    icon: "tree-table"
  },
  children: [{
    path: "/marketingManage/rules",
    name: "marketingRules",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/rules/index"));
      });
    },
    meta: {
      title: "营销规则",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/marketingManage/addRules",
    name: "marketingAddRules",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/rules/add"));
      });
    },
    meta: {
      title: "营销规则",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/marketingManage/active",
    name: "marketingActive",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/active/index"));
      });
    },
    meta: {
      title: "营销活动",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/marketingManage/active/add",
    name: "marketingAddActive",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/active/add"));
      });
    },
    meta: {
      title: "新增营销活动",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/marketingManage/index",
    name: "marketingManage",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/list/index"));
      });
    },
    meta: {
      title: "营销管理",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/marketingManage/add",
    name: "AddMarketing",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/list/add"));
      });
    },
    meta: {
      title: "新建任务",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/marketingManage/list",
    name: "marketingList",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/list/list"));
      });
    },
    meta: {
      title: "任务列表",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/sopTemplate/index",
    name: "sopTemplateIndex",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/sopTemplate/index"));
      });
    },
    meta: {
      title: "SOP模版",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/sopTemplate/add",
    name: "sopTemplateAdd",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/sopTemplate/add"));
      });
    },
    meta: {
      title: "创建SOP模版",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/sopTemplate/edit",
    name: "sopTemplateEdit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/sopTemplate/edit"));
      });
    },
    meta: {
      title: "编辑SOP模版",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }, {
    path: "/welcomeSpeech/index",
    name: "welcomeSpeech",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/welcomeSpeech/index"));
      });
    },
    meta: {
      title: "欢迎语配置",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/welcomeSpeech/add",
    name: "welcomeSpeechAdd",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/marketingManage/welcomeSpeech/add"));
      });
    },
    meta: {
      title: "新增欢迎语配置",
      icon: "list",
      keepAlive: true
    },
    hidden: true
  }]
}, {
  path: "/operationMonitor",
  component: Layout,
  redirect: "/operationMonitor/sysExportMonitor",
  meta: {
    title: "运行监控",
    icon: "odometer"
  },
  children: [{
    path: "/operationMonitor/sysExportMonitor",
    name: "SysExportMonitor",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/operationMonitor/sysExportMonitor"));
      });
    },
    meta: {
      title: "系统导出监控",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/material/knowledgeMonitoring",
    name: "KnowledgeMonitoring",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/material/monitoring/index"));
      });
    },
    meta: {
      title: "车险知识调用监控",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/jobConfig/taskLog",
    name: "TaskLog",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/jobConfig/taskLog"));
      });
    },
    meta: {
      title: "计划任务监控",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: '/systemLog',
    name: 'SystemLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/systemLog'));
      });
    },
    meta: {
      title: '系统日志',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: "/operate/insCorpMaintain",
  component: Layout,
  redirect: "/operate/insCorpMaintain",
  meta: {
    title: "运营管理",
    icon: "odometer"
  },
  children: [{
    path: "/operate/insCorpMaintain",
    name: "insCorpMaintain",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/operate/insCorpMaintain"));
      });
    },
    meta: {
      title: "保司维护",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/operate/product",
    name: "Product",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/operate/product"));
      });
    },
    meta: {
      title: "产品管理",
      icon: "list",
      keepAlive: true
    }
  }]
}, {
  path: "/jobConfig",
  component: Layout,
  redirect: "/jobConfig",
  meta: {
    title: "运维管理",
    icon: "odometer"
  },
  children: [{
    path: "/jobConfig",
    name: "jobConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/jobConfig/index"));
      });
    },
    meta: {
      title: "计划任务配置",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/provincesMaintenance",
    name: "provincesMaintenance",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/provincesMaintenance/index"));
      });
    },
    meta: {
      title: "省市区维护",
      icon: "list",
      keepAlive: true
    }
  }]
}, {
  path: "/sysManage",
  component: Layout,
  redirect: "/sysManage/roleManage",
  meta: {
    title: "系统管理",
    icon: "setting"
  },
  children: [{
    path: "/sysManage/roleManage",
    name: "RoleManage",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sysManage/roleManage"));
      });
    },
    meta: {
      title: "角色管理",
      icon: "peoples",
      keepAlive: true
    }
  }, {
    path: "/sysManage/userManage",
    name: "UserManage",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sysManage/userManage"));
      });
    },
    meta: {
      title: "用户管理",
      icon: "peoples",
      keepAlive: true
    }
  }, {
    path: "/sysManage/memberManage",
    name: "MemberManage",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sysManage/memberManage"));
      });
    },
    meta: {
      title: "成员管理",
      icon: "peoples",
      keepAlive: true
    }
  }, {
    path: "/sysManage/enterpriseConfig",
    name: "EnterpriseConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sysManage/enterpriseConfig"));
      });
    },
    meta: {
      title: "企业配置",
      icon: "added-service",
      keepAlive: true
    }
  }, {
    path: "/weixinConfig/index",
    name: "WeixinConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/weixinConfig/index"));
      });
    },
    meta: {
      title: "微信配置",
      icon: "added-service",
      keepAlive: true
    }
  }, {
    path: "/sysManage/scan",
    name: "scanConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sysManage/scan/index"));
      });
    },
    meta: {
      title: "企微扫码授权",
      icon: "added-service",
      keepAlive: true
    }
  }, {
    path: "/sysManage/openPlatform",
    component: appMain,
    redirect: "/openPlatform/routeManage",
    meta: {
      title: "开放平台",
      icon: "component"
    },
    children: [{
      path: "/openPlatform/routeManage",
      name: "RouteManage",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/openPlatform/routeManage"));
        });
      },
      meta: {
        title: "路由管理",
        icon: "list",
        keepAlive: true
      }
    }, {
      path: "/openPlatform/routingGroupManage",
      name: "RoutingGroupManage",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/openPlatform/routingGroupManage"));
        });
      },
      meta: {
        title: "路由组管理",
        icon: "list",
        keepAlive: true
      }
    }, {
      path: "/openPlatform/accessApplicationManage",
      name: "AccessApplicationManage",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/openPlatform/accessApplicationManage"));
        });
      },
      meta: {
        title: "接入应用管理",
        icon: "list",
        keepAlive: true
      }
    }, {
      path: "/openPlatform/externalAccessMonitor",
      name: "ExternalAccessMonitor",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/openPlatform/externalAccessMonitor"));
        });
      },
      meta: {
        title: "接入应用监控",
        icon: "list",
        keepAlive: true
      }
    }]
  }, {
    path: "/sysManage/dataInteractionPlatform",
    component: appMain,
    redirect: "/dataInteractionPlatform/pushDataConfig",
    meta: {
      title: "数据交互平台",
      icon: "component"
    },
    children: [{
      path: "/dataInteractionPlatform/pushDataConfig",
      name: "PushDataConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/dataInteractionPlatform/pushDataConfig"));
        });
      },
      meta: {
        title: "数据类型配置",
        icon: "list",
        keepAlive: true
      }
    }, {
      path: "/dataInteractionPlatform/externalSubscripConfig",
      name: "ExternalSubscripConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/dataInteractionPlatform/externalSubscripConfig"));
        });
      },
      meta: {
        title: "外部数据源配置",
        icon: "list",
        keepAlive: true
      }
    }, {
      path: "/externalAccessConfig",
      name: "ExternalAccessConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/dataInteractionPlatform/externalAccessConfig"));
        });
      },
      meta: {
        title: "外部接入配置",
        icon: "list",
        keepAlive: true
      },
      hidden: true
    }, {
      path: "/dataInteractionPlatform/externalSubscripMonitor",
      name: "ExternalSubscripMonitor",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/dataInteractionPlatform/externalSubscripMonitor"));
        });
      },
      meta: {
        title: "外部交互监控",
        icon: "list",
        keepAlive: true
      }
    }, {
      path: "/dataInteractionPlatform/internalSubscripConfig",
      name: "InternalSubscripConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/dataInteractionPlatform/internalSubscripConfig"));
        });
      },
      meta: {
        title: "内部数据源配置",
        icon: "list",
        keepAlive: true
      }
    }, {
      path: "/dataInteractionPlatform/internalSubscripMonitor",
      name: "InternalSubscripMonitor",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/dataInteractionPlatform/internalSubscripMonitor"));
        });
      },
      meta: {
        title: "内部交互监控",
        icon: "list",
        keepAlive: true
      }
    }]
  }, {
    path: "/sysManage/storageServiceConfig",
    name: "StorageServiceConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sysManage/storageServiceConfig"));
      });
    },
    meta: {
      title: "存储服务配置",
      icon: "list",
      keepAlive: true
    }
  }, {
    path: "/sysManage/dictManage",
    name: "DictManage",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sysManage/dictManage"));
      });
    },
    meta: {
      title: "字典管理",
      icon: "dicts",
      keepAlive: true
    }
  }, {
    path: "/sysManage/manageChatSensitiveWords",
    name: "ManageChatSensitiveWords",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sysManage/manageChatSensitiveWords"));
      });
    },
    meta: {
      title: "管理聊天敏感词",
      icon: "dicts",
      keepAlive: true
    }
  }, {
    path: "/sysManage/messagePlatform",
    component: appMain,
    redirect: "/messagePlatform/messageTypeConfig",
    meta: {
      title: "消息平台",
      icon: "added-service"
    },
    children: [{
      path: "/messagePlatform/messageTypeConfig",
      name: "MessageTypeConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/messagePlatform/messageTypeConfig"));
        });
      },
      meta: {
        title: "消息类型配置",
        icon: "form",
        keepAlive: true
      }
    }, {
      path: "/messagePlatform/messageNotifyChannel",
      name: "MessageNotifyChannel",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/messagePlatform/messageNotifyChannel"));
        });
      },
      meta: {
        title: "消息通知渠道配置",
        icon: "form",
        keepAlive: true
      }
    }, {
      path: "/messagePlatform/messageTemplateManage",
      name: "MessageTemplateManage",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/messagePlatform/messageTemplateManage"));
        });
      },
      meta: {
        title: "消息模版管理",
        icon: "form",
        keepAlive: true
      }
    }, {
      path: "/messagePlatform/messageTemplateManageAdd",
      name: "MessageTemplateManageAdd",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/messagePlatform/messageTemplateManageAdd"));
        });
      },
      meta: {
        title: "新增消息模版管理",
        icon: "form"
      },
      hidden: true
    }, {
      path: "/messagePlatform/messageTable",
      name: "MessageTable",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/messagePlatform/messageTable"));
        });
      },
      meta: {
        title: "系统消息记录",
        icon: "form",
        keepAlive: true
      }
    }, {
      path: "/messagePlatform/msgDetailPage",
      name: "MsgDetailPage",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/sysManage/messagePlatform/msgDetailPage"));
        });
      },
      meta: {
        title: "消息详情",
        icon: "form"
      },
      hidden: true
    }]
  }]
}];
export var asyncRoutes = [{
  path: "*",
  redirect: "/404",
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    base: "".concat(process.env.VUE_APP_ROOT_PATH),
    mode: "history",
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;