var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "verifyBox" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "mask",
        },
        [
          _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: true,
                expression: "true",
              },
            ],
            staticStyle: { width: "100%", height: "100%" },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "rowBox" },
        [
          _c(
            "el-row",
            { staticStyle: { width: "100%" } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "headerTitle box-card" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.insCorpName) +
                      ": " +
                      _vm._s(_vm.docTitle) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { xs: 23, sm: 16, md: 14, lg: 12, xl: 10 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box-card",
                      staticStyle: { padding: "0 20px" },
                    },
                    [
                      _c("div", {
                        staticClass: "contents",
                        attrs: { id: "content_div" },
                        domProps: { innerHTML: _vm._s(_vm.knowledgeBody) },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { border: "0" },
                  attrs: { xs: 23, sm: 16, md: 14, lg: 12, xl: 10 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box-card",
                      staticStyle: { padding: "0 20px" },
                    },
                    [
                      _vm.keywordsList && _vm.keywordsList.length != 0
                        ? _c("div", { staticClass: "keywordsBox" }, [
                            _c("div", { staticClass: "keywordsItemTitle" }, [
                              _vm._v(" 关键字： "),
                            ]),
                            _c(
                              "div",
                              { staticClass: "keywordsItemList" },
                              _vm._l(_vm.keywordsList, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "keywordsItem" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.keywordsContent) + " "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "isHelpful" }, [
                        _c("div", { staticClass: "itemTitle" }, [
                          _vm._v("文章是否对你有帮助？"),
                        ]),
                        _c("div", { staticClass: "chose" }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.giveALike(true)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "class-name": "zan",
                                  "icon-class": "zan",
                                },
                              }),
                              _c("div", [
                                _vm._v("有用（" + _vm._s(_vm.usefulNum) + "）"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.giveALike(false)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "class-name": "cai",
                                  "icon-class": "cai",
                                },
                              }),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "5px" } },
                                [
                                  _vm._v(
                                    "没用（" + _vm._s(_vm.uselessNum) + "）"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("viewer", { attrs: { images: _vm.images } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }