var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          "label-width": "100px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            model: {
                              value: _vm.activeNames,
                              callback: function ($$v) {
                                _vm.activeNames = $$v
                              },
                              expression: "activeNames",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-between",
                                      staticStyle: {
                                        width: "100%",
                                        "line-height": "25px",
                                      },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "基本信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-descriptions",
                                  {
                                    staticClass: "margin-top",
                                    attrs: {
                                      title: "",
                                      column: 3,
                                      border: "",
                                      labelStyle: { width: "110px" },
                                      contentStyle: { width: "300px" },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "群名称" } },
                                      [_vm._v(_vm._s(_vm.form.groupChatName))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "群主" } },
                                      [_vm._v(_vm._s(_vm.form.owner))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "群人数" } },
                                      [_vm._v(_vm._s(_vm.form.userCount))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "创建时间" } },
                                      [_vm._v(_vm._s(_vm.form.chatCreateTime))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "群公告" } },
                                      [_vm._v(_vm._s(_vm.form.notice))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "table-cls normal-btn-cls" },
                  [
                    _c(
                      "el-card",
                      {
                        style: { height: _vm.tableHeightSS, overflow: "auto" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "el-tabs",
                          {
                            model: {
                              value: _vm.tabActiveName,
                              callback: function ($$v) {
                                _vm.tabActiveName = $$v
                              },
                              expression: "tabActiveName",
                            },
                          },
                          [
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "成员信息",
                                  name: "MEMBERINFO",
                                },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      size: "mini",
                                      data: _vm.form.groupChatMemberList,
                                      height: _vm.tableHeight,
                                      "header-cell-style": {
                                        background: "#F7F7F7",
                                      },
                                      "element-loading-text": "加载中...",
                                      border: "",
                                      "highlight-current-row": "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "name",
                                        label: "群成员",
                                        "min-width": "150",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "typeDisplay",
                                        label: "关系",
                                        "min-width": "150",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "joinTime",
                                        label: "进群时间",
                                        width: "150",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "joinSceneDisplay",
                                        label: "进群方式",
                                        "min-width": "150",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c("div", { staticClass: "footer-btn-list" }, [
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", icon: "el-icon-back" },
                        on: { click: _vm.closePage },
                      },
                      [_vm._v("返回")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }