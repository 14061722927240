/**
 * 车险知识管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 调用监控--不分页查询
   */
  getKnowledgemonitorList: function getKnowledgemonitorList(data) {
    return request.get(getUrl("/knowledgemonitor/list", 8), {
      params: data
    });
  },
  /**
   * 调用监控--分页查询
   */
  getKnowledgemonitorSearch: function getKnowledgemonitorSearch(data) {
    return request.get(getUrl("/knowledgemonitor/search", 8), {
      params: data
    });
  },
  /**
   * 调用监控--查看
   */
  getKnowledgemonitorDetail: function getKnowledgemonitorDetail(id) {
    return request.get(getUrl("/knowledgemonitor/get?id=".concat(id), 8));
  },
  /**
   * 关键词--新增
   */
  createKeywords: function createKeywords(data) {
    return request.post(getUrl("/knowledge/keywords/create", 8), data);
  },
  /**
   * 关键词--删除
   */
  batchDeleteKeywords: function batchDeleteKeywords(id) {
    return request.post(getUrl("/knowledge/keywords/batchDelete?keywordsIds=".concat(id), 8));
  },
  /**
   * 车险知识--分页查询
   */
  getKnowledgeList: function getKnowledgeList(data) {
    return request.get(getUrl("/knowledge/search", 8), {
      params: data
    });
  },
  /**
   * 车险知识--新增申请
   */
  createKnowledge: function createKnowledge(data) {
    return request.post(getUrl("/knowledge/propose", 8), data);
  },
  /**
   * 车险知识--审核
   */
  examineKnowledge: function examineKnowledge(id, data) {
    return request.post(getUrl("/knowledge/examine?knowledgeId=".concat(id), 8), data);
  },
  /**
   * 车险知识--编辑更新
   */
  updateKnowledge: function updateKnowledge(id, data) {
    return request.post(getUrl("/knowledge/update?knowledgeId=".concat(id), 8), data);
  },
  /**
   * 车险知识--上架下架
   */
  publishedKnowledge: function publishedKnowledge(id) {
    return request.post(getUrl("/knowledge/published?knowledgeId=".concat(id), 8));
  },
  /**
   * 车险知识--分享页面验证
   */
  checkKnowledge: function checkKnowledge(data) {
    return request.post(getUrl("/knowledge/getKnowledge", 8), data);
  },
  /**
   * 车险知识--关键字验证
   */
  checkedKnowledgeSearch: function checkedKnowledgeSearch(data) {
    return request.post(getUrl("/solution/search", 8), data);
  },
  /**
   * 车险知识--原始问题验证
   */
  checkedKnowledgeValid: function checkedKnowledgeValid(data) {
    return request.post(getUrl("/solution/valid", 8), data);
  },
  /**
   * 素材库--删除
   */
  delKnowledge: function delKnowledge(ids) {
    return request.delete(getUrl("/knowledge/delete?knowledgeId=".concat(ids), 8));
  }
};
export default objApi;