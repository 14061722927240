import _createForOfIteratorHelper from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import request from '@/api/jobConfig';
import dictRequest from '@/api/dictionary';
import { commonExport } from '@/utils/common';
export default {
  name: "jobConfig",
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight],
  data: function data() {
    var _jobFormRules;
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      showJobConfigDialog: false,
      formLoading: false,
      jobForm: {
        serviceCode: '',
        serviceName: '',
        configList: []
      },
      jobServiceList: [],
      jobFormRules: (_jobFormRules = {
        jobName: [{
          required: true,
          message: '请输入任务名称',
          trigger: 'blur'
        }],
        serviceCode: [{
          required: true,
          message: '请输入服务名称',
          trigger: 'blur'
        }]
      }, _defineProperty(_jobFormRules, "serviceCode", [{
        required: true,
        message: '请输入服务名称',
        trigger: 'change'
      }]), _defineProperty(_jobFormRules, "jobName", [{
        required: true,
        message: '请输入服务名称',
        trigger: 'blur'
      }]), _defineProperty(_jobFormRules, "jobCode", [{
        required: true,
        message: '请输入任务编码',
        trigger: 'blur'
      }]), _jobFormRules),
      jobConfigId: null,
      opType: ""
    };
  },
  watch: {
    showJobConfigDialog: function showJobConfigDialog(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          return _this.$refs['jobForm'].clearValidate();
        });
      }
    }
  },
  created: function created() {
    this.getDictItemsByCode();
    this.loadData();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: "计划任务配置列表",
        exportUrl: '/tp-core-service/job/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs['multipleTable']);
    },
    //执行任务
    implementJobClicked: function implementJobClicked(row) {
      var _this2 = this;
      this.$confirm('是否确认执行?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var dataTableData = _.cloneDeep(_this2.tableData);
        var jobItem = _.find(dataTableData, function (item) {
          return item.jobId === row.jobId;
        });
        jobItem.disabled = true;
        _this2.tableData = dataTableData;
        _this2.implementJob1(row);
      }).catch(function () {});
    },
    //执行任务
    implementJob1: function implementJob1(row) {
      var _this3 = this;
      request.implementJob(row.jobCode, row.serviceCode).then(function (res) {
        if (res && res.code == 200) {
          _this3.$notify({
            type: 'success',
            title: '提示',
            message: "\u4EFB\u52A1\u540D\u79F0\u4E3A\u2018".concat(row.jobName, "\u2019").concat(res.msg),
            duration: 0
          });
        }
      });
    },
    getServiceNameByJobCode: function getServiceNameByJobCode(val) {
      var jobServive = _.find(this.jobServiceList, function (item) {
        return item.dictCode == val;
      });
      if (jobServive) this.jobForm.serviceName = jobServive.dictName;
      this.getConfigInfoByJobCode(val, this.jobForm.jobCode);
    },
    getConfigInfoByJobCode: function getConfigInfoByJobCode(serviceCode, jobCode) {
      var _this4 = this;
      this.jobForm.configList = [];
      if (jobCode && serviceCode) {
        request.getConfigByJobCode(jobCode, serviceCode).then(function (res) {
          _this4.formLoading = false;
          if (res.code == 200 && res.data) {
            _this4.jobForm.configList = res.data.items || [];
            if (_this4.jobForm.jobConfigJson) {
              var jobConfigJson = JSON.parse(_this4.jobForm.jobConfigJson);
              _this4.jobForm.configList.forEach(function (item) {
                for (var key in jobConfigJson) {
                  if (key == item.storeKey) {
                    item.storeValue = jobConfigJson[key];
                  }
                }
              });
            }
          }
        });
      }
    },
    getDetailByjobId: function getDetailByjobId() {
      var _this5 = this;
      this.formLoading = true;
      request.getDetailByjobId(this.jobConfigId).then(function (res) {
        if (res.code == 200) {
          _this5.jobForm = _objectSpread(_objectSpread({}, res.data), {}, {
            configList: []
          });
          if (_this5.jobForm.serviceCode && _this5.jobForm.jobCode) {
            _this5.getServiceNameByJobCode(_this5.jobForm.serviceCode);
          }
        }
      });
    },
    createJobConfig: function createJobConfig() {
      this.opType = 'add';
      this.showJobConfigDialog = true;
      this.formLoading = false;
      this.jobForm = {
        enabled: true,
        configList: []
      };
    },
    getDictItemsByCode: function getDictItemsByCode() {
      var _this6 = this;
      //获取字典
      this.formLoading = false;
      dictRequest.getDictItemsByCode("SYSTEM_MICRO_SERVICE").then(function (res) {
        if (!_.isEmpty(res.data)) {
          _this6.jobServiceList = res.data.map(function (item) {
            return {
              dictCode: item.itemCode,
              dictName: item.itemName
            };
          });
        }
      });
    },
    ediPolicyFactorst: function ediPolicyFactorst(type, item) {
      this.opType = type;
      this.showJobConfigDialog = true;
      this.jobConfigId = item.jobId;
      this.getDetailByjobId();
    },
    deletePolicyFactors: function deletePolicyFactors(item) {
      var _this7 = this;
      this.$confirm('您确定要将该配置删除？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.deleteJobConfig([item.jobId]).then(function (res) {
          if (res.code === 200) {
            _this7.$message({
              message: res.msg,
              type: 'success'
            });
            _this7.loadData();
          }
        });
      });
    },
    save: function save() {
      var _this8 = this;
      this.$refs['jobForm'].validate(function (valid) {
        if (valid) {
          _this8.formLoading = true;
          if (!_.isEmpty(_this8.jobForm.configList)) {
            var temp = {};
            _this8.jobForm.configList.forEach(function (item) {
              if (item.storeValue) {
                var newField = item.storeKey;
                temp[newField] = item.storeValue;
              }
            });
            _this8.jobForm['jobConfigJson'] = JSON.stringify(temp);
          } else {
            _this8.jobForm['jobConfigJson'] = "{}";
          }
          var jobServive = _.find(_this8.jobServiceList, function (item) {
            return item.dictCode == _this8.jobForm.serviceCode;
          });
          _this8.jobForm['serviceName'] = jobServive && jobServive.dictName;
          var req = _this8.opType == 'add' ? request.createJobConfig(_this8.jobForm) : request.editJobConfig(_this8.jobForm);
          req.then(function (res) {
            if (res.code == 200) {
              _this8.$message.success(res.msg);
              _this8.loadData();
            }
            _this8.formLoading = false;
          });
        }
      });
    },
    closeShowDialog: function closeShowDialog() {
      this.showJobConfigDialog = false;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgData = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.resetSearch();
      this.searchData();
    },
    loadData: function loadData() {
      var _this9 = this;
      //获取列表数据
      this.showJobConfigDialog = false;
      this.loading = true;
      this.drawer = false;
      request.getList(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery)).then(function (res) {
        _this9.loading = false;
        var resList = res.data.list ? res.data.list : [];
        var _iterator = _createForOfIteratorHelper(resList),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var i = _step.value;
            i.disabled = false;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        _this9.tableData = resList;
        //总条数
        _this9.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this9.loading = false;
      });
    }
  }
};