var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "600px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.formRules,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业：", prop: "corpId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择企业",
                                    clearable: "",
                                  },
                                  on: { change: _vm.getGroupChatList },
                                  model: {
                                    value: _vm.addForm.corpId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "corpId", $$v)
                                    },
                                    expression: "addForm.corpId",
                                  },
                                },
                                _vm._l(_vm.corpList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.corpName,
                                      value: item.corpId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.addForm.corpId
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "elCheckboxGroupList",
                                  attrs: {
                                    label: "群聊：",
                                    prop: "groupChatId",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入群名称",
                                      clearable: "",
                                    },
                                    on: { input: _vm.search },
                                    model: {
                                      value: _vm.searchVal,
                                      callback: function ($$v) {
                                        _vm.searchVal =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "searchVal",
                                    },
                                  }),
                                  _vm.groupChatLists &&
                                  _vm.groupChatLists.length != 0
                                    ? _c(
                                        "div",
                                        { staticClass: "groupChatList" },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              model: {
                                                value: _vm.addForm.groupChatId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addForm,
                                                    "groupChatId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "addForm.groupChatId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.groupChatLists,
                                              function (item, index) {
                                                return _c(
                                                  "el-checkbox",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      label: item.groupChatId,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.groupChatName)
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "groupChatList" },
                                        [_vm._v(" 暂无数据 ")]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "标签：" } },
                                [
                                  _c("el-tree", {
                                    ref: "tree",
                                    attrs: {
                                      data: _vm.labelData,
                                      "show-checkbox": "",
                                      "node-key": "id",
                                      props: _vm.defaultProps,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }