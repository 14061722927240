import _toConsumableArray from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.json.stringify.js";
import { asyncRoutes, constantRoutes } from '@/router/modules';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
    if (localStorage.getItem('_routes')) {
      try {
        state.routes = JSON.parse(localStorage.getItem('_routes'));
      } catch (err) {
        state.routes = constantRoutes.concat(routes);
      }
    } else {
      try {
        var roleData = JSON.parse(localStorage.getItem('roleData'));
        if (!roleData) return;
        var menuList = roleData;
        if (!_.isEmpty(menuList)) {
          var _ref;
          var checkedRoutes = [];
          menuList.forEach(function (menu) {
            var tempArr = [];
            try {
              tempArr = JSON.parse(menu);
            } catch (err) {
              tempArr = menu;
            }
            checkedRoutes = _.concat(checkedRoutes, tempArr);
          });
          var _checkedRoutes = (_ref = _).concat.apply(_ref, _toConsumableArray(checkedRoutes));
          _checkedRoutes = _.uniqWith(_checkedRoutes, _.isEqual);
          var all = _.find(_checkedRoutes, function (item) {
            return item.code == 'All';
          });
          if (all) {
            // checkedRoutes = all;
            var allItems = [];
            all.children.forEach(function (aitem) {
              if (!_.isEmpty(aitem.children)) {
                allItems = _.concat(allItems, aitem.children);
                aitem.children.forEach(function (citem) {
                  if (!_.isEmpty(citem.children)) {
                    allItems = _.concat(allItems, citem.children);
                  }
                });
              }
            });
            checkedRoutes = allItems;
          } else {
            checkedRoutes = _checkedRoutes;
          }
          var _routes = _.cloneDeep(state.routes);
          _routes.forEach(function (element) {
            if (!_.isEmpty(element.children)) {
              var parentRoutes = _.find(checkedRoutes, function (citem) {
                return citem.code == element.name;
              });
              if (!parentRoutes && !all) {
                var children = _.filter(element.children, function (o) {
                  return _.find(checkedRoutes, function (citem) {
                    return citem.code == o.name;
                  });
                });
                if (!element.hidden) {
                  element.hidden = false;
                }
                if (_.isEmpty(children)) {
                  element.hidden = true;
                }
              }
              element.children.forEach(function (eitem) {
                if (_.isEmpty(eitem.children)) {
                  var ctemp = _.find(checkedRoutes, function (citem) {
                    return citem.code == eitem.name;
                  });
                  if (!ctemp) {
                    eitem.hidden = true;
                  } else {
                    if (!eitem.hidden) {
                      eitem.hidden = false;
                    }
                  }
                } else {
                  eitem.children.forEach(function (_eitem) {
                    var ctemp = _.find(checkedRoutes, function (citem) {
                      return citem.code == _eitem.name;
                    });
                    if (!ctemp) {
                      if (_.isEmpty(_eitem.children)) {
                        var _ctemp = _.find(checkedRoutes, function (citem) {
                          return citem.code == _eitem.name;
                        });
                        if (!_ctemp) {
                          _eitem.hidden = true;
                        } else {
                          if (!_eitem.hidden) {
                            _eitem.hidden = false;
                          }
                        }
                      } else {
                        _eitem.children.forEach(function (_eitems) {
                          var ctemp = _.find(checkedRoutes, function (citem) {
                            return citem.code == _eitems.name;
                          });
                          if (!ctemp) {
                            _eitems.hidden = true;
                          } else {
                            if (!_eitems.hidden) {
                              _eitems.hidden = false;
                            }
                          }
                        });
                      }
                    } else {
                      if (!_eitem.hidden) {
                        _eitem.hidden = false;
                      }
                    }
                  });
                }
              });
            }
          });
          _routes.forEach(function (item) {
            if (!_.isEmpty(item.children)) {
              var _temp = _.find(item.children, function (eitem) {
                return !eitem.hidden;
              });
              if (!_temp) {
                item.hidden = true;
              } else {
                item.children.forEach(function (_item) {
                  if (!_.isEmpty(_item.children)) {
                    var _temp2 = _.find(_item.children, function (eitem) {
                      return !eitem.hidden;
                    });
                    if (!_temp2) {
                      _item.hidden = true;
                    }
                  }
                });
                var len = 0;
                item.children.forEach(function (_item) {
                  if (_item.hidden) {
                    len++;
                  }
                });
                if (len == item.children.length) {
                  item.hidden = true;
                }
              }
            } else {
              item.hidden = true;
            }
          });
          state.routes = _routes;
          localStorage.setItem('_routes', JSON.stringify(_routes));
        } else {
          // state.routes = [];
        }
      } catch (error) {
        console.log('SET_ROUTES=>:', error);
      }
    }
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref2, roles) {
    var commit = _ref2.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;
      if (roles.includes('admin')) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      }
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};