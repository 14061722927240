var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "OtherChat" }, [
    _c("div", { staticClass: "other_user" }, [
      _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))]),
      _c("div", { staticClass: "user_main" }, [
        _c("div", { staticClass: "avatar_warp" }, [
          _c("img", {
            staticStyle: { "border-radius": "50%" },
            attrs: { width: "46", height: "46", src: _vm.avatar },
          }),
        ]),
        _c(
          "div",
          {
            staticClass: "footer-btn-list",
            staticStyle: {
              "align-items": "baseline",
              "max-width": "60%",
              "justify-content": "flex-start",
            },
          },
          [
            _c("div", { staticClass: "triangle_left" }),
            _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "user_message wave-loading" },
                  _vm._l(_vm.con, function (item, index) {
                    return _c(
                      "span",
                      { key: index, style: { "--time": index } },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "user_message" }, [
                  _c("div", { domProps: { innerHTML: _vm._s(_vm.con) } }),
                ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }