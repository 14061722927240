import "core-js/modules/es.array.concat.js";
/**
 * 保司维护
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/suppliers?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 6), {
      params: data
    });
  },
  /**
   * 新增 
   * @param {*} data 内容
   */
  add: function add(data) {
    return request.post(getUrl("/suppliers", 6), data);
  },
  /**
   * 查询
   * @param {*} id 
   */
  query: function query(id) {
    return request.get(getUrl("/suppliers/".concat(id), 6));
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  update: function update(id, data) {
    return request.put(getUrl("/suppliers/".concat(id), 6), data);
  },
  /**
   * 删除
   * @param {*} id 
   */
  delete: function _delete(id) {
    return request.delete(getUrl("/suppliers/".concat(id), 6));
  },
  /**
   * 获取保司下拉列表
   * @param {*} type 
   */
  getAllSuppliers: function getAllSuppliers(type) {
    return request.get(getUrl("/suppliers/getByType?type=".concat(type), 6));
  },
  /**
   * 获取省市下拉列表
   */
  getAllCityTree: function getAllCityTree() {
    return request.get(getUrl("/districts/tree", 6));
  },
  /**
   * 获取省市区下拉列表
   */
  getAllCityAreaTree: function getAllCityAreaTree() {
    return request.get(getUrl("/districts/tree/all", 6));
  }
};
export default objApi;