import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import dataInteractionPlatformApi from '@/api/dataInteractionPlatform';
import AddDataConfig from './dialog/addDataConfig';
import { commonExport } from '@/utils/common';
export default {
  name: 'PushDataConfig',
  components: {
    Pagination: Pagination,
    AddDataConfig: AddDataConfig
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      showDialog: false,
      opType: '',
      dataConfigId: '',
      drawer: false
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '数据类型配置',
        exportUrl: '/tp-data-process-service/duip/dataconfig/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.dataConfigId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'dataConfigId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        dataInteractionPlatformApi.deleteData(ids).then(function (res) {
          if (res.code === 200) {
            _this.$message.success(res.msg);
            _this.loadData();
          }
          ;
        });
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      dataInteractionPlatformApi.getDuipdataconfigList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this2.loading = false;
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑
    editClicked: function editClicked(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.dataConfigId = item.dataConfigId.toString();
    },
    // 新增
    add: function add(type) {
      this.opType = type;
      this.showDialog = true;
    },
    // 复制
    copy: function copy(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.dataConfigId = item.dataConfigId.toString();
    }
  }
};