var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "form_label_style" },
      [
        _vm._t("preSlot"),
        _c("span", {
          staticStyle: { "margin-right": "2px", "background-color": "#C1272D" },
          domProps: { innerHTML: _vm._s("&nbsp;") },
        }),
        _vm._v(" " + _vm._s(_vm.formLabel) + " "),
        _vm._t("sufSlot"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }