import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import knowledgeApi from '@/api/knowledge';
import dictionary from '@/api/dictionary';
import sysManageApi from '@/api/sysManage';
import VI_CityAreaTree from '@/assets/json/VI_CityAreaTree';
import { getSupplyers, commonExport } from '@/utils/common';
export default {
  name: "MaterialAutoInsurance",
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      loading: false,
      materialGroup: [{
        itemCode: 'ARTICLE',
        itemName: '文章'
      }, {
        itemCode: 'VIDEO',
        itemName: '视频'
      }],
      examineStatusList: [],
      publishedList: [{
        itemCode: 'true',
        itemName: '是'
      }, {
        itemCode: 'false',
        itemName: '否'
      }],
      insCityCode: [],
      searchForm: {},
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      drawer: false,
      supplyers: [],
      timeoutForSetPublished: null,
      timeoutForExport: null,
      knowledgeAppraiseLists: [],
      createdStartEndTime: [],
      userList: [],
      questionOptions: [],
      regionData: VI_CityAreaTree || [],
      regionOptions: []
    };
  },
  created: function created() {
    this.getAddress();
    this.supplyers = getSupplyers();
    this.getQuestionTypeLists();
    this.getKnowledgeAppraiseLists();
    this.getExamineStatusList();
    this.loadData();
    this.getUserList();
  },
  methods: {
    // 省市区
    getAddress: function getAddress() {
      var partialAddress = [];
      this.regionData.forEach(function (item, index) {
        partialAddress.push({
          label: item.label,
          value: item.value,
          // children: []
          children: item.children ? [] : null
        });
        if (item.children) {
          item.children.forEach(function (eitem) {
            partialAddress[index].children.push({
              label: eitem.label == '市辖区' ? item.label : eitem.label,
              value: eitem.value
            });
          });
        }
      });
      this.regionOptions = partialAddress;
    },
    // 获取省市编码
    getCityCode: function getCityCode(val) {
      if (val) {
        this.searchForm.provinceCode = val && val[0]; //省
        this.searchForm.cityCode = val && val[1]; //市
      }
    },
    // 获取问题出现原因
    getQuestionTypeLists: function getQuestionTypeLists() {
      var _this = this;
      knowledgeApi.getQuestionTypeList().then(function (res) {
        if (res.code == 200) {
          _this.questionOptions = res.data || [];
        }
        ;
      });
    },
    getUserList: function getUserList() {
      var _this2 = this;
      var data = {
        pageNum: 1,
        pageSize: 9999
      };
      sysManageApi.getUsersList(data).then(function (res) {
        if (res.code === 200) {
          _this2.userList = res.data.list;
        }
        ;
      });
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    getKnowledgeAppraiseLists: function getKnowledgeAppraiseLists() {
      var _this3 = this;
      dictionary.getDictItemsByCode('KNOWLEDGEAPPRAISELISTS').then(function (res) {
        if (res.code === 200) {
          _this3.knowledgeAppraiseLists = res.data || [];
        }
        ;
      });
    },
    getExamineStatusList: function getExamineStatusList() {
      var _this4 = this;
      dictionary.getDictItemsByCode('EXAMINESTATUSLIST').then(function (res) {
        if (res.code === 200) {
          _this4.examineStatusList = res.data || [];
        }
        ;
      });
    },
    // 查询车辆防抖
    setPublished: function setPublished(row) {
      var _this5 = this;
      this.loading = true;
      if (this.timeoutForSetPublished) {
        clearTimeout(this.timeoutForSetPublished);
      }
      this.timeoutForSetPublished = setTimeout(function () {
        _this5.setPublished1(row);
      }, 500);
    },
    setPublished1: function setPublished1(row) {
      var _this6 = this;
      knowledgeApi.publishedKnowledge(row.guid).then(function (res) {
        _this6.loadData();
      });
    },
    getMaterialGroup: function getMaterialGroup() {
      var _this7 = this;
      dictionary.getDictItemsByCode('MATERIALTYPES').then(function (res) {
        if (res.code === 200) {
          _this7.materialGroup = res.data || [];
        }
        ;
      });
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this8 = this;
      this.loading = true;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this8.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        fileName: "车险知识列表",
        exportUrl: '/biz-cms-service/knowledge/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    addClicked: function addClicked() {
      this.$router.push({
        name: 'MaterialAutoInsuranceAdd',
        query: {
          tabName: '新增车险知识',
          type: 'add'
        }
      });
    },
    // 编辑
    editDetail: function editDetail(row) {
      this.$router.push({
        name: 'MaterialAutoInsuranceEdit',
        query: {
          guid: row.guid,
          tabName: "\u8F66\u9669\u77E5\u8BC6\u7F16\u8F91-".concat(row.knowledgeType, "-").concat(row.title),
          type: 'edit'
        }
      });
    },
    // 查看
    viewDetail: function viewDetail(row) {
      var newUrl = window.location.href.split('material')[0];
      var toUrl = "".concat(newUrl, "autoInsurance/detail?guid=").concat(row.guid);
      window.open(toUrl);
    },
    delArticle: function delArticle(row) {
      var _this9 = this;
      this.$confirm('是否删除该条素材?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        knowledgeApi.delKnowledge(row.guid).then(function (res) {
          if (res.code === 200) {
            _this9.$message.success(res.msg);
            _this9.loadData();
          }
          ;
        });
      }).catch(function () {});
    },
    refreshClicked: function refreshClicked() {
      this.loadData();
    },
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.createdStartEndTime = [];
    },
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this10 = this;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      this.drawer = false;
      this.loading = true;
      knowledgeApi.getKnowledgeList(data).then(function (res) {
        _this10.loading = false;
        if (res.code == 200) {
          var resList = res.data && res.data.list;
          _this10.tableData = resList;
          _this10.total = res.data && res.data.page && res.data.page.recordsTotal || 0;
        }
        ;
      }).catch(function (err) {
        _this10.loading = false;
      });
    }
  }
};