var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "700px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "700px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.addForm,
                    "label-width": "130px",
                    size: "mini",
                  },
                },
                [
                  _vm.invokeMonitorId
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "请求ID：" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.addForm.traceId) + " "
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: _vm.addForm.traceId,
                                              expression: "addForm.traceId",
                                              arg: "copy",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:success",
                                              value: function (event) {
                                                return _vm.onCopy(
                                                  event,
                                                  "请求ID"
                                                )
                                              },
                                              expression:
                                                "event => onCopy(event, '请求ID')",
                                              arg: "success",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:error",
                                              value: function (event) {
                                                return _vm.onError(
                                                  event,
                                                  "请求ID"
                                                )
                                              },
                                              expression:
                                                "event => onError(event, '请求ID')",
                                              arg: "error",
                                            },
                                          ],
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            plain: "",
                                          },
                                        },
                                        [_vm._v("复制")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "应用id：" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.addForm.appId) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "应用名称：" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.addForm.appName) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "保险公司：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.insCorpName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "方法名称：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.methodName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "交互结果：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.successDisplay) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "耗时(ms)：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.intervalTime) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "请求开始时间：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.requestTime) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "请求响应时间：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.responseTime) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "记录时间：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.createdTime) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "搜索内容：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.searchText) +
                                          " "
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: _vm.addForm.searchText,
                                              expression: "addForm.searchText",
                                              arg: "copy",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:success",
                                              value: function (event) {
                                                return _vm.onCopy(
                                                  event,
                                                  "搜索内容"
                                                )
                                              },
                                              expression:
                                                "event => onCopy(event, '搜索内容')",
                                              arg: "success",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:error",
                                              value: function (event) {
                                                return _vm.onError(
                                                  event,
                                                  "搜索内容"
                                                )
                                              },
                                              expression:
                                                "event => onError(event, '搜索内容')",
                                              arg: "error",
                                            },
                                          ],
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            plain: "",
                                          },
                                        },
                                        [_vm._v("复制")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm._.isEmpty(_vm.addForm.drawingKnowledgeList)
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "关联车险知识文章：",
                                          },
                                        },
                                        _vm._l(
                                          _vm.addForm.drawingKnowledgeList,
                                          function (item) {
                                            return _c(
                                              "div",
                                              { key: item.knowledgeGuid },
                                              [
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      href: item.url,
                                                      type: "primary",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.addForm.success
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "错误信息：" } },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.addForm.errorMessage)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.addForm.success
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "错误堆栈：" } },
                                        [
                                          _c(
                                            "pre",
                                            { staticClass: "errorStack" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.addForm.errorStack)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(false)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }