import _defineProperty from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/defineProperty.js";
var _objApi;
import "core-js/modules/es.array.concat.js";
/**
 * 任务配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = (_objApi = {
  /**
   * 获取任务分页列表
   * @param {*} data 查询参数
   */
  getList: function getList(data) {
    return request.get(getUrl("/job/search", 3), {
      params: data
    });
  },
  /**
   * 动态获取配置
   * @param {*} code 内容
   */
  getConfigByJobCode: function getConfigByJobCode(jobCode, serviceCode) {
    return request.get(getUrl("/job/getConfigSchema?jobCode=".concat(jobCode, "&serviceCode=").concat(serviceCode), 3));
  },
  /**
   * 创建配置
   * @param {*} data 内容
   */
  createJobConfig: function createJobConfig(data) {
    return request.post(getUrl("/job/create", 3), data);
  }
}, _defineProperty(_objApi, "createJobConfig", function createJobConfig(data) {
  return request.post(getUrl("/job/create", 3), data);
}), _defineProperty(_objApi, "getDetailByjobId", function getDetailByjobId(id) {
  return request.get(getUrl("/job/get?id=".concat(id), 3));
}), _defineProperty(_objApi, "editJobConfig", function editJobConfig(data) {
  return request.put(getUrl("/job/update", 3), data);
}), _defineProperty(_objApi, "deleteJobConfig", function deleteJobConfig(ids) {
  return request.delete(getUrl("/job/batchDelete?ids=".concat(ids), 3));
}), _defineProperty(_objApi, "getJobLogList", function getJobLogList(data) {
  return request.get(getUrl("/job/log/search", 3), {
    params: data
  });
}), _defineProperty(_objApi, "implementJob", function implementJob(jobCode, serviceCode) {
  return request.post(getUrl("/job/execTask?jobCode=".concat(jobCode, "&serviceCode=").concat(serviceCode), 3));
}), _objApi);
export default objApi;