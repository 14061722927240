//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import customerManagementApi from '@/api/customerManagement';
export default {
  mixins: [initHeight],
  props: {
    unionId: {
      type: String,
      default: ''
    },
    tabActiveName: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listData: [],
      listLoading: false,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      }
    };
  },
  watch: {
    tabActiveName: {
      handler: function handler(newValue, oldValue) {
        if (newValue == 'READARTICLE') {
          // this.loadData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 计算表格高度
    tableHeight: function tableHeight() {
      return document.body.clientHeight - 480 + 'px';
    }
  },
  methods: {
    browerConversion: function browerConversion(item) {
      var browerName = '';
      switch (item.brower) {
        case 'MiniProgram':
          browerName = '小程序';
          break;
        case 'H5':
          browerName = 'H5';
          break;
      }
      ;
      return browerName;
    },
    // 时间换算
    durationConversion: function durationConversion(time) {
      var _time = '';
      if (time && time < 60) {
        _time = "".concat(parseInt(time), "\u79D2");
      } else if (time && time < 3600) {
        _time = "".concat(parseInt(time / 60), "\u5206\u949F");
      } else if (time) {
        _time = "".concat(parseInt(time / 3600), "\u5C0F\u65F6");
      }
      ;
      return _time;
    },
    // 初始化数据列表
    loadData: function loadData() {
      var _this = this;
      // if (!this.unionId) return;
      this.listLoading = true;
      var params = {
        appId: process.env.VUE_APP_T_AppId,
        unionId: this.unionId || null
      };
      customerManagementApi.listMaterialByUser(params).then(function (res) {
        _this.listLoading = false;
        if (res.code === 200) {
          _this.listData = res.data || [];
          _this.listData = _.orderBy(_this.listData, ['startTime'], ['desc']);
        }
        ;
      }).catch(function (err) {
        _this.listLoading = false;
      });
    }
  }
};