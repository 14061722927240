import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import dataInteractionPlatformApi from '@/api/dataInteractionPlatform';
import MonitorPushDetails from './dialog/monitorPushDetails';
import { commonExport, getCurrentDate } from '@/utils/common';
export default {
  name: 'ExternalSubscripMonitor',
  components: {
    Pagination: Pagination,
    MonitorPushDetails: MonitorPushDetails
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        createdTimeStart: getCurrentDate(),
        createdTimeEnd: getCurrentDate()
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      pushStateList: [{
        value: 'NEW',
        label: '新建'
      }, {
        value: 'SUCCESS',
        label: '成功'
      }, {
        value: 'FAILURE',
        label: '失败'
      }, {
        value: 'FAILURE_RETRY',
        label: '失败尝试重发'
      }, {
        value: 'INPROGRESS',
        label: '进行中'
      }],
      subscriptionList: [],
      showDialog: false,
      outputRecordId: "",
      outputRecordFlowNo: "",
      startEndTime: [],
      createdStartEndTime: [getCurrentDate(), getCurrentDate()],
      emptyText: '默认不加载数据，请点击查询进行搜索',
      drawer: false
    };
  },
  created: function created() {
    this.getSubscriptionList();
    this.loadData();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '外部交互监控',
        exportUrl: '/tp-data-process-service/duip/external/record/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    getSubscriptionList: function getSubscriptionList() {
      var _this = this;
      dataInteractionPlatformApi.getPushData().then(function (res) {
        if (res.code == 200) {
          _this.subscriptionList = res.data;
        }
        ;
      });
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        requestOrgCustomName: '',
        requestOrgCustomCode: '',
        createdTimeStart: getCurrentDate(),
        createdTimeEnd: getCurrentDate()
      };
      this.startEndTime = [];
      this.createdStartEndTime = [getCurrentDate(), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      if (this.searchForm && this.searchForm.outputRecordLastSendTimeEnd) {
        this.searchForm.outputRecordLastSendTimeEnd = this.searchForm.outputRecordLastSendTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      ;
      if (this.searchForm && this.searchForm.createdTimeStart) {
        this.searchForm.createdTimeStart = this.searchForm.createdTimeStart.split(' ')[0] + ' 00:00:00';
      }
      ;
      if (this.searchForm && this.searchForm.createdTimeEnd) {
        this.searchForm.createdTimeEnd = this.searchForm.createdTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      ;
      dataInteractionPlatformApi.getDuipoutputrecordList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this2.loading = false;
          _this2.tableData = res.data.list;
          if (_.isEmpty(_this2.tableData)) _this2.emptyText = "暂无数据";
          _this2.total = res.data.page.recordsTotal;
        } else {
          _this2.emptyText = "暂无数据";
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    view: function view(item) {
      this.showDialog = true;
      this.outputRecordId = item.outputRecordId.toString();
      this.outputRecordFlowNo = item.outputRecordFlowNo.toString();
    },
    ReSendData: function ReSendData(ids) {
      var _this3 = this;
      dataInteractionPlatformApi.ReSendData(ids).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success(res.msg);
          _this3.loadData();
        }
        ;
      });
    },
    toPush: function toPush(item) {
      var _this4 = this;
      var ids = [item.outputRecordId];
      this.$confirm('您确定要重新推送么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        _this4.ReSendData(ids);
      });
    },
    batchPush: function batchPush() {
      var _this5 = this;
      var ids = [];
      if (!_.isEmpty(this.multipleSelection)) {
        ids = _.map(this.multipleSelection, 'outputRecordId');
      }
      ;
      this.$confirm('您确定要重新推送么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        _this5.ReSendData(ids);
      });
    }
  }
};