import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import knowledgeApi from '@/api/knowledge';
var Base64 = require('js-base64').Base64;
import { htmlDecodeByRegExp } from '@/utils/common';
export default {
  data: function data() {
    return {
      timeout1: null,
      insCorpName: '',
      //保司名称
      knowledgeBody: '',
      //富文本内容
      keywordsList: [],
      //关键字列表
      loading: false,
      docTitle: null,
      //文章标题
      images: [],
      usefulNum: 0,
      uselessNum: 0
    };
  },
  created: function created() {
    if (this.$route.query) {
      if (this.$route.query.paramsObj) {
        var paramsObj = JSON.parse(Base64.decode(this.$route.query.paramsObj));
        this.checkKnowledge(paramsObj);
      }
    }
  },
  methods: {
    // 预览图片
    previewImg: function previewImg() {
      this.$viewerApi({
        images: this.images
      });
    },
    checkKnowledge: function checkKnowledge(data) {
      var _this = this;
      knowledgeApi.checkKnowledge(data).then(function (res) {
        _this.loading = true;
        if (res.code == 200 && res.data) {
          setTimeout(function () {
            _this.loading = false;
          }, 1000);
          if (res.code == 200 && res.data) {
            var resData = res.data;
            _this.docTitle = resData.title || ''; //标题
            document.title = resData.title;
            _this.examineStatus = resData.examineStatus || null; //审核结果
            _this.knowledgeTypeDisplay = resData.knowledgeTypeDisplay || ''; //分类
            _this.insCorpName = resData.insCorpName || 1; //保司
            _this.keywordsList = resData.keywordsList || []; //关键字列表
            _this.usefulNum = resData.countUseful || 0;
            _this.uselessNum = resData.countUseless || 0;
            var newHtml = resData.knowledgeBody && htmlDecodeByRegExp(resData.knowledgeBody);
            /* *、+限定符都是贪婪的，因为它们会尽可能多的匹配文字，只有在它们的后面加上一个?就可以实现非贪婪或最小匹配。*/
            var content = newHtml.replace(/\<img/gi, '<img style=\'max-width: 100%;height:auto;display:block;margin-bottom:10px;cursor: pointer;\'');
            _this.knowledgeBody = content || ''; //正文
            // this.knowledgeBody = resData.knowledgeBody && htmlDecodeByRegExp(resData.knowledgeBody) || ''; //正文
            _this.images = [];
            var dom = document.getElementById('content_div');
            dom.addEventListener("click", function (e) {
              // 点击的目标是图片标签时，则响应图片预览
              if (e.target.nodeName == 'IMG' && e.target.currentSrc) {
                _this.images = [e.target.currentSrc];
                _this.previewImg();
              }
            });
          }
        }
      });
    },
    // 点赞
    giveALike: function giveALike(boolean) {
      var _this2 = this;
      var data = {
        knowledgeId: this.guid,
        used: boolean
      };
      this.loading = true;
      knowledgeApi.commentKnowledge(data).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          boolean ? _this2.$message.success('点赞爆棚，感谢您的肯定！') : _this2.$message('很抱歉没能帮到您，我们将完善信息！');
          _this2.getKnowledgeDetail(_this2.guid);
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};