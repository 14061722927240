//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customerManagementApi from '@/api/customerManagement';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        address: null,
        cityCode: null,
        code: null,
        corpId: null,
        fullName: null,
        personnelSize: null,
        phone: null,
        shortName: null,
        source: null,
        websiteUrl: null
      },
      rules: {
        fullName: {
          required: true,
          message: '请输入企业全称',
          trigger: "blur"
        },
        shortName: {
          required: true,
          message: '请输入企业简称',
          trigger: "blur"
        }
      },
      formLoading: false,
      roleIdList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {
        address: null,
        cityCode: null,
        code: null,
        corpId: null,
        fullName: null,
        personnelSize: null,
        phone: null,
        shortName: null,
        source: null,
        websiteUrl: null
      };
      if (this.opType === "edit") {
        this.formLoading = true;
        customerManagementApi.getTerpriseInfo(this.currentId).then(function (res) {
          _this.formLoading = false;
          if (res.code === 200) {
            _this.form = res.data;
          }
          ;
        }).catch(function (err) {
          _this.formLoading = false;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          var req = _this2.opType === 'add' ? customerManagementApi.createTerprise(_this2.form) : customerManagementApi.updateTerprise(_this2.currentId, _this2.form);
          req.then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};