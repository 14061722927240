import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//

import userRequest from "@/api/user";
export default {
  name: 'App',
  created: function created() {
    // let corpId = this.$route.query["corpId"];
    // let agentid = this.$route.query["agentid"];
    // let xAppId = this.$route.query["xAppId"];
  },
  mounted: function mounted() {
    if (navigator.userAgent.indexOf('MicroMessenger') != -1) {
      if (navigator.userAgent.indexOf('wxwork') != -1) {
        localStorage.clear();
        if (this.getQueryString('corpId')) {
          localStorage.setItem('corpId', this.getQueryString('corpId'));
        }
        if (this.getQueryString('agentid')) {
          localStorage.setItem('agentid', this.getQueryString('agentid'));
        }
        if (this.getQueryString('xAppId')) {
          localStorage.setItem('xAppId', this.getQueryString('xAppId'));
        }
      }
    }
    document.title = process.env.VUE_APP_TITLE;
    this.getOfflineData();
    setInterval(function () {
      var token = localStorage.getItem('token') || "";
      if (!token) return;
      userRequest.refreshToken().then(function (result) {
        if (result.data) {
          localStorage.setItem('token', result.data.accessToken);
        }
      });
    }, 1000 * 60 * 5);
  },
  methods: {
    getQueryString: function getQueryString(key) {
      var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // 批量获取字典数据
    getOfflineData: function getOfflineData() {}
  }
};