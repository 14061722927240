/**  水印添加方法  */

var setWatermark = function setWatermark(str1) {
  var str2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var id = '1.23452384164.123412415';
  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id));
  }
  var can = document.createElement('canvas');
  // 设置canvas画布大小
  can.width = 380;
  can.height = 90;
  var cans = can.getContext('2d');
  cans.rotate(-18 * Math.PI / 180); // 水印旋转角度
  cans.font = '14px Vedana';
  cans.fillStyle = '#666666';
  cans.textAlign = 'center';
  cans.textBaseline = 'Middle';
  cans.fillText(str1, can.width / 2, can.height); // 水印在画布的位置x，y轴
  if (str2) {
    cans.fillText(str2, can.width / 2, can.height + 22);
  }
  var div = document.createElement('div');
  div.id = id;
  div.style.pointerEvents = 'none';
  div.style.top = '40px';
  div.style.left = '0px';
  div.style.opacity = '0.40';
  div.style.position = 'fixed';
  div.style.zIndex = '100000';
  div.style.width = document.documentElement.clientWidth + 'px';
  div.style.height = document.documentElement.clientHeight + 'px';
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';
  document.body.appendChild(div);
  return id;
};

// 添加水印方法
export var setWaterMark = function setWaterMark(str1) {
  var str2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (str1) {
    var id = setWatermark(str1);
    if (document.getElementById(id) === null) {
      id = setWatermark(str1);
    }
  } else {
    var _id = setWatermark(str1, str2);
    if (document.getElementById(_id) === null) {
      _id = setWatermark(str1, str2);
    }
  }
};

// 移除水印方法
export var removeWatermark = function removeWatermark() {
  var id = '1.23452384164.123412415';
  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id));
  }
};