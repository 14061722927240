//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import productRequest from '@/api/product';
import Rules from '@/utils/rules';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: {}
    },
    productItem: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      form: {
        productCode: "",
        shortName: "",
        fullName: "",
        sortBy: "",
        phone: ""
      },
      listLoading: false,
      rules: {
        productCode: [{
          required: true,
          message: '请输入产品编码',
          trigger: 'blur'
        }],
        productName: [{
          required: true,
          message: '请输入产品名称',
          trigger: 'blur'
        }],
        costPrice: [{
          required: true,
          message: '请输入成本价',
          trigger: 'blur'
        }],
        remark: [{
          required: true,
          message: '请输入产品说明',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.detail.resetFields();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      this.form = {
        productCode: "",
        type: "INSURANCE",
        shortName: "",
        fullName: "",
        sortBy: "",
        phone: ""
      };
      if (this.opType === "add") {
        this.loadData();
      } else {
        productRequest.query(this.productItem.productId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
          }
        });
      }
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var req = _this2.opType === "add" ? productRequest.add(_this2.form) : productRequest.updata(_this2.productItem.productId, _this2.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this2.$message({
                message: res.msg,
                type: 'success'
              });
              _this2.closePage();
            }
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    loadData: function loadData() {//加载数据
    }
  }
};