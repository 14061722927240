var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "170px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "接入模式：",
                                prop: "accessMode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择接入模式",
                                    clearable: "",
                                    filterable: "",
                                    disabled: _vm.opType != "add",
                                  },
                                  model: {
                                    value: _vm.form.accessMode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "accessMode", $$v)
                                    },
                                    expression: "form.accessMode",
                                  },
                                },
                                _vm._l(
                                  _vm.accessModeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业ID：", prop: "corpId" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入企业ID",
                                  clearable: "",
                                  disabled: _vm.opType != "add",
                                },
                                model: {
                                  value: _vm.form.corpId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "corpId", $$v)
                                  },
                                  expression: "form.corpId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "域名配置：", prop: "realmName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  placeholder: "请输入域名配置",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.realmName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "realmName", $$v)
                                  },
                                  expression: "form.realmName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "企业名称：", prop: "corpName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入企业名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.corpName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "corpName", $$v)
                                  },
                                  expression: "form.corpName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.accessMode == "SELF"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "企业应用ID：",
                                        prop: "appId",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          placeholder: "请输入企业应用ID",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.appId,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "appId", $$v)
                                          },
                                          expression: "form.appId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "企业应用Secret：",
                                        prop: "appSecret",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder: "请输入企业应用Secret",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.appSecret,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "appSecret", $$v)
                                          },
                                          expression: "form.appSecret",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "企业通讯录Secret：",
                                        prop: "contactsSecret",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder: "请输入企业通讯录Secret",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.contactsSecret,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "contactsSecret",
                                              $$v
                                            )
                                          },
                                          expression: "form.contactsSecret",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "扫码登录用户默认角色：",
                                        prop: "defaultRolesList",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder:
                                              "请选择扫码登录用户默认角色",
                                            clearable: "",
                                            filterable: "",
                                            multiple: "",
                                            "collapse-tags": "",
                                          },
                                          model: {
                                            value: _vm.form.defaultRolesList,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "defaultRolesList",
                                                $$v
                                              )
                                            },
                                            expression: "form.defaultRolesList",
                                          },
                                        },
                                        _vm._l(
                                          _vm.roleIdList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.roleName,
                                                value: item.roleId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "群发小程序appId：",
                                        prop: "miniProgramAppId",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder: "请输入群发小程序appId",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.miniProgramAppId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "miniProgramAppId",
                                              $$v
                                            )
                                          },
                                          expression: "form.miniProgramAppId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "通讯录通知密钥：",
                                        prop: "contactsEncodingAesKey",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder: "请输入通讯录通知密钥",
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.form.contactsEncodingAesKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "contactsEncodingAesKey",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.contactsEncodingAesKey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "通讯录通知Token：",
                                        prop: "contactsToken",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder: "请输入通讯录通知Token",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.contactsToken,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "contactsToken",
                                              $$v
                                            )
                                          },
                                          expression: "form.contactsToken",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "客户联系Secret：",
                                        prop: "customerSecret",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder: "请输入客户联系Secret",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.customerSecret,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "customerSecret",
                                              $$v
                                            )
                                          },
                                          expression: "form.customerSecret",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "客户联系通知Token：",
                                        prop: "externalUserToken",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder:
                                            "请输入客户联系通知Token",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.externalUserToken,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "externalUserToken",
                                              $$v
                                            )
                                          },
                                          expression: "form.externalUserToken",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "客户联系通知：",
                                        prop: "externalUserEncodingAesKey",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder: "请输入客户联系通知",
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.form.externalUserEncodingAesKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "externalUserEncodingAesKey",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.externalUserEncodingAesKey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "配置通讯录转发服务信息：",
                                        prop: "forwardAddress",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder:
                                            "请输入配置通讯录转发服务信息",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.forwardAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "forwardAddress",
                                              $$v
                                            )
                                          },
                                          expression: "form.forwardAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "配置客户转发服务信息：",
                                        prop: "forwardCustomerAddress",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder:
                                            "请输入配置客户转发服务信息",
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.form.forwardCustomerAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "forwardCustomerAddress",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.forwardCustomerAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "验证文件名：",
                                        prop: "credFileName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder: "请输入验证文件名",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.credFileName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "credFileName",
                                              $$v
                                            )
                                          },
                                          expression: "form.credFileName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "验证文件内容：",
                                        prop: "credFileContent",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "100",
                                          placeholder: "请输入验证文件内容",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.credFileContent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "credFileContent",
                                              $$v
                                            )
                                          },
                                          expression: "form.credFileContent",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.accessMode == "SUITE"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "代开发应用通知token：" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          placeholder:
                                            "请输入代开发应用通知token(非必填，为空则使用服务商的)",
                                          disabled: _vm.opType != "add",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.suiteToken,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "suiteToken",
                                              $$v
                                            )
                                          },
                                          expression: "form.suiteToken",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "代开发应用通知私钥：" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          placeholder:
                                            "请输入代开发应用通知私钥(非必填，为空则使用服务商的)",
                                          disabled: _vm.opType != "add",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.suiteEncodingAesKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "suiteEncodingAesKey",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.suiteEncodingAesKey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.opType == "add"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "手机号：",
                                            prop: "mobile",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "11",
                                              placeholder:
                                                "请输入手机号(用于登录)",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.mobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "mobile",
                                                  $$v
                                                )
                                              },
                                              expression: "form.mobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.opType == "add"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "密码：",
                                            prop: "password",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "16",
                                              type: "password",
                                              placeholder:
                                                "请输入密码(长度不小于8位)",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.password,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "password",
                                                  $$v
                                                )
                                              },
                                              expression: "form.password",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.opType == "add"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "选择角色：",
                                            prop: "roleIds",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请选择角色",
                                                clearable: "",
                                                filterable: "",
                                                multiple: "",
                                                "collapse-tags": "",
                                              },
                                              model: {
                                                value: _vm.form.roleIds,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "roleIds",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.roleIds",
                                              },
                                            },
                                            _vm._l(
                                              _vm.roleIdsList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.roleName,
                                                    value: item.roleId,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }