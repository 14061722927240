var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.welcomeSpeechLoading,
          expression: "welcomeSpeechLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "welcomeSpeechDetail",
                  attrs: {
                    model: _vm.welcomeSpeechForm,
                    rules: _vm.welcomeSpeechFormRules,
                    "label-width": "90px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "配置名称：",
                                prop: "configName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  disabled: _vm.dialogType == "view",
                                  "show-word-limit": "",
                                  type: "text",
                                  placeholder: "请输入配置名称",
                                },
                                model: {
                                  value: _vm.welcomeSpeechForm.configName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.welcomeSpeechForm,
                                      "configName",
                                      $$v
                                    )
                                  },
                                  expression: "welcomeSpeechForm.configName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择企业：", prop: "corpId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.dialogType == "view",
                                    placeholder: "请选择企业",
                                    clearable: "",
                                  },
                                  on: { change: _vm.getDepartmentList },
                                  model: {
                                    value: _vm.welcomeSpeechForm.corpId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.welcomeSpeechForm,
                                        "corpId",
                                        $$v
                                      )
                                    },
                                    expression: "welcomeSpeechForm.corpId",
                                  },
                                },
                                _vm._l(_vm.corpLists, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.corpName,
                                      value: item.corpId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择部门：",
                                prop: "departmentId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.dialogType == "view",
                                    clearable: "",
                                    placeholder: "请选择部门",
                                  },
                                  on: { change: _vm.getEmployeeList },
                                  model: {
                                    value: _vm.welcomeSpeechForm.departmentId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.welcomeSpeechForm,
                                        "departmentId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "welcomeSpeechForm.departmentId",
                                  },
                                },
                                _vm._l(
                                  _vm.departmentLists,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择员工：",
                                prop: "memberUserId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled:
                                      _vm.dialogType == "view" ||
                                      !_vm.welcomeSpeechForm.corpId ||
                                      (_vm.welcomeSpeechForm.departmentId &&
                                        _vm.welcomeSpeechForm.departmentId
                                          .length == 0),
                                    "collapse-tags": "",
                                    clearable: "",
                                    placeholder: "请选择员工",
                                  },
                                  model: {
                                    value: _vm.welcomeSpeechForm.memberUserId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.welcomeSpeechForm,
                                        "memberUserId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "welcomeSpeechForm.memberUserId",
                                  },
                                },
                                _vm._l(
                                  _vm.employeeLists,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.memberName,
                                        value: item.memberUserId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "SOP模版：",
                                prop: "sopTemplateId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.dialogType == "view",
                                    clearable: "",
                                    placeholder: "请选择SOP模版",
                                  },
                                  on: { change: _vm.setSopTemplate },
                                  model: {
                                    value: _vm.welcomeSpeechForm.sopTemplateId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.welcomeSpeechForm,
                                        "sopTemplateId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "welcomeSpeechForm.sopTemplateId",
                                  },
                                },
                                _vm._l(
                                  _vm.sopTemplatesLists,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.sopTemplateName,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "欢迎语：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "200",
                                  rows: "4",
                                  disabled: "",
                                  "show-word-limit": "",
                                  type: "textarea",
                                  placeholder: "请输入消息内容",
                                },
                                model: {
                                  value: _vm.welcomeSpeechForm.content,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.welcomeSpeechForm,
                                      "content",
                                      $$v
                                    )
                                  },
                                  expression: "welcomeSpeechForm.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "line-height": "46px",
            height: "46px",
            padding: "8px 0px",
            "margin-top": "5px",
          },
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dialogType != "view",
                  expression: "dialogType != 'view'",
                },
              ],
              staticStyle: { width: "80px" },
              attrs: {
                size: "mini",
                disabled: _vm.dialogType == "view",
                type: "primary",
                plain: "",
              },
              on: {
                click: function ($event) {
                  return _vm.saveWelcomeSpeech()
                },
              },
            },
            [_vm._v("确认")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "80px" },
              attrs: { sizi: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }