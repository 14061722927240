import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import dataInteractionPlatformApi from '@/api/dataInteractionPlatform';
import InternalConfig from './dialog/internalConfig';
import dictRequest from "@/api/dictionary";
import { commonExport } from '@/utils/common';
export default {
  name: 'InternalSubscripConfig',
  components: {
    Pagination: Pagination,
    InternalConfig: InternalConfig
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      showDialog: false,
      opType: '',
      inputConfigId: null,
      serviceList: [],
      allDataConfigList: [],
      drawer: false
    };
  },
  mounted: function mounted() {
    this.loadData();
    this.getDict();
    this.getAllDuipdataconfigList();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '内部数据源配置',
        exportUrl: '/tp-data-process-service/duip/internal/config/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    getAllDuipdataconfigList: function getAllDuipdataconfigList() {
      var _this = this;
      dataInteractionPlatformApi.getAllDuipdataconfigList().then(function (res) {
        if (res.code == 200) {
          _this.allDataConfigList = res.data.map(function (item) {
            return {
              dictName: item.dataConfigName,
              dictCode: item.dataConfigCode
            };
          });
        }
        ;
      });
    },
    getDict: function getDict() {
      var _this2 = this;
      dictRequest.getDictItemsByCode("SYSTEM_MICRO_SERVICE").then(function (res) {
        if (res.code == 200) {
          _this2.serviceList = res.data.map(function (item) {
            return {
              dictName: item.itemName,
              dictCode: item.itemCode
            };
          });
        }
        ;
      });
    },
    // 删除
    deleteConfig: function deleteConfig(item) {
      var _this3 = this;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        dataInteractionPlatformApi.deleteDuipInternaConfig(item.inputConfigId).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this4 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      dataInteractionPlatformApi.getDuipInternaConfiglist(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        if (res.code === 200) {
          _this4.loading = false;
          _this4.tableData = res.data.list;
          _this4.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this4.loading = false;
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.inputConfigId = item.inputConfigId;
    },
    // 新增
    add: function add(type) {
      this.opType = type;
      this.showDialog = true;
    }
  }
};