import _toConsumableArray from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customerManagementApi from '@/api/customerManagement';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    customerInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {
        externalIds: '',
        addTagList: [],
        delTagList: []
      },
      formLoading: false,
      allLabelList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.allLabelList = [];
      this.getAllLabelList();
      this.form = {
        externalIds: this.customerInfo.id,
        addTagList: [],
        delTagList: []
      };
      if (!_.isEmpty(this.customerInfo)) this.customerInfo.tagsList.forEach(function (ele) {
        _this.form.addTagList.push(ele.tagId);
      });
    },
    // 获取客户标签
    getAllLabelList: function getAllLabelList() {
      var _this2 = this;
      this.formLoading = true;
      var data = {
        corpId: this.customerInfo.corpId
      };
      customerManagementApi.getWecomtagsGroupList(data).then(function (res) {
        _this2.formLoading = false;
        if (res.code === 200 && !_.isEmpty(res.data)) {
          _this2.allLabelList = res.data[0].groupList || [];
        }
        ;
      }).catch(function (err) {
        _this2.formLoading = false;
      });
    },
    // 保存数据
    save: function save() {
      var _this3 = this;
      var _delTagList = [];
      var _selectTagList = [];
      _selectTagList = this.customerInfo.tagsList.map(function (selectVal) {
        return selectVal.tagId;
      });
      if (!_.isEmpty(this.form.addTagList)) {
        this.form.addTagList.forEach(function (addVal) {
          if (!_.isEmpty(_selectTagList)) {
            _selectTagList.filter(function (selectTag) {
              if (selectTag != addVal && !_this3.form.addTagList.includes(selectTag)) {
                _delTagList.push(selectTag);
              }
              ;
            });
          }
          ;
        });
        this.form.delTagList = _toConsumableArray(new Set(_delTagList));
      } else {
        this.form.delTagList = _selectTagList;
      }
      ;
      this.formLoading = true;
      customerManagementApi.wecomenExternalMarkTag(this.form).then(function (res) {
        _this3.formLoading = false;
        if (res.code === 200) {
          _this3.$message.success(res.msg);
          _this3.closePage(false);
        }
        ;
      }).catch(function (err) {
        _this3.formLoading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};