import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var Base64 = require('js-base64').Base64;
import knowledgeApi from '@/api/knowledge';
import { htmlDecodeByRegExp } from '@/utils/common';
export default {
  data: function data() {
    return {
      timeout1: null,
      auditStatus: 'KEE02',
      examineStatus: null,
      examineStatusDisplay: null,
      examineTime: null,
      examineBy: null,
      examineRemark: '',
      insCorpName: '',
      knowledgeAppraiseDisplay: '',
      knowledgeBody: '',
      keywordsList: [],
      loading: false,
      docTitle: null,
      audit: false,
      queryAudit: null,
      guid: null,
      images: [],
      usefulNum: 0,
      uselessNum: 0
    };
  },
  created: function created() {
    var overTimeData = Base64.encode('{"signature":"XEUQAxQwGeB4aSgHmwxdzTRWihE=","expires":1681021533,"knowledgeId":"0c3e5ad451ce496383f3c0eddd816358"}');
    console.log(overTimeData);
    if (this.$route.query) {
      if (this.$route.query.audit) {
        this.queryAudit = this.$route.query.audit;
        this.audit = true;
        if (this.$route.query.audit == '2A3C') {
          this.auditStatus = 'KEE03';
        }
      }
      if (this.$route.query.guid) {
        this.guid = this.$route.query.guid;
        this.getKnowledgeDetail(this.$route.query.guid);
      }
    }
  },
  methods: {
    // 预览图片
    previewImg: function previewImg() {
      this.$viewerApi({
        images: this.images
      });
    },
    // 获取文件后缀
    getFileSuffix: function getFileSuffix(fileName) {
      if (!fileName) return false;
      var _suffix = fileName.substring(fileName.lastIndexOf(".") + 1).toLocaleUpperCase();
      return _suffix == 'JPEG' || _suffix == 'PNG' || _suffix == 'JPG' || _suffix == 'GIF';
    },
    // 点赞
    giveALike: function giveALike(boolean) {
      var _this = this;
      var data = {
        knowledgeId: this.guid,
        used: boolean
      };
      this.loading = true;
      knowledgeApi.commentKnowledge(data).then(function (res) {
        _this.loading = false;
        if (res.code == 200) {
          boolean ? _this.$message.success('点赞爆棚，感谢您的肯定！') : _this.$message('很抱歉没能帮到您，我们将完善信息！');
          _this.getKnowledgeDetail(_this.guid);
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 审核详情
    getKnowledgeDetail: function getKnowledgeDetail(id) {
      var _this2 = this;
      this.loading = true;
      knowledgeApi.getKnowledgeDetail(id).then(function (res) {
        setTimeout(function () {
          _this2.loading = false;
        }, 1000);
        if (res.code == 200 && res.data) {
          var resData = res.data;
          _this2.docTitle = resData.title || ''; //标题
          document.title = resData.title;
          _this2.knowledgeAppraiseDisplay = resData.knowledgeAppraiseDisplay || null; //审核结果
          _this2.examineStatus = resData.examineStatus || null; //审核结果
          _this2.examineStatusDisplay = resData.examineStatusDisplay || ''; //审核结果中文
          _this2.examineRemark = resData.examineRemark || ''; //审核结果备注
          _this2.examineTime = resData.examineTime || ''; //审核时间
          _this2.examineBy = resData.examineBy || ''; //审核人
          _this2.knowledgeTypeDisplay = resData.knowledgeTypeDisplay || ''; //分类
          _this2.insCorpName = resData.insCorpName || 1; //保司
          _this2.keywordsList = resData.keywordsList || []; //关键字列表
          _this2.usefulNum = resData.countUseful || 0;
          _this2.uselessNum = resData.countUseless || 0;
          var newHtml = resData.knowledgeBody && htmlDecodeByRegExp(resData.knowledgeBody);
          // console.log(newHtml)
          // let richText = ' <p style="font-size: 25px;color: white">&nbsp; &nbsp; &nbsp; &nbsp;sdaflsjf的丰富及饿哦塞尔</p><span>dsfjlie</span>';
          /* 去除富文本中的html标签 */
          /* *、+限定符都是贪婪的，因为它们会尽可能多的匹配文字，只有在它们的后面加上一个?就可以实现非贪婪或最小匹配。*/
          var content = newHtml.replace(/\<img/gi, '<img style=\'max-width: 100%;height:auto;display:block;margin-bottom:10px;cursor: pointer;\'');
          // content = newHtml.replace(/<.+?>/g, '');
          /* 去除&nbsp; */
          // if(content.indexOf('&nbsp;')){}
          // content = content.replace(/&nbsp;/ig, '');
          /* 去除空格 */
          // content = content.replace(/\s/ig, '');
          // console.log(content);
          _this2.knowledgeBody = content || ''; //正文
          // 给富文本容器添加点击事件监听
          _this2.images = [];
          var dom = document.getElementById('content_div');
          dom.addEventListener("click", function (e) {
            // 点击的目标是图片标签时，则响应图片预览
            if (e.target.nodeName == 'IMG' && e.target.currentSrc) {
              _this2.images = [e.target.currentSrc];
              _this2.previewImg();
            }
          });
        }
      });
    },
    // 提交审核防抖
    submitAudit: function submitAudit() {
      var _this3 = this;
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this3.submitAudit1();
      }, 500);
    },
    // 提交审核
    submitAudit1: function submitAudit1() {
      var _this4 = this;
      if (!this.auditStatus) {
        this.$message.warning('请选择审核意见!');
        return;
      }
      if (this.auditStatus && this.auditStatus === 'KEE03' && (!this.examineRemark || this.examineRemark == '')) {
        this.$message.warning('请填写不通过原因!');
        return;
      }
      this.loading = true;
      var data = {
        examineStatus: this.auditStatus,
        examineRemark: this.examineRemark
      };
      knowledgeApi.examineKnowledge(this.guid, data).then(function (res) {
        if (res.code == 200) {
          _this4.loading = false;
          _this4.$message.success('审核成功，即将关闭当前页面！');
          setTimeout(function () {
            _this4.$router.replace({
              name: 'knowledgeAuditorList'
            });
          }, 2000);
          // this.getKnowledgeDetail(this.guid)
        }
      });
    },
    submitBack: function submitBack() {
      this.$router.replace({
        name: 'knowledgeAuditorList'
      });
    }
  }
};