import _createForOfIteratorHelper from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import knowledgeMonitoring from '@/api/knowledgeMonitoring';
import viewDiadlog from './dialog/detail.vue';
import { getSupplyers, commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'KnowledgeMonitoring',
  components: {
    Pagination: Pagination,
    viewDiadlog: viewDiadlog
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      showAddDialog: false,
      drawer: false,
      loading: true,
      searchFormSuccess: '',
      tableData: [],
      searchForm: {
        insCorpCode: null,
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      supplyers: getSupplyers() || [],
      invokeMonitorId: '',
      category: '1',
      viewTraceId: '',
      summaryData: {},
      alltotal: 0,
      multipleSelection: [],
      invokeMonitorIds: [],
      createdStartEndTime: [getBeforeDate(30), getCurrentDate()],
      timeoutForExport: null
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 导出防抖
    exportExcels: function exportExcels() {
      var _this = this;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this.exportFn1();
      }, 500);
    },
    //导出
    exportFn1: function exportFn1() {
      var _this2 = this;
      if (this.total > 30000) {
        this.$message.warning('超出了最大可导出数量(3万)！');
        return;
      } else {
        this.loading = true;
        setTimeout(function () {
          var exporParams = {
            total: _this2.total,
            fileName: '车险知识调用监控',
            exportUrl: '/biz-cms-service/knowledgemonitor/search',
            searchForm: _this2.searchForm
          };
          commonExport(exporParams, _this2.$refs.multipleTable);
          _this2.loading = false;
        }, 500);
      }
    },
    viewDetail: function viewDetail(row) {
      this.invokeMonitorId = row.monitorId.toString();
      this.showAddDialog = true;
    },
    //获取列表
    loadData: function loadData() {
      var _this3 = this;
      if (this.category) {
        this.searchForm.success = parseInt(this.category) === 1 ? null : parseInt(this.category) === 2 ? true : false;
      }
      ;
      this.drawer = false;
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      knowledgeMonitoring.getKnowledgemonitorSearch(data).then(function (res) {
        _this3.loading = false;
        if (res.code == 200 && res.data && res.data.list) {
          var resData = res.data.list || [];
          var _iterator = _createForOfIteratorHelper(resData),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              var newResultGuid = [];
              if (i.drawingKnowledgeList) {
                var _iterator2 = _createForOfIteratorHelper(i.drawingKnowledgeList),
                  _step2;
                try {
                  for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                    var j = _step2.value;
                    newResultGuid.push({
                      title: j.title,
                      url: "".concat(window.location.origin, "/autoInsurance/detail?guid=").concat(j.knowledgeGuid)
                    });
                  }
                } catch (err) {
                  _iterator2.e(err);
                } finally {
                  _iterator2.f();
                }
                ;
              }
              ;
              i.resultGuidlist = newResultGuid;
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          ;
          _this3.tableData = resData;
          _this3.total = res.data.page.recordsTotal;
        } else {
          _this3.tableData = [];
          _this3.total = 0;
          _this3.loading = false;
        }
        ;
      });
    },
    resetSearch: function resetSearch() {
      this.searchForm = {
        insCorpCode: null,
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.createdStartEndTime = [getBeforeDate(30), getCurrentDate()];
    },
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    breakData: function breakData() {
      this.loadData();
    },
    // tab切换
    orgTabClick: function orgTabClick() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    closeLoadData: function closeLoadData(val) {
      if (val && val.needRefresh) {
        this.loadData();
      }
      this.showAddDialog = false;
    }
  }
};