import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import groupChatLabelApi from '@/api/groupChatLabel';
import sysManageApi from '@/api/sysManage';
import { commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
import AddTagDialog from './dialog/addTagDialog';
export default {
  name: 'GroupLabel',
  components: {
    Pagination: Pagination,
    AddTagDialog: AddTagDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      corpList: [],
      dialogType: null,
      showAddLabelDialog: false,
      labelId: null,
      groupChatTagDetail: {},
      searchForm: {
        addTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        addTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      genderList: [{
        value: '0',
        label: '未知'
      }, {
        value: '1',
        label: '男性'
      }, {
        value: '2',
        label: '女性'
      }],
      addStartEndTime: [getBeforeDate(30), getCurrentDate()]
    };
  },
  created: function created() {
    this.getCorpList();
    this.loadData();
  },
  methods: {
    // 获取企业列表
    getCorpList: function getCorpList() {
      var _this = this;
      sysManageApi.getAllWecomCorpConfigList().then(function (res) {
        if (res.code == 200) {
          _this.corpList = res.data || [];
        }
      });
    },
    add: function add(type) {
      this.dialogType = type;
      this.showAddLabelDialog = true;
    },
    toEdit: function toEdit(row, type) {
      this.dialogType = type;
      if (row && row.id) {
        this.labelId = row.id || null;
        this.showAddLabelDialog = true;
      }
    },
    toDelete: function toDelete(row) {
      var _this2 = this;
      if (row && row.id) {
        this.$confirm('是否删除该条标签组?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          groupChatLabelApi.deleteGroupChatTagClassification(row.id).then(function (res) {
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.loadData();
            }
            ;
          });
        }).catch(function () {});
      }
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        addTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        addTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.addStartEndTime = [getBeforeDate(30), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      this.loading = true;
      this.drawer = false;
      this.showAddLabelDialog = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      groupChatLabelApi.getGroupChatTagClassificationList(data).then(function (res) {
        _this3.loading = false;
        if (res.code === 200 && res.data) {
          _this3.tableData = res.data.list || [];
          _this3.total = res.data.page.recordsTotal;
          _this3.tableData.forEach(function (ele) {
            if (!ele.avatar) {
              ele.avatar = require("@/assets/images/default_avatar.png");
            }
            ;
          });
        }
        ;
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    // 跳转详情
    toDetails: function toDetails(item) {
      this.$router.push({
        path: '/customerManagement/customerDetail',
        query: {
          customerId: item.id,
          tabName: "\u5BA2\u6237\u8BE6\u60C5-".concat(item.externalUserName)
        }
      });
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '客户列表',
        exportUrl: '/biz-scrm-service/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    }
  }
};