import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from "@/components/FormSubLabel";
import sysManageApi from '@/api/sysManage';
import PageContainer from "@/components/PageContainer";
export default {
  name: "scan",
  components: {
    FormLabel: FormLabel,
    PageContainer: PageContainer
  },
  data: function data() {
    return {
      qiyeweixinIcon: require('@/assets/scan/qywx.png'),
      scanImg: require('@/assets/scan/scan.jpg'),
      authImg: require('@/assets/scan/auth.jpg'),
      step1Img: require('@/assets/scan/step1.jpg'),
      step2Img: require('@/assets/scan/step2.jpg'),
      step3Img: require('@/assets/scan/step3.jpg'),
      step4Img: require('@/assets/scan/step4.jpg'),
      srcList: [require('@/assets/scan/step1.jpg'), require('@/assets/scan/step2.jpg'), require('@/assets/scan/step3.jpg')],
      finishList: [require('@/assets/scan/step4.jpg')],
      pageContainerLoading: false,
      active: 1,
      factive: 1,
      getInNow: 1,
      orgInfo: null
    };
  },
  created: function created() {
    console.log(JSON.parse(localStorage.getItem('userInfo')));
    if (localStorage.getItem('userInfo')) {
      this.orgInfo = JSON.parse(localStorage.getItem('userInfo')).data || null;
    }
  },
  methods: {
    completeSetup: function completeSetup() {
      var _this = this;
      sysManageApi.confirmAuth().then(function (res) {
        if (res.code == 200) {
          _this.$message.success(res.msg);
          _this.getInNow = 3;
        }
      });
    }
  }
};