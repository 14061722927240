var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "素材类型" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择素材类型",
                                    },
                                    model: {
                                      value: _vm.searchForm.materialGroup,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "materialGroup",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.materialGroup",
                                    },
                                  },
                                  _vm._l(_vm.materialGroup, function (item) {
                                    return _c("el-option", {
                                      key: item.itemCode,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "文章标题" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入文章标题",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.materialTitle,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "materialTitle",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.materialTitle",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "文章质量" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择文章质量",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.knowledgeAppraise,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "knowledgeAppraise",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.knowledgeAppraise",
                                    },
                                  },
                                  _vm._l(
                                    _vm.knowledgeAppraiseLists,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.itemName,
                                          value: item.itemCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "关联地区" } },
                              [
                                _c("el-cascader", {
                                  ref: "address",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择所在省市",
                                    options: _vm.regionOptions,
                                  },
                                  on: { change: _vm.getCityCode },
                                  model: {
                                    value: _vm.insCityCode,
                                    callback: function ($$v) {
                                      _vm.insCityCode = $$v
                                    },
                                    expression: "insCityCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "问题类型" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择问题类型",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.questionType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "questionType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.questionType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.questionOptions,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.description,
                                          value: item.code,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保险公司" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择保险公司",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.insCorpCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "insCorpCode",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.insCorpCode",
                                    },
                                  },
                                  _vm._l(_vm.supplyers, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "审核状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择审核状态",
                                    },
                                    model: {
                                      value: _vm.searchForm.examineStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "examineStatus",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.examineStatus",
                                    },
                                  },
                                  _vm._l(
                                    _vm.examineStatusList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.itemCode,
                                        attrs: {
                                          label: item.itemName,
                                          value: item.itemCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否上架" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择是否上架",
                                    },
                                    model: {
                                      value: _vm.searchForm.published,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "published",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.published",
                                    },
                                  },
                                  _vm._l(_vm.publishedList, function (item) {
                                    return _c("el-option", {
                                      key: item.itemCode,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "table-cls normal-btn-cls",
          },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-box",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("文章标题：")]),
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            maxlength: "40",
                            size: "mini",
                            placeholder: "请输入文章标题",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "title", $$v)
                            },
                            expression: "searchForm.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("保险公司：")]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "请选择保险公司",
                              filterable: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.insCorpCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "insCorpCode", $$v)
                              },
                              expression: "searchForm.insCorpCode",
                            },
                          },
                          _vm._l(_vm.supplyers, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.shortName,
                                value: item.code,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("审核状态：")]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择审核状态",
                            },
                            model: {
                              value: _vm.searchForm.examineStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "examineStatus", $$v)
                              },
                              expression: "searchForm.examineStatus",
                            },
                          },
                          _vm._l(_vm.examineStatusList, function (item) {
                            return _c("el-option", {
                              key: item.itemCode,
                              attrs: {
                                label: item.itemName,
                                value: item.itemCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("是否上架：")]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择是否上架",
                            },
                            model: {
                              value: _vm.searchForm.published,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "published", $$v)
                              },
                              expression: "searchForm.published",
                            },
                          },
                          _vm._l(_vm.publishedList, function (item) {
                            return _c("el-option", {
                              key: item.itemCode,
                              attrs: {
                                label: item.itemName,
                                value: item.itemCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.refreshClicked },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportFn },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightJD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "knowledgeTypeDisplay",
                        label: "素材类型",
                        width: "80",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "title",
                        label: "文章标题",
                        "min-width": "360",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insCorpName",
                        label: "保险公司",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "examineStatusDisplay",
                        label: "审核状态",
                        width: "90",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "knowledgeAppraiseDisplay",
                        label: "文章质量",
                        width: "90",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cityName",
                        label: "关联地区",
                        width: "90",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "questionTypeDisplay",
                        label: "问题类型",
                        width: "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "publishedDisplay",
                        label: "是否上架",
                        width: "80",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "creatorBy",
                        label: "创建人",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifierBy",
                        label: "修改人",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "examineBy",
                        label: "审核人",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "examineTime",
                        label: "审核时间",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "examineRemark",
                        label: "审核备注",
                        "min-width": "300",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "创建时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        width: "80",
                        align: "center",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("审核")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.auditDialog,
            "close-on-click-modal": false,
            width: "500px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.auditDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("审核")]
              ),
            ]
          ),
          _c("div", [_vm._v(" 审核 ")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }