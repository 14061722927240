var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          "label-width": "100px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            model: {
                              value: _vm.activeNames,
                              callback: function ($$v) {
                                _vm.activeNames = $$v
                              },
                              expression: "activeNames",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-between",
                                      staticStyle: {
                                        width: "100%",
                                        "line-height": "25px",
                                      },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "基本信息" },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "addFollow" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                plain: "",
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  _vm.showCustomerInfoDialog = true
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                plain: "",
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  _vm.showAddTracksDialog = true
                                                },
                                              },
                                            },
                                            [_vm._v("新增跟进")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-descriptions",
                                  {
                                    staticClass: "margin-top",
                                    attrs: {
                                      title: "",
                                      column: 3,
                                      border: "",
                                      labelStyle: { width: "110px" },
                                      contentStyle: { width: "300px" },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "客户" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "user-box" },
                                          [
                                            _c("el-image", {
                                              staticClass: "user-img",
                                              attrs: {
                                                src: _vm.form.avatar,
                                                "preview-src-list": [
                                                  _vm.form.avatar,
                                                ],
                                              },
                                            }),
                                            _c("span", [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.form.externalUserName
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#2150a3",
                                                    margin: "0 3px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.form.corpName)
                                                  ),
                                                ]
                                              ),
                                              _vm.form.type == 1
                                                ? _c(
                                                    "span",
                                                    { staticClass: "wx" },
                                                    [_vm._v("@微信")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "企业全称" } },
                                      [_vm._v(_vm._s(_vm.form.corpFullName))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "性别" } },
                                      [_vm._v(_vm._s(_vm.form.genderDisplay))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "所属员工" } },
                                      [_vm._v(_vm._s(_vm.form.memberName))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "来源" } },
                                      [_vm._v(_vm._s(_vm.form.addWayDisplay))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "添加时间" } },
                                      [_vm._v(_vm._s(_vm.form.addTime))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "添加时长" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.form.addTimeDays || "-") +
                                            "天"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "unionId" } },
                                      [_vm._v(_vm._s(_vm.form.unionId))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "年龄" } },
                                      [_vm._v(_vm._s(_vm.form.age))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "车型" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.form.modelName || "-")
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "年龄段" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.ageGroupDisplay || "-"
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "车架号" } },
                                      [_vm._v(_vm._s(_vm.form.vin || "-"))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "车牌号" } },
                                      [_vm._v(_vm._s(_vm.form.plateNo || "-"))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "手机号" } },
                                      [_vm._v(_vm._s(_vm.form.phone || "-"))]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "购买城市" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.paymentCityName || "-"
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "购车日期" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.vehiclePurchaseTime || "-"
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "支付方式" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.paymentTypeDisplay || "-"
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "标签" } },
                                      [
                                        _vm._l(
                                          _vm.form.tagsList,
                                          function (item, index) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: index,
                                                staticStyle: {
                                                  margin: "0 5px 5px 0",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.tagName))]
                                            )
                                          }
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "button-new-tag",
                                            attrs: {
                                              plain: "",
                                              type: "primary",
                                              size: "small",
                                              icon: "el-icon-edit",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.showLabelDialog = true
                                              },
                                            },
                                          },
                                          [_vm._v("打标签")]
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "备注" } },
                                      [_vm._v(_vm._s(_vm.form.remark))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "table-cls normal-btn-cls" },
                  [
                    _c(
                      "el-card",
                      { attrs: { shadow: "never" } },
                      [
                        _c(
                          "el-tabs",
                          {
                            staticClass: "tabsBox",
                            on: { "tab-click": _vm.tabNameChange },
                            model: {
                              value: _vm.tabActiveName,
                              callback: function ($$v) {
                                _vm.tabActiveName = $$v
                              },
                              expression: "tabActiveName",
                            },
                          },
                          _vm._l(_vm.tabNameList, function (item, index) {
                            return _c("el-tab-pane", {
                              key: index,
                              attrs: {
                                label: item.itemName,
                                name: item.itemCode,
                              },
                            })
                          }),
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "tabContent",
                            style: {
                              height: _vm.scrollerHeight,
                              overflow: "auto",
                            },
                          },
                          [
                            _c("UserPortrait", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tabActiveName == "USERPORTRAIT",
                                  expression: "tabActiveName=='USERPORTRAIT'",
                                },
                              ],
                              ref: "USERPORTRAIT",
                              attrs: {
                                tabActiveName: _vm.tabActiveName,
                                unionId: _vm.form.unionId,
                              },
                            }),
                            _c("ReadArticleList", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tabActiveName == "READARTICLE",
                                  expression: "tabActiveName=='READARTICLE'",
                                },
                              ],
                              ref: "READARTICLE",
                              attrs: {
                                tabActiveName: _vm.tabActiveName,
                                unionId: _vm.form.unionId,
                              },
                            }),
                            _c("TRACKList", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tabActiveName == "TRACKLIST",
                                  expression: "tabActiveName=='TRACKLIST'",
                                },
                              ],
                              ref: "TRACKLIST",
                              attrs: {
                                tabActiveName: _vm.tabActiveName,
                                externalId:
                                  _vm.customerId && _vm.customerId.toString(),
                              },
                            }),
                            _c("VehicleInfo", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tabActiveName == "VEHICLEINFO",
                                  expression: "tabActiveName=='VEHICLEINFO'",
                                },
                              ],
                              ref: "VEHICLEINFO",
                              attrs: {
                                vehicleForm: _vm.form,
                                tabActiveName: _vm.tabActiveName,
                                externalId:
                                  _vm.customerId && _vm.customerId.toString(),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c("div", { staticClass: "footer-btn-list" }, [
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", icon: "el-icon-back" },
                        on: { click: _vm.closePage },
                      },
                      [_vm._v("返回")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showLabelDialog,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showLabelDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("打标签")]
              ),
            ]
          ),
          _c("LabelDialog", {
            attrs: { refresh: _vm.showLabelDialog, customerInfo: _vm.form },
            on: { closePage: _vm.getDeatils },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAddTracksDialog,
            "close-on-click-modal": false,
            width: "550px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddTracksDialog = $event
            },
            handleClose: function ($event) {
              _vm.showDistributeDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("新增跟进")]
              ),
            ]
          ),
          _c("FollowUpDialog", {
            attrs: {
              lastTrackDetails: _vm.lastTrackDetails,
              customerId: _vm.customerId && _vm.customerId.toString(),
              refresh: _vm.showAddTracksDialog,
            },
            on: { closePage: _vm.getDeatils },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showCustomerInfoDialog,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCustomerInfoDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("编辑客户信息")]
              ),
            ]
          ),
          _c("CustomerInfoDialog", {
            attrs: {
              refresh: _vm.showCustomerInfoDialog,
              customerInfo: _vm.form,
            },
            on: { closePage: _vm.getDeatils },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }