import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import customerManagementApi from "@/api/customerManagement";
import initHeight from "@/mixins/initHeight";
import ReadArticleList from "./components/readArticleList";
import TRACKList from "./components/TrackList";
import LabelDialog from "./dialog/labelDialog";
import FollowUpDialog from "./dialog/followUpDialog";
import UserPortrait from "./components/userPortrait";
import VehicleInfo from "./components/vehicleInfo";
import CustomerInfoDialog from "./dialog/customerInfoDialog";
export default {
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel,
    ReadArticleList: ReadArticleList,
    LabelDialog: LabelDialog,
    FollowUpDialog: FollowUpDialog,
    TRACKList: TRACKList,
    UserPortrait: UserPortrait,
    VehicleInfo: VehicleInfo,
    CustomerInfoDialog: CustomerInfoDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      followHistory: [],
      //跟进历史记录
      lastTrackDetails: {},
      //最新的跟进状态
      showAddTracksDialog: false,
      //新增跟进
      formLoading: false,
      activeNames: ["1"],
      form: {},
      customerId: "",
      tabActiveName: "USERPORTRAIT",
      tabNameList: [{
        itemCode: 'USERPORTRAIT',
        itemName: '用户画像'
      }, {
        itemCode: 'READARTICLE',
        itemName: '阅读文章列表'
      }, {
        itemCode: 'TRACKLIST',
        itemName: '跟进记录'
      }, {
        itemCode: 'VEHICLEINFO',
        itemName: '车辆信息'
      }],
      showLabelDialog: false,
      showCustomerInfoDialog: false
    };
  },
  watch: {
    $route: {
      handler: function handler(val) {
        this.initData();
      },
      immediate: true
    }
  },
  computed: {
    // 计算卡片高度
    scrollerHeight: function scrollerHeight() {
      return document.body.clientHeight - 456 + "px";
    }
  },
  methods: {
    tabNameChange: function tabNameChange(tab) {
      if (tab.name) {
        this.$refs[tab.name].loadData();
      }
    },
    // 获取跟进详情
    getLastTrack: function getLastTrack() {
      var _this = this;
      customerManagementApi.getLastTrack(this.customerId).then(function (res) {
        if (res.code == 200 && res.data) {
          _this.lastTrackDetails = res.data || {};
        }
      });
    },
    // 初始化数据
    initData: function initData() {
      this.customerId = this.$route.query.customerId || "";
      if (this.customerId) {
        this.getDeatils();
        this.getLastTrack();
      }
    },
    // 获取详情数据
    getDeatils: function getDeatils() {
      var _this2 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showLabelDialog = false;
      this.showAddTracksDialog = false;
      this.showCustomerInfoDialog = false;
      if (isDialogNoRefresh) return;
      this.formLoading = true;
      customerManagementApi.getCostomerInfo(this.customerId).then(function (res) {
        _this2.formLoading = false;
        if (res.code === 200) {
          _this2.form = res.data;
          if (!_this2.form.avatar) {
            _this2.form.avatar = require("@/assets/images/default_avatar.png");
          }
        }
      }).catch(function (err) {
        _this2.formLoading = false;
      });
    },
    // 关闭
    closePage: function closePage() {
      this.$store.dispatch("tagsView/delView", this.$route);
      this.$router.go(-1);
    }
  }
};