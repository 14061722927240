/**
 * 组织机构管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 
   * 获取token
   */
  getToken: function getToken(data) {
    return request.post(getUrl("/token", 1), data);
  },
  /**
   * 
   * 刷新Token
   */
  refreshToken: function refreshToken() {
    return request.post(getUrl("/token/refresh", 1));
  },
  /**
   * 
   * 获取验证码图片
   */
  getValidateImg: function getValidateImg() {
    return request.post(getUrl("/captcha/getImage", 1));
  },
  /**
   * 
   * 授权登录
   */
  userAuth: function userAuth(data) {
    return request.post(getUrl("/net/wecom/auth/web", 4), data);
  },
  /**
   * 
   * 添加激活形式
   */
  changeLicenseActiveType: function changeLicenseActiveType(data) {
    return request.post(getUrl("/wecomMember/empower", 4), data);
  },
  /**
   * 
   * 匿名访问
   */
  loginVest: function loginVest(account) {
    return request.post(getUrl("/token/loginVest?account=".concat(account), 1));
  }
};
export default objApi;