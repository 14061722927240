/**
 * 组织机构管理
 */
import request from "@/utils/request";
import getUrl from "@/utils/get-url";
var objApi = {
  /**
   *
   * 获取问卷列表
   */
  questionnaireList: function questionnaireList(data) {
    return request.get(getUrl("/wechat/user/questionnaire/v1/search", 4), data);
  },
  /**
   *
   * 获取问卷详情
   */
  questionnaireDetail: function questionnaireDetail() {
    return request.post(getUrl("/wechat/user/questionnaire/v1/get", 4));
  }
};
export default objApi;