var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-card",
                      { attrs: { shadow: "never" } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              model: _vm.form,
                              rules: _vm.formRules,
                              "label-width": "90px",
                              size: "mini",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 9,
                                      md: 9,
                                      lg: 9,
                                      xl: 9,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "消息类型：",
                                                  prop: "messageTypeId",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "请选择消息类型",
                                                      clearable: "",
                                                      filterable: "",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.changeMessageType,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.messageTypeId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "messageTypeId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.messageTypeId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.messageTypeList,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label:
                                                            item.messageTypeName,
                                                          value:
                                                            item.messageTypeId,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "消息参数：" },
                                              },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    ref: "multipleTable",
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      size: "mini",
                                                      border: "",
                                                      data: _vm.tableData,
                                                      "header-cell-style": {
                                                        background: "#F7F7F7",
                                                      },
                                                      "highlight-current-row":
                                                        "",
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "parameterDescription",
                                                        label: "名称",
                                                        "min-width": "50",
                                                        "show-overflow-tooltip":
                                                          "",
                                                      },
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "parameterCode",
                                                        label: "参数值",
                                                        "min-width": "50",
                                                        "show-overflow-tooltip":
                                                          "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("el-col", {
                                  attrs: { xs: 24, sm: 1, md: 1, lg: 1, xl: 1 },
                                }),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 14,
                                      md: 14,
                                      lg: 14,
                                      xl: 14,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "模板编码：",
                                                  prop: "messageTemplateCode",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "40",
                                                    placeholder:
                                                      "请输入模板编码",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.inputMessageTemplateCode,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .messageTemplateCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "messageTemplateCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.messageTemplateCode",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "模板名称：",
                                                  prop: "messageTemplateName",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "40",
                                                    placeholder:
                                                      "请输入模板名称",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .messageTemplateName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "messageTemplateName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.messageTemplateName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "通知方式：",
                                                  prop: "notifyTypeCode",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "请选择通知方式",
                                                      clearable: "",
                                                      filterable: "",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.changeNotifyTypeCode,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.notifyTypeCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "notifyTypeCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.notifyTypeCode",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.notifyTypeList,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label:
                                                            item.notifyConfigName +
                                                            "(" +
                                                            item.notifyTypeName +
                                                            ")",
                                                          value:
                                                            item.notifyTypeCode,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    !_vm._.isEmpty(_vm.form.templateContentList)
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "15px",
                                              "padding-bottom": "15px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: { height: "15px" },
                                              },
                                              [
                                                _c(
                                                  "el-divider",
                                                  {
                                                    attrs: {
                                                      "content-position":
                                                        "left",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.configName)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._l(
                                              _vm.form.templateContentList,
                                              function (item, index) {
                                                return _c(
                                                  "el-form-item",
                                                  {
                                                    key: index,
                                                    staticStyle: {
                                                      "line-height": "40px",
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      "label-width": "130px",
                                                      label: item.display,
                                                      prop:
                                                        "templateContentList." +
                                                        index +
                                                        ".storeValue",
                                                      rules: {
                                                        required: item.required,
                                                        message:
                                                          "请输入" +
                                                          item.display,
                                                        trigger: "blur",
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        (_vm._.toLower(
                                                          item.inputType
                                                        ) == "text" ||
                                                          _vm._.toLower(
                                                            item.inputType
                                                          ) == "email") &&
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) != "number" &&
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) != "select"
                                                          ? _c("el-input", {
                                                              attrs: {
                                                                maxlength:
                                                                  "200",
                                                                clearable: "",
                                                                size: "mini",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.storeValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "storeValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.storeValue",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) == "number"
                                                          ? _c("el-input", {
                                                              attrs: {
                                                                maxlength:
                                                                  "200",
                                                                clearable: "",
                                                                size: "mini",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    item.storeValue =
                                                                      item.storeValue.replace(
                                                                        /[^\d]/g,
                                                                        ""
                                                                      )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.storeValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "storeValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.storeValue",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) == "textarea"
                                                          ? _c("el-input", {
                                                              attrs: {
                                                                maxlength:
                                                                  "200",
                                                                clearable: "",
                                                                size: "mini",
                                                                type: "textarea",
                                                                rows: 5,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.storeValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "storeValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.storeValue",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) == "select"
                                                          ? _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择",
                                                                  size: "mini",
                                                                  filterable:
                                                                    "",
                                                                  clearable: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.storeValue,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "storeValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.storeValue",
                                                                },
                                                              },
                                                              _vm._l(
                                                                item.options,
                                                                function (
                                                                  _item,
                                                                  _index
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: _index,
                                                                      attrs: {
                                                                        label:
                                                                          _item.name,
                                                                        value:
                                                                          _item.value,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) == "file"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                },
                                                              },
                                                              [
                                                                !item.storeValue
                                                                  ? _c(
                                                                      "el-upload",
                                                                      {
                                                                        ref: "upload",
                                                                        refInFor: true,
                                                                        attrs: {
                                                                          "before-remove":
                                                                            function () {
                                                                              return (item.storeValue =
                                                                                "")
                                                                            },
                                                                          "show-file-list": false,
                                                                          limit: 1,
                                                                          action:
                                                                            "",
                                                                          "http-request":
                                                                            function (
                                                                              param
                                                                            ) {
                                                                              return _vm.uploadFile(
                                                                                param,
                                                                                item,
                                                                                index
                                                                              )
                                                                            },
                                                                          "file-list":
                                                                            _vm.fileList,
                                                                          accept:
                                                                            ".xlsx",
                                                                          "list-type":
                                                                            "text",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                size: "mini",
                                                                                plain:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "选择文件"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c("div", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "file-box",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "file-text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "文件id：" +
                                                                                  _vm._s(
                                                                                    item.storeValue
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-close file-btn",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    item.storeValue =
                                                                                      ""
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "space-between",
                                                        },
                                                      },
                                                      [
                                                        _vm.ENV_TITLE != "prd"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  color: "#999",
                                                                  "line-height":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    "[" +
                                                                      item.storeKey +
                                                                      "]"
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _c("div", {
                                                              staticStyle: {
                                                                color: "#999",
                                                                "line-height":
                                                                  "20px",
                                                              },
                                                            }),
                                                        item.description
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  color: "red",
                                                                  "line-height":
                                                                    "20px",
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    "[" +
                                                                      item.description +
                                                                      "]"
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c("div", { staticClass: "footer-btn-list" }, [
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", icon: "el-icon-back" },
                        on: { click: _vm.closePage },
                      },
                      [_vm._v(" 返回 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          icon: "el-icon-check",
                          type: "primary",
                        },
                        on: { click: _vm.save },
                      },
                      [_vm._v(" 保存 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }