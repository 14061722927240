import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
// import OrgSelect from '@/components/OrgSelect'
import customerManagementApi from '@/api/customerManagement';
import { character, commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'CustomersTracks',
  components: {
    Pagination: Pagination
    // OrgSelect
  },

  mixins: [initHeight, dict],
  data: function data() {
    return {
      activeName: "All",
      drawer: false,
      searchForm: {},
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      trackStatus: null,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      startEndTime: null,
      loading: false,
      highlightCurrent: true,
      users: [],
      summaryData: {
        DEFEAT: 0,
        INVALID: 0,
        TRACKING: 0,
        SUCCEED: 0
      },
      followUpStartEndTime: [getBeforeDate(30), getCurrentDate()]
    };
  },
  watch: {
    '$route': {
      handler: function handler(val) {
        switch (val.path) {
          // 跟进明细
          case "/customers/tracks":
            this.searchForm = {
              isMy: null
            };
            this.followUpStartEndTime = [getBeforeDate(30), getCurrentDate()];
            break;
          // 我的跟进
          case "/customers/myTracks":
            this.searchForm = {
              isMy: true
            };
            this.followUpStartEndTime = [getBeforeDate(30), getCurrentDate()];
            break;
          default:
            return;
        }
        ;
        this.tableData = [];
        this.total = 0;
        this.listQuery = {
          pageNum: 1,
          pageSize: 20
        };
        this.orgForm = {
          salesOrgCode: '',
          salesOrgName: ''
        };
        this.users = [];
        this.activeName = "All";
        this.summaryData = {
          DEFEAT: 0,
          INVALID: 0,
          TRACKING: 0,
          SUCCEED: 0
        };
        this.loadData();
      },
      immediate: true
    }
  },
  created: function created() {
    this.tableData = [];
    this.total = 0;
    this.listQuery = {
      pageNum: 1,
      pageSize: 20
    };
    this.orgForm = {
      salesOrgCode: '',
      salesOrgName: ''
    };
    this.users = [];
    this.activeName = "All";
    this.summaryData = {
      DEFEAT: 0,
      INVALID: 0,
      TRACKING: 0,
      SUCCEED: 0
    };
    this.loadData();
  },
  methods: {
    // 日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = '';
        this.searchForm[end] = '';
      }
      ;
    },
    nodeClick: function nodeClick(data) {
      this.searchForm.ownerBy = '';
      this.searchForm.ownerByName = '';
      this.searchForm.ownerOrgCode = data.salesOrgCode;
      this.searchForm.ownerOrgName = data.salesOrgName;
      if (!data.salesOrgCode && !data.salesOrgName) {
        this.users = [];
      }
    },
    breakData: function breakData() {
      this.searchData();
    },
    //导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: this.searchForm.isMy ? "我的跟进" : "跟进明细",
        exportUrl: '/biz-scrm-service/wecomexternaltrack/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      this.searchForm.trackStatus = tab.name;
      this.trackStatus = tab.name;
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.loadData();
    },
    // 查看客户详情
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: '/customerManagement/customerDetail',
        query: {
          customerId: item.externalId,
          tabName: "\u5BA2\u6237\u8BE6\u60C5-".concat(item.externalUserName)
        }
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        isMy: this.searchForm.isMy
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      this.users = [];
      this.followUpStartEndTime = [getBeforeDate(6), getCurrentDate()];
      this.searchForm.trackStatus = this.trackStatus;
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 双击打开详情
    dbSelected: function dbSelected(item) {
      this.viewDetail(item);
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      if (this.followUpStartEndTime) {
        this.searchForm.trackTimeStart = this.followUpStartEndTime[0] && this.followUpStartEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.trackTimeEnd = this.followUpStartEndTime[1] && this.followUpStartEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      if (this.searchForm && this.searchForm.trackTimeEnd) {
        this.searchForm.trackTimeEnd = this.searchForm.trackTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      if (this.searchForm.trackStatus === 'All') {
        this.searchForm.trackStatus = null;
      }
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      customerManagementApi.getTrackSearchlist(data).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data.list;
        if (res.data.summaryData) {
          _this.summaryData = res.data.summaryData;
        } else {
          _this.summaryData = {
            DEFEAT: 0,
            INVALID: 0,
            TRACKING: 0,
            SUCCEED: 0
          };
        }
        _this.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 列表行选择事件
    getUsers: function getUsers(val) {
      this.users = val;
    },
    // 监听选择业务员事件
    salemanNameChange: function salemanNameChange(val) {
      if (val) {
        var _user = _.find(this.users, function (item) {
          return item.userId == val;
        });
        if (_user) {
          this.searchForm.ownerBy = _user.userId;
          this.searchForm.ownerByName = _user.userName;
          this.$forceUpdate();
        }
      }
    },
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    }
  }
};