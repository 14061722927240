import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import customerManagementApi from '@/api/customerManagement';
import FormLabel from "@/components/FormSubLabel";
import VehicleInfoDialog from "@/views/customerManagement/customerList/dialog/vehicleInfoDialog";
export default {
  components: {
    FormLabel: FormLabel,
    VehicleInfoDialog: VehicleInfoDialog
  },
  mixins: [initHeight],
  props: {
    externalId: {
      type: String,
      default: ''
    },
    tabActiveName: {
      type: String,
      default: ''
    },
    vehicleForm: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      vehicleList: [],
      listLoading: false,
      activeNames: [],
      showAddVehicleDialog: false,
      opType: '',
      currentId: ''
    };
  },
  watch: {
    tabActiveName: {
      handler: function handler(newValue, oldValue) {
        if (newValue == 'VEHICLEINFO') {
          // this.loadData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 新增
    add: function add(type) {
      this.opType = type;
      this.showAddVehicleDialog = true;
    },
    // 编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.currentId = item.id.toString();
      this.showAddVehicleDialog = true;
    },
    getPurchasedServiceName: function getPurchasedServiceName(list) {
      var name = [];
      if (!_.isEmpty(list)) {
        list.forEach(function (element) {
          name.push(element.description);
        });
      }
      ;
      return !_.isEmpty(name) ? name.join('，') : '-';
    },
    // 初始化数据列表
    loadData: function loadData() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showAddVehicleDialog = false;
      if (isDialogNoRefresh) return;
      if (!this.externalId) return;
      this.listLoading = true;
      var data = {
        externalId: this.externalId
      };
      customerManagementApi.getWecomexternalvehicleList(data).then(function (res) {
        _this.listLoading = false;
        if (res.code === 200) {
          _this.vehicleList = res.data || [];
          if (_.isEmpty(_this.vehicleList)) {
            _this.vehicleList = [{
              vin: '',
              carAge: '',
              modelName: '',
              operatedDisplay: '',
              loanedDisplay: '',
              purchasedService: []
            }];
          }
          ;
          _this.activeNames = [];
          _this.vehicleList.forEach(function (element, index) {
            _this.activeNames.push(index);
          });
        }
        ;
      }).catch(function (err) {
        _this.listLoading = false;
      });
    },
    remove: function remove(item) {
      var _this2 = this;
      this.$confirm('您确定要删除该数据么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        _this2.listLoading = true;
        customerManagementApi.deleteWecomexternalvehicle(item.id).then(function (res) {
          _this2.listLoading = false;
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          ;
        }).catch(function (err) {
          _this2.listLoading = false;
        });
      }).catch(function () {});
    }
  }
};