import _typeof from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.function.name.js";
import operationMonitorApi from '@/api/operationMonitor';
import { Message, MessageBox } from 'element-ui';
import router from '@/router/modules';

/**
 * @desc 扩展对象继承
 * @param  {Object} out 一个或多个对象
 * @return {Object} 对象
 */
Object.extend = function (out) {
  out = out || {};
  for (var i = 1; i < arguments.length; i++) {
    if (!arguments[i]) continue;
    for (var key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key)) out[key] = arguments[i][key];
    }
  }
  return out;
};
export default {
  /**
   * @desc  判断对象是否为空
   * @param  {Object} o 对象
   * @return {Boolean}
   */
  isEmptyObject: function isEmptyObject() {
    var o = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var flag = true;
    for (var k in o) {
      if (k) {
        flag = false;
        break;
      }
    }
    return flag;
  },
  /**
   * @description 去除前后空格
   * @param  {String} 值
   * @return {String}
   */
  trim: function trim(val) {
    return val.replace(/(^\s*)|(\s*$)/g, '');
  },
  /**
   * @desc  获取 localStorage 中指定的变量
   * @param  {String} name 名称
   * @return {*} rs
   */
  getStorage: function getStorage(name) {
    return this._string2json(window.localStorage[name]);
  },
  /**
   * @desc  设置或添加 localStorage 中指定的变量
   * @param {String} name 名称
   */
  setStorage: function setStorage(name, value) {
    window.localStorage[name] = this._json2string(value);
  },
  /**
   * @desc  删除 localStorage 中指定的变量
   * @param  {String} name 名称
   */
  delStorage: function delStorage(name) {
    window.localStorage.removeItem(name);
  },
  /**
   * @desc json转string
   * @param  {*} value 值
   * @return {*} value 值
   */
  _json2string: function _json2string(value) {
    return JSON.stringify(value);
  },
  /**
   * @desc  string转json
   * @param  {*} value 值
   * @return {*} value 值
   */
  _string2json: function _string2json(value) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      //
    }
    return value;
  },
  // 保留小数位，替代Number.toFixed()方法，针对于某些数据（16.455）不能做到四舍五入
  toFixed: function toFixed(value) {
    var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var pos = value.toString().indexOf('.');
    var decimalPlaces = value.toString().length - pos - 1;
    var intValue = value * Math.pow(10, decimalPlaces);
    var divisor1 = Math.pow(10, decimalPlaces - num);
    var divisor2 = Math.pow(10, num);
    return Math.round(intValue / divisor1) / divisor2;
  },
  /**
   * 如果是小数则保留小数位，默认两位
   * @param  {[type]} value [description]
   * @param  {Number} num   [description]
   * @return {[type]}       [description]
   */
  toFloatFixed: function toFloatFixed(value) {
    var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    var values = Number(value);
    if (values) {
      if (/^\d+\.\d+/.test(values)) {
        return this.toFixed(values, num);
      } else {
        return this.toFixed(values);
      }
    }
    return value;
  }
};

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * 保存文件
 * @param {} res 
 * @param {} fileName 
 */
export function saveFile(resData, contentType, fileName) {
  if (!resData) return false;
  try {
    var blob = new Blob([resData], {
      type: contentType
    });
    var downloadElement = document.createElement('a');
    var href = URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = fileName;
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    URL.revokeObjectURL(href); //释放掉blob对象
    return true;
  } catch (err) {
    console.log('Download=>error:', err);
    return false;
  }
  return true;
}
/**
 * 格式化日期
 * @param {*} time 
 */
export function dataFormat(time) {
  return "".concat(time.getFullYear(), "-").concat(time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1), "-").concat(time.getDate() >= 10 ? time.getDate() : '0' + time.getDate(), " ").concat(time.getHours() >= 10 ? time.getHours() : '0' + time.getHours(), ":").concat(time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes(), ":").concat(time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds());
}
/**
 * 格式化日期
 * @param {*} fmt "YYYY-mm-dd HH:MM"
 * @param {*} date 日期
 */
export function dateFormat(fmt, date) {
  var ret;
  var _date = new Date(date);
  var opt = {
    "Y+": _date.getFullYear().toString(),
    // 年
    "m+": (_date.getMonth() + 1).toString(),
    // 月
    "d+": _date.getDate().toString(),
    // 日
    "H+": _date.getHours().toString(),
    // 时
    "M+": _date.getMinutes().toString(),
    // 分
    "S+": _date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };

  for (var k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
    }
    ;
  }
  ;
  return fmt;
}
/**
 * 格式化保险投保起止日期
 * @param {} time 
 */
export function formatInsTime(time) {
  if (!time) return;
  var newTime = '';
  var timeYear = time.split(' ')[0];
  var timeMinute = time.split(' ')[1];
  var newtimeMinute = '24:00:00';
  if (timeMinute == '23:59:59') {
    newTime = timeYear + ' ' + newtimeMinute;
  } else {
    newTime = timeYear + ' ' + timeMinute;
  }
  newTime = newTime.slice(0, 16);
  return newTime;
}
export function getCurrentDate() {
  //获取当前日期
  var myDate = new Date();
  var nowY = myDate.getFullYear();
  var nowM = myDate.getMonth() + 1;
  var nowD = myDate.getDate();
  var date = nowY + "-" + (nowM < 10 ? "0" + nowM : nowM) + "-" + (nowD < 10 ? "0" + nowD : nowD); //当前日期
  return date;
}
export function getNextDate(date, day) {
  //获取指定日期的前几天，后几天
  var dd = new Date(date);
  dd.setDate(dd.getDate() + day);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
  var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
  return y + "-" + m + "-" + d;
}
;
export function getBeforeDate(day) {
  //获取指定天数前的日期
  var myDate = new Date();
  var lw = new Date(myDate.getTime() - 1000 * 60 * 60 * 24 * day);
  var lastY = lw.getFullYear();
  var lastM = lw.getMonth() + 1;
  var lastD = lw.getDate();
  var date = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD); //day之前日期
  return date;
}
;
export function getLastMonthDays() {
  var y = new Date().getFullYear();
  var m = new Date().getMonth();
  var lastMonthDays = new Date(y, m, 0).getDate();
  //获取上一个月的天数
  var myDate = new Date();
  var lw = new Date(myDate.getTime() - 1000 * 60 * 60 * 24 * lastMonthDays);
  var lastY = lw.getFullYear();
  var lastM = lw.getMonth() + 1;
  var lastD = lw.getDate();
  var date = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD); //day之前日期
  return date;
}
export function getAfterDate(day) {
  //获取指定天数后的日期
  var myDate = new Date();
  var lw = new Date(myDate.getTime() + 1000 * 60 * 60 * 24 * day);
  var lastY = lw.getFullYear();
  var lastM = lw.getMonth() + 1;
  var lastD = lw.getDate();
  var date = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD); //day之后日期
  return date;
}
export function getAfterDateHoursMin(day) {
  //获取指定天数后的日期
  var myDate = new Date();
  var lw = new Date(myDate.getTime() + 1000 * 60 * 60 * 24 * day);
  var lastY = lw.getFullYear();
  var lastM = lw.getMonth() + 1;
  var lastD = lw.getDate();
  var lastHou = lw.getHours();
  var lastMin = lw.getMinutes();
  var date = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD) + ' ' + (lastHou < 10 ? "0" + lastHou : lastHou) + ':' + (lastMin < 10 ? "0" + lastMin : lastMin); //day之后日期
  return date;
}
export function getLastWeekData() {
  //得到上周周一~周日的年月日 
  var lastweek = [];
  var date = new Date();
  // 上周一的日期
  date.setDate(date.getDate() - 7 - date.getDay() + 1);
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var start_day = date.getFullYear() + "-" + (month < 10 ? '0' + month : month) + "-" + (day < 10 ? '0' + day : day);
  lastweek.push(start_day);
  // 上周日的日期
  date.setDate(date.getDate() + 6);
  month = date.getMonth() + 1;
  day = date.getDate();
  var end_day = date.getFullYear() + "-" + (month < 10 ? '0' + month : month) + "-" + (day < 10 ? '0' + day : day);
  lastweek.push(end_day);
  return lastweek;
}
//获取上个月的第一天和最后一天
export function getLastMonth() {
  var arr = [];
  var nowdays = new Date();
  var year = nowdays.getFullYear();
  var month = nowdays.getMonth();
  if (month == 0) {
    month = 12;
    year = year - 1;
  }
  if (month < 10) {
    month = '0' + month;
  }
  var myDate = new Date(year, month, 0);
  var startDate = year + '-' + month + '-01'; //上个月第一天
  var endDate = year + '-' + month + '-' + myDate.getDate(); //上个月最后一天
  arr.push(startDate);
  arr.push(endDate);
  return arr;
}

//获取上个月的第一天和最后一天
export function getLastDate(day) {
  var arr = [];
  var nowdays = day ? new Date(day) : new Date();
  var year = nowdays.getFullYear();
  var month = nowdays.getMonth();
  if (month == 0) {
    month = 12;
    year = year - 1;
  }
  if (month < 10) {
    month = '0' + month;
  }
  var startDate = year + '-' + month + '-01'; //上个月第一天
  var endDate = year + '-' + month + '-' + getDayOfMonth(month); //上个月最后一天
  arr.push(startDate);
  arr.push(endDate);
  return arr;
}
//获取当前月的第一天和最后一天
export function getNowDate(day) {
  var arr = [];
  var nowdays = day ? new Date(day) : new Date();
  var year = nowdays.getFullYear();
  var month = nowdays.getMonth() + 1;
  var d = getDayOfMonth(month);
  if (month == 0) {
    month = 12;
    year = year - 1;
  }
  if (month == 2) {
    //当前日期为闰年的2/29时，则默认为3/1
    var isCurLeap = isLeapYear(year); //当前年份是否为闰年
    d = isCurLeap ? 29 : 28;
  }
  if (month < 10) {
    month = '0' + month;
  }
  var startDate = year + '-' + month + '-01 00:00:00'; //上个月第一天
  var endDate = year + '-' + month + '-' + (d < 10 ? '0' + d : d); //上个月最后一天
  arr.push(startDate);
  arr.push(endDate);
  return arr;
}

//获取上一年的第一天和最后一天
export function getLastYear() {
  var arr = [];
  var nowdays = new Date();
  var year = nowdays.getFullYear() - 1;
  var beginTime = year + '-01-01';
  var endTime = year + '-12-31';
  arr.push(beginTime);
  arr.push(endTime);
  return arr;
}
//获取上一年的第一天和今年最后一天
export function getPrevCurYear() {
  var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var currentYear = '';
  var nowdays = new Date();
  var year = nowdays.getFullYear() - num;
  if (num) {
    currentYear = year + '-01-01';
  } else {
    currentYear = year + '-12-31';
  }
  return currentYear;
}
/**
 * 获取生日，性别，年龄
 * @param {*} card 
 * @param {*} num 
 */
export function getAnalysisIdCard(card, num) {
  if (num == 1) {
    //获取出生日期
    var birth = card.substring(6, 10) + "-" + card.substring(10, 12) + "-" + card.substring(12, 14);
    return birth;
  }
  if (num == 2) {
    //获取性别
    if (parseInt(card.substr(16, 1)) % 2 == 1) {
      //男
      return "M";
    } else {
      //女
      return "F";
    }
  }
  if (num == 3) {
    //获取年龄
    var myDate = new Date();
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var age = myDate.getFullYear() - card.substring(6, 10) - 1;
    if (card.substring(10, 12) < month || card.substring(10, 12) == month && card.substring(12, 14) <= day) {
      age++;
    }
    return age;
  }
}

/**
 * 通过城市code获取对应的城市item
 * @param {*} code  
 */
export function getCityItem(code) {
  if (!code) return {};
  var ctiy = JSON.parse(localStorage.getItem('VI_CityPcTree')) || [];
  var cityItem = {};
  ctiy.forEach(function (item) {
    if (item.children && item.children.length > 0) {
      item.children.forEach(function (eitem) {
        if (eitem.value == code) {
          cityItem = eitem;
        }
      });
    }
  });
  return cityItem;
}
export function isLeapYear(year) {
  //判断是否为闰年
  if (year % 400 === 0 || year % 4 === 0 && year % 100 !== 0) {
    return true;
  }
  return false;
}
//传入参数获取N天后日期
export function GetDateStr(AddDayCount) {
  var dd = new Date();
  dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期  
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1; //获取当前月份的日期，不足10补0  
  var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(); //获取当前几号，不足10补0  
  return y + "-" + m + "-" + d;
}

// 获取本周周一日期
export function getMonday() {
  // getDay()获取到的是星期几,范围是0~6,0代表星期日
  var day = new Date().getDay() || 7;
  return GetDateStr(1 - day);
}
// 获取本周周日日期
export function getSunday() {
  // getDay()获取到的是星期几,范围是0~6,0代表星期日
  var day = new Date().getDay() || 7;
  return GetDateStr(7 - day);
}
/**
 * 根据月份获取对应的天数
 * @param {*} month 
 * @returns 
 */
export function getDayOfMonth(month) {
  var day = 31; //默认31天
  switch (month) {
    case 4:
    case '04':
    case 6:
    case '06':
    case 9:
    case '09':
    case '11':
      day = 30;
      break;
  }
  return day;
}
/**
 * 计算下一年{add}天后的日期
 * @param {*} startTime 
 * @param {*} add 
 * @returns 
 */
export function getNextYear(startTime) {
  var add = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var curDay = new Date(startTime);
  // 下一年
  var y = curDay.getFullYear() + 1;
  var m = curDay.getMonth() + 1;
  var d = curDay.getDate();
  var currentDay = getDayOfMonth(m);
  d = parseInt(d) + add;
  if (m == 12) {
    //如果是12月份的最后一天，则年份加1，月份和天数默认1；
    y = d > 31 ? y + 1 : y;
    m = d > 31 ? 1 : m;
    d = d > 31 ? 1 : d;
  } else if (m == 2) {
    //当前日期为闰年的2/29时，则默认为3/1
    var isCurLeap = isLeapYear(curDay.getFullYear()); //当前年份是否为闰年
    var isNextLeap = isLeapYear(y); //下一年年份是否为闰年
    if (d > 28) {
      if (isCurLeap) {
        m = 3;
        d = 1;
      } else {
        m = isNextLeap ? m : 3;
        d = isNextLeap ? d : 1;
      }
    }
  } else {
    //非2，12月的其他月份，如果月份大于30或31则月份加1和天数默认1；
    m = d > currentDay ? m + 1 : m;
    d = d > currentDay ? 1 : d;
  }
  return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);
}

/**
 * 获取供应商列表
 */
export function getSupplyers() {
  var localData = JSON.parse(localStorage.getItem('VI_PROP_SUPPLYER')) || [];
  var supplyers = [];
  localData.forEach(function (item) {
    if (item.code) {
      supplyers.push(item);
    }
  });
  return supplyers;
}

/**
 * 获取当前登录用户所在的组织机构
 */
export function getUserOrgInfo() {
  var userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
  var _orgInfo = userInfo && userInfo.organization;
  if (!_orgInfo && userInfo && userInfo.data) {
    _orgInfo = userInfo.data.organizationVO || userInfo.data.organization;
    if (_orgInfo) {
      _orgInfo.orgName = _orgInfo.fullName;
    }
  }
  return _orgInfo || {};
}

/**
 * 获取当前登录用户所在的组织机构信息(经销商-主机厂)
 */
export function getUserOrgInfoDealerOem(bizType) {
  var userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
  var userInfoObj = {};
  if (bizType === "DEALER") {
    userInfoObj.salesOrgCode = userInfo.data.orgCode;
    userInfoObj.salesOrgName = userInfo.data.orgName;
    userInfoObj.salesOrgId = userInfo.data.orgId;
    userInfoObj.bizType = userInfo.bizType || userInfo.orgType;
    userInfoObj.salesOrgType = userInfo.bizType || userInfo.orgType;
  } else if (bizType === "OEM") {
    userInfoObj.salesOrgCode = userInfo.data.organization.oemCode;
    userInfoObj.salesOrgName = userInfo.data.organization.oemName;
    userInfoObj.salesOrgId = userInfo.data.orgId;
    userInfoObj.bizType = userInfo.bizType || userInfo.orgType;
    userInfoObj.salesOrgType = userInfo.bizType || userInfo.orgType;
  }
  return userInfoObj;
}
/**
 * 获取当前登录用户所在的组织机构列表
 */
export function findChildByOrgCode(item, _orgCode) {
  if (_.isEmpty(item.children)) return null;
  var child = _.find(item.children, function (eitem) {
    return eitem.orgCode == _orgCode;
  });
  if (child) {
    return child;
  } else {
    item.children.some(function (eitem) {
      var _temp = findChildByOrgCode(eitem, _orgCode);
      if (_temp) {
        child = _temp;
        return true;
      }
    });
  }
  return child;
}

/**
 * 获取当前登录用户所在的组织机构列表
 */
export function getCurrentOrganization(organizationInfo) {
  var organizations = {};
  if (!_.isEmpty(organizationInfo)) {
    var _orgInfo = getUserOrgInfo();
    var orgCode = _orgInfo.orgCode || '';
    // orgCode = '.1.3.721.11.'
    if (!orgCode) {
      return organizationInfo[0];
    }
    organizationInfo.forEach(function (item) {
      if (_.toString(item.orgCode) == _.toString(orgCode)) {
        organizations = item;
      } else {
        organizations = findChildByOrgCode(item, orgCode);
      }
    });
  }
  return organizations || {};
}

/**
 * 获取指定日期之前的天数
 */
export function getBeforeOfDate(time, day) {
  //获取指定天数前的日期
  var myDate = new Date(time);
  var lw = new Date(myDate.getTime() - 1000 * 60 * 60 * 24 * day);
  var lastY = lw.getFullYear();
  var lastM = lw.getMonth() + 1;
  var lastD = lw.getDate();
  var date = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD); //day之前日期
  return date;
}

/**
 * 通过省code获取对应的省item
 * @param {*} code  
 */
export function getProvinceItem(code) {
  if (!code) return {};
  var province = JSON.parse(localStorage.getItem('VI_CityPcTree')) || [];
  var provinceItem = _.find(province, function (eitem) {
    return eitem.value == code;
  });
  return provinceItem;
}
/**
 * 通过号牌种类匹配号牌颜色
 * @param {*} code  
 */
export function getPlateColorType(code) {
  var color = '';
  switch (code) {
    case '01':
    case '16':
      color = '04';
      break;
    case '02':
      color = '01';
      break;
    case '03':
    case '04':
    case '05':
    case '06':
      color = '02';
      break;
    case '18':
    case '20':
    case '23':
    case '31':
    case '32':
      color = '03';
      break;
    case '51':
      color = '07';
      break;
    case '52':
      color = '06';
      break;
    default:
      break;
  }
  return color;
}
/**
 * 通过车型品牌型号获取号牌种类
 * @param {*} code  
 */
export function getVehicleType(code) {
  var typeCode = 'K33';
  switch (code) {
    case '哈弗':
    case 'WEY':
      typeCode = 'K31';
      break;
    case '皮卡':
      typeCode = 'H31';
      break;
    case '欧拉':
    case '腾翼':
      typeCode = 'K33';
      break;
    default:
      break;
  }
  return typeCode;
}
/**
 *@desc 只能输入字母和数字
 *@param { String } value
 */
export function character(value) {
  if (value) {
    value = value.replace(/[^A-Za-z0-9]/g, '');
  }
  return value;
}

/**
 * 能源类型匹配
 * @param {*} code 
 * @param {*} isBj 
 */
export function getFuelTypeCode(code, isBj) {
  if (!code) return '';
  var ENERGYTYPECONVERSION = {
    "D1": "0",
    "D2": "0",
    "D3": "0",
    "D4": "0",
    "D5": "4",
    "D6": "1",
    "D7": "0",
    "D8": "2",
    "D9": "0",
    "D11": "0",
    "D12": "3",
    "D13": "0",
    "D14": "0",
    "D15": "0"
  };
  var BJ_ENERGYTYPECONVERSION = {
    "D1": "A",
    "D2": "B",
    "D3": "E",
    "D4": "F",
    "D5": "O",
    "D6": "C",
    "D7": "O",
    "D8": "C",
    "D9": "L",
    "D11": "Z",
    "D12": "O",
    "D13": "D",
    "D14": "N",
    "D15": "Y"
  };
  return isBj ? BJ_ENERGYTYPECONVERSION[code] : ENERGYTYPECONVERSION[code];
}
/**
 * 车辆行驶证类型匹配
 * @param {*} classid 
 */
export function getVechileTypeByClassid(classid) {
  if (!classid) return;
  //匹配车辆类型
  var jqxClasses = JSON.parse(localStorage.getItem('VI_JQX_CLASS')) || [];
  var jqxClass = _.find(jqxClasses, function (item) {
    return item.dictName == classid;
  });
  if (!jqxClass) return;
  var VEHTYPETOOWNERVEHTYPEMAP = {
    "A012": "K33",
    "A022": "K31",
    "A032": "K21",
    "A042": "K11",
    "A052": "K11",
    "B012": "H31",
    "B022": "H21",
    "B032": "H21",
    "B042": "H11",
    "C022": "H14",
    "B052": "H51",
    "B062": "N11",
    "B142": "Q11",
    "C032": "Z11",
    "C042": "Z11",
    "C112": "G31",
    "C122": "G21",
    "C132": "G21",
    "C142": "G11",
    "C152": "G13",
    "C162": "G13",
    "C172": "G12",
    "C182": "B17",
    "B101": "H51",
    "B102": "H51",
    "B103": "H51",
    "B104": "H51",
    "B105": "H51",
    "D112": "M22",
    "D012": "M11",
    "D022": "M11",
    "E012": "T11",
    "E022": "T11",
    "E112": "T11",
    "E122": "T11",
    "E201": "T11",
    "E202": "T11",
    "E203": "T11",
    "E204": "T11",
    "E205": "T11",
    "E301": "T11",
    "E302": "T11",
    "E303": "T11",
    "E304": "T11",
    "E305": "T11",
    "B201": "N11",
    "B202": "N11",
    "B203": "N11",
    "B204": "N11",
    "B205": "N11"
  };
  return VEHTYPETOOWNERVEHTYPEMAP[jqxClass.dictCode];
}

/**
 * 根据车型查询返回的“车辆种类”、“商业险字典”对应带出“行驶证车辆类型”
 * @param {*} classid 
 * @param {*} className
 */
export function getVechileTypeByClass(classId, className) {
  if (!classId) return;
  if (!className) {
    return getVechileTypeByClassid(classId);
  }
  //匹配车辆类型
  var typeCode = '';
  switch (className) {
    case '轿车类':
      switch (classId) {
        case 'KA':
          typeCode = 'K33';
          break;
      }
      break;
    case '旅行车类':
      switch (classId) {
        case 'KB':
          typeCode = 'K31';
          break;
        case 'KC':
          typeCode = 'K21';
          break;
      }
      break;
    case '越野车类':
      switch (classId) {
        case 'KA':
        case 'KB':
          typeCode = 'K31';
          break;
        case 'KC':
          typeCode = 'K21';
          break;
      }
      break;
    case '套牌车类':
      switch (classId) {
        case 'KA':
          typeCode = 'K31';
          break;
      }
      break;
    case '大型客车类':
      switch (classId) {
        case 'KD':
        case 'KE':
          typeCode = 'K11';
          break;
      }
      break;
    case '轻型货车类':
      switch (classId) {
        case 'HA':
          typeCode = 'H41';
          break;
        case 'HB':
          typeCode = 'H31';
          break;
      }
      break;
    case '货车类':
      switch (classId) {
        case 'HC':
          typeCode = 'H21';
          break;
        case 'HD':
          typeCode = 'H11';
          break;
      }
      break;
    case '农用车类':
      switch (classId) {
        case 'NA':
          typeCode = 'H51';
          break;
      }
      break;
    default:
      break;
  }
  return typeCode;
}

/**
 * 限制精度
 * @param {*} value 
 * @param {*} limit 
 * @param {*} maxVlue 
 */
export function limitInput(value) {
  var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  if (!value) return '';
  var isMinus = parseFloat(value) < 0 || value == '-';
  if (isMinus) {
    value = value.toString();
    value = value.split('-')[1];
  }
  var str = value;
  if (str) {
    str = value.toString();
    var len1 = str.substr(0, 1);
    var len2 = str.substr(1, 1);
    //如果第一位是0，第二位不是点，就用数字把点替换掉
    if (str.length > 1 && len1 == 0 && len2 != ".") {
      str = str.substr(1, 1);
    }
    //第一位不能是.
    if (len1 == ".") {
      str = "";
    }
    //限制只能输入一个小数点
    if (str.indexOf(".") != -1) {
      var str_ = str.substr(str.indexOf(".") + 1);
      if (str_.indexOf(".") != -1) {
        str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
      }
    }
    //正则替换
    str = str.replace(/[^\d^\.]+/g, ''); // 保留数字和小数点
    var temp = str.split('.');
    //保留指定小数位
    if (temp.length > 1 && temp[1].length > limit && limit > 0) {
      temp[1] = temp[1].substring(0, limit);
      str = _.join([temp[0], temp[1]], '.');
    } else {
      if (limit == 0) {
        str = temp[0];
      }
    }
  }
  return isMinus ? '-' + str : str;
}

/**
 * 获取指定月数之前的日期
 */
export function getBeforeOfMonth(date, monthNum) {
  var dateArr = date.split('-');
  var year = dateArr[0]; //获取当前日期的年份
  var month = dateArr[1]; //获取当前日期的月份
  var day = dateArr[2]; //获取当前日期的日
  var days = new Date(year, month, 0);
  days = days.getDate(); //获取当前日期中月的天数
  var year2 = year;
  var month2 = parseInt(month) - monthNum;
  if (month2 <= 0) {
    year2 = parseInt(year2) - parseInt(month2 / 12 == 0 ? 1 : Math.abs(parseInt(month2 / 12)) + 1);
    month2 = 12 - Math.abs(month2) % 12;
  }
  var day2 = day;
  var days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }
  var t2 = year2 + '-' + month2 + '-' + day2;
  return t2;
}

/**
 * 获取指定月数之后的日期
 */
export function getAfterOfMonth(date, monthNum) {
  var dd = new Date(date);
  dd.setMonth(dd.getMonth() + monthNum);
  var Y = dd.getFullYear() + '-';
  var M = (dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1) + '-';
  var D = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
  return Y + M + D;
}

// 获取操作系统信息 
export function getOsInfo() {
  var userAgent = navigator.userAgent.toLowerCase();
  var name = 'Unknown';
  var version = 'Unknown';
  if (userAgent.indexOf('win') > -1) {
    name = 'Windows';
    if (userAgent.indexOf('windows nt 5.0') > -1) {
      version = 'Windows 2000';
    } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
      version = 'Windows XP';
    } else if (userAgent.indexOf('windows nt 6.0') > -1) {
      version = 'Windows Vista';
    } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
      version = 'Windows 7';
    } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
      version = 'Windows 8';
    } else if (userAgent.indexOf('windows nt 6.3') > -1) {
      version = 'Windows 8.1';
    } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
      version = 'Windows 10';
    } else {
      version = 'Unknown';
    }
  } else if (userAgent.indexOf('iphone') > -1) {
    name = 'Iphone';
  } else if (userAgent.indexOf('mac') > -1) {
    name = 'Mac';
  } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
    name = 'Unix';
  } else if (userAgent.indexOf('linux') > -1) {
    if (userAgent.indexOf('android') > -1) {
      name = 'Android';
    } else {
      name = 'Linux';
    }
  } else {
    name = 'Unknown';
  }
  return {
    name: name,
    version: version
  };
}
var Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1]; // 加权因子
var ValideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]; // 身份证验证位值.10代表X
/**
 * 验证18位数身份证号码
 * @param idCard 18位书身份证字符串
 * @return
 */
export function idCardValidate(idCard) {
  if (idCard.length == 18) {
    var a_idCard = idCard.split(""); // 得到身份证数组
    if (isValidityBrithBy18IdCard(idCard) && isTrueValidateCodeBy18IdCard(a_idCard)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
/**
 * 验证18位数身份证号码中的生日是否是有效生日
 * @param idCard 18位书身份证字符串
 * @return
 */
export function isValidityBrithBy18IdCard(idCard18) {
  var year = idCard18.substring(6, 10);
  var month = idCard18.substring(10, 12);
  var day = idCard18.substring(12, 14);
  var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
  // 这里用getFullYear()获取年份，避免千年虫问题
  if (temp_date.getFullYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
    return false;
  } else {
    return true;
  }
}
/**
 * 验证15位数身份证号码中的生日是否是有效生日
 * @param idCard15 15位书身份证字符串
 * @return
 */
export function isValidityBrithBy15IdCard(idCard15) {
  var year = idCard15.substring(6, 8);
  var month = idCard15.substring(8, 10);
  var day = idCard15.substring(10, 12);
  var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
  // 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
  if (temp_date.getYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
    return false;
  } else {
    return true;
  }
}
/**
 * 判断身份证号码为18位时最后的验证位是否正确
 * @param a_idCard 身份证号码数组
 * @return
 */
export function isTrueValidateCodeBy18IdCard(a_idCard) {
  var sum = 0; // 声明加权求和变量
  if (a_idCard[17].toLowerCase() == 'x') {
    a_idCard[17] = 10; // 将最后位为x的验证码替换为10方便后续操作
  }

  for (var i = 0; i < 17; i++) {
    sum += Wi[i] * a_idCard[i]; // 加权求和
  }

  var valCodePosition = sum % 11; // 得到验证码所位置
  if (a_idCard[17] == ValideCode[valCodePosition]) {
    return true;
  } else {
    return false;
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (_typeof(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if (_typeof(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && _typeof(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

/**
 * 缓存省市区列表
 * @param {} data 
 */
export function initCityTree(data) {
  var VI_CityAreaTree = [];
  var VI_CityPcTree = [];
  data.forEach(function (province) {
    //判断是否有城市
    if (!_.isEmpty(province.child)) {
      var _province = {
        label: province.name,
        value: province.code,
        children: []
      };
      var _children = [];
      var _provinces = {
        label: province.name,
        value: province.code,
        children: []
      };
      var _cityChildren = [];
      province.child.forEach(function (city) {
        var _city = {
          label: city.name,
          value: city.code,
          children: []
        };
        _cityChildren.push({
          label: city.name,
          value: city.code,
          children: null
        });
        var _childrens = [];
        if (!_.isEmpty(city.child)) {
          city.child.forEach(function (area) {
            if (area) {
              _childrens.push({
                label: area.name,
                value: area.code,
                children: null
              });
            }
          });
          _children.push(_city);
        }
        _city.children = _childrens;
      });
      _provinces.children = _cityChildren;
      if (!_.isEmpty(_provinces.children)) {
        VI_CityPcTree.push(_provinces);
      }
      _province.children = _children;
      if (!_.isEmpty(_province.children)) {
        VI_CityAreaTree.push(_province);
      }
    }
  });
  //省市
  if (!_.isEmpty(VI_CityPcTree)) localStorage.setItem('VI_CityPcTree', JSON.stringify(VI_CityPcTree));
  //省市区
  if (!_.isEmpty(VI_CityAreaTree)) localStorage.setItem('VI_CityAreaTree', JSON.stringify(VI_CityAreaTree));
}

/**
 * 计算相差月份
 * @param {*} date1 
 * @param {*} date2 
 * @returns 
 */
export function calcBetweenMonths(date1, date2) {
  //用-分成数组
  date1 = date1.split("-");
  date2 = date2.split("-");
  //获取年,月数
  var year1 = parseInt(date1[0]),
    month1 = parseInt(date1[1]),
    year2 = parseInt(date2[0]),
    month2 = parseInt(date2[1]),
    day1 = parseInt(date1[2]),
    day2 = parseInt(date2[2]);
  //通过年,月差计算月份差
  var months = (year2 - year1) * 12 + (month2 - month1);
  if (day1 > day2) {
    months = months - 1;
  }
  return months;
}
// 将html代码转换成encode
export function htmlEncodeByRegExp(str) {
  var s = '';
  if (str.length === 0) {
    return '';
  }
  s = str.replace(/&/g, '&amp;');
  s = s.replace(/</g, '&lt;');
  s = s.replace(/>/g, '&gt;');
  s = s.replace(/ /g, '&nbsp;');
  s = s.replace(/\'/g, '&#39;');
  s = s.replace(/\"/g, '&quot;');
  return s;
}
// 将encode转换成html代码
export function htmlDecodeByRegExp(str) {
  var s = '';
  if (str.length === 0) {
    return '';
  }
  s = str.replace(/&amp;/g, '&');
  s = s.replace(/&lt;/g, '<');
  s = s.replace(/&gt;/g, '>');
  s = s.replace(/&nbsp;/g, ' ');
  s = s.replace(/&#39;/g, '\'');
  s = s.replace(/&quot;/g, '\"');
  return s;
}
/**
 * 公共导出方法
 * @param {object} tableEle 表格元素
 * @param {object} exportParams 导出的参数（以下是这对象里面每个属性）
 * 
 * @param {number} total 表格数量
 * @param {number} limitNum 限制导出数量
 * @param {string} fileName 导出文件名
 * @param {string} exportUrl 导出接口
 * @param {object} searchForm 搜索过滤参数
 * @param {array} exportData 导出的数据
 * @param {string} jumpPath 跳转路径
 */
export function commonExport() {
  var exportParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var tableEle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  // 没传的需要给默认值
  exportParams = {
    total: exportParams.total || 0,
    limitNum: exportParams.limitNum || 30000,
    fileName: exportParams.fileName || '',
    exportUrl: exportParams.exportUrl || '',
    searchForm: exportParams.searchForm || {},
    exportData: exportParams.exportData || [],
    jumpPath: exportParams.jumpPath || '/myExportLog'
  };
  if (tableEle.$children) {
    tableEle.$children.forEach(function (ele) {
      if (ele.columnConfig && ele.columnConfig.property) {
        exportParams.exportData.push(ele.columnConfig);
      }
      ;
    });
  }
  ;
  if (exportParams.total && exportParams.total < exportParams.limitNum) {
    var fieldsObj = {};
    exportParams.exportData.forEach(function (val) {
      fieldsObj[val.property] = val.label;
    });
    var postData = {
      "fields": fieldsObj,
      "name": exportParams.fileName,
      "params": exportParams.searchForm,
      "url": "".concat(process.env.VUE_APP_APIDOMAIN).concat(exportParams.exportUrl)
    };
    operationMonitorApi.generateExcel(postData).then(function (res) {
      MessageBox("".concat(res.msg), '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: "前往查看",
        showCancelButton: false
      }).then(function () {
        router.push({
          path: exportParams.jumpPath
        });
      }).catch(function () {});
    });
  } else {
    Message.warning("\u5BFC\u51FA\u6570\u91CF\u4E3A\u7A7A\u6216\u8D85\u8FC7\u4E86\u6700\u5927\u53EF\u5BFC\u51FA\u6570\u91CF(".concat(_.divide(exportParams.limitNum, 10000), "\u4E07)"));
  }
  ;
}