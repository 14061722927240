//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import customerManagementApi from '@/api/customerManagement';
import FormLabel from "@/components/FormSubLabel";
export default {
  components: {
    FormLabel: FormLabel
  },
  mixins: [initHeight],
  props: {
    unionId: {
      type: String,
      default: ''
    },
    tabActiveName: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {},
      listLoading: false,
      activeNames: ['1', '2', '3', '4', '5', '6']
    };
  },
  watch: {
    tabActiveName: {
      handler: function handler(newValue, oldValue) {
        if (newValue == 'USERPORTRAIT') {
          // this.loadData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 计算表格高度
    tableHeight: function tableHeight() {
      return document.body.clientHeight - 480 + 'px';
    }
  },
  methods: {
    // 初始化数据列表
    loadData: function loadData() {
      if (!this.unionId) return;
      this.listLoading = true;
      // customerManagementApi.用户画像接口().then((res) => {
      //     this.listLoading = false;
      //     if (res.code === 200) {
      //         this.form = res.data || {};
      //     };
      // }).catch((err) => {
      //     this.listLoading = false;
      // });
    }
  }
};