import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import marketingSOPApi from '@/api/marketingSOP';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      form: {
        sopTemplateId: null
      },
      loading: false,
      sopTemplatesLists: [],
      formRules: {
        sopTemplateId: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }]
      },
      sopTemplateItem: null
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      this.getSopTemplatesList();
      this.loading = true;
      this.form = {
        sopTemplateId: null
      };
    },
    getSopTemplatesList: function getSopTemplatesList() {
      var _this = this;
      marketingSOPApi.getSopTemplateList().then(function (res) {
        _this.loading = false;
        if (res.code == 200 && res.data) {
          _this.sopTemplatesLists = res.data || [];
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    setSopTemplate: function setSopTemplate(val) {
      var item = _.find(this.sopTemplatesLists, function (sItem) {
        return sItem.id == val;
      });
      if (item) {
        this.sopTemplateItem = item;
      }
      ;
    },
    saveClicked: function saveClicked() {
      this.$emit('confrimDialog', this.sopTemplateItem || {});
    },
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};