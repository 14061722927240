import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import operationMonitorApi from '@/api/operationMonitor';
import { saveFile, commonExport } from '@/utils/common';
export default {
  name: 'SysExportMonitor',
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      detailTotal: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      detail: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      detailTableData: [],
      loading: false,
      showDetail: false
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: "系统导出监控表",
        exportUrl: "/tp-data-process-service/export-log",
        searchForm: _objectSpread(_objectSpread({}, this.searchForm), this.listQuery)
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      operationMonitorApi.getExportLogList(data).then(function (res) {
        _this.loading = false;
        if (!_.isEmpty(res.data)) {
          _this.tableData = res.data.list;
          // 总条数
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 下载
    downFile: function downFile(item) {
      var _this2 = this;
      if (item.exportStatus != 'SUCCESS') {
        this.$message({
          message: "\u5BFC\u51FA\u5DF2".concat(item.exportStatusDisplay, "\uFF0C\u4E0D\u80FD\u8FDB\u884C\u4E0B\u8F7D"),
          type: 'warning'
        });
        return;
      }
      ;
      operationMonitorApi.exportExcel(item.exportId).then(function (res) {
        var fileName = item.fileName;
        var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        saveFile(res, contentType, fileName);
        _this2.loadData();
      });
    },
    // 获取明细数据
    getData: function getData(row) {
      var _this3 = this;
      this.listLoading = true;
      this.showDetail = true;
      if (row) {
        this.exportId = row.exportId;
      }
      ;
      operationMonitorApi.getExportLogDetailList(this.detail.pageNum, this.detail.pageSize, this.exportId).then(function (res) {
        _this3.listLoading = false;
        if (res.code == 200 && res.data) {
          _this3.detailTableData = res.data.list || [];
          _this3.detailTotal = res.data.page && res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this3.listLoading = false;
      });
    }
  }
};