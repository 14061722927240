/**
 * 配置编译环境
 */

// 可以多个接口域名，按需添加
var envInfo = process.env;
var _ref = [envInfo.VUE_APP_APIDOMAIN, envInfo.VUE_APP_APIDOMAIN_dev, envInfo.VUE_APP_ANSWEB_API, envInfo.VUE_APP_TP_AUTH_SERVICE, envInfo.VUE_APP_TP_ORG_SERVICE, envInfo.VUE_APP_TP_CORE_SERVICE, envInfo.VUE_APP_BIZ_SCRM_SERVICE, envInfo.VUE_APP_STORAGE_SERVER, envInfo.VUE_APP_TP_DATA_PROCESS_SERVICE, envInfo.VUE_APP_TP_RESOURCE_SERVICE, envInfo.VUE_APP_BIZ_CMS_SERVICE, envInfo.VUE_APP_ANS_OFFLINE_ANALYSIS_SERVICE, envInfo.VUE_APP_TP_MESSAGE_SERVICE, envInfo.VUE_APP_OPENAI_CORE_SERVICE],
  apiDomain = _ref[0],
  apiDomainDev = _ref[1],
  answebapiDomain = _ref[2],
  tpAuthService = _ref[3],
  tpOrgService = _ref[4],
  tpCoreService = _ref[5],
  bizScrmService = _ref[6],
  tpStorageService = _ref[7],
  tpDataProcessService = _ref[8],
  tpResourceService = _ref[9],
  bizCmsService = _ref[10],
  ansOfflineAnalysisService = _ref[11],
  tpMessageService = _ref[12],
  openaiCoreService = _ref[13];
/**
 *
 */
export default {
  apiDomain: apiDomain,
  // 主域名
  apiDomainDev: apiDomainDev,
  // 测试环境域名
  answebapiDomain: answebapiDomain,
  // 用户行为分析域名
  tpAuthService: tpAuthService,
  // 认证服务
  tpOrgService: tpOrgService,
  // 组织服务
  tpCoreService: tpCoreService,
  // 核心服务
  bizScrmService: bizScrmService,
  // biz-scrm服务
  tpStorageService: tpStorageService,
  // 储存服务
  tpDataProcessService: tpDataProcessService,
  // 数据处理服务
  tpResourceService: tpResourceService,
  // 资源服务
  bizCmsService: bizCmsService,
  // scrm-biz-cms服务
  ansOfflineAnalysisService: ansOfflineAnalysisService,
  // ans-offline-analysis-service服务
  tpMessageService: tpMessageService,
  // 站内消息服务
  openaiCoreService: openaiCoreService // openai-core-service服务
};