//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import ProductDialog from './dialog/productDialog';
import productRequest from '@/api/product';
export default {
  name: 'ProductManage',
  components: {
    Pagination: Pagination,
    ProductDialog: ProductDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      form: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showDialog: false,
      opType: "",
      productItem: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  activated: function activated() {
    var _this = this;
    this.$nextTick(function () {
      _this.$refs.multipleTable.doLayout();
    });
  },
  methods: {
    toggleState: function toggleState(item) {
      var _this2 = this;
      //是否启用
      item.enabled = !item.enabled;
      this.$confirm("\u662F\u5426".concat(item.enabled ? "禁用" : "启用", "\u8BE5\u4EA7\u54C1?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        productRequest.toggleState(item.productId).then(function (res) {
          if (res.code == 200) {
            _this2.$message.success(res.msg);
          }
          _this2.loadData();
        });
      }).catch(function () {
        _this2.loadData();
      });
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    deleteProduct: function deleteProduct(item) {
      var _this3 = this;
      //删除产品
      this.$confirm('您确定要将该产品删除？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        productRequest.delete(item.productId).then(function (res) {
          if (res.code === 200) {
            _this3.$message({
              message: res.msg,
              type: 'success'
            });
            _this3.loadData();
          }
        });
      });
    },
    editProduct: function editProduct(type, item) {
      //编辑
      this.opType = type;
      this.showDialog = true;
      this.productItem = item;
    },
    addInscorp: function addInscorp(type) {
      //新增
      this.opType = type;
      this.showDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this4 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showDialog = false;
      productRequest.getList(this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this4.tableData = res.data.list;
          _this4.loading = false;
          _this4.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this4.loading = false;
      });
    }
  }
};