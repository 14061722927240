import "core-js/modules/es.array.concat.js";
/**
 * 开放平台接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // ******************************路由管理***************************
  /**
    * 路由列表
    * @param {*} pageNum 当前页
    * @param {*} pageSize 每页显示条数
    */
  getRouteList: function getRouteList(pageNum, pageSize, data) {
    return request.get(getUrl("/apiRoute/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 3), {
      params: data
    });
  },
  /**
   * 单独获取一条信息
   * @param {*} data 内容
   */
  getOneRoute: function getOneRoute(data) {
    return request.get(getUrl("/apiRoute/get?id=".concat(data.id), 3));
  },
  /**
   * 批量导入
   * @param {*} data 内容
   */
  batchImport: function batchImport(data) {
    return request.post(getUrl("/apiRoute/batchImport?objStorageId=".concat(data), 3));
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  createRoute: function createRoute(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/apiRoute/create", 3), data, {
      headers: headers
    });
  },
  /**
   * 删除
   * @param {*} data 内容
   */
  deleteRoute: function deleteRoute(data) {
    return request.delete(getUrl("/apiRoute/delete", 3), {
      params: data
    });
  },
  /**
   * 是否启用,编辑
   * @param {*} data 内容
   */
  updateRoute: function updateRoute(data) {
    var headers = {
      showLoading: true
    };
    return request.put(getUrl("/apiRoute/update?id=".concat(data.id), 3), data, {
      headers: headers
    });
  },
  // ******************************路由组管理***************************
  /**
   * 路由组列表
   * @param {*} data 内容
   */
  RouteGroupLists: function RouteGroupLists(data) {
    return request.get(getUrl("/apiGroup/search", 3), {
      params: data
    });
  },
  /**
   * 新增一个路由组 (data)
   * @param {*} data 内容
   */
  createRouteGroup: function createRouteGroup(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/apiGroup/create", 3), data, {
      headers: headers
    });
  },
  /**
   * 删除一个路由组 (拼接)
   * @param {*} data 内容
   */
  deleteRouteGroup: function deleteRouteGroup(data) {
    return request.delete(getUrl("/apiGroup/delete", 3), {
      params: data
    });
  },
  /**
   * 修改一个路由组 (拼接+data)
   * @param {*} data 内容
   */
  updateRouteGroup: function updateRouteGroup(data) {
    var headers = {
      showLoading: true
    };
    return request.put(getUrl("/apiGroup/update?id=".concat(data.id), 3), data, {
      headers: headers
    });
  },
  /**
   * 获取一个路由组 (拼接)
   * @param {*} data 内容
   */
  getOneRouteGroup: function getOneRouteGroup(data) {
    return request.get(getUrl("/apiGroup/get", 3), {
      params: data
    });
  },
  // ******************************接入应用管理***************************
  /**
   * 接入应用列表 (拼接)
   * @param {*} data 内容
   */
  applicationLists: function applicationLists(data) {
    return request.get(getUrl("/application/search", 3), {
      params: data
    });
  },
  /**
   * 新建应用 (data)
   * @param {*} data 内容
   */
  createApplication: function createApplication(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/application/create", 3), data, {
      headers: headers
    });
  },
  /**
   * 删除应用 (拼接)
   * @param {*} data 内容
   */
  deleteApplication: function deleteApplication(data) {
    return request.delete(getUrl("/application/delete", 3), {
      params: data
    });
  },
  /**
   * 编辑应用 (拼接+data)
   * @param {*} data 内容
   */
  updateApplication: function updateApplication(data) {
    var headers = {
      showLoading: true
    };
    return request.put(getUrl("/application/update?id=".concat(data.applicationId), 3), data, {
      headers: headers
    });
  },
  /**
   * 获取单个应用详情 (拼接)
   * @param {*} data 内容
   */
  getOneApplication: function getOneApplication(data) {
    return request.get(getUrl("/application/getByAppId", 3), {
      params: data
    });
  },
  // ******************************接入应用监控***************************
  /**
     * 列表
     * @param {*} pageNum 当前页
     * @param {*} pageSize 每页显示条数
     */
  getDuiprouteinputrecordList: function getDuiprouteinputrecordList(pageNum, pageSize, data) {
    return request.get(getUrl("/duip/route/input/record/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 5), {
      params: data
    });
  },
  /**
  * 查询数据
  * @param {*} flowNo
  */
  getDuiprouteinputrecordlog: function getDuiprouteinputrecordlog(flowNo) {
    return request.get(getUrl("/duip/route/input/record/log/getByFlowNo?flowNo=".concat(flowNo), 5));
  }
};
export default objApi;