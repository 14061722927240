import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import sysManageApi from '@/api/sysManage';
import EnterpriseConfigDialog from './dialog/enterpriseConfigDialog';
import AddAdminDialog from './dialog/addAdmin';
import { commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'EnterpriseConfig',
  components: {
    Pagination: Pagination,
    EnterpriseConfigDialog: EnterpriseConfigDialog,
    AddAdminDialog: AddAdminDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59"),
        enabled: null
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      createdStartEndTime: [getBeforeDate(30), getCurrentDate()],
      multipleSelection: [],
      opType: '',
      showAddDialog: false,
      adminType: '',
      adminItem: {},
      showAddAdminDialog: false,
      addAdminItem: {
        corpId: "",
        mobile: "",
        password: "",
        roleIds: []
      },
      currentId: '',
      statusList: [{
        value: null,
        label: '全部'
      }, {
        value: true,
        label: '是'
      }, {
        value: false,
        label: '否'
      }]
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    addAdmin: function addAdmin(type, row) {
      this.adminType = type;
      this.addAdminItem = row;
      this.showAddAdminDialog = true;
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 监听输入事件
    inputValue: function inputValue(val, type) {
      this.searchForm[type] = val.replace(/[^A-Za-z0-9_-]/g, '');
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59"),
        enabled: null
      };
      this.createdStartEndTime = [getBeforeDate(30), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showAddDialog = false;
      this.showAddAdminDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      sysManageApi.getWecomCorpConfigList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '企业配置',
        exportUrl: '/biz-scrm-service/wecomCorpConfig/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 新增
    add: function add(type) {
      this.opType = type;
      this.showAddDialog = true;
    },
    // 编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.currentId = item.configId.toString();
      this.showAddDialog = true;
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this2 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.configId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'configId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        sysManageApi.deleteWecomCorpConfig(ids).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          ;
        });
      });
    },
    // 是否启用
    enabledChange: function enabledChange(item) {
      var _this3 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981".concat(item.enabled ? '禁用' : '启用', "\u8BE5\u6570\u636E\u5417\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        sysManageApi.enabledWecomCorpConfig(item.configId).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      }).catch(function () {});
    }
  }
};