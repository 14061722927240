var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "105px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "el-row-style" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业：", prop: "corpId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择企业",
                                    clearable: "",
                                  },
                                  on: { change: _vm.corpIdChange },
                                  model: {
                                    value: _vm.form.corpId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "corpId", $$v)
                                    },
                                    expression: "form.corpId",
                                  },
                                },
                                _vm._l(_vm.corpList, function (item) {
                                  return _c("el-option", {
                                    key: item.corpId,
                                    attrs: {
                                      label: item.corpName,
                                      value: item.corpId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "规则名称：", prop: "ruleName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入规则名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ruleName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "ruleName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.ruleName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "敏感词：", prop: "wordList" } },
                            [
                              _c(
                                "div",
                                { staticClass: "tags-list" },
                                [
                                  _vm._l(
                                    _vm.form.wordList,
                                    function (item, index) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: index,
                                          attrs: {
                                            "disable-transitions": false,
                                            size: "small",
                                            closable: true,
                                          },
                                          on: {
                                            close: function ($event) {
                                              return _vm.handleTagClose(index)
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item) + " ")]
                                      )
                                    }
                                  ),
                                  _vm.inputVisible
                                    ? _c("el-input", {
                                        ref: "saveTagInput",
                                        staticClass: "input-new-tag",
                                        attrs: {
                                          maxlength: "32",
                                          size: "small",
                                        },
                                        on: { blur: _vm.handleInputConfirm },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleInputConfirm(
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.inputValue,
                                          callback: function ($$v) {
                                            _vm.inputValue = $$v
                                          },
                                          expression: "inputValue",
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "button-new-tag",
                                      attrs: { size: "small" },
                                      on: { click: _vm.showInput },
                                    },
                                    [_vm._v("+ 添加")]
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "其他：", prop: "semanticsList" },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.form.semanticsList,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "semanticsList", $$v)
                                    },
                                    expression: "form.semanticsList",
                                  },
                                },
                                [
                                  _c("el-checkbox", { attrs: { label: 1 } }, [
                                    _vm._v("手机号"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: 2 } }, [
                                    _vm._v("邮箱地"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: 3 } }, [
                                    _vm._v("红包"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "使用范围：",
                                prop: "departmentList",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "footer-btn-list" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-right": "5px",
                                      },
                                      attrs: {
                                        multiple: "",
                                        "collapse-tags": "",
                                        clearable: "",
                                        placeholder: "请选择部门",
                                      },
                                      on: { change: _vm.departmentChange },
                                      model: {
                                        value: _vm.form.departmentList,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "departmentList",
                                            $$v
                                          )
                                        },
                                        expression: "form.departmentList",
                                      },
                                    },
                                    _vm._l(
                                      _vm.departmentList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        multiple: "",
                                        "collapse-tags": "",
                                        clearable: "",
                                        placeholder: "请选择成员",
                                      },
                                      on: { change: _vm.getEmployee },
                                      model: {
                                        value: _vm.form.userList,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "userList", $$v)
                                        },
                                        expression: "form.userList",
                                      },
                                    },
                                    _vm._l(
                                      _vm.employeeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.memberName,
                                            value: item.memberId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "拦截方式：",
                                prop: "interceptType",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.interceptType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "interceptType", $$v)
                                    },
                                    expression: "form.interceptType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "INTERCEPT" } },
                                    [_vm._v("警告并拦截发送")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "WARNING" } },
                                    [_vm._v("仅发警告")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }