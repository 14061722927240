//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customerManagementApi from '@/api/customerManagement';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    userList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    userClueIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      form: {
        trackPeople: '',
        userClueIds: []
      },
      rules: {
        trackPeople: {
          required: true,
          message: "请选择所属员工",
          trigger: 'change'
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.form = {
        trackPeople: '',
        userClueIds: this.userClueIds
      };
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          customerManagementApi.distribution(_this.form).then(function (res) {
            _this.formLoading = false;
            if (res.code === 200) {
              _this.$message.success(res.msg);
              _this.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};