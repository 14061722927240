var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chatgpt" }, [
    _c(
      "div",
      { staticClass: "Serve", style: { height: _vm.curHeight + "px" } },
      [
        _c(
          "div",
          { ref: "list", staticClass: "serve_main" },
          _vm._l(_vm.messageList, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "message_warp" },
              [
                item.user == "other"
                  ? _c("other-chat", {
                      attrs: {
                        time: item.time,
                        avatar: item.avatar,
                        con: item.con,
                        isLoading: item.isLoading,
                      },
                    })
                  : _c("my-chat", {
                      attrs: {
                        time: item.time,
                        avatar: item.avatar,
                        con: item.con,
                      },
                    }),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "inp_warp" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.sendVal,
                  expression: "sendVal",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "inp",
              attrs: { type: "text", placeholder: "输入内容，开始聊天" },
              domProps: { value: _vm.sendVal },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.send($event)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.sendVal = $event.target.value.trim()
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c(
              "el-button",
              {
                attrs: {
                  size: "medium",
                  round: "",
                  type: "primary",
                  icon: "el-icon-chat-line-round",
                },
                on: { click: _vm.send },
              },
              [_vm._v("发送")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }