import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import sysManageApi from '@/api/sysManage';
export default {
  name: 'MemberManage',
  mixins: [initHeight],
  data: function data() {
    return {
      loading: false,
      filterText: '',
      treeData: [],
      memberData: [],
      highlightCurrent: true,
      defaultCheckedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      treeParams: {
        // ww8676100b96524443
        corpId: ''
      },
      memberParams: {
        corpId: '',
        departmentId: '',
        memberName: ''
      },
      corpList: [],
      corpName: '',
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      searchFixed: false,
      showActiveTypeDialog: false,
      licenseActiveTypeOptions: [{
        itemCode: 'FOUNDATION',
        itemName: '基础'
      }, {
        itemCode: 'CURRENT',
        itemName: '通用'
      }],
      activeTypeFormRules: {},
      listLoading: false,
      pageDialogType: null,
      activeTypeForm: {
        licenseActiveType: '',
        activeCode: ''
      },
      activeTypeItem: {}
    };
  },
  computed: {
    containerHeight: function containerHeight() {
      return document.body.clientHeight - 132;
    }
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  created: function created() {
    this.initData();
  },
  mounted: function mounted() {
    var _this = this;
    //监听滚轮滚动事件
    document.getElementById("aside").onscroll = function () {
      var scrollTop = document.getElementById("aside").scrollTop;
      _this.searchFixed = scrollTop ? true : false;
    };
  },
  methods: {
    saveActiveForm: function saveActiveForm() {
      var _this2 = this;
      var data = {
        corpId: this.treeParams.corpId,
        memberUserId: this.activeTypeItem.memberId,
        licenseActiveType: this.activeTypeForm.licenseActiveType || null,
        activeCode: this.activeTypeForm.activeCode || null
      };
      sysManageApi.wecomMemberEmpower(data).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.$message.success(res.msg);
          _this2.showActiveTypeDialog = false;
        }
        ;
      });
    },
    closeActiveForm: function closeActiveForm() {
      this.showActiveTypeDialog = false;
    },
    addActiveType: function addActiveType(row) {
      console.log(row);
      this.activeTypeItem = row;
      this.pageDialogType = 'add';
      this.showActiveTypeDialog = true;
    },
    // 初始化数据
    initData: function initData() {
      if (!this.userInfo.data) {
        this.getCorpList();
      } else {
        this.treeParams.corpId = this.userInfo.data.memberUser.corpId;
        this.loadData();
      }
      ;
    },
    // 过滤树节点
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 树节点的点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      this.corpName = data.name;
      this.memberParams = {
        corpId: this.treeParams.corpId,
        departmentId: data.id,
        memberName: ''
      };
      this.getMemberList();
    },
    // 获取部门列表
    getMemberList: function getMemberList() {
      var _this3 = this;
      this.loading = true;
      sysManageApi.getWecomMemberList(this.memberParams).then(function (res) {
        _this3.loading = false;
        if (res.code === 200) {
          _this3.memberData = res.data || [];
        }
        ;
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    // 获取树形数据
    loadData: function loadData() {
      var _this4 = this;
      this.loading = true;
      sysManageApi.getDepartmentTree(this.treeParams).then(function (res) {
        _this4.loading = false;
        if (res.code === 200) {
          _this4.treeData = res.data || [];
        }
        ;
      }).catch(function (err) {
        _this4.loading = false;
      });
    },
    // 获取企业列表
    getCorpList: function getCorpList() {
      var _this5 = this;
      sysManageApi.getAllWecomCorpConfigList().then(function (res) {
        if (res.code === 200) {
          _this5.corpList = res.data || [];
        }
        ;
      });
    },
    // 企业过滤
    corpIdChange: function corpIdChange(val) {
      this.treeData = [];
      this.memberData = [];
      this.filterText = '';
      this.corpName = '';
      if (val) {
        this.loadData();
      }
    },
    // 是否授权
    enabledChange: function enabledChange(item) {
      var _this6 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981".concat(item.authStatus ? '授权' : '关闭授权', "\u5417\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        _this6.loading = true;
        var data = {
          corpId: _this6.treeParams.corpId,
          memberUserId: item.memberId
        };
        sysManageApi.wecomMemberEmpower(data).then(function (res) {
          _this6.loading = false;
          if (res.code === 200) {
            _this6.$message.success(res.msg);
          }
          ;
        });
      }).catch(function (err) {
        _this6.loading = false;
      });
    },
    memberNameChange: function memberNameChange(val) {
      this.getMemberList();
    }
  }
};