/**
 * 车险知识管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取所有启用的规则
   */
  getMarketingRulesList: function getMarketingRulesList(data) {
    return request.get(getUrl("/marketing/rules/getRulesList", 4), {
      params: data
    });
  },
  /**
   * 营销规则--分页查询
   */
  getMarketingRuleSearch: function getMarketingRuleSearch(data) {
    return request.get(getUrl("/marketing/rules/search", 4), {
      params: data
    });
  },
  /**
   * 获取所有规则类型
   */
  getAllRulesTypes: function getAllRulesTypes(data) {
    return request.get(getUrl("/marketing/rules/getAllRulesType", 4));
  },
  /**
   * 通过规则类型 --- 获取规则参数
   */
  getRulesParamsByRulesType: function getRulesParamsByRulesType(code) {
    return request.get(getUrl("/marketing/rules/getRulesParams?rulesCode=".concat(code), 4));
  },
  /**
   * 获取结束方式类型
   */
  getAllEndMethods: function getAllEndMethods(data) {
    return request.get(getUrl("/marketing/rules/getAllEndMethod", 4));
  },
  /**
   * 新增规则
   */
  createRules: function createRules(data) {
    return request.post(getUrl("/marketing/rules/create", 4), data);
  },
  /**
   * 更新规则
   */
  updateRules: function updateRules(id, data) {
    return request.post(getUrl("/marketing/rules/update?rulesId=".concat(id), 4), data);
  },
  /**
   * 启用/禁用规则
   */
  enabledRules: function enabledRules(id) {
    return request.post(getUrl("/marketing/rules/enabled?rulesId=".concat(id), 4));
  },
  /**
   * 查询规则
   */
  getRulesDetail: function getRulesDetail(id) {
    return request.get(getUrl("/marketing/rules/get?rulesId=".concat(id), 4));
  },
  /**
   * 批量删除一条规则
   */
  deleteListRules: function deleteListRules(id) {
    return request.post(getUrl("/marketing/rules/batchDelete?ids=".concat(id), 4));
  }
};
export default objApi;