/**
 * chatgpt接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取AI回答信息
   * @param {*} data
   */
  getAskQuestionResult: function getAskQuestionResult(data) {
    return request.post(getUrl("/chatGpt/askQuestion", 11), data);
  }
};
export default objApi;