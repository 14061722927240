import "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_core-js@3.32.2@core-js/modules/es.array.iterator.js";
import "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_core-js@3.32.2@core-js/modules/es.promise.js";
import "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_core-js@3.32.2@core-js/modules/es.object.assign.js";
import "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_core-js@3.32.2@core-js/modules/es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import Vue from 'vue';
import 'normalize.css/normalize.css';
import Element from 'element-ui';
import '@/styles/element-variables.scss';
import '@/styles/index.scss';
import App from './App';
import store from './store';
import router from './router/modules';
// import Base64 from 'js-base64'
import './icons';
import './permission';
import './utils/error-log';
import * as filters from './filters';
import _ from 'lodash';
import './config/global.js';
import VueClipboard from 'vue-clipboard2';
import versionTood from './utils/versionUpdate';
Vue.prototype.$Bus = new Vue();
// Vue.prototype.$Base64 = Base64;
// Vue.use(Base64)
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
Vue.use(VueQuillEditor);
Vue.use(Viewer);
Viewer.setDefaults({
  title: false,
  toolbar: true
});
Vue.use(VueClipboard);
Vue.use(Element, {
  size: 'small',
  zIndex: 3000
});
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.prototype._ = _;
Vue.config.productionTip = false;
window.$store = store;
router.beforeEach(function (to, from, next) {
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion();
  next();
});

// 初始化主应用
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');