var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mainContainer" }, [
      _c("div", {
        staticClass: "bgImgContainer",
        style: {
          "background-image": "url(" + _vm.imgSrc + ")",
          "background-size": "cover",
        },
      }),
      _c("div", { staticClass: "login-right" }, [
        _c("div", { staticClass: "login-container" }, [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                width: "100%",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "loginFormBox",
                  staticStyle: { width: "100%", "max-height": "450px" },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "loginForm",
                      staticClass: "login-form",
                      attrs: {
                        model: _vm.loginForm,
                        rules: _vm.loginRules,
                        "auto-complete": "on",
                        "label-position": "left",
                      },
                    },
                    [
                      _c("div", { staticClass: "title-container" }, [
                        _c("img", {
                          staticStyle: { width: "80%", height: "100%" },
                          attrs: {
                            src: require("@/assets/zxlogo/login_logo.png"),
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "18px",
                              "line-height": "35px",
                            },
                          },
                          [_c("span", [_vm._v("登录系统")])]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { "padding-top": "5px" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "account" } },
                            [
                              _c(
                                "el-input",
                                {
                                  ref: "account",
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入账号",
                                    name: "account",
                                    type: "text",
                                    tabindex: "1",
                                    "auto-complete": "on",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.loginForm.account,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.loginForm,
                                        "account",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "loginForm.account",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _c("i", {
                                      staticClass: "el-icon-user",
                                      staticStyle: { "font-size": "16px" },
                                    }),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "password" } },
                            [
                              _c(
                                "el-input",
                                {
                                  key: _vm.passwordType,
                                  ref: "password",
                                  attrs: {
                                    maxlength: "40",
                                    type: _vm.passwordType,
                                    placeholder: "请输入密码",
                                    name: "password",
                                    tabindex: "2",
                                    "auto-complete": "on",
                                    clearable: "",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleLogin($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.loginForm.password,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.loginForm,
                                        "password",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "loginForm.password",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _c("i", {
                                      staticClass: "el-icon-unlock",
                                      staticStyle: { "font-size": "16px" },
                                    }),
                                  ]),
                                  _c("template", { slot: "append" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "show-pwd",
                                        on: { click: _vm.showPwd },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class":
                                              _vm.passwordType === "password"
                                                ? "eye"
                                                : "eye-open",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "5px" },
                              attrs: { prop: "verificationCode" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "60%" },
                                    attrs: {
                                      maxlength: "40",
                                      placeholder: "请输入验证码",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleLogin($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.loginForm.verificationCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.loginForm,
                                          "verificationCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "loginForm.verificationCode",
                                    },
                                  }),
                                  _c("div", { staticClass: "codeImgBox" }, [
                                    _vm.loginForm.verifyCodeId
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "80%",
                                            height: "30px",
                                          },
                                          attrs: {
                                            src:
                                              "data:image/jpg;base64," +
                                              _vm.codeImg,
                                            alt: "",
                                          },
                                        })
                                      : _c("img", {
                                          staticStyle: {
                                            width: "80%",
                                            height: "30px",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/404.png"),
                                            alt: "",
                                          },
                                        }),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-refresh refreshButton",
                                      on: { click: _vm.changeCodeImg },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "login-btn",
                          attrs: {
                            size: "small",
                            loading: _vm.loading,
                            disabled: _vm.loading,
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleLogin($event)
                            },
                          },
                        },
                        [_vm._v("登录")]
                      ),
                      _vm.qwAppId
                        ? _c(
                            "el-button",
                            {
                              staticClass: "qw-login-btn",
                              attrs: { disabled: _vm.loading },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.qwLogin($event)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  position: "absolute",
                                  left: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  width: "24px",
                                },
                                attrs: {
                                  src: require("@/assets/images/qw.png"),
                                },
                              }),
                              _c("span", [_vm._v("企微扫描二维码登录")]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }