var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "flex-end mb10" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", plain: "", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.add("add")
                },
              },
            },
            [_vm._v("录入")]
          ),
        ],
        1
      ),
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        _vm._l(_vm.vehicleList, function (item, index) {
          return _c(
            "el-collapse-item",
            {
              key: item.id,
              staticClass: "collapse-item-style",
              attrs: { name: index },
            },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _c("FormLabel", {
                    staticStyle: { flex: "1", "line-height": "25px" },
                    attrs: {
                      "form-label":
                        "车架号" + (index + 1) + "：" + (item.vin || "-"),
                    },
                  }),
                  item.id
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            "z-index": "999",
                          },
                          attrs: { size: "mini", plain: "", type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.edit("edit", item)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                  item.id
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-right": "15px",
                            "z-index": "999",
                          },
                          attrs: { size: "mini", plain: "", type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.remove(item)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [_vm._v(" 车龄：" + _vm._s(item.carAge || "-") + " ")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [
                      _vm._v(
                        " 车型：" +
                          _vm._s(
                            item.modelName || _vm.vehicleForm.modelName || "-"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [
                      _vm._v(
                        " 是否营运车：" +
                          _vm._s(
                            item.operatedDisplay ||
                              _vm.vehicleForm.isOperatingVehiclesDisplay ||
                              "-"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 } },
                    [
                      _vm._v(
                        " 支付方式：" +
                          _vm._s(
                            item.loaned
                              ? "贷款"
                              : item.loaned == false
                              ? "全款"
                              : _vm.vehicleForm.paymentTypeDisplay || "-"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 } },
                    [
                      _vm._v(
                        " 已购买的增值服务：" +
                          _vm._s(
                            _vm.getPurchasedServiceName(
                              item.purchasedService
                            ) ||
                              _vm.vehicleForm.newTrackMarketingProductName ||
                              "-"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          )
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAddVehicleDialog,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddVehicleDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [
                  _vm._v(
                    _vm._s(_vm.opType === "edit" ? "编辑" : "录入") + "车辆信息"
                  ),
                ]
              ),
            ]
          ),
          _c("VehicleInfoDialog", {
            attrs: {
              opType: _vm.opType,
              refresh: _vm.showAddVehicleDialog,
              externalId: _vm.externalId,
              currentId: _vm.currentId,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }