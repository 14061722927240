import "core-js/modules/es.array.concat.js";
/**
 * 通用接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // *************************系统导出监控****************************
  /**
   * 导出日志分页查询
   * @param {*} data 内容
   */
  getExportLogList: function getExportLogList(data) {
    return request.get(getUrl("/export-log", 5), {
      params: data
    });
  },
  /**
    * 我的导出日志分页查询
    * 
    * @param {*} data 内容
    */
  getMyExportLogList: function getMyExportLogList(data) {
    return request.get(getUrl("/export-log/me", 5), {
      params: data
    });
  },
  /**
    * 下载详细信息分页查询
    * @param {*} pageNum 当前页
    * @param {*} pageSize 每页显示条数
    * @param {*} exportId 导出id
    */
  getExportLogDetailList: function getExportLogDetailList(pageNum, pageSize, exportId) {
    return request.get(getUrl("/export-log/detail/".concat(exportId, "?pageNum=").concat(pageNum, "&pageSize=").concat(pageSize), 5));
  },
  /**
   * 生成导出文件
   * @param {*} data 内容
   */
  generateExcel: function generateExcel(data) {
    return request.post(getUrl("/excel/generateFile", 5), data);
  },
  /**
   * 导出
   * @param {*} data 内容
   */
  exportExcel: function exportExcel(data) {
    return request({
      method: 'get',
      url: getUrl("/excel/export/".concat(data), 5),
      responseType: 'blob'
    });
  }
};
export default objApi;