import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import customerManagementApi from '@/api/customerManagement';
import { commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'CustomerBase',
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        chatCreateTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        chatCreateTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      createdStartEndTime: [getBeforeDate(30), getCurrentDate()]
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        chatCreateTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        chatCreateTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.createdStartEndTime = [getBeforeDate(30), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      customerManagementApi.getCostomerGroupList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 跳转详情
    toDetails: function toDetails(item) {
      this.$router.push({
        path: '/customerManagement/customerBaseDetail',
        query: {
          groupChatId: item.id,
          tabName: "\u5BA2\u6237\u7FA4\u8BE6\u60C5-".concat(item.groupChatName)
        }
      });
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '客户群',
        exportUrl: '/biz-scrm-service/wecomGroupchat/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    }
  }
};