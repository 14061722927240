import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sysManageApi from '@/api/sysManage';
import { constantRoutes } from '@/router/modules';
import { mapGetters } from 'vuex';
export default {
  props: {
    itemId: {
      type: String,
      default: null
    },
    opType: {
      type: String,
      default: ""
    },
    refresh: {
      type: Boolean,
      default: true
    }
  },
  computed: _objectSpread({}, mapGetters(['permission_routes'])),
  data: function data() {
    return {
      form: {
        roleName: '',
        roleMenu: ''
      },
      loading: false,
      formRules: {
        roleName: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          message: "角色名称格式有误",
          trigger: ["blur", "change"]
        }]
      },
      treeData: [{
        code: 'All',
        label: '系统菜单',
        children: []
      }],
      props: {
        label: 'label',
        children: 'children'
      },
      chekcedMenus: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        if (this.opType != 'add') {
          this.loadData();
        } else {
          this.$refs.tree.setCheckedNodes([]);
          this.form = {
            roleName: '',
            roleMenu: ''
          };
          this.$refs['detail'].resetFields();
        }
      }
    }
  },
  created: function created() {
    this.loadData();
  },
  mounted: function mounted() {
    var _this = this;
    constantRoutes.forEach(function (element) {
      if (element.meta && !element.hidden) {
        var obj = {
          code: element.name,
          label: element.meta.title,
          children: []
        };
        if (!_.isEmpty(element.children)) {
          element.children.forEach(function (eitem) {
            if (!eitem.hidden) {
              var cobj = {
                code: eitem.name,
                label: eitem.meta.title,
                children: []
              };
              if (!_.isEmpty(eitem.children)) {
                eitem.children.forEach(function (_item) {
                  var cobjs = {
                    code: _item.name,
                    label: _item.meta.title,
                    children: []
                  };
                  cobj.children.push(cobjs);
                });
              }
              obj.children.push(cobj);
            }
          });
        }
        _this.treeData[0].children.push(obj);
      }
    });
  },
  methods: {
    loadData: function loadData() {
      var _this2 = this;
      if (!this.itemId) return;
      this.loading = true;
      sysManageApi.recentGetRole(this.itemId).then(function (res) {
        _this2.loading = false;
        _this2.form = res.data;
        if (!_.isEmpty(res.data.roleMenu)) {
          var checkedItems = JSON.parse(res.data.roleMenu);
          _this2.$nextTick(function () {
            this.$refs.tree.setCheckedNodes(checkedItems);
          });
        }
      }).catch(function (err) {
        _this2.loading = false;
        _this2.form = {
          roleName: '',
          roleMenu: ''
        };
      });
    },
    save: function save() {
      var _this3 = this;
      this.$refs["detail"].validate(function (valid, object) {
        if (valid) {
          _this3.form.enable = _this3.opType == 'add' ? true : _this3.form.enable;
          var checkedItems = _this3.$refs.tree.getCheckedNodes();
          if (!_.isEmpty(checkedItems)) {
            _this3.form.roleMenu = JSON.stringify(checkedItems);
          } else {
            _this3.$message({
              type: "error",
              message: '请选择您要配置的菜单！'
            });
            return;
          }
          _this3.form.tenantId = '100001';
          _this3.form.bizType = "PLATFORM";
          if (_this3.opType == 'add') {
            _this3.form.enabled = true;
          }
          ;
          _this3.loading = true;
          var localRes = _this3.opType == 'add' ? sysManageApi.recentCreate(_this3.form) : sysManageApi.recentUpdate(_this3.form);
          localRes.then(function (res) {
            _this3.loading = false;
            if (res.code == 200) {
              _this3.$message({
                type: "success",
                message: res.msg
              });
              _this3.closePage(false);
            }
          }).catch(function (err) {
            _this3.loading = false;
          });
        }
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.form = {
        roleName: '',
        roleMenu: ''
      };
      this.$refs['detail'].resetFields();
      this.$emit('closePage', boolean);
    }
  }
};