import "core-js/modules/es.object.seal.js";
//
//
//
//
//
//
//

import Vue from 'vue';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import storageRequest from '@/api/storage';
export default Vue.extend({
  props: {
    htmlContent: {
      type: String,
      default: '<p></p>'
    }
  },
  components: {
    Editor: Editor,
    Toolbar: Toolbar
  },
  data: function data() {
    return {
      editor: null,
      html: '<p></p>',
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          fontSize: {
            fontSizeList: ['10px', '11px', '12px', '13px', '14px', '15px', '16px', '17px', '18px', '19px', '20px', '21px', '22px', '23px', '24px', '25px', '26px', '27px', '28px', '29px', '30px', '31px', '32px', '33px', '34px', '35px', '36px', '37px', '38px', '39px', '40px', '41px', '42px', '43px', '44px', '45px', '46px', '47px', '48px', '49px', '50px', '60px', '70px', '80px', '90px', '100px']
          },
          color: {
            colors: ["rgb(0, 0, 0)", "rgb(38, 38, 38)", "rgb(89, 89, 89)", "rgb(140, 140, 140)", "rgb(191, 191, 191)", "rgb(217, 217, 217)", "rgb(233, 233, 233)", "rgb(245, 245, 245)", "rgb(250, 250, 250)", "rgb(255, 255, 255)", "rgb(225, 60, 57)", "rgb(231, 95, 51)", "rgb(235, 144, 58)", "rgb(245, 219, 77)", "rgb(114, 192, 64)", "rgb(89, 191, 192)", "rgb(66, 144, 247)", "rgb(54, 88, 226)", "rgb(106, 57, 201)", "rgb(216, 68, 147)", "rgb(251, 233, 230)", "rgb(252, 237, 225)", "rgb(252, 239, 212)", "rgb(252, 251, 207)", "rgb(231, 246, 213)", "rgb(218, 244, 240)", "rgb(217, 237, 250)", "rgb(224, 232, 250)", "rgb(237, 225, 248)", "rgb(246, 226, 234)", "rgb(255, 163, 158)", "rgb(255, 187, 150)", "rgb(255, 213, 145)", "rgb(255, 251, 143)", "rgb(183, 235, 143)", "rgb(135, 232, 222)", "rgb(145, 213, 255)", "rgb(173, 198, 255)", "rgb(211, 173, 247)", "rgb(255, 173, 210)", "rgb(255, 77, 79)", "rgb(255, 122, 69)", "rgb(255, 169, 64)", "rgb(255, 236, 61)", "rgb(115, 209, 61)", "rgb(54, 207, 201)", "rgb(64, 169, 255)", "rgb(89, 126, 247)", "rgb(146, 84, 222)", "rgb(247, 89, 171)", "rgb(207, 19, 34)", "rgb(212, 56, 13)", "rgb(212, 107, 8)", "rgb(212, 177, 6)", "rgb(56, 158, 13)", "rgb(8, 151, 156)", "rgb(9, 109, 217)", "rgb(29, 57, 196)", "rgb(83, 29, 171)", "rgb(196, 29, 127)", "rgb(130, 0, 20)", "rgb(135, 20, 0)", "rgb(135, 56, 0)", "rgb(97, 71, 0)", "rgb(19, 82, 0)", "rgb(0, 71, 79)", "rgb(0, 58, 140)", "rgb(6, 17, 120)", "rgb(34, 7, 94)", "rgb(120, 6, 80)", "rgb(137, 201, 254)", "rgb(92, 181, 255)", "rgb(51, 162, 255)", "rgb(14, 146, 255)", "rgb(1, 134, 245)", "rgb(149, 147, 254)", "rgb(109, 106, 255)", "rgb(72, 68, 255)", "rgb(35, 31, 255)", "rgb(6, 2, 246)", "rgb(255, 224, 129)", "rgb(255, 212, 80)", "rgb(255, 201, 36)", "rgb(255, 192, 0)", "rgb(255, 202, 129)", "rgb(255, 181, 80)", "rgb(255, 163, 36)", "rgb(255, 148, 0)"]
          },
          bgColor: {
            colors: ["rgb(0, 0, 0)", "rgb(38, 38, 38)", "rgb(89, 89, 89)", "rgb(140, 140, 140)", "rgb(191, 191, 191)", "rgb(217, 217, 217)", "rgb(233, 233, 233)", "rgb(245, 245, 245)", "rgb(250, 250, 250)", "rgb(255, 255, 255)", "rgb(225, 60, 57)", "rgb(231, 95, 51)", "rgb(235, 144, 58)", "rgb(245, 219, 77)", "rgb(114, 192, 64)", "rgb(89, 191, 192)", "rgb(66, 144, 247)", "rgb(54, 88, 226)", "rgb(106, 57, 201)", "rgb(216, 68, 147)", "rgb(251, 233, 230)", "rgb(252, 237, 225)", "rgb(252, 239, 212)", "rgb(252, 251, 207)", "rgb(231, 246, 213)", "rgb(218, 244, 240)", "rgb(217, 237, 250)", "rgb(224, 232, 250)", "rgb(237, 225, 248)", "rgb(246, 226, 234)", "rgb(255, 163, 158)", "rgb(255, 187, 150)", "rgb(255, 213, 145)", "rgb(255, 251, 143)", "rgb(183, 235, 143)", "rgb(135, 232, 222)", "rgb(145, 213, 255)", "rgb(173, 198, 255)", "rgb(211, 173, 247)", "rgb(255, 173, 210)", "rgb(255, 77, 79)", "rgb(255, 122, 69)", "rgb(255, 169, 64)", "rgb(255, 236, 61)", "rgb(115, 209, 61)", "rgb(54, 207, 201)", "rgb(64, 169, 255)", "rgb(89, 126, 247)", "rgb(146, 84, 222)", "rgb(247, 89, 171)", "rgb(207, 19, 34)", "rgb(212, 56, 13)", "rgb(212, 107, 8)", "rgb(212, 177, 6)", "rgb(56, 158, 13)", "rgb(8, 151, 156)", "rgb(9, 109, 217)", "rgb(29, 57, 196)", "rgb(83, 29, 171)", "rgb(196, 29, 127)", "rgb(130, 0, 20)", "rgb(135, 20, 0)", "rgb(135, 56, 0)", "rgb(97, 71, 0)", "rgb(19, 82, 0)", "rgb(0, 71, 79)", "rgb(0, 58, 140)", "rgb(6, 17, 120)", "rgb(34, 7, 94)", "rgb(120, 6, 80)", "rgb(137, 201, 254)", "rgb(92, 181, 255)", "rgb(51, 162, 255)", "rgb(14, 146, 255)", "rgb(1, 134, 245)", "rgb(149, 147, 254)", "rgb(109, 106, 255)", "rgb(72, 68, 255)", "rgb(35, 31, 255)", "rgb(6, 2, 246)", "rgb(255, 224, 129)", "rgb(255, 212, 80)", "rgb(255, 201, 36)", "rgb(255, 192, 0)", "rgb(255, 202, 129)", "rgb(255, 181, 80)", "rgb(255, 163, 36)", "rgb(255, 148, 0)"]
          },
          uploadImage: {
            customUpload: this.uploadImg
          }
        }
      },
      mode: 'default' // or 'simple'
    };
  },

  methods: {
    uploadImg: function uploadImg(file, insertFn) {
      var _this = this;
      var formData = new FormData();
      formData.append('file', file);
      storageRequest.uploadLongTermFile(formData).then(function (res) {
        if (res.code === 200 && res.data) {
          var editFileUrl = res.data.fileUrl || '';
          var node = {
            type: "image",
            src: editFileUrl,
            style: {
              with: "",
              height: ""
            },
            children: [{
              text: ""
            }]
          };
          _this.editor.insertNode(node);
        }
        ;
      });
    },
    onCreated: function onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      // console.log(this.editor)
    },
    onChange: function onChange(editor) {
      var htmlVal = editor.getHtml();
      this.$emit('setHtml', htmlVal);
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(function () {
      _this2.html = _this2.htmlContent;
    }, 800);
  },
  beforeDestroy: function beforeDestroy() {
    var editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  }
});