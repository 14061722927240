var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MyTiny" },
    [
      _c("Editor", {
        attrs: { id: "tinymce", init: _vm.editorInit },
        model: {
          value: _vm.articleContent,
          callback: function ($$v) {
            _vm.articleContent = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "articleContent",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }