var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                {
                  ref: "knowledgeType",
                  attrs: { label: "素材类型：", prop: "knowledgeType" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择素材类型",
                      },
                      model: {
                        value: _vm.form.knowledgeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "knowledgeType", $$v)
                        },
                        expression: "form.knowledgeType",
                      },
                    },
                    _vm._l(_vm.knowledgeTypes, function (item) {
                      return _c("el-option", {
                        key: item.itemCode,
                        attrs: { label: item.itemName, value: item.itemCode },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { ref: "title", attrs: { label: "文章标题：", prop: "title" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "40",
                          rows: "4",
                          size: "mini",
                          "show-word-limit": "",
                          type: "textarea",
                          placeholder: "请输入文章标题",
                        },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                {
                  ref: "knowledgeAppraise",
                  attrs: { label: "文章质量：", prop: "knowledgeAppraise" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择文章质量",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.form.knowledgeAppraise,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "knowledgeAppraise", $$v)
                        },
                        expression: "form.knowledgeAppraise",
                      },
                    },
                    _vm._l(_vm.knowledgeAppraiseLists, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.itemName, value: item.itemCode },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                {
                  ref: "insCorpCode",
                  attrs: { label: "保险公司：", prop: "insCorpCode" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择保险公司",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.form.insCorpCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "insCorpCode", $$v)
                        },
                        expression: "form.insCorpCode",
                      },
                    },
                    _vm._l(_vm.supplyers, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.shortName, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                {
                  ref: "insCityCode",
                  attrs: {
                    label: "关联地区：",
                    prop: "insCityCode",
                    rules: [
                      {
                        required: _vm.form.insCorpCode === "PAIC",
                        message: "请选择关联地区",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-cascader", {
                    ref: "address",
                    staticStyle: { width: "100%" },
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: "请选择所在省市",
                      options: _vm.regionOptions,
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.form.insCityCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "insCityCode", $$v)
                      },
                      expression: "form.insCityCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                {
                  ref: "questionType",
                  attrs: { label: "问题类型：", prop: "questionType" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择问题类型",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.form.questionType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "questionType", $$v)
                        },
                        expression: "form.questionType",
                      },
                    },
                    _vm._l(_vm.questionOptions, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.description, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "添加文章关键词：", prop: "keyword" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "small",
                            placeholder: "请选择关键词",
                            filterable: "",
                            multiple: "",
                          },
                          on: { change: _vm.setKeywordsList },
                          model: {
                            value: _vm.keyswordList,
                            callback: function ($$v) {
                              _vm.keyswordList = $$v
                            },
                            expression: "keyswordList",
                          },
                        },
                        _vm._l(_vm.keywordList, function (item) {
                          return _c("el-option", {
                            key: item.keywordsId,
                            attrs: {
                              label: item.keywordsContent,
                              value: item.keywordsId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "flex-direction": "column",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            display: "flex",
                            "flex-wrap": "wrap",
                            "flex-direction": "row",
                            "justify-content": "space-between",
                            "align-items": "center",
                          },
                        },
                        [
                          _vm._v(" 全部关键字： "),
                          _vm.inputVisible
                            ? _c("el-input", {
                                ref: "saveTagInput",
                                staticClass: "input-new-tag",
                                staticStyle: { width: "400px" },
                                attrs: {
                                  size: "small",
                                  clearable: "",
                                  placeholder: "请输入关键字",
                                  maxlength: "40",
                                  "show-word-limit": "",
                                },
                                on: { blur: _vm.handleInputConfirm },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleInputConfirm($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.keywordsContent,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "keywordsContent", $$v)
                                  },
                                  expression: "form.keywordsContent",
                                },
                              })
                            : _c(
                                "el-button",
                                {
                                  staticClass: "button-new-tag addButton",
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    plain: "",
                                  },
                                  on: { click: _vm.showInput },
                                },
                                [_vm._v("+ 新增关键字")]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "el-link",
                        {
                          staticClass: "button-new-tag toggle",
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.isView = !_vm.isView
                            },
                          },
                        },
                        [
                          _vm.isView
                            ? _c("i", { staticClass: "el-icon-arrow-up" })
                            : _c("i", { staticClass: "el-icon-arrow-down" }),
                          _vm._v(
                            " " + _vm._s(_vm.isView ? "收起" : "展开") + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: { display: "flex", "flex-wrap": "wrap" },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: _vm.isView ? "show" : "hide",
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "wrap",
                              },
                            },
                            _vm._l(_vm.keywordList, function (tag) {
                              return _c(
                                "el-tag",
                                {
                                  key: tag.keywordsId,
                                  staticClass: "elTag",
                                  attrs: {
                                    size: "lag",
                                    type: "info",
                                    closable: "",
                                    "disable-transitions": false,
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.handleClose(tag)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(tag.keywordsContent) + " "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                          _c(
                            "el-link",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isView,
                                  expression: "isView",
                                },
                              ],
                              staticClass: "button-new-tag toggle",
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.isView = !_vm.isView
                                },
                              },
                            },
                            [
                              _vm.isView
                                ? _c("i", { staticClass: "el-icon-arrow-up" })
                                : _c("i", {
                                    staticClass: "el-icon-arrow-down",
                                  }),
                              _vm._v(
                                " " + _vm._s(_vm.isView ? "收起" : "展开") + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-form-item", { attrs: { label: "文章封面：" } }, [
                _vm.form.mediaUrl
                  ? _c(
                      "div",
                      { staticClass: "coverBox" },
                      [
                        _c("img", {
                          staticClass: "coverImg",
                          attrs: { src: _vm.form.mediaUrl, alt: "" },
                        }),
                        _c(
                          "el-upload",
                          {
                            staticClass: "coverDel",
                            attrs: {
                              limit: 2,
                              action: "",
                              multiple: "",
                              "show-file-list": false,
                              "http-request": function (val) {
                                return _vm.uploadFileReplace(val)
                              },
                              "before-upload": function (val) {
                                return _vm.beforeUploadReplace(val)
                              },
                              "file-list": _vm.fileList,
                              accept: _vm.uploadType,
                              "list-type": "text",
                            },
                          },
                          [_c("div", [_vm._v("更换封面")])]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              limit: 2,
                              action: "",
                              multiple: "",
                              "show-file-list": false,
                              "http-request": function (val) {
                                return _vm.uploadFile(val)
                              },
                              "before-upload": function (val) {
                                return _vm.beforeUpload(val)
                              },
                              "file-list": _vm.fileList,
                              accept: _vm.uploadType,
                              "list-type": "text",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-plus",
                              staticStyle: { "font-size": "24px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-form-item", { attrs: { label: "备注：" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c("el-input", {
                      attrs: {
                        maxlength: "200",
                        rows: "4",
                        size: "mini",
                        "show-word-limit": "",
                        type: "textarea",
                        placeholder: "请输入文章备注",
                      },
                      model: {
                        value: _vm.form.knowledgeRemark,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "knowledgeRemark", $$v)
                        },
                        expression: "form.knowledgeRemark",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }