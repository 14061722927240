import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import {
//     client
// } from '@/utils/alioss.js'
import artilceApi from '@/api/article';
import storageRequest from '@/api/storage';
export default {
  props: {
    form: {
      type: Object,
      default: function _default() {}
    },
    materialGroups: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    materialClassificationLists: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    associatedLabelList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      pageContainerLoading: false,
      activeTitle: '1',
      uploadType: '.jpg,.gif,.png,.jpeg,.rar,.zip,.xls,.xlsx,.doc,.pdf,.docx',
      fileList: [],
      inputVisible: false,
      isView: false
    };
  },
  methods: {
    // 自定义上传
    uploadFile: function uploadFile(file) {
      var _this = this;
      var formData = new FormData();
      formData.append('file', file.file);
      storageRequest.uploadLongTermFile(formData).then(function (res) {
        if (res.code === 200 && res.data) {
          _this.form.coverImage = res.data.fileUrl || '';
        }
        ;
      });
    },
    // 更换
    uploadFileReplace: function uploadFileReplace(file) {
      var _this2 = this;
      this.form.coverImage = null;
      this.fileList = [];
      var formData = new FormData();
      formData.append('file', file.file);
      storageRequest.uploadLongTermFile(formData).then(function (res) {
        if (res.code === 200 && res.data) {
          _this2.form.coverImage = res.data.fileUrl || '';
        }
        ;
      });
    },
    // 判断文件类型
    beforeUpload: function beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
      var fileNameLength = file.name.split('.' + testmsg)[0] && file.name.split('.' + testmsg)[0].length;
      var arr = ['jpg', 'gif', 'png', 'jpeg', 'xls', 'xlsx', 'doc', 'pdf', 'docx'];
      if (!arr.includes(testmsg)) {
        this.$message.warning("目前支持的类型有：[.jpg|.gif|.png|.jepg|.xls|.xlsx|.doc|.pdf|.docx]");
        return false;
      } else {
        var uploadFileSize = file.size / 1024 / 1024 > 2;
        if (uploadFileSize) {
          this.$message.warning("上传附件大小不超过2M");
          return false;
        }
        ;
        if (fileNameLength > 80) {
          this.$message.warning("文件名称过长，请控制在80个字符以内");
          return false;
        }
        ;
      }
      ;
    },
    // 判断文件类型
    beforeUploadReplace: function beforeUploadReplace(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
      var fileNameLength = file.name.split('.' + testmsg)[0] && file.name.split('.' + testmsg)[0].length;
      var arr = ['jpg', 'gif', 'png', 'jpeg', 'xls', 'xlsx', 'doc', 'pdf', 'docx'];
      if (!arr.includes(testmsg)) {
        this.$message.warning("目前支持的类型有：[.jpg|.gif|.png|.jepg|.xls|.xlsx|.doc|.pdf|.docx]");
        return false;
      } else {
        var uploadFileSize = file.size / 1024 / 1024 > 2;
        if (uploadFileSize) {
          this.$message.warning("上传附件大小不超过2M");
          return false;
        }
        ;
        if (fileNameLength > 80) {
          this.$message.warning("文件名称过长，请控制在80个字符以内");
          return false;
        }
        ;
      }
      ;
      this.form.coverImage = null;
      this.fileList = [];
    },
    // 显示输入框
    showInput: function showInput() {
      var _this3 = this;
      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this3.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 创建标签
    handleInputConfirm: function handleInputConfirm() {
      this.inputVisible = false;
      if (!_.isEmpty(this.form.associatedLabelsInput)) {
        var inputValue = this.form.associatedLabelsInput;
        this.createLabel({
          labelContent: inputValue
        });
      }
      ;
    },
    // 创建标签接口
    createLabel: function createLabel(data) {
      var _this4 = this;
      artilceApi.createMaterialLabel(data).then(function (res) {
        if (res.code === 200) {
          _this4.form.associatedLabelsInput = '';
          _this4.$message.success(res.msg);
          _this4.$emit('getAssociatedLabelList');
        }
        ;
      });
    },
    // 删除标签
    handleClose: function handleClose(tag, index) {
      var _this5 = this;
      this.$confirm('是否删除该标签?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.associatedLabelList.splice(index, 1);
        _this5.form.associatedLabels.some(function (ele, eleIndex) {
          if (ele === tag.labelId) {
            _this5.form.associatedLabels.splice(eleIndex, 1);
            return true;
          }
          ;
        });
        artilceApi.deleteMaterialLabel(tag.labelId).then(function (res) {
          if (res.code === 200) {
            _this5.$message.success(res.msg);
            _this5.$emit('getAssociatedLabelList');
          }
          ;
        });
        _this5.$forceUpdate();
      }).catch(function () {});
    }
  }
};