var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              !_vm._.isEmpty(_vm.allLabelList)
                ? _c(
                    "el-form",
                    {
                      ref: "detail",
                      attrs: {
                        model: _vm.form,
                        "label-width": "100px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "标签：",
                                    prop: "addTagList",
                                  },
                                },
                                _vm._l(_vm.allLabelList, function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.groupId,
                                      attrs: { label: item.groupName },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(item.groupName)),
                                      ]),
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          model: {
                                            value: _vm.form.addTagList,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "addTagList",
                                                $$v
                                              )
                                            },
                                            expression: "form.addTagList",
                                          },
                                        },
                                        _vm._l(item.tags, function (tagItem) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: tagItem.tagId,
                                              attrs: { label: tagItem.tagId },
                                            },
                                            [_vm._v(_vm._s(tagItem.name))]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "no-data" }, [_vm._v(" 暂无数据 ")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
                disabled: _vm._.isEmpty(_vm.allLabelList),
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }