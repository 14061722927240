//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "MyChat",
  props: {
    time: {
      type: String,
      default: ""
    },
    avatar: {
      type: String,
      default: ""
    },
    con: {
      type: String,
      default: ""
    }
  }
};