var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "700px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "700px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.addForm,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "素材类型", prop: "mediaTypeId" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择素材类型" },
                                  model: {
                                    value: _vm.addForm.mediaTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "mediaTypeId", $$v)
                                    },
                                    expression: "addForm.mediaTypeId",
                                  },
                                },
                                _vm._l(_vm.classificationList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.classificationName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.addForm.storageUrl
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "文件名称",
                                    prop: "fileName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "请输入文件名称" },
                                    model: {
                                      value: _vm.addForm.fileName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addForm, "fileName", $$v)
                                      },
                                      expression: "addForm.fileName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "上传文件" } },
                            [
                              _vm.addForm.storageUrl
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "110px",
                                        height: "100px",
                                        position: "relative",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "coverImg",
                                        attrs: {
                                          src: _vm.addForm.storageUrl,
                                          alt: "",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "deleteIcon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.delFileList(_vm.fitem)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-circle-close",
                                            staticStyle: {
                                              color: "red",
                                              "font-size": "16px",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-upload",
                                    {
                                      staticClass: "upload-demo",
                                      attrs: {
                                        limit: 1,
                                        action: "",
                                        multiple: "",
                                        "show-file-list": false,
                                        "http-request": function (val) {
                                          return _vm.uploadFile(val)
                                        },
                                        "before-upload": function (val) {
                                          return _vm.beforeUpload(val)
                                        },
                                        "file-list": _vm.fileList,
                                        accept: _vm.uploadType,
                                        "list-type": "text",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-plus",
                                        staticStyle: { "font-size": "24px" },
                                      }),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "100px" },
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("addForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100px" },
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage()
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }