import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dataInteractionPlatformApi from '@/api/dataInteractionPlatform';
import dict from '@/mixins/dict';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    outputConfigId: {
      type: String,
      default: ''
    }
  },
  mixins: [dict],
  data: function data() {
    return {
      form: {
        bizType: JSON.parse(localStorage.getItem("userInfo")).bizType || '',
        dataConfigCode: '',
        orgCustomCode: '',
        orgName: '',
        // outputPlatformAppid: '',
        outputPlatformName: '',
        // outputPlatformSecret: '',
        outputPlatformUrl: '',
        outputSendStrategy: '',
        outputConfigState: false,
        subOrgCanUse: false,
        successFlag: 'CODE200',
        //成功依据
        directConnect: false,
        //是否直连
        dataOrgRange: 'ALL' //订阅范围
      },

      successFlagList: [{
        label: '响应码200',
        value: 'CODE200'
      }, {
        label: '响应码200同时返回SUCCESS',
        value: 'SUCCESS'
      }, {
        label: '响应码200同时返回msg值为SUCCESS',
        value: 'MSGSUCCESS'
      }],
      rules: {
        dataConfigCode: {
          required: true,
          message: '请选择订阅数据',
          trigger: "change"
        },
        orgCustomCode: {
          required: true,
          message: '请选择所属机构',
          trigger: "change"
        },
        subOrgCanUse: {
          required: true,
          message: '请选择是否订阅下级机构',
          trigger: "change"
        },
        outputConfigState: {
          required: true,
          message: '请选择是否启用',
          trigger: "change"
        },
        directConnect: {
          required: true,
          message: '请选择是否直连',
          trigger: "change"
        },
        outputPlatformName: {
          required: true,
          message: '请输入订阅配置名称',
          trigger: "blur"
        },
        outputPlatformUrl: {
          required: true,
          message: '请输入订阅地址',
          trigger: "blur"
        }
      },
      subscriptionList: [],
      pushStrategyList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.getDuipdataconfigList();
        this.getPushstragegy();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
    this.getDuipdataconfigList();
    this.getPushstragegy();
  },
  methods: {
    getOrgCode: function getOrgCode(val) {
      this.getAllList();
    },
    getPushstragegy: function getPushstragegy() {
      var _this = this;
      dataInteractionPlatformApi.getPushstragegy().then(function (res) {
        if (res.code == 200) {
          _this.pushStrategyList = res.data;
        }
      });
    },
    getDuipdataconfigList: function getDuipdataconfigList() {
      var _this2 = this;
      //获取订阅数据下拉列表
      dataInteractionPlatformApi.getDuipdataconfigList(1, 100000).then(function (res) {
        if (res.code == 200) {
          _this2.subscriptionList = res.data.list ? res.data.list : [];
        }
      });
    },
    initData: function initData() {
      var _this3 = this;
      this.form = {
        bizType: '',
        dataConfigCode: '',
        orgCustomCode: '',
        orgName: '',
        // outputPlatformAppid: '',
        // outputPlatformSecret: '',
        outputPlatformName: '',
        outputPlatformUrl: '',
        outputSendStrategy: '',
        outputConfigState: false,
        subOrgCanUse: false,
        successFlag: 'CODE200',
        //成功依据
        directConnect: false,
        dataOrgRange: 'ALL'
      };
      if (this.opType === "edit" || this.opType === 'copy') {
        dataInteractionPlatformApi.getDuipoutputconfigInfo(this.outputConfigId).then(function (res) {
          if (res.code === 200) {
            _this3.form = res.data;
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    clearBizType: function clearBizType() {
      this.form.bizType = 'PLATFORM';
    },
    nodeClick: function nodeClick(data) {
      this.form.orgCustomCode = data.salesOrgCode;
      this.form.orgName = data.salesOrgName;
    },
    // 保存数据
    save: function save() {
      var _this4 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var data = {};
          if (_this4.opType == 'edit') {
            data = {
              outputConfigId: _this4.outputConfigId,
              bizType: 'PLATFORM',
              dataConfigCode: _this4.form.dataConfigCode,
              orgCustomCode: _this4.form.orgCustomCode,
              orgName: _this4.form.orgName,
              outputConfigState: _this4.form.outputConfigState,
              // outputPlatformAppid: this.form.outputPlatformAppid,
              // outputPlatformSecret: this.form.outputPlatformSecret,
              outputPlatformName: _this4.form.outputPlatformName,
              outputPlatformUrl: _this4.form.outputPlatformUrl,
              outputSendStrategy: _this4.form.outputSendStrategy,
              subOrgCanUse: _this4.form.subOrgCanUse,
              successFlag: _this4.form.successFlag,
              //成功依据
              directConnect: _this4.form.directConnect,
              dataOrgRange: _this4.form.dataOrgRange
            };
          }
          _this4.form.bizType = "PLATFORM";
          var req = _this4.opType === 'add' || _this4.opType === 'copy' ? dataInteractionPlatformApi.duipoutputconfigAddData(_this4.form) : dataInteractionPlatformApi.duipoutputconfigUpdateData(data);
          req.then(function (res) {
            if (res.code === 200) {
              _this4.$message({
                message: res.msg,
                type: 'success'
              });
              _this4.closePage(false);
            }
            ;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};