//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dataInteractionPlatformApi from '@/api/dataInteractionPlatform';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    cameraBlackId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      // dataInteractionPlatformApi.getCameraBlackInfo(this.cameraBlackId).then(res => {
      //     if (res.code === 200) {
      //         this.form = res.data;
      //     };
      // });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};