var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MyChat" }, [
    _c("div", { staticClass: "my_user" }, [
      _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))]),
      _c("div", { staticClass: "other_main" }, [
        _c(
          "div",
          {
            staticClass: "footer-btn-list",
            staticStyle: {
              "align-items": "baseline",
              "max-width": "60%",
              "justify-content": "flex-end",
            },
          },
          [
            _c("div", { staticClass: "other_message" }, [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.con) } }),
            ]),
            _c("div", { staticClass: "triangle_right" }),
          ]
        ),
        _c("div", { staticClass: "avatar_warp" }, [
          _c("img", {
            staticStyle: { "border-radius": "50%" },
            attrs: { width: "46", height: "46", src: _vm.avatar },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }