import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sysManageApi from '@/api/sysManage';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    addAdminItem: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      form: {
        accessMode: '',
        appId: '',
        appSecret: '',
        contactsSecret: '',
        corpId: '',
        corpName: '',
        realmName: '',
        contactsEncodingAesKey: '',
        contactsToken: '',
        credFileContent: '',
        credFileName: '',
        customerSecret: '',
        externalUserEncodingAesKey: '',
        externalUserToken: '',
        forwardAddress: '',
        forwardCustomerAddress: '',
        defaultRolesList: [],
        miniProgramAppId: '',
        mobile: '',
        password: '',
        roleIds: []
      },
      accessModeList: [{
        itemCode: 'SELF',
        itemName: '自建应用接入'
      }, {
        itemCode: 'SUITE',
        itemName: '代开发应用接入'
      }],
      roleIdsList: [],
      rules: {
        corpId: {
          required: true,
          message: '请输入企业ID',
          trigger: "blur"
        },
        realmName: {
          required: true,
          message: '请输入域名配置',
          trigger: "blur"
        },
        corpName: {
          required: true,
          message: '请输入企业名称',
          trigger: "blur"
        },
        appId: {
          required: true,
          message: '请输入企业应用ID',
          trigger: "blur"
        },
        appSecret: {
          required: true,
          message: '请输入企业应用Secret',
          trigger: "blur"
        },
        contactsSecret: {
          required: true,
          message: '请输入企业通讯录Secret',
          trigger: "blur"
        },
        defaultRolesList: {
          required: true,
          message: '请选择扫码登录用户默认角色',
          trigger: "change"
        }
      },
      formLoading: false,
      roleIdList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.getRoles();
    this.initData();
  },
  methods: {
    getRoles: function getRoles() {
      var _this = this;
      sysManageApi.getList(1, 100).then(function (res) {
        if (res.code == 200) {
          _this.roleIdsList = res.data.list || [];
        }
        ;
      });
    },
    // 初始化数据
    initData: function initData() {
      var _this2 = this;
      this.form = {
        realmName: this.addAdminItem.realmName || '',
        accessMode: this.addAdminItem.accessMode || '',
        corpId: this.addAdminItem.corpId || '',
        corpName: this.addAdminItem.corpName || '',
        mobile: this.addAdminItem.mobile || '',
        password: this.addAdminItem.password || '',
        roleIds: this.addAdminItem.roleIds || []
      };
      this.roleIdList = [];
      sysManageApi.getRoleDropdownList().then(function (res) {
        if (res.code === 200) {
          _this2.roleIdList = res.data || [];
        }
        ;
      });
      if (this.opType === "edit") {
        this.formLoading = true;
        sysManageApi.getWecomCorpConfigInfo(this.currentId).then(function (res) {
          _this2.formLoading = false;
          if (res.code === 200) {
            _this2.form = res.data;
            var rolesList = _this2.form.defaultRoles ? _this2.form.defaultRoles.split(',') : [];
            rolesList = rolesList.map(function (i) {
              return parseInt(i, 0);
            });
            _this2.$set(_this2.form, 'defaultRolesList', rolesList);
          }
          ;
        }).catch(function (err) {
          _this2.formLoading = false;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this3 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          var data = {
            corpId: _this3.form.corpId,
            mobile: _this3.form.mobile,
            password: _this3.form.password,
            roleIds: _this3.form.roleIds || []
          };
          var req = sysManageApi.createCorpAdminConfig(data);
          req.then(function (res) {
            _this3.formLoading = false;
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};