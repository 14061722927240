import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customerManagementApi from '@/api/customerManagement';
import { limitInput } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        corpId: '',
        updateId: '',
        updateName: '',
        updateOrderBy: 0
      },
      rules: {
        updateName: {
          required: true,
          message: "\u8BF7\u8F93\u5165".concat(this.opType === 'groupName' ? '标签组名称' : '标签'),
          trigger: "blur"
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {
        corpId: '',
        updateId: '',
        updateName: '',
        updateOrderBy: 0
      };
      if (this.opType) {
        this.formLoading = true;
        customerManagementApi.getWecomtagsInfo(this.currentId).then(function (res) {
          _this.formLoading = false;
          if (res.code === 200) {
            var resData = res.data;
            _this.form = {
              corpId: resData.corpId,
              updateId: resData.tagId,
              updateName: resData.name,
              updateOrderBy: resData.orderBy
            };
          }
          ;
        }).catch(function (err) {
          _this.formLoading = false;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 对输入内容做精度校验
    onInput: function onInput(num, min, max, limit) {
      this.$forceUpdate();
      if (min && num < min) {
        num = min;
      }
      ;
      if (max && num > max) {
        num = max;
      }
      ;
      return limitInput(num, limit);
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          customerManagementApi.updateWecomtags(_this2.form).then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};