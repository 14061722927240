var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {
        "max-height": "700px",
        padding: "10px",
        "box-sizing": "border-box",
      },
    },
    [
      _c(
        "div",
        { staticClass: "tableDataList" },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "margin-bottom": "10px",
                display: "flex",
                "flex-direction": "row",
                "justify-content": "flex-end",
                "align-items": "center",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "80px" },
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.refreshClicked()
                    },
                  },
                },
                [_vm._v("刷新")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "80px" },
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.addType()
                    },
                  },
                },
                [_vm._v("新增类型")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: {
                width: "100%",
                "max-height": "500px",
                "overflow-y": "scroll",
              },
              attrs: { data: _vm.tableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "类型id", width: "180" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "classificationName",
                  label: "类型名称",
                  width: "auto",
                },
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.pageNum,
              limit: _vm.listQuery.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "pageSize", $event)
              },
              pagination: function ($event) {
                return _vm.loadData()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "100px" },
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage()
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageAddTypeDialog",
          attrs: {
            visible: _vm.showAddTypeDialog,
            "close-on-click-modal": false,
            width: "500px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddTypeDialog = $event
            },
            handleClose: function ($event) {
              _vm.showAddTypeDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("新增素材类型")]
              ),
            ]
          ),
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.addTypeForm,
                "label-width": "100px",
                size: "mini",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "素材分类名称",
                            prop: "classificationName",
                            rules: [
                              {
                                required: true,
                                message: "请输入素材分类名称",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            model: {
                              value: _vm.addTypeForm.classificationName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addTypeForm,
                                  "classificationName",
                                  $$v
                                )
                              },
                              expression: "addTypeForm.classificationName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "flex",
                "justify-content": "center",
                "margin-top": "8px",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "80px" },
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.saveAddType("addForm")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "80px" },
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.closeAddType()
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }