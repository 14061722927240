/**
 * 消息平台
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // ******************************消息类型配置******************************
  /**
   * 分页查询消息类型
   * @param {*} data
   */
  getMessageTypeList: function getMessageTypeList(data) {
    return request.get(getUrl("/messageType/search", 10), {
      params: data
    });
  },
  /**
   * 不分页查询消息类型
   * @param {*} data
   */
  getNoPageMessageTypeList: function getNoPageMessageTypeList(data) {
    return request.get(getUrl("/messageType/list", 10), {
      params: data
    });
  },
  /**
   * 创建消息类型
   * @param {*} data
   */
  createMessageType: function createMessageType(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/messageType/create", 10), data, {
      headers: headers
    });
  },
  /**
   * 更新消息类型
   * @param {*} messageTypeId
   * @param {*} data
   */
  updateMessageType: function updateMessageType(messageTypeId, data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/messageType/update?messageTypeId=".concat(messageTypeId), 10), data, {
      headers: headers
    });
  },
  /**
   * 查询一条消息类型
   * @param {*} messageTypeId
   */
  getMessageTypeInfo: function getMessageTypeInfo(messageTypeId) {
    return request.get(getUrl("/messageType/get?messageTypeId=".concat(messageTypeId), 10));
  },
  /**
   * 批量删除消息类型
   * @param {*} messageTypeIds
   */
  deleteMessageType: function deleteMessageType(messageTypeIds) {
    return request.delete(getUrl("/messageType/batchDelete/".concat(messageTypeIds), 10));
  },
  // ******************************消息通知渠道配置******************************
  /**
   * 分页查询消息通知渠道
   * @param {*} data
   */
  getMessageNotifyChannelList: function getMessageNotifyChannelList(data) {
    return request.get(getUrl("/messageNotifyChannel/search", 10), {
      params: data
    });
  },
  /**
   * 不分页查询消息通知渠道
   * @param {*} data
   */
  getNoPageMessageNotifyChannelList: function getNoPageMessageNotifyChannelList(data) {
    return request.get(getUrl("/messageNotifyChannel/list", 10), {
      params: data
    });
  },
  /**
   * 创建消息通知渠道
   * @param {*} data
   */
  createMessageNotifyChannel: function createMessageNotifyChannel(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/messageNotifyChannel/create", 10), data, {
      headers: headers
    });
  },
  /**
   * 更新消息通知渠道
   * @param {*} messageNotifyChannelId
   * @param {*} data
   */
  updateMessageNotifyChannel: function updateMessageNotifyChannel(messageNotifyChannelId, data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/messageNotifyChannel/update?messageNotifyChannelId=".concat(messageNotifyChannelId), 10), data, {
      headers: headers
    });
  },
  /**
   * 查询一条消息通知渠道
   * @param {*} messageNotifyChannelId
   */
  getMessageNotifyChannelInfo: function getMessageNotifyChannelInfo(messageNotifyChannelId) {
    return request.get(getUrl("/messageNotifyChannel/get?messageNotifyChannelId=".concat(messageNotifyChannelId), 10));
  },
  /**
  * 批量删除消息类型
  * @param {*} messageNotifyChannelId
  */
  deleteMessageNotifyChannel: function deleteMessageNotifyChannel(messageNotifyChannelId) {
    return request.delete(getUrl("/messageNotifyChannel/batchDelete/".concat(messageNotifyChannelId), 10));
  },
  /**
  * 获取通知方式配置
  * @param {*} data
  */
  getConfigSchema: function getConfigSchema(data) {
    return request.get(getUrl("/messageNotifyChannel/getConfigSchema", 10), {
      params: data
    });
  },
  /**
  * 启用/禁用通知渠道
  * @param {*} messageNotifyChannelId
  */
  enableDisableConfigSchema: function enableDisableConfigSchema(messageNotifyChannelId) {
    return request.put(getUrl("/messageNotifyChannel/enabled?messageNotifyChannelId=".concat(messageNotifyChannelId), 10));
  },
  // ******************************消息模板管理******************************
  /**
   * 分页查询消息模板
   * @param {*} data
   */
  getMessageTemplateList: function getMessageTemplateList(data) {
    return request.get(getUrl("/messageTemplate/search", 10), {
      params: data
    });
  },
  /**
  * 新增消息模板
  * @param {*} data
  */
  createMessageTemplate: function createMessageTemplate(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/messageTemplate/create", 10), data, {
      headers: headers
    });
  },
  /**
  * 更新消息模板
  * @param {*} templateId
  * @param {*} data
  */
  updateMessageTemplate: function updateMessageTemplate(templateId, data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/messageTemplate/update?templateId=".concat(templateId), 10), data, {
      headers: headers
    });
  },
  /**
   * 查询一条消息模板
   * @param {*} templateId
   */
  getMessageTemplateInfo: function getMessageTemplateInfo(templateId) {
    return request.get(getUrl("/messageTemplate/get?templateId=".concat(templateId), 10));
  },
  /**
  * 批量删除消息模板
  * @param {*} templateIds
  */
  deleteMessageTemplate: function deleteMessageTemplate(templateIds) {
    return request.delete(getUrl("/messageTemplate/batchDelete/".concat(templateIds), 10));
  },
  /**
  * 获取消息类型参数
  * @param {*} data
  */
  getSchema: function getSchema(data) {
    return request.get(getUrl("/messageType/getSchema", 10), {
      params: data
    });
  },
  /**
  * 获取消息模板
  * @param {*} data
  */
  getTemplateSchema: function getTemplateSchema(data) {
    return request.get(getUrl("/messageNotifyChannel/getTemplateSchema", 10), {
      params: data
    });
  },
  // ******************************消息表******************************
  /**
   * 分页查询消息
   * @param {*} data
   */
  getMessageList: function getMessageList(data) {
    return request.get(getUrl("/message/search", 10), {
      params: data
    });
  },
  /**
   * 根据主键查询消息
   * @param {*} messageId
   */
  getMessageInfo: function getMessageInfo(messageId) {
    return request.get(getUrl("/message/get?messageId=".concat(messageId), 10));
  },
  /**
   * 读取消息
   * @param {*} messageId
   */
  readMessage: function readMessage(messageId) {
    return request.post(getUrl("/message/read?messageId=".concat(messageId), 10));
  }
};
export default objApi;