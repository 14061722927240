/**
 * 客户管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // *************************客户公海****************************
  /**
   * 分页查询
   * @param {*} data 参数
   */
  getCostomerClueList: function getCostomerClueList(data) {
    return request.get(getUrl("/wechat/userClue/search", 4), {
      params: data
    });
  },
  /**
   * 分配跟进人
   * @param {*} data 参数
   */
  distribution: function distribution(data) {
    return request.post(getUrl("/wechat/userClue/distribution", 4), data);
  },
  /**
   * 添加跟进
   * @param {*} data 参数
   */
  addFollow: function addFollow(data) {
    return request.post(getUrl("/wechat/userClue/addFollow", 4), data);
  },
  // *************************客户列表****************************
  /**
   * 分页查询
   * @param {*} data 参数
   */
  getCostomerList: function getCostomerList(data) {
    return request.get(getUrl("/wecomenExternal/search", 4), {
      params: data
    });
  },
  /**
   * 查询客户详情
   * @param {*} id
   */
  getCostomerInfo: function getCostomerInfo(id) {
    return request.get(getUrl("/wecomenExternal/get?id=".concat(id), 4));
  },
  /**
   * 给客户打标签
   * @param {*} data
   */
  wecomenExternalMarkTag: function wecomenExternalMarkTag(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomenExternal/markTag", 4), data, {
      headers: headers
    });
  },
  /**
   * 编辑客户的基本信息
   * @param {*} data
   */
  updateCostomerInfo: function updateCostomerInfo(data) {
    return request.post(getUrl("/wecomenExternal/update", 4), data);
  },
  // *************************客户群****************************
  /**
   * 分页查询
   * @param {*} data 参数
   */
  getCostomerGroupList: function getCostomerGroupList(data) {
    return request.get(getUrl("/wecomGroupchat/search", 4), {
      params: data
    });
  },
  /**
   * 不分页查询
   * @param {*} data 参数
   */
  getCostomerGroupsLists: function getCostomerGroupsLists(data) {
    return request.get(getUrl("/wecomGroupchat/list", 4), {
      params: data
    });
  },
  /**
   * 查询一条
   * @param {*} id
   */
  getCostomerGroupInfo: function getCostomerGroupInfo(id) {
    return request.get(getUrl("/wecomGroupchat/get?id=".concat(id), 4));
  },
  // *************************企业管理****************************
  /**
   * 分页查询
   * @param {*} data 参数
   */
  getTerpriseList: function getTerpriseList(data) {
    return request.get(getUrl("/wecomenTerprise/search", 4), {
      params: data
    });
  },
  /**
   * 新增
   * @param {*} data
   */
  createTerprise: function createTerprise(data) {
    return request.post(getUrl("/wecomenTerprise/create", 4), data);
  },
  /**
   * 查询一条
   * @param {*} id
  */
  getTerpriseInfo: function getTerpriseInfo(id) {
    return request.get(getUrl("/wecomenTerprise/get?id=".concat(id), 4));
  },
  /**
   * 更新
   * @param {*} id
   * @param {*} data
   */
  updateTerprise: function updateTerprise(id, data) {
    return request.post(getUrl("/wecomenTerprise/update?enterpriseId=".concat(id), 4), data);
  },
  /**
   * 批量删除
   * @param {*} data
   */
  deleteTerprise: function deleteTerprise(data) {
    return request.post(getUrl("/wecomenTerprise/batchDeleteList", 4), data);
  },
  /**
   * 查询指定用户浏览文章的记录
   * @param {*} data
   */
  listMaterialByUser: function listMaterialByUser(data) {
    return request.get(getUrl("/scrmSearch/listMaterialByUser", 9), {
      params: data
    });
  },
  // ******************************用户类型*******************************************
  /**
   * 分页查询
   * @param {*} data
   */
  getWecomtagsGroupList: function getWecomtagsGroupList(data) {
    return request.get(getUrl("/wecomtags/groupList", 4), {
      params: data
    });
  },
  /**
   * 添加企业客户标签
   * @param {*} data
   */
  createWecomtags: function createWecomtags(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomtags/create", 4), data, {
      headers: headers
    });
  },
  /**
   * 查询一条
   * @param {*} id
   */
  getWecomtagsInfo: function getWecomtagsInfo(id) {
    return request.get(getUrl("/wecomtags/get?id=".concat(id), 4));
  },
  /**
   * 修改企业客户标签
   * @param {*} data
   */
  updateWecomtags: function updateWecomtags(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomtags/update", 4), data, {
      headers: headers
    });
  },
  /**
   * 删除企业客户标签
   * @param {*} data
   */
  deleteWecomtags: function deleteWecomtags(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomtags/delete", 4), data, {
      headers: headers
    });
  },
  /**
   * 不分页查询跟进列表
   * @param {*} data
   */
  getTracklist: function getTracklist(data) {
    var headers = {
      showLoading: true
    };
    return request.get(getUrl("/wecomexternaltrack/list", 4), {
      params: data
    }, {
      headers: headers
    });
  },
  /**
   * 分页查询跟进列表
   * @param {*} data
   */
  getTrackSearchlist: function getTrackSearchlist(data) {
    var headers = {
      showLoading: true
    };
    return request.get(getUrl("/wecomexternaltrack/search", 4), {
      params: data
    }, {
      headers: headers
    });
  },
  /**
   * 获取客户最新跟进情况
   * @param {*} data
   */
  getLastTrack: function getLastTrack(id) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomexternaltrack/getLastTrack?externalId=".concat(id), 4), {
      headers: headers
    });
  },
  /**
   * 客户新增跟进
   * @param {*} data
   */
  createTrack: function createTrack(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/wecomexternaltrack/create", 4), data, {
      headers: headers
    });
  },
  // ******************************成交记录*******************************************
  /**
   * 分页查询
   * @param {*} data
   */
  getTransactionRecordsList: function getTransactionRecordsList(data) {
    return request.get(getUrl("/wecomexternaltrack/transaction", 4), {
      params: data
    });
  },
  // ******************************企业微信-客户车辆*******************************************
  /**
   * 不分页查询
   * @param {*} data
   */
  getWecomexternalvehicleList: function getWecomexternalvehicleList(data) {
    return request.get(getUrl("/wecomexternalvehicle/list", 4), {
      params: data
    });
  },
  /**
   * 新增
   * @param {*} data
   */
  createWecomexternalvehicle: function createWecomexternalvehicle(data) {
    return request.post(getUrl("/wecomexternalvehicle/create", 4), data);
  },
  /**
   * 更新
   * @param {*} vehicleId
   * @param {*} data
   */
  updateWecomexternalvehicle: function updateWecomexternalvehicle(vehicleId, data) {
    return request.post(getUrl("/wecomexternalvehicle/update?vehicleId=".concat(vehicleId), 4), data);
  },
  /**
   * 查询一条
   * @param {*} id
   */
  getWecomexternalvehicleInfo: function getWecomexternalvehicleInfo(id) {
    return request.get(getUrl("/wecomexternalvehicle/get?id=".concat(id), 4));
  },
  /**
   * 删除一条
   * @param {*} id
   */
  deleteWecomexternalvehicle: function deleteWecomexternalvehicle(id) {
    return request.post(getUrl("/wecomexternalvehicle/delete?id=".concat(id), 4));
  },
  /**
   * 主动拉取我的客户
   * @param {*} id
   */
  syncExternalGetMycustomer: function syncExternalGetMycustomer() {
    return request.post(getUrl("/wecomenExternal/syncExternal", 4));
  }
};
export default objApi;