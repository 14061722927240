var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.PageContainerLoading,
              expression: "PageContainerLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 120px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.formRules,
                          "label-width": "140px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeInfo,
                              callback: function ($$v) {
                                _vm.activeInfo = $$v
                              },
                              expression: "activeInfo",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "营销基础信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 14 } },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "营销类型：",
                                                      prop: "taskType",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          disabled:
                                                            _vm.type ==
                                                            "detail",
                                                          clearable: "",
                                                          placeholder:
                                                            "请选择营销类型",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.changeTaskType,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.taskType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "taskType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.taskType",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.taskTypes,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.itemName,
                                                                value:
                                                                  item.itemCode,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "任务名称：",
                                                      prop: "name",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            maxlength: "40",
                                                            disabled:
                                                              _vm.type ==
                                                              "detail",
                                                            placeholder:
                                                              "请输入任务名称",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.name,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.name",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0px",
                                                    },
                                                    attrs: {
                                                      label: "分配发送员工：",
                                                      required: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 8 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "",
                                                                  prop: "corpId",
                                                                  rules: [
                                                                    {
                                                                      required: true,
                                                                      message:
                                                                        "请选择企业",
                                                                      trigger:
                                                                        "change",
                                                                    },
                                                                  ],
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    attrs: {
                                                                      placeholder:
                                                                        "请选择企业",
                                                                      disabled:
                                                                        _vm.type ==
                                                                        "detail",
                                                                      clearable:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.getDepartmentList,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .corpId,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "corpId",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.corpId",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.corpLists,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: index,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.corpName,
                                                                              value:
                                                                                item.corpId,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("el-col", {
                                                          attrs: { span: 1 },
                                                        }),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 8 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "",
                                                                  prop: "departmentId",
                                                                  rules: [
                                                                    {
                                                                      required: true,
                                                                      message:
                                                                        "请选择部门",
                                                                      trigger:
                                                                        "change",
                                                                    },
                                                                  ],
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    attrs: {
                                                                      disabled:
                                                                        !_vm
                                                                          .form
                                                                          .corpId ||
                                                                        _vm.type ==
                                                                          "detail",
                                                                      multiple:
                                                                        "",
                                                                      clearable:
                                                                        "",
                                                                      placeholder:
                                                                        "请选择部门",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.getEmployeeList,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .departmentId,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "departmentId",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.departmentId",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.departmentLists,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: index,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.name,
                                                                              value:
                                                                                item.id,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("el-col", {
                                                          attrs: { span: 1 },
                                                        }),
                                                        _vm.type !== "detail"
                                                          ? _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 8,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-form-item",
                                                                  {
                                                                    attrs: {
                                                                      label: "",
                                                                      prop: "employeeId",
                                                                      rules: [
                                                                        {
                                                                          required: true,
                                                                          message:
                                                                            "请选择员工",
                                                                          trigger:
                                                                            "change",
                                                                        },
                                                                      ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                        attrs: {
                                                                          disabled:
                                                                            !_vm
                                                                              .form
                                                                              .corpId ||
                                                                            (_vm
                                                                              .form
                                                                              .departmentId &&
                                                                              _vm
                                                                                .form
                                                                                .departmentId
                                                                                .length ==
                                                                                0),
                                                                          multiple:
                                                                            "",
                                                                          "collapse-tags":
                                                                            "",
                                                                          clearable:
                                                                            "",
                                                                          placeholder:
                                                                            "请选择员工",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            _vm.setEmployee,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form
                                                                              .employeeId,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.form,
                                                                                "employeeId",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form.employeeId",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.employeeLists,
                                                                        function (
                                                                          item,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: index,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.memberName,
                                                                                  value:
                                                                                    item.memberUserId,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.form.memberList &&
                                        _vm.form.memberList.length != 0
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 24 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "已选择员工：",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "membersList",
                                                          },
                                                          _vm._l(
                                                            _vm.form.memberList,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "el-button",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    type: "info",
                                                                    plain: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.memberName ||
                                                                          item.memberUserName
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        "是否允许部分发送：",
                                                      prop: "allowSelect",
                                                    },
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      attrs: {
                                                        disabled:
                                                          _vm.type == "detail",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeAllowSelect,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.allowSelect,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "allowSelect",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.allowSelect",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.form.taskType === "SINGLE" ||
                                        _vm.form.taskType === "GROUP"
                                          ? _c(
                                              "el-row",
                                              [
                                                _vm.type != "detail"
                                                  ? _c(
                                                      "el-col",
                                                      { attrs: { span: 24 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "营销对象：",
                                                              prop: "externalAllFlag",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio-group",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.form
                                                                          .memberList
                                                                          .length ===
                                                                          0 ||
                                                                        _vm.type ==
                                                                          "detail",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.setExternalCount,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .externalAllFlag,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "externalAllFlag",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.externalAllFlag",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-radio",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-right":
                                                                              "10px",
                                                                          },
                                                                        attrs: {
                                                                          label:
                                                                            "1",
                                                                          border:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "全部客户"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "el-radio",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-right":
                                                                              "10px",
                                                                          },
                                                                        attrs: {
                                                                          disabled:
                                                                            !_vm
                                                                              .form
                                                                              .allowSelect,
                                                                          label:
                                                                            "2",
                                                                          border:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "指定客户"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "el-radio",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "3",
                                                                          disabled:
                                                                            !_vm
                                                                              .form
                                                                              .allowSelect ||
                                                                            _vm
                                                                              .form
                                                                              .taskType !=
                                                                              "GROUP",
                                                                          border:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "标签客户"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "el-col",
                                                      { attrs: { span: 24 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "营销对象：",
                                                              prop: "externalAllFlag",
                                                            },
                                                          },
                                                          [
                                                            _vm.form.taskType ==
                                                            "SINGLE"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "flex",
                                                                        "flex-wrap":
                                                                          "wrap",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.singleExternalListsByMember,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "el-button",
                                                                        {
                                                                          key: index,
                                                                          staticStyle:
                                                                            {
                                                                              "margin-bottom":
                                                                                "6px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.externalUsername
                                                                              ) +
                                                                              " (" +
                                                                              _vm._s(
                                                                                item.memberUsername
                                                                              ) +
                                                                              ") "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.form.taskType ==
                                                            "GROUP"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "flex",
                                                                        "flex-wrap":
                                                                          "wrap",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.groupExternalListsByMember,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "el-button",
                                                                        {
                                                                          key: index,
                                                                          staticStyle:
                                                                            {
                                                                              "margin-bottom":
                                                                                "6px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.groupChatName
                                                                              ) +
                                                                              " (" +
                                                                              _vm._s(
                                                                                item.senderName
                                                                              ) +
                                                                              ") "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.form.taskType === "SINGLE" &&
                                        _vm.form.externalAllFlag === "2"
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 24 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label:
                                                            "指定营销对象：",
                                                          prop: "externalLists",
                                                          rules: [
                                                            {
                                                              required:
                                                                _vm.form
                                                                  .externalAllFlag ===
                                                                "2"
                                                                  ? true
                                                                  : false,
                                                              message:
                                                                "请选择指定营销对象",
                                                              trigger: "change",
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.type ==
                                                                    "detail",
                                                                  size: "mini",
                                                                  multiple: "",
                                                                  "collapse-tags":
                                                                    _vm.form
                                                                      .externalLists &&
                                                                    _vm.form
                                                                      .externalLists
                                                                      .length >
                                                                      7
                                                                      ? true
                                                                      : false,
                                                                  clearable: "",
                                                                  placeholder:
                                                                    "请选择客户",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setExternalCount(
                                                                        false,
                                                                        "SINGLE2"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .externalLists,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        "externalLists",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form.externalLists",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.costomersList,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        label:
                                                                          item.externalUserName,
                                                                        value:
                                                                          item.onlyId,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              float:
                                                                                "left",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.externalUserName
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              float:
                                                                                "right",
                                                                              color:
                                                                                "#8492a6",
                                                                              "font-size":
                                                                                "13px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.memberUserName
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.form.taskType === "GROUP" &&
                                        _vm.form.externalAllFlag === "2"
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 24 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label:
                                                            "指定营销对象：",
                                                          prop: "externalLists",
                                                          rules: [
                                                            {
                                                              required:
                                                                _vm.form
                                                                  .externalAllFlag ===
                                                                "2"
                                                                  ? true
                                                                  : false,
                                                              message:
                                                                "请选择指定营销对象",
                                                              trigger: "change",
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.type ==
                                                                    "detail",
                                                                  size: "mini",
                                                                  multiple: "",
                                                                  "collapse-tags":
                                                                    _vm.form
                                                                      .externalLists &&
                                                                    _vm.form
                                                                      .externalLists
                                                                      .length >
                                                                      7
                                                                      ? true
                                                                      : false,
                                                                  clearable: "",
                                                                  placeholder:
                                                                    "请选择客户群",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setExternalCount(
                                                                        false,
                                                                        "GROUP2"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .externalLists,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        "externalLists",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form.externalLists",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.costomerGroupsList,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        label:
                                                                          item.groupChatName,
                                                                        value:
                                                                          item.groupChatId,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              float:
                                                                                "left",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.groupChatName
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              float:
                                                                                "right",
                                                                              color:
                                                                                "#8492a6",
                                                                              "font-size":
                                                                                "13px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.ownerName
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.form.taskType === "GROUP" &&
                                        _vm.form.externalAllFlag === "3"
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 24 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label:
                                                            "指定标签对象：",
                                                          prop: "externalLists",
                                                          rules: [
                                                            {
                                                              required:
                                                                _vm.form
                                                                  .externalAllFlag ===
                                                                "2"
                                                                  ? true
                                                                  : false,
                                                              message:
                                                                "请选择指定营销对象",
                                                              trigger: "change",
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _c("el-cascader", {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                props:
                                                                  _vm.props,
                                                                "form.externalLists":
                                                                  "",
                                                                clearable: "",
                                                                filterable: "",
                                                                "collapse-tags":
                                                                  "",
                                                                options:
                                                                  _vm.labelData,
                                                                placeholder:
                                                                  "请选择指定标签",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.handleTagChange,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.tagValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.tagValue =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "tagValue",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "消息内容：",
                                                      prop: "content",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            maxlength: "200",
                                                            rows: "4",
                                                            disabled:
                                                              _vm.type ==
                                                              "detail",
                                                            "show-word-limit":
                                                              "",
                                                            type: "textarea",
                                                            placeholder:
                                                              "请输入消息内容",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.content,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "content",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.content",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "发送方式：",
                                                      prop: "scheduleType",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          disabled:
                                                            _vm.type ==
                                                            "detail",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .scheduleType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "scheduleType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.scheduleType",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "TIMELY",
                                                              border: "",
                                                            },
                                                          },
                                                          [_vm._v("立即发送")]
                                                        ),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "FIXED",
                                                              border: "",
                                                            },
                                                          },
                                                          [_vm._v("定时发送")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.form.scheduleType === "FIXED"
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 24 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "发送时间：",
                                                          prop: "scheduleTime",
                                                        },
                                                      },
                                                      [
                                                        _c("el-date-picker", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.type ==
                                                              "detail",
                                                            type: "datetime",
                                                            format:
                                                              "yyyy-MM-dd HH:mm:ss",
                                                            "value-format":
                                                              "yyyy-MM-dd HH:mm:ss",
                                                            placeholder:
                                                              "请选择发送时间",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .scheduleTime,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "scheduleTime",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.scheduleTime",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeSetting,
                              callback: function ($$v) {
                                _vm.activeSetting = $$v
                              },
                              expression: "activeSetting",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title sopTemplateTitle",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "添加素材" },
                                      }),
                                      _vm.type != "detail"
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.openSopTemplate(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("SOP模版")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm.type != "detail"
                                  ? _c(
                                      "el-row",
                                      _vm._l(
                                        _vm.form.materialList,
                                        function (taskItem, taskIndex) {
                                          return _c(
                                            "el-col",
                                            {
                                              key: taskIndex,
                                              staticClass: "colCard",
                                              attrs: { span: 11 },
                                            },
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "素材类型：",
                                                            prop:
                                                              "materialList" +
                                                              "[" +
                                                              taskIndex +
                                                              "]" +
                                                              ".materialType",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择素材类型",
                                                                trigger:
                                                                  "change",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                filterable: "",
                                                                disabled:
                                                                  _vm.type ==
                                                                  "detail",
                                                                placeholder:
                                                                  "请选择素材类型",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeGetMaterialGroup(
                                                                      $event,
                                                                      taskIndex
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  taskItem.materialType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      taskItem,
                                                                      "materialType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "taskItem.materialType",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.materialGroupList,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.itemCode,
                                                                    attrs: {
                                                                      label:
                                                                        item.itemName,
                                                                      value:
                                                                        item.itemCode,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              taskItem.materialType == "ARTICLE"
                                                ? _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "消息类型：",
                                                                prop:
                                                                  "materialList" +
                                                                  "[" +
                                                                  taskIndex +
                                                                  "]" +
                                                                  ".msgType",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请选择素材类型",
                                                                    trigger:
                                                                      "change",
                                                                  },
                                                                ],
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    clearable:
                                                                      "",
                                                                    filterable:
                                                                      "",
                                                                    disabled:
                                                                      _vm.type ==
                                                                      "detail",
                                                                    placeholder:
                                                                      "请选择消息类型",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.changeMsgType(
                                                                          $event,
                                                                          taskIndex
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      taskItem.msgType,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          taskItem,
                                                                          "msgType",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "taskItem.msgType",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.articleTypeList,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: item.itemCode,
                                                                        attrs: {
                                                                          label:
                                                                            item.itemName,
                                                                          value:
                                                                            item.itemCode,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              taskItem.materialType == "FILE"
                                                ? _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "消息类型：",
                                                                prop:
                                                                  "materialList" +
                                                                  "[" +
                                                                  taskIndex +
                                                                  "]" +
                                                                  ".msgType",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请选择素材类型",
                                                                    trigger:
                                                                      "change",
                                                                  },
                                                                ],
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    clearable:
                                                                      "",
                                                                    filterable:
                                                                      "",
                                                                    disabled:
                                                                      _vm.type ==
                                                                      "detail",
                                                                    placeholder:
                                                                      "请选择消息类型",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.changeMsgType(
                                                                          $event,
                                                                          taskIndex
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      taskItem.msgType,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          taskItem,
                                                                          "msgType",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "taskItem.msgType",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.fileTypeList,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: item.itemCode,
                                                                        attrs: {
                                                                          label:
                                                                            item.itemName,
                                                                          value:
                                                                            item.itemCode,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.form.materialList[taskIndex][
                                                "materialType"
                                              ] == "ARTICLE"
                                                ? _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "素材列表：",
                                                                prop:
                                                                  "materialList" +
                                                                  "[" +
                                                                  taskIndex +
                                                                  "]" +
                                                                  ".materialId",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请选择素材类型",
                                                                    trigger:
                                                                      "change",
                                                                  },
                                                                ],
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    clearable:
                                                                      "",
                                                                    filterable:
                                                                      "",
                                                                    disabled:
                                                                      _vm.type ==
                                                                      "detail",
                                                                    placeholder:
                                                                      "请选择素材列表",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.getMaterials(
                                                                          $event,
                                                                          taskIndex
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      taskItem.materialId,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          taskItem,
                                                                          "materialId",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "taskItem.materialId",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.materialsList,
                                                                  function (
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          label:
                                                                            item.materialTitle,
                                                                          value:
                                                                            item.materialId,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.form.materialList[taskIndex][
                                                "materialType"
                                              ] == "FILE"
                                                ? _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "选择文件：",
                                                                prop:
                                                                  "materialList" +
                                                                  "[" +
                                                                  taskIndex +
                                                                  "]" +
                                                                  ".storageUrl",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请选择文件",
                                                                    trigger:
                                                                      "change",
                                                                  },
                                                                ],
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "primary",
                                                                    plain: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openImgListDialog(
                                                                          taskIndex
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 素材库选择"
                                                                  ),
                                                                ]
                                                              ),
                                                              taskItem.storageUrl
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          "margin-top":
                                                                            "10px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100px",
                                                                              height:
                                                                                "auto",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              src: taskItem.storageUrl,
                                                                              alt: "",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: { label: "" },
                                                        },
                                                        [
                                                          taskIndex ==
                                                          (_vm.form
                                                            .materialList &&
                                                            _vm.form
                                                              .materialList
                                                              .length - 1)
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "primary",
                                                                    plain: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.addTaskMaterialItem,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 新增"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.form
                                                            .materialList &&
                                                          _vm.form.materialList
                                                            .length > 1
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "danger",
                                                                    plain: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.delTaskMaterialItem(
                                                                          taskIndex
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 删除"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _vm.resMaterialList &&
                                _vm.resMaterialList.length != 0 &&
                                _vm.type == "detail"
                                  ? _c(
                                      "el-row",
                                      _vm._l(
                                        _vm.resMaterialList,
                                        function (item, index) {
                                          return _c(
                                            "el-col",
                                            {
                                              staticClass: "colCard",
                                              attrs: { span: 11 },
                                            },
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "素材类型：",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                filterable: "",
                                                                disabled:
                                                                  _vm.type ==
                                                                  "detail",
                                                                placeholder:
                                                                  "请选择素材类型",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.materialType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "materialType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.materialType",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.materialGroupList,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.itemCode,
                                                                    attrs: {
                                                                      label:
                                                                        item.itemName,
                                                                      value:
                                                                        item.itemCode,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "消息类型：",
                                                            prop: "msgType",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                filterable: "",
                                                                disabled:
                                                                  _vm.type ==
                                                                  "detail",
                                                                placeholder:
                                                                  "请选择消息类型",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.msgType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "msgType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.msgType",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.detailMsgTypeList,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.itemCode,
                                                                    attrs: {
                                                                      label:
                                                                        item.itemName,
                                                                      value:
                                                                        item.itemCode,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              item.materialType == "ARTICLE"
                                                ? _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "素材列表：",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    clearable:
                                                                      "",
                                                                    filterable:
                                                                      "",
                                                                    disabled:
                                                                      _vm.type ==
                                                                      "detail",
                                                                    placeholder:
                                                                      "请选择素材列表",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.materialId,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "materialId",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.materialId",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.materialsList,
                                                                  function (
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          label:
                                                                            item.materialTitle,
                                                                          value:
                                                                            item.materialId,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.materialType == "FILE"
                                                ? _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label: "素材：",
                                                                prop: "msgType",
                                                              },
                                                            },
                                                            [
                                                              item.storageUrl
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          "margin-top":
                                                                            "10px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100px",
                                                                              height:
                                                                                "auto",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              src: item.storageUrl,
                                                                              alt: "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.previewFile(
                                                                                  item
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "footer_box",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "footer-btn" }, [
                _c(
                  "div",
                  { staticClass: "footer-btn-list" },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "mini" }, on: { click: _vm.closePage } },
                      [_vm._v("返回")]
                    ),
                    _vm.type !== "detail"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.saveClicked },
                          },
                          [_vm._v(" 提交保存 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showSopTemplateDialog,
            "close-on-click-modal": false,
            width: "500px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSopTemplateDialog = $event
            },
            handleClose: function ($event) {
              _vm.showSopTemplateDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("选择SOP模版")]
              ),
            ]
          ),
          _c("SopTemplate", {
            attrs: { refresh: _vm.showSopTemplateDialog },
            on: {
              closePage: _vm.closeSopTemplateDialogDialog,
              confrimDialog: _vm.confrimSopTemplateDialog,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showImgListDialog,
            "close-on-click-modal": false,
            width: "1000px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImgListDialog = $event
            },
            handleClose: function ($event) {
              _vm.showImgListDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("图片素材库")]
              ),
            ]
          ),
          _c("ImgDialog", {
            attrs: { refresh: _vm.showImgListDialog },
            on: {
              closeDialog: _vm.closeDialog,
              confrimDialog: _vm.confrimDialog,
            },
          }),
        ],
        1
      ),
      _vm.preViewImg
        ? _c(
            "div",
            {
              staticClass: "image_preview",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.closePreViewImg($event)
                },
              },
            },
            [
              _c("el-image", {
                staticStyle: {
                  width: "500px",
                  height: "auto",
                  "background-color": "#FFF",
                },
                attrs: { src: _vm.previewUrl, "preview-src-list": _vm.srcList },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }