import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customerManagementApi from '@/api/customerManagement';
import { limitInput } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    },
    corpList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      form: {
        corpId: '',
        groupName: '',
        orderBy: 0,
        tags: [{
          name: '',
          orderBy: 0
        }]
      },
      rules: {
        corpId: {
          required: true,
          message: '请选择企业',
          trigger: "change"
        },
        groupName: {
          required: true,
          message: '请输入标签组名称',
          trigger: "blur"
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {
        corpId: '',
        groupName: '',
        orderBy: 0,
        tags: [{
          name: '',
          orderBy: 0
        }]
      };
      if (this.opType === "edit") {
        this.formLoading = true;
        customerManagementApi.getWecomtagsInfo(this.currentId).then(function (res) {
          _this.formLoading = false;
          if (res.code === 200) {
            _this.form = res.data;
          }
          ;
        }).catch(function (err) {
          _this.formLoading = false;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 新增子级
    addItem: function addItem() {
      var items = {
        name: null,
        orderBy: 0
      };
      this.form.tags.push(items);
    },
    // 删除子级
    deleteitem: function deleteitem(index) {
      this.form.tags.splice(index, 1);
      if (this.form.tags.length == 0) {
        this.addItem();
      }
      ;
    },
    // 对输入内容做精度校验
    onInput: function onInput(num, min, max, limit) {
      this.$forceUpdate();
      if (min && num < min) {
        num = min;
      }
      ;
      if (max && num > max) {
        num = max;
      }
      ;
      return limitInput(num, limit);
    },
    // 联系人自定义校验
    validated: function validated() {
      var _this2 = this;
      var flag = false;
      this.form.tags.some(function (val, index) {
        if (!val.name) {
          _this2.$message.warning("\u6807\u7B7E\u7B2C".concat(index + 1, "\u884C\u6807\u7B7E\u540D\u4E0D\u80FD\u4E3A\u7A7A"));
          flag = true;
          return flag;
        }
        ;
      });
      return flag;
    },
    // 保存数据
    save: function save() {
      var _this3 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this3.validated()) return;
          _this3.formLoading = true;
          var req = _this3.opType === 'add' ? customerManagementApi.createWecomtags(_this3.form) : customerManagementApi.updateWecomtags(_this3.form);
          req.then(function (res) {
            _this3.formLoading = false;
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};