/**
 * 营销管理接口
 */
import request from "@/utils/request";
import getUrl from "@/utils/get-url";
var objApi = {
  // *************************营销管理****************************
  /**
   * 分页查询
   * @param {*} data 参数
   */
  getWecomMarketingList: function getWecomMarketingList(data) {
    return request.get(getUrl("/wecomMarketing/search", 4), {
      params: data
    });
  },
  /**
   * 通过成员列表查询客户列表
   * @param {*} data 参数
   */
  getListByMember: function getListByMember(data) {
    return request.post(getUrl("/wecomenExternal/listByMember", 4), data);
  },
  /**
   * 创建营销
   * @param {*} id
   * @param {*} data
   */
  createWecomMarketing: function createWecomMarketing(data) {
    return request.post(getUrl("/wecomMarketing/create", 4), data);
  },
  /**
   * 查看详情
   * @param {*} id
   * @param {*} data
   */
  getWecomMarketingDetail: function getWecomMarketingDetail(id) {
    return request.get(getUrl("/wecomMarketing/get?id=".concat(id), 4));
  },
  /**
   * 更新
   * @param {*} id
   * @param {*} data
   */
  updateTerprise: function updateTerprise(id, data) {
    return request.post(getUrl("/wecomenTerprise/update?enterpriseId=".concat(id), 4), data);
  },
  /**
   * 更新
   * @param {*} id
   * @param {*} data
   */
  getGroupListByMember: function getGroupListByMember(data) {
    return request.post(getUrl("/wecomGroupchat/listByMember", 4), data);
  }
};
export default objApi;