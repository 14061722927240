/**
 * 产品管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
     * 列表
     * @param {*} data 参数
     */
  getList: function getList(data) {
    return request.get(getUrl("/marketing/product/search", 4), {
      params: data
    });
  },
  /**
  * 删除
  * @param {*} id
  */
  delete: function _delete(id) {
    return request.delete(getUrl("/duip/route/input/record/log/getByFlowNo?flowNo=".concat(id), 4));
  },
  /**
  * 查询
  * @param {*} id
  */
  query: function query(id) {
    return request.get(getUrl("/marketing/product/get?productId=".concat(id), 4));
  },
  /**
  * 新增
  * @param {*} data
  */
  add: function add(data) {
    return request.post(getUrl("/marketing/product/create", 4), data);
  },
  /**
  * 更新
  * @param {*} data
  * @param {*} id
  */
  updata: function updata(id, data) {
    return request.post(getUrl("/marketing/product/update?productId=".concat(id), 4), data);
  },
  /**
  * 是否启用
  * @param {*} id
  */
  toggleState: function toggleState(id, data) {
    return request.post(getUrl("/marketing/product/enabled?productId=".concat(id), 4));
  },
  /**
  * 获取所有的产品
  */
  getAllProductList: function getAllProductList() {
    return request.get(getUrl("/marketing/product/allProduct", 4));
  }
};
export default objApi;