import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
import artilceApi from '@/api/article';
import addDiadlog from '../picture/add';
export default {
  components: {
    Pagination: Pagination,
    addDiadlog: addDiadlog
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      batchDelete: false,
      preViewImg: false,
      previewUrl: '',
      srcList: [],
      showUploadDialog: false,
      formLoading: false,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      classificationIndex: null,
      checkedFile: [],
      storageFileList: [],
      classificationList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.batchDelete = false;
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.checkedFile = [];
      this.getAllTypeInfoList();
    },
    // 预览
    previewFile: function previewFile(row) {
      this.preViewImg = true;
      this.previewUrl = row.storageUrl;
      this.srcList.push(row.storageUrl);
    },
    closePreViewImg: function closePreViewImg() {
      this.preViewImg = false;
      this.previewUrl = null;
      this.srcList = [];
    },
    setClassificationIndex: function setClassificationIndex(row) {
      this.classificationIndex = row.id || null;
      this.getStrogeList();
    },
    getStrogeList: function getStrogeList() {
      var _this = this;
      var data = _objectSpread(_objectSpread({}, this.listQuery), {}, {
        'mediaTypeId': this.classificationIndex || null
      });
      artilceApi.storageSearch(data).then(function (res) {
        if (res.code == 200) {
          _this.checkedFile = [];
          _this.storageFileList = res.data.list || [];
          _this.total = res.data && res.data.page && res.data.page.recordsTotal || 0;
        }
      });
    },
    getAllTypeInfoList: function getAllTypeInfoList() {
      var _this2 = this;
      artilceApi.allTypeInfoList().then(function (res) {
        if (res.code == 200 && res.data) {
          _this2.classificationIndex = res.data[0].id || null;
          _this2.getStrogeList();
          _this2.classificationList = res.data || [];
        }
      });
    },
    // 上传文件创建素材
    createFileStorage: function createFileStorage(val) {
      var _this3 = this;
      artilceApi.createStorage(val).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success("".concat(res.msg));
          _this3.getAllTypeInfoList();
          _this3.getStrogeList();
          _this3.showUploadDialog = false;
        }
      }).catch(function (err) {
        _this3.showUploadDialog = false;
      });
    },
    closeUploadDialog: function closeUploadDialog() {
      this.showUploadDialog = false;
    },
    confrim: function confrim() {
      if (this.checkedFile.length < 1) {
        this.$message.warning('请至少选择一个素材');
        return;
      }
      ;
      var data = {
        checkedFile: this.checkedFile,
        storageFileList: this.storageFileList
      };
      this.$emit('confrimDialog', data);
    },
    cancelDelete: function cancelDelete() {
      this.checkedFile = [];
      this.$forceUpdate();
      this.batchDelete = false;
    },
    confrimBatchDelete: function confrimBatchDelete() {
      var _this4 = this;
      if (this.checkedFile.length < 1) {
        this.$message.warning('请至少选择一个素材');
        return;
      }
      ;
      this.$confirm('是否批量删除选中图片素材?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.deleteFile(_this4.checkedFile);
      }).catch(function () {
        console.log('已取消删除');
      });
    },
    deleteFile: function deleteFile(data) {
      var _this5 = this;
      artilceApi.deleteStorage(data).then(function (res) {
        if (res.code === 200) {
          _this5.$message.success(res.msg);
          _this5.getAllTypeInfoList();
          _this5.getStrogeList();
          _this5.$forceUpdate();
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage(needRefresh) {
      this.$emit('closeDialog', {
        needRefresh: needRefresh
      });
    }
  }
};