var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "存储平台名称:",
                                prop: "category",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.opType == "edit",
                                    filterable: "",
                                    placeholder: "请选择存储平台名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.category,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "category", $$v)
                                    },
                                    expression: "form.category",
                                  },
                                },
                                _vm._l(
                                  _vm.categoryList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.categoryName,
                                        value: item.categoryCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "存储桶名称:",
                                prop: "storageName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.opType == "edit",
                                  placeholder: "请输入存储桶名称",
                                  maxlength: "40",
                                  clearable: "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.form.storageName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "storageName", $$v)
                                  },
                                  expression: "form.storageName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "存储访问Key:",
                                prop: "accessKey",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入存储访问Key",
                                  maxlength: "100",
                                  clearable: "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.form.accessKey,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "accessKey", $$v)
                                  },
                                  expression: "form.accessKey",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "存储访问密钥:", prop: "secret" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入存储访问密钥",
                                  clearable: "",
                                  maxlength: "1000",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.form.secret,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "secret", $$v)
                                  },
                                  expression: "form.secret",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "存储地址:",
                                prop: "storageAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入存储地址",
                                  maxlength: "100",
                                  clearable: "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.form.storageAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "storageAddress", $$v)
                                  },
                                  expression: "form.storageAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公网下载地址:",
                                prop: "storagePublicAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入公网下载地址",
                                  maxlength: "100",
                                  clearable: "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.form.storagePublicAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "storagePublicAddress",
                                      $$v
                                    )
                                  },
                                  expression: "form.storagePublicAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公网支持SSL:",
                                prop: "storagePublicAddressSupportSsl",
                              },
                            },
                            [
                              _c("el-switch", {
                                model: {
                                  value:
                                    _vm.form.storagePublicAddressSupportSsl,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "storagePublicAddressSupportSsl",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.storagePublicAddressSupportSsl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "使用自有下载平台:",
                                prop: "usedSelfPublicAddress",
                              },
                            },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.form.usedSelfPublicAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "usedSelfPublicAddress",
                                      $$v
                                    )
                                  },
                                  expression: "form.usedSelfPublicAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "备注",
                                  maxlength: "200",
                                  clearable: "",
                                  type: "textarea",
                                  rows: 3,
                                },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "line-height": "46px",
            height: "46px",
            padding: "8px 0px",
            "margin-top": "5px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }