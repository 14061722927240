import _createForOfIteratorHelper from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import {
//     client
// } from '@/utils/alioss.js'
import knowledgeApi from '@/api/knowledge';
import storageRequest from '@/api/storage';
// import VI_CityAreaTree from '@/assets/json/VI_CityAreaTree'
export default {
  props: {
    form: {
      type: Object,
      default: function _default() {}
    },
    knowledgeTypes: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    supplyers: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    keyswordListValue: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    keywordList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    knowledgeAppraiseLists: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      inputVisible: false,
      keyswordList: this.keyswordListValue || [],
      pageContainerLoading: false,
      activeTitle: '1',
      uploadType: '.jpg,.gif,.png,.jpeg,.rar,.zip,.xls,.xlsx,.doc,.pdf,.docx',
      fileList: [],
      isView: false,
      regionData: VI_CityAreaTree || [],
      regionOptions: [],
      questionOptions: []
    };
  },
  created: function created() {
    this.getAddress();
    this.getQuestionTypeLists();
  },
  methods: {
    // 省市区
    getAddress: function getAddress() {
      var partialAddress = [];
      this.regionData.forEach(function (item, index) {
        partialAddress.push({
          label: item.label,
          value: item.value,
          // children: []
          children: item.children ? [] : null
        });
        if (item.children) {
          item.children.forEach(function (eitem) {
            partialAddress[index].children.push({
              label: eitem.label == '市辖区' ? item.label : eitem.label,
              value: eitem.value
            });
          });
        }
      });
      this.regionOptions = partialAddress;
    },
    getQuestionTypeLists: function getQuestionTypeLists() {
      var _this = this;
      knowledgeApi.getQuestionTypeList().then(function (res) {
        if (res.code == 200) {
          _this.questionOptions = res.data || [];
        }
        ;
      });
    },
    //选择省/市/区
    handleChange: function handleChange(val) {
      this.$emit('setCity', val);
    },
    setKeywordList: function setKeywordList(val) {
      var keywordsId = val.keywordsId;
      var list = _.cloneDeep(this.keywordList);
      var _iterator = _createForOfIteratorHelper(list),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var i = _step.value;
          if (i.keywordsId == keywordsId) {
            if (i.checked) {
              i.checked = false;
            } else {
              i.checked = true;
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.keywordList = list;
    },
    // 删除关键字
    handleClose: function handleClose(tag) {
      var _this2 = this;
      this.$confirm('是否删除该标签?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.keywordList.splice(_this2.keywordList.indexOf(tag), 1);
        _this2.keyswordList.splice(_this2.keyswordList.indexOf(tag), 1);
        knowledgeApi.batchDeleteKeywords(tag.keywordsId).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.$emit('getKeywordsList');
          }
          ;
        });
        _this2.$forceUpdate();
      });
    },
    // 显示输入框
    showInput: function showInput() {
      var _this3 = this;
      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this3.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 创建关键字
    handleInputConfirm: function handleInputConfirm() {
      this.inputVisible = false;
      this.form.keyword = '';
      if (this.form.keywordsContent) {
        var inputValue = this.form.keywordsContent;
        this.createKeywords({
          keywordsContent: inputValue
        });
      }
    },
    // 创建关键字接口
    createKeywords: function createKeywords(data) {
      var _this4 = this;
      knowledgeApi.createKeywords(data).then(function (res) {
        if (res.code === 200) {
          _this4.$message.success(res.msg);
          _this4.showKeyWordsDialog = false;
          _this4.$emit('getKeywordsList');
        }
      });
    },
    // 本地选择关键字列表
    setKeywordsList: function setKeywordsList(val) {
      this.$emit('KeywordLists', val);
    },
    // 自定义上传
    uploadFile: function uploadFile(file) {
      var _this5 = this;
      var formData = new FormData();
      formData.append('file', file.file);
      storageRequest.uploadLongTermFile(formData).then(function (res) {
        if (res.code === 200 && res.data) {
          _this5.form.mediaUrl = res.data.fileUrl || '';
        }
        ;
      });
    },
    // 更换
    uploadFileReplace: function uploadFileReplace(file) {
      var _this6 = this;
      this.form.mediaUrl = null;
      this.fileList = [];
      var formData = new FormData();
      formData.append('file', file.file);
      storageRequest.uploadLongTermFile(formData).then(function (res) {
        if (res.code === 200 && res.data) {
          _this6.form.mediaUrl = res.data.fileUrl || '';
        }
        ;
      });
    },
    // 判断文件类型
    beforeUpload: function beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
      var fileNameLength = file.name.split('.' + testmsg)[0] && file.name.split('.' + testmsg)[0].length;
      var arr = ['jpg', 'gif', 'png', 'jpeg', 'xls', 'xlsx', 'doc', 'pdf', 'docx'];
      if (!arr.includes(testmsg)) {
        this.$message.warning("目前支持的类型有：[.jpg|.gif|.png|.jepg|.xls|.xlsx|.doc|.pdf|.docx]");
        return false;
      } else {
        var uploadFileSize = file.size / 1024 / 1024 > 2;
        if (uploadFileSize) {
          this.$message.warning("上传附件大小不超过2M");
          return false;
        }
        ;
        if (fileNameLength > 80) {
          this.$message.warning("文件名称过长，请控制在80个字符以内");
          return false;
        }
        ;
      }
      ;
    },
    // 判断文件类型
    beforeUploadReplace: function beforeUploadReplace(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
      var fileNameLength = file.name.split('.' + testmsg)[0] && file.name.split('.' + testmsg)[0].length;
      var arr = ['jpg', 'gif', 'png', 'jpeg', 'xls', 'xlsx', 'doc', 'pdf', 'docx'];
      if (!arr.includes(testmsg)) {
        this.$message.warning("目前支持的类型有：[.jpg|.gif|.png|.jepg|.xls|.xlsx|.doc|.pdf|.docx]");
        return false;
      } else {
        var uploadFileSize = file.size / 1024 / 1024 > 2;
        if (uploadFileSize) {
          this.$message.warning("上传附件大小不超过2M");
          return false;
        }
        ;
        if (fileNameLength > 80) {
          this.$message.warning("文件名称过长，请控制在80个字符以内");
          return false;
        }
        ;
      }
      ;
      this.form.mediaUrl = null;
      this.fileList = [];
    }
  }
};