import _createForOfIteratorHelper from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import openPlatformRequest from '@/api/openPlatform';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: {}
    },
    DialogId: {
      type: String,
      default: {}
    }
  },
  data: function data() {
    return {
      addForm: {
        name: '',
        //路由组名称
        apiRouteIdList: [] //已选列表
      },

      formLoading: false,
      formRules: {
        name: [{
          required: true,
          message: '请输入路由组名称',
          trigger: 'blur'
        }]
      },
      routesData: [],
      routesList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.addForm.resetFields();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData(); //初始化数据
    this.getRouteLists(); //获取所有路由数据列表
  },

  methods: {
    getRouteLists: function getRouteLists() {
      var _this = this;
      //获取可选列表数据
      openPlatformRequest.getRouteList(1, 2147483647, {}).then(function (res) {
        if (res.code == 200) {
          if (res.data && res.data.list) {
            var resList = res.data.list;
            _this.routesList = resList;
            var data = [];
            var _iterator = _createForOfIteratorHelper(resList),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var i = _step.value;
                data.push({
                  value: i.apiRouteId,
                  desc: i.name + '(' + i.routePath + ')',
                  disabled: !i.enabled
                });
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            _this.routesData = data;
          }
        }
      });
    },
    initData: function initData() {
      var _this2 = this;
      //初始化数据
      if (this.DialogType == 'add') {
        this.addForm = {
          name: '',
          //路由组名称
          apiRouteIdList: [] //已选列表
        };
      } else {
        openPlatformRequest.getOneRouteGroup({
          id: this.DialogId
        }).then(function (res) {
          if (res.code == 200) {
            _this2.addForm = res.data;
          }
        });
      }
    },
    save: function save() {
      var _this3 = this;
      //保存数据
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          if (_this3.DialogType == 'add') {
            //新增
            openPlatformRequest.createRouteGroup(_this3.addForm).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this3.formLoading = false;
                _this3.$message.success(res.msg);
                _this3.closePage(false);
              }
            });
          } else {
            //编辑
            _this3.addForm.id = _this3.DialogId;
            openPlatformRequest.updateRouteGroup(_this3.addForm).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this3.formLoading = false;
                _this3.$message.success(res.msg);
                _this3.closePage(false);
              }
            });
          }
        }
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$refs.addForm.resetFields();
      this.$emit('closePage', boolean);
    }
  }
};