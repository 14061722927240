import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Page404',
  computed: {
    message: function message() {
      return 'The webmaster said that you can not enter this page...';
    }
  },
  methods: {
    goHome: function goHome() {
      this.$router.push('/welcome');
    }
  }
};