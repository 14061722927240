import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import knowledgeMonitoring from '@/api/knowledgeMonitoring';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    invokeMonitorId: {
      type: String,
      default: {}
    },
    ids: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      formLoading: false,
      addForm: {
        categoryId: '',
        errorAnalysisContent: ''
      },
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 剪贴板回调函数
    onCopy: function onCopy(e, msgType) {
      this.$message.success("".concat(msgType, "\u590D\u5236\u6210\u529F\uFF01"));
    },
    // 剪贴板回调函数
    onError: function onError(e, msgType) {
      this.$message.error("".concat(msgType, "\u590D\u5236\u5931\u8D25\uFF01"));
    },
    // 初始化数据
    initData: function initData() {
      var _this = this;
      if (!this.invokeMonitorId) return;
      this.formLoading = true;
      knowledgeMonitoring.getKnowledgemonitorDetail(this.invokeMonitorId).then(function (res) {
        _this.formLoading = false;
        if (res.code == 200) {
          _this.addForm = res.data;
          _this.addForm.drawingKnowledgeList.forEach(function (ele) {
            ele.url = "".concat(window.location.origin, "/autoInsurance/detail?guid=").concat(ele.knowledgeGuid);
          });
        }
        ;
      }).catch(function (err) {
        _this.formLoading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage(needRefresh) {
      this.$emit('closePage', {
        needRefresh: needRefresh
      });
    }
  }
};