import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import dataInteractionPlatformApi from '@/api/dataInteractionPlatform';
import dictRequest from "@/api/dictionary";
import { commonExport } from '@/utils/common';
export default {
  name: 'InternalSubscripMonitor',
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight],
  data: function data() {
    return {
      activeName: '1',
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showDialog: false,
      detailForm: {},
      allDataConfigList: [],
      serviceList: [],
      drawer: false
    };
  },
  created: function created() {
    this.loadData();
    this.getAllDuipdataconfigList();
    this.getDict();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '内部交互监控',
        exportUrl: '/tp-data-process-service/duip/internal/record/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    getDict: function getDict() {
      var _this = this;
      dictRequest.getDictItemsByCode("SYSTEM_MICRO_SERVICE").then(function (res) {
        if (res.code == 200) {
          _this.serviceList = res.data.map(function (item) {
            return {
              dictName: item.itemName,
              dictCode: item.itemCode
            };
          });
        }
        ;
      });
    },
    getAllDuipdataconfigList: function getAllDuipdataconfigList() {
      var _this2 = this;
      dataInteractionPlatformApi.getAllDuipdataconfigList().then(function (res) {
        if (res.code == 200) {
          _this2.allDataConfigList = res.data.map(function (item) {
            return {
              dictName: item.dataConfigName,
              dictCode: item.dataConfigCode
            };
          });
        }
        ;
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    viewDetail: function viewDetail(item) {
      this.showDialog = true;
      this.getRecordDetailById(item);
    },
    getRecordDetailById: function getRecordDetailById(item) {
      var _this3 = this;
      dataInteractionPlatformApi.getRecordDetailById(item.inputRecordId).then(function (res) {
        if (res.code == 200) {
          _this3.detailForm = _objectSpread(_objectSpread({}, res.data), {}, {
            data: JSON.parse(res.data.data)
          });
        }
        ;
      });
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this4 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      dataInteractionPlatformApi.getDuipInternaRecordList(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        if (res.code === 200) {
          _this4.loading = false;
          _this4.tableData = res.data.list;
          _this4.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this4.loading = false;
      });
    }
  }
};