import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dataInteractionPlatformApi from '@/api/dataInteractionPlatform';
import dictRequest from "@/api/dictionary";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    inputConfigId: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      form: {},
      rules: {
        dataConfigCode: {
          required: true,
          message: '请选择数据类型',
          trigger: "change"
        },
        serviceCode: {
          required: true,
          message: '请选择服务名称',
          trigger: "change"
        },
        inputConfigName: {
          required: true,
          message: '请输入数据源名称',
          trigger: "blur"
        },
        servicePath: {
          required: true,
          message: '请输入接口路径',
          trigger: "blur"
        }
      },
      serviceList: [],
      allDataConfigList: [],
      loading: false
    };
  },
  watch: {
    refresh: {
      handler: function handler(newValue) {
        if (newValue) {
          this.initData();
          this.getAllDuipdataconfigList();
          this.getDict();
        }
        ;
      },
      immediate: true
    }
  },
  methods: {
    getDetailById: function getDetailById() {
      var _this = this;
      this.loading = true;
      dataInteractionPlatformApi.getInternalConfigDetailById(this.inputConfigId).then(function (res) {
        if (res.code == 200) {
          _this.form = res.data;
        }
        _this.loading = false;
      });
    },
    getDict: function getDict() {
      var _this2 = this;
      dictRequest.getDictItemsByCode("SYSTEM_MICRO_SERVICE").then(function (res) {
        if (res.code == 200) {
          _this2.serviceList = res.data.map(function (item) {
            return {
              dictName: item.itemName,
              dictCode: item.itemCode
            };
          });
        }
      });
    },
    getAllDuipdataconfigList: function getAllDuipdataconfigList() {
      var _this3 = this;
      dataInteractionPlatformApi.getAllDuipdataconfigList().then(function (res) {
        if (res.code == 200) {
          _this3.allDataConfigList = res.data.map(function (item) {
            return {
              dictName: item.dataConfigName,
              dictCode: item.dataConfigCode
            };
          });
        }
      });
    },
    initData: function initData() {
      var _this4 = this;
      if (this.opType == 'add') {
        this.form = {
          dataConfigCode: "",
          serviceCode: "",
          inputConfigName: "",
          servicePath: "",
          realTimeFlag: true
        };
      } else {
        this.getDetailById();
      }
      this.$nextTick(function () {
        return _this4.$refs.detail.clearValidate();
      });
    },
    // 保存数据
    save: function save() {
      var _this5 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var dataConfig = _.find(_this5.allDataConfigList, function (item) {
            return item.dictCode == _this5.form.dataConfigCode;
          });
          _this5.form.dataConfigName = dataConfig && dataConfig.dictName;
          var service = _.find(_this5.serviceList, function (item) {
            return item.dictCode == _this5.form.serviceCode;
          });
          _this5.form.serviceName = service && service.dictName;
          _this5.loading = true;
          var req = _this5.opType == 'add' ? dataInteractionPlatformApi.createtDuipInternaConfig(_this5.form) : dataInteractionPlatformApi.updataDuipInternaConfig(_this5.inputConfigId, _this5.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this5.$message({
                message: res.msg,
                type: 'success'
              });
              _this5.loading = false;
              _this5.closePage(false);
            }
            ;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};