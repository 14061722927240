var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "550px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "140px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车架号：", prop: "vin" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "17",
                                  placeholder: "请输入车架号",
                                  clearable: "",
                                },
                                on: {
                                  input: _vm.checkValue,
                                  blur: _vm.vincheck,
                                },
                                model: {
                                  value: _vm.form.vin,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "vin",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.vin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车龄：", prop: "carAge" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "4",
                                  placeholder: "请输入车龄",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.carAge,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "carAge",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.carAge",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车型：",
                                prop: "modelNameSelectData",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择车型",
                                  },
                                  model: {
                                    value: _vm.form.modelNameSelectData,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "modelNameSelectData",
                                        $$v
                                      )
                                    },
                                    expression: "form.modelNameSelectData",
                                  },
                                },
                                _vm._l(_vm.modelList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.itemName,
                                      value: item.itemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.modelNameSelectData === "OTHER"
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "其他车型：",
                                    prop: "modelNameInputData",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "40",
                                      placeholder: "请输入其他车型",
                                      clearable: "",
                                    },
                                    on: { input: _vm.inputModelNameInputData },
                                    model: {
                                      value: _vm.form.modelNameInputData,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "modelNameInputData",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.modelNameInputData",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否营运车：",
                                prop: "operated",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.operated,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "operated", $$v)
                                    },
                                    expression: "form.operated",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "支付方式：", prop: "loaned" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.loaned,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "loaned", $$v)
                                    },
                                    expression: "form.loaned",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("贷款"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("全款"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "已购买的增值服务：",
                                prop: "purchasedServices",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.form.purchasedServices,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "purchasedServices",
                                        $$v
                                      )
                                    },
                                    expression: "form.purchasedServices",
                                  },
                                },
                                _vm._l(
                                  _vm.purchasedServicesList,
                                  function (item) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: item.itemCode,
                                        attrs: { label: item.itemCode },
                                      },
                                      [_vm._v(_vm._s(item.itemName))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }