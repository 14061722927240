import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import artilceApi from '@/api/article';
import dictionary from '@/api/dictionary';
import { commonExport } from '@/utils/common';
export default {
  name: "Article",
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      loading: false,
      materialGroup: [],
      searchForm: {},
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      drawer: false,
      timeoutForExport: null,
      materialClassificationLists: []
    };
  },
  created: function created() {
    this.getMaterialClassificationLists();
    this.getMaterialGroup();
    this.loadData();
  },
  methods: {
    getMaterialClassificationLists: function getMaterialClassificationLists() {
      var _this = this;
      dictionary.getDictItemsByCode('MATERIAL_CLASSIFICATION').then(function (res) {
        if (res.code === 200) {
          _this.materialClassificationLists = res.data || [];
        }
        ;
      });
    },
    getMaterialGroup: function getMaterialGroup() {
      var _this2 = this;
      dictionary.getDictItemsByCode('MATERIALTYPES').then(function (res) {
        if (res.code === 200) {
          _this2.materialGroup = res.data || [];
        }
        ;
      });
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this3 = this;
      this.loading = true;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this3.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        fileName: "文章列表",
        exportUrl: '/biz-cms-service/material/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    addClicked: function addClicked() {
      this.$router.push({
        name: 'MaterialAddArticle',
        query: {
          tabName: '新增文章',
          type: 'add'
        }
      });
    },
    // 查看
    viewDetail: function viewDetail(row) {
      var newUrl = window.location.href.split('material')[0];
      var toUrl = "".concat(newUrl, "article/detail?articleId=").concat(row.materialId);
      window.open(toUrl);
    },
    // 编辑
    editDetail: function editDetail(row) {
      this.$router.push({
        name: 'MaterialEditArticle',
        // name: 'AddArticle',
        query: {
          materialId: row.materialId,
          tabName: "\u6587\u7AE0\u7F16\u8F91-".concat(row.materialGroupDisplay, "-").concat(row.materialTitle),
          type: 'edit'
        }
      });
    },
    delArticle: function delArticle(row) {
      var _this4 = this;
      this.$confirm('是否删除该条素材?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        artilceApi.delMaterial(row.materialId).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.msg);
            _this4.loadData();
          }
          ;
        });
      }).catch(function () {});
    },
    refreshClicked: function refreshClicked() {
      this.loadData();
    },
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this5 = this;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      this.drawer = false;
      this.loading = true;
      artilceApi.getMaterial(data).then(function (res) {
        _this5.loading = false;
        if (res.code == 200) {
          var resList = res.data && res.data.list;
          _this5.tableData = resList;
          _this5.total = res.data && res.data.page && res.data.page.recordsTotal || 0;
        }
        ;
      }).catch(function (err) {
        _this5.loading = false;
      });
    }
  }
};