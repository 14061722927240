var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "app-m-cls",
          staticStyle: { padding: "13px 15px", "max-height": "91vh" },
        },
        [
          _c(
            "el-container",
            { staticStyle: { "background-color": "#fff" } },
            [
              _c(
                "el-aside",
                {
                  style: {
                    height: _vm.containerHeight + "px",
                    "background-color": "white",
                    "border-right": "1px solid #eee",
                    padding: "0 12px",
                  },
                  attrs: { width: "345px", id: "aside" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { padding: "20px 0px" } },
                    [
                      _c(
                        "div",
                        { class: { "search-fixed": _vm.searchFixed } },
                        [
                          !_vm.userInfo.data
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择企业",
                                        clearable: "",
                                      },
                                      on: { change: _vm.corpIdChange },
                                      model: {
                                        value: _vm.treeParams.corpId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.treeParams,
                                            "corpId",
                                            $$v
                                          )
                                        },
                                        expression: "treeParams.corpId",
                                      },
                                    },
                                    _vm._l(_vm.corpList, function (item) {
                                      return _c("el-option", {
                                        key: item.corpId,
                                        attrs: {
                                          label: item.corpName,
                                          value: item.corpId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("el-input", {
                            attrs: {
                              placeholder: "搜索过滤",
                              maxlength: "40",
                              clearable: "",
                            },
                            model: {
                              value: _vm.filterText,
                              callback: function ($$v) {
                                _vm.filterText = $$v
                              },
                              expression: "filterText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-tree", {
                        ref: "tree",
                        staticClass: "filter-tree",
                        staticStyle: { "padding-top": "5px" },
                        style: {
                          marginTop: _vm.searchFixed
                            ? _vm.userInfo.data
                              ? "20px"
                              : "60px"
                            : "0",
                        },
                        attrs: {
                          "expand-on-click-node": false,
                          indent: 10,
                          "node-key": "organizationId",
                          "default-checked-keys": _vm.defaultCheckedKeys,
                          "highlight-current": _vm.highlightCurrent,
                          data: _vm.treeData,
                          props: _vm.defaultProps,
                          "default-expand-all": "",
                          "filter-node-method": _vm.filterNode,
                        },
                        on: { "node-click": _vm.handleNodeClick },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-container",
                [
                  _c(
                    "el-main",
                    {
                      style: {
                        height: _vm.containerHeight,
                        "background-color": "white",
                      },
                    },
                    [
                      _vm.corpName
                        ? _c(
                            "div",
                            {
                              staticClass: "flex-between",
                              staticStyle: { "margin-bottom": "10px" },
                            },
                            [
                              _c("b", [_vm._v(_vm._s(_vm.corpName))]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "max-width": "100px",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v("成员名称：")]
                                  ),
                                  _c("el-input", {
                                    staticStyle: { flex: "1" },
                                    attrs: {
                                      placeholder: "请输入成员名称",
                                      maxlength: "40",
                                      "prefix-icon": "el-icon-search",
                                      clearable: "",
                                    },
                                    on: { change: _vm.memberNameChange },
                                    model: {
                                      value: _vm.memberParams.memberName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.memberParams,
                                          "memberName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "memberParams.memberName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            data: _vm.memberData,
                            height: _vm.containerHeight - 60 + "px",
                            border: "",
                            "header-cell-style": {
                              background: "#F7F7F7",
                              color: "#515151",
                            },
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "memberName",
                              label: "成员名称",
                              "min-width": "150",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.memberName)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "departmentName",
                              label: "所属部门",
                              "min-width": "150",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "leaderDepartmentName",
                              label: "所管部门",
                              "min-width": "150",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "licenseActiveType",
                              label: "激活形式",
                              "min-width": "150",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.licenseActiveType ==
                                            "FOUNDATION"
                                            ? "基础"
                                            : scope.row.licenseActiveType ==
                                              "CURRENT"
                                            ? "通用"
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "authStatusDisplay",
                              label: "授权状态",
                              "min-width": "120",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-switch", {
                                      attrs: {
                                        "active-value": true,
                                        "active-text": scope.row.authStatus
                                          ? "已授权"
                                          : "未授权",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.enabledChange(scope.row)
                                        },
                                      },
                                      model: {
                                        value: scope.row.authStatus,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "authStatus", $$v)
                                        },
                                        expression: "scope.row.authStatus",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "mobile",
                              label: "手机号",
                              "min-width": "150",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: "140",
                              align: "center",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.addActiveType(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("添加激活形式")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showActiveTypeDialog,
            "close-on-click-modal": false,
            width: "520px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showActiveTypeDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [
                  _vm._v(
                    _vm._s(_vm.pageDialogType === "edit" ? "编辑" : "新增") +
                      "产品"
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "app-m-cls",
              staticStyle: {},
            },
            [
              _c(
                "div",
                {
                  ref: "header",
                  staticClass: "header-cls",
                  staticStyle: { "max-height": "500px", overflow: "auto" },
                },
                [
                  _c(
                    "el-card",
                    { attrs: { shadow: "never" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "detail",
                          staticStyle: { "margin-bottom": "-20px" },
                          attrs: {
                            model: _vm.activeTypeForm,
                            rules: _vm.activeTypeFormRules,
                            "label-width": "110px",
                            size: "mini",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "激活形式：",
                                        prop: "type",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请选择激活形式",
                                          },
                                          model: {
                                            value:
                                              _vm.activeTypeForm
                                                .licenseActiveType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.activeTypeForm,
                                                "licenseActiveType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeTypeForm.licenseActiveType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.licenseActiveTypeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.itemCode,
                                              attrs: {
                                                label: item.itemName,
                                                value: item.itemCode,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "激活码：",
                                        prop: "activeCode",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          placeholder: "请输入激活码",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.activeTypeForm.activeCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.activeTypeForm,
                                              "activeCode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "activeTypeForm.activeCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-top": "8px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-check",
                        type: "primary",
                        plain: "",
                        size: "mini",
                      },
                      on: { click: _vm.saveActiveForm },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-close", size: "mini" },
                      on: { click: _vm.closeActiveForm },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }