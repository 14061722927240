import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import knowledgeApi from '@/api/knowledge';
export default {
  data: function data() {
    return {
      showEditPageCurrentNum: false,
      activeName: 'all',
      loading: false,
      showViewImg: false,
      tableData: [],
      examineStatusList: [{
        code: 'KEE01',
        name: '待审核'
      }],
      pageCurrentNum: 1,
      pageCurrentNums: 1,
      pageNumTotals: 1
    };
  },
  created: function created() {
    document.title = '车险知识审核列表';
    if (sessionStorage.getItem('activeName')) {
      this.activeName = sessionStorage.getItem('activeName');
    }
    this.loadData();
  },
  methods: {
    // 上一页
    prevClicked: function prevClicked(val) {
      if (this.pageCurrentNum == 1) {
        this.pageCurrentNum = 1;
        return;
      } else {
        this.pageCurrentNum--;
      }
      this.loadData();
    },
    // 下一页
    nextClicked: function nextClicked(val) {
      if (this.pageCurrentNum == this.pageNumTotals) {
        this.pageCurrentNum = this.pageNumTotals;
        return;
      } else {
        this.pageCurrentNum++;
      }
      this.loadData();
    },
    // 跳转至多少页
    toPageNum: function toPageNum() {
      this.showEditPageCurrentNum = true;
      // this.pageCurrentNum++;
    },
    jumpToPageNum: function jumpToPageNum() {
      if (this.pageCurrentNums > this.pageNumTotals) {
        this.pageCurrentNum = this.pageNumTotals;
        this.pageCurrentNums = this.pageNumTotals;
        this.$message.warning('超出了最大页数');
        return;
      } else if (this.pageCurrentNums < 1) {
        this.$message.warning('最小页数不能小于1');
        this.pageCurrentNum = 1;
        this.pageCurrentNums = 1;
        return;
      } else {
        this.pageCurrentNum = this.pageCurrentNums;
      }
      this.loadData();
    },
    handleClick: function handleClick() {
      this.activeName;
      this.loadData();
    },
    // 获取列表
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      var data = {
        examineStatus: this.activeName == 'all' ? null : this.activeName == 'audit' ? 'KEE01' : 'KEE02,KEE03',
        pageNum: this.pageCurrentNum,
        pageSize: 20
      };
      knowledgeApi.getKnowledgeList(data).then(function (res) {
        _this.loading = false;
        if (res.code == 200) {
          var resList = res.data && res.data.list;
          _this.tableData = resList;
          _this.total = res.data && res.data.page && res.data.page.recordsTotal || 0;
          _this.pageCurrentNum = res.data && res.data.page && res.data.page.pageNum || 0;
          _this.pageNumTotals = res.data && res.data.page && res.data.page.pageTotal || 0;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 去详情
    toDetail: function toDetail(row) {
      if (row.guid) {
        sessionStorage.setItem('activeName', this.activeName);
        this.$router.push({
          name: 'knowledgeAuditorDetail',
          query: {
            guid: row.guid
          }
        });
      }
    },
    // 去审核
    toEdit: function toEdit(row) {
      if (row.guid) {
        sessionStorage.setItem('activeName', this.activeName);
        this.$router.push({
          name: 'knowledgeAuditorDetail',
          query: {
            guid: row.guid,
            audit: "'6D8F'"
          }
        });
      }
    },
    logout: function logout() {
      var _this2 = this;
      this.$confirm('确认退出重新登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(function () {
        sessionStorage.clear();
        localStorage.clear();
        _this2.$router.replace({
          name: "knowledgeAuditorLogin"
        });
      }).catch(function () {});
    }
  }
};