/**
 * 欢迎语
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 分页列表搜索(分页)
   */
  getWelcomeSpeechSearch: function getWelcomeSpeechSearch(data) {
    return request.get(getUrl("/wecom/welcome/config/search", 4), {
      params: data
    });
  },
  /**
   * 欢迎语--详情
   */
  getWelcomeSpeechDetail: function getWelcomeSpeechDetail(id) {
    return request.get(getUrl("/wecom/welcome/config/get?id=".concat(id), 4));
  },
  /**
   * 欢迎语--创建
   */
  welcomeSpeechCreate: function welcomeSpeechCreate(data) {
    return request.post(getUrl("/wecom/welcome/config/create", 4), data);
  },
  /**
   * 欢迎语--删除
   */
  welcomeSpeechDel: function welcomeSpeechDel(data) {
    return request.post(getUrl("/wecom/welcome/config/batchDelete", 4), data);
  },
  /**
   * 欢迎语--更新
   */
  welcomeSpeechUpdate: function welcomeSpeechUpdate(id, data) {
    return request.post(getUrl("/wecom/welcome/config/update?id=".concat(id), 4), data);
  }
};
export default objApi;