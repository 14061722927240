import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
import artilceApi from '@/api/article';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      formLoading: false,
      addTypeForm: {},
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      classificationList: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      showAddTypeDialog: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.loadData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    addType: function addType() {
      this.showAddTypeDialog = true;
    },
    allTypeInfoList: function allTypeInfoList() {
      var _this = this;
      artilceApi.allTypeInfoList().then(function (res) {
        if (res.code == 200 && res.data) {
          _this.classificationList = res.data || [];
        }
      });
    },
    refreshClicked: function refreshClicked() {
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      var data = _objectSpread({}, this.listQuery);
      artilceApi.typeSearch().then(function (res) {
        if (res.code == 200 && res.data) {
          _this2.tableData = res.data.list || [];
          _this2.total = res.data.page && res.data.page.recordsTotal || 0;
        }
      });
    },
    // 新增分类
    saveAddType: function saveAddType(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          artilceApi.createType(_this3.addTypeForm).then(function (res) {
            if (res.code == 200) {
              _this3.loadData();
              _this3.$message.success(res.msg);
              _this3.showAddTypeDialog = false;
            }
          });
        }
      });
    },
    // 关闭新增
    closeAddType: function closeAddType() {
      this.$refs.addForm.resetFields();
      this.showAddTypeDialog = false;
    },
    // 删除分类
    delType: function delType(row) {
      var _this4 = this;
      this.$confirm('是否删除该分类?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = [row.id];
        console.log(data);
        artilceApi.deleteType(data).then(function (res) {
          console.log(res);
          if (res.code === 200) {
            _this4.loadData();
            _this4.$message.success(res.msg);
          }
        });
      }).catch(function () {});
    },
    // 初始化数据
    initData: function initData() {
      this.allTypeInfoList();
      this.loadData();
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.$emit('loadForm', _this5.addForm);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};