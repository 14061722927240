//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import openPlatformRequest from '@/api/openPlatform';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: {}
    },
    DialogId: {
      type: String,
      default: {}
    }
  },
  data: function data() {
    return {
      addForm: {
        httpMethod: '',
        //单选框路由类型
        name: '',
        routePath: '',
        enabled: true
      },
      formLoading: false,
      formRules: {
        httpMethod: [{
          required: true,
          message: '请选择路由类型',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入路由名称',
          trigger: 'blur'
        }],
        routePath: [{
          required: true,
          message: '请输入路由地址',
          trigger: 'blur'
        }],
        enabled: [{
          required: true,
          message: '请选择是否启用',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.addForm.resetFields();
        this.addForm.enabled = true;
      }
    }
  },
  mounted: function mounted() {
    this.initData();
    this.addForm.enabled = true;
  },
  methods: {
    initData: function initData() {
      var _this = this;
      //初始化数据
      if (this.DialogType == 'add') {
        this.addForm = {
          httpMethod: '',
          //单选框路由类型
          name: '',
          routePath: '',
          enabled: true
        };
      } else {
        openPlatformRequest.getOneRoute({
          id: this.DialogId
        }).then(function (res) {
          if (res.code == 200) {
            _this.addForm = res.data;
          }
        });
      }
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          if (_this2.DialogType == 'add') {
            openPlatformRequest.createRoute(_this2.addForm).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this2.formLoading = false;
                _this2.$message.success(res.msg);
                _this2.closePage(false);
              }
            });
          } else {
            _this2.addForm.id = _this2.DialogId;
            openPlatformRequest.updateRoute(_this2.addForm).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this2.formLoading = false;
                _this2.$message.success(res.msg);
                _this2.closePage(false);
              }
            });
          }
        }
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$refs.addForm.resetFields();
      this.$emit('closePage', boolean);
    }
  }
};