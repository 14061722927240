var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageContainerLoading,
              expression: "pageContainerLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 120px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.formRules,
                          "label-width": "140px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTitle,
                              callback: function ($$v) {
                                _vm.activeTitle = $$v
                              },
                              expression: "activeTitle",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "文章标题" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 16 } },
                                      [
                                        _c("CommonForm", {
                                          ref: "CommonForm",
                                          attrs: {
                                            form: _vm.form,
                                            knowledgeTypes: _vm.knowledgeTypes,
                                            supplyers: _vm.supplyers,
                                            knowledgeAppraiseLists:
                                              _vm.knowledgeAppraiseLists,
                                            keyswordListValue:
                                              _vm.keyswordListValue,
                                            keywordList: _vm.keywordList,
                                          },
                                          on: {
                                            setCity: _vm.getCityCode,
                                            KeywordLists: _vm.setKeywordLists,
                                            getKeywordsList:
                                              _vm.getKeywordsList,
                                          },
                                        }),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "文章内容：",
                                                    },
                                                  },
                                                  [
                                                    _c("wangEdit", {
                                                      ref: "autoInsuranceEditor",
                                                      attrs: {
                                                        htmlContent:
                                                          _vm.form
                                                            .knowledgeBody,
                                                      },
                                                      on: {
                                                        setHtml: _vm.setHtml,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "previewBox",
                                        attrs: { span: 8 },
                                      },
                                      [
                                        _c("div", { staticClass: "preview" }, [
                                          _c("div", {
                                            staticClass: "conetnt",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.form.knowledgeBody &&
                                                  _vm.form.knowledgeBody.replace(
                                                    /\<img/gi,
                                                    "<img style='max-width: 100%;height:auto;display:block;margin-bottom:10px'"
                                                  )
                                              ),
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "footer_box",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "footer-btn" }, [
                _c(
                  "div",
                  { staticClass: "footer-btn-list" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", icon: "el-icon-back" },
                        on: { click: _vm.closePage },
                      },
                      [_vm._v("返回")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          icon: "el-icon-check",
                          type: "primary",
                        },
                        on: { click: _vm.saveClicked },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.form.examineStatus === "KEE03"
                                ? "提交审核"
                                : "保存"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }