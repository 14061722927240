/**
 * 素材库文章管理
 */
import request from "@/utils/request";
import getUrl from "@/utils/get-url";
var objApi = {
  /**
   * 素材库--不分页查询
   */
  getMaterialList: function getMaterialList(data) {
    return request.get(getUrl("/material/list", 8), {
      params: data
    });
  },
  /**
   * 素材库--分页查询
   */
  getMaterial: function getMaterial(data) {
    return request.get(getUrl("/material/search", 8), {
      params: data
    });
  },
  /**
   * 素材库--新增
   */
  createMaterial: function createMaterial(data) {
    return request.post(getUrl("/material/create", 8), data);
  },
  /**
   * 素材库--查看
   */
  getMaterialDetail: function getMaterialDetail(id) {
    return request.get(getUrl("/material/get?id=".concat(id), 8));
  },
  /**
   * 素材库--编辑更新
   */
  updateMaterial: function updateMaterial(data) {
    return request.post(getUrl("/material/update", 8), data);
  },
  /**
   * 素材库--删除
   */
  delMaterial: function delMaterial(ids) {
    return request.delete(getUrl("/material/batchDelete/".concat(ids), 8));
  },
  /**
   * 查询指定文章的浏览用户记录
   * @param {*} data
   */
  listUserByMaterial: function listUserByMaterial(data) {
    return request.get(getUrl("/scrmSearch/listUserByMaterial", 9), {
      params: data
    });
  },
  /**
   * 获取所有标签
   */
  getMaterialLabelList: function getMaterialLabelList() {
    return request.get(getUrl("/material/label/list", 8));
  },
  /**
   * 新增标签
   * @param {*} data
   */
  createMaterialLabel: function createMaterialLabel(data) {
    return request.post(getUrl("/material/label/create", 8), data);
  },
  /**
   * 删除一条标签
   * @param {*} id
   */
  deleteMaterialLabel: function deleteMaterialLabel(id) {
    return request.post(getUrl("/material/label/delete?id=".concat(id), 8));
  },
  /**
   * 获取所有的媒体类型分类
   * @param {*} data
   */
  allTypeInfoList: function allTypeInfoList(data) {
    return request.get(getUrl("/media/type/allTypeInfo", 4), {
      params: data
    });
  },
  /**
   * 创建类型分类
   * @param {*} data
   */
  createType: function createType(data) {
    return request.post(getUrl("/media/type/create", 4), data);
  },
  /**
   * 删除类型分类
   * @param {*} data
   */
  deleteType: function deleteType(data) {
    return request.post(getUrl("/media/type/delete", 4), data);
  },
  /**
   * 图片素材库分类不分页查询
   * @param {*} data
   */
  typeList: function typeList(data) {
    return request.get(getUrl("/media/type/list", 4), {
      params: data
    });
  },
  /**
   * 图片素材库分类分页查询
   * @param {*} data
   */
  typeSearch: function typeSearch(data) {
    return request.get(getUrl("/media/type/search", 4), {
      params: data
    });
  },
  /**
   * 分页查询媒体资源存储记录
   * @param {*} data
   */
  storageSearch: function storageSearch(data) {
    return request.get(getUrl("/media/storage/search", 4), {
      params: data
    });
  },
  /**
   * 分页查询媒体资源存储记录
   * @param {*} data
   */
  storageList: function storageList(data) {
    return request.post(getUrl("/media/storage/list ", 4), data);
  },
  /**
   * 上传文件
   * @param {*} data
   */
  createStorage: function createStorage(data) {
    return request.post(getUrl("/media/storage/create ", 4), data);
  },
  /**
   * 删除文件
   * @param {*} data
   */
  deleteStorage: function deleteStorage(data) {
    return request.post(getUrl("/media/storage/delete ", 4), data);
  }
};
export default objApi;