import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import customerManagementApi from '@/api/customerManagement';
import { character, commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'CustomersTracks',
  components: {
    Pagination: Pagination
    // OrgSelect
  },

  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      startEndTime: null,
      loading: false,
      highlightCurrent: true,
      users: [],
      followUpStartEndTime: [getBeforeDate(30), getCurrentDate()]
    };
  },
  watch: {
    '$route': {
      handler: function handler(val) {
        switch (val.path) {
          // 跟进明细
          case "/customers/tracks":
            this.searchForm = {
              isMy: null
            };
            this.followUpStartEndTime = [getBeforeDate(30), getCurrentDate()];
            break;
          // 我的跟进
          case "/customers/myTracks":
            this.searchForm = {
              isMy: true
            };
            this.followUpStartEndTime = [getBeforeDate(30), getCurrentDate()];
            break;
          default:
            return;
        }
        ;
        this.tableData = [];
        this.total = 0;
        this.listQuery = {
          pageNum: 1,
          pageSize: 20
        };
        this.orgForm = {
          salesOrgCode: '',
          salesOrgName: ''
        };
        this.loadData();
      },
      immediate: true
    }
  },
  created: function created() {
    this.tableData = [];
    this.total = 0;
    this.listQuery = {
      pageNum: 1,
      pageSize: 20
    };
    this.orgForm = {
      salesOrgCode: '',
      salesOrgName: ''
    };
    this.users = [];
    this.summaryData = {
      DEFEAT: 0,
      INVALID: 0,
      TRACKING: 0,
      SUCCEED: 0
    };
    this.loadData();
  },
  methods: {
    // 日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = '';
        this.searchForm[end] = '';
      }
      ;
    },
    breakData: function breakData() {
      this.searchData();
    },
    //导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: "成交记录",
        exportUrl: '/biz-scrm-service/wecomexternaltrack/transaction',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      customerManagementApi.getTransactionRecordsList(data).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data.list;
        _this.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 列表行选择事件
    getUsers: function getUsers(val) {
      this.users = val;
    },
    // 监听选择业务员事件
    salemanNameChange: function salemanNameChange(val) {
      if (val) {
        var _user = _.find(this.users, function (item) {
          return item.userId == val;
        });
        if (_user) {
          this.searchForm.ownerBy = _user.userId;
          this.searchForm.ownerByName = _user.userName;
          this.$forceUpdate();
        }
      }
    },
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    }
  }
};