//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sysManageApi from "@/api/sysManage";
export default {
  props: {
    currentId: {
      type: String,
      default: ''
    },
    opType: {
      type: String,
      default: ""
    },
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      form: {
        accessKey: '',
        category: '',
        remarks: '',
        secret: '',
        storageAddress: '',
        storageName: '',
        storagePublicAddress: '',
        storagePublicAddressSupportSsl: false,
        usedSelfPublicAddress: false
      },
      loading: false,
      categoryList: [{
        categoryCode: 'ALI',
        categoryName: '阿里云OSS'
      }, {
        categoryCode: 'TENCENT',
        categoryName: '腾讯云OSS'
      }, {
        categoryCode: 'MIN_IO',
        categoryName: 'MinIO OSS'
      }, {
        categoryCode: 'QINIU',
        categoryName: '七牛云OSS'
      }, {
        categoryCode: 'HUAWEI',
        categoryName: '华为云OBS'
      }],
      formRules: {
        accessKey: [{
          required: true,
          message: '请输入存储访问Key',
          trigger: 'blur'
        }],
        category: [{
          required: true,
          message: '请选择存储平台名称',
          trigger: 'change'
        }],
        secret: [{
          required: true,
          message: '请输入存储访问密钥',
          trigger: 'blur'
        }],
        storageAddress: [{
          required: true,
          message: '请输入存储地址',
          trigger: 'blur'
        }],
        storageName: [{
          required: true,
          message: '请输入存储桶名称',
          trigger: 'blur'
        }],
        storagePublicAddress: [{
          required: true,
          message: '请输入公网下载地址',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        if (this.opType != 'add') {
          this.initData();
        } else {
          this.form = {
            accessKey: '',
            category: '',
            remarks: '',
            secret: '',
            storageAddress: '',
            storageName: '',
            storagePublicAddress: '',
            storagePublicAddressSupportSsl: false,
            usedSelfPublicAddress: false
          };
          this.$refs['detail'].resetFields();
        }
      }
      ;
    }
  },
  created: function created() {
    if (this.opType == 'edit') {
      this.initData();
    }
  },
  mounted: function mounted() {},
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      if (!this.currentId) return;
      this.loading = true;
      sysManageApi.getData(this.currentId).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.form = res.data;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
        _this.form = {};
      });
    },
    save: function save() {
      var _this2 = this;
      this.$refs["detail"].validate(function (valid, object) {
        if (valid) {
          _this2.loading = true;
          var saveData = _this2.opType == 'add' ? sysManageApi.addData(_this2.form) : sysManageApi.editData(_this2.currentId, _this2.form);
          saveData.then(function (res) {
            _this2.loading = false;
            if (res.code == 200) {
              _this2.$message.success(res.msg);
              _this2.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this2.loading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};