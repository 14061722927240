var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls gift-maintain" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入客户",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.externalUserName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "externalUserName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.externalUserName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "企业全称" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入企业全称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.corpFullName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "corpFullName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.corpFullName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "性别" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择性别",
                                    },
                                    model: {
                                      value: _vm.searchForm.gender,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "gender", $$v)
                                      },
                                      expression: "searchForm.gender",
                                    },
                                  },
                                  _vm._l(
                                    _vm.genderList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "添加时间" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "start-placeholder": "起期",
                                    "end-placeholder": "止期",
                                    type: "daterange",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    "unlink-panels": "",
                                    "range-separator": "——",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.dateChange(
                                        "addTimeStart",
                                        "addTimeEnd",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.addStartEndTime,
                                    callback: function ($$v) {
                                      _vm.addStartEndTime = $$v
                                    },
                                    expression: "addStartEndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("客户：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入客户",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.externalUserName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "externalUserName", $$v)
                            },
                            expression: "searchForm.externalUserName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("企业全称：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入企业全称",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.corpFullName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "corpFullName", $$v)
                            },
                            expression: "searchForm.corpFullName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("性别：")]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择性别",
                            },
                            model: {
                              value: _vm.searchForm.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "gender", $$v)
                              },
                              expression: "searchForm.gender",
                            },
                          },
                          _vm._l(_vm.genderList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("添加时间：")]),
                        _c("el-date-picker", {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "start-placeholder": "起期",
                            "end-placeholder": "止期",
                            type: "daterange",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            "unlink-panels": "",
                            "range-separator": "——",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.dateChange(
                                "addTimeStart",
                                "addTimeEnd",
                                $event
                              )
                            },
                          },
                          model: {
                            value: _vm.addStartEndTime,
                            callback: function ($$v) {
                              _vm.addStartEndTime = $$v
                            },
                            expression: "addStartEndTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.add("add")
                              },
                            },
                          },
                          [_vm._v("新增")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportExcels },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightJD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "tagClassificationName",
                        label: "标签组名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "externalUserName",
                        label: "客户",
                        "min-width": "300",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "user-box" },
                                [
                                  _c("el-image", {
                                    staticClass: "user-img",
                                    attrs: {
                                      src: scope.row.avatar,
                                      "preview-src-list": [scope.row.avatar],
                                    },
                                  }),
                                  _c("span", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.externalUserName)
                                      ),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#2150a3",
                                          margin: "0 3px",
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.corpName))]
                                    ),
                                    scope.row.type == 1
                                      ? _c("span", { staticClass: "wx" }, [
                                          _vm._v("@微信"),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "tagsList",
                        label: "标签",
                        "min-width": "250",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(
                              scope.row.groupChatTagVOList,
                              function (item, index) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    staticStyle: { margin: "0 5px 5px 0" },
                                  },
                                  [_vm._v(_vm._s(item.tagName))]
                                )
                              }
                            )
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        width: "160",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toEdit(scope.row, "edit")
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toDelete(scope.row, "edit")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAddLabelDialog,
            "close-on-click-modal": false,
            width: "550px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddLabelDialog = $event
            },
            handleClose: function ($event) {
              _vm.showDistributeDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("新增标签分组和标签")]
              ),
            ]
          ),
          _c("AddTagDialog", {
            attrs: {
              corpList: _vm.corpList,
              dialogType: _vm.dialogType,
              labelId: _vm.labelId && _vm.labelId.toString(),
              refresh: _vm.showAddLabelDialog,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }