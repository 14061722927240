import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
// import artilceApi from '@/api/article'
import dictionary from '@/api/dictionary';
import knowledgeApi from '@/api/knowledge';
import VI_CityAreaTree from '@/assets/json/VI_CityAreaTree';
import { getSupplyers, commonExport } from '@/utils/common';
export default {
  name: "Article",
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      auditDialog: false,
      loading: false,
      materialGroup: [{
        itemCode: 'ARTICLE',
        itemName: '文章'
      }, {
        itemCode: 'VIDEO',
        itemName: '视频'
      }],
      examineStatusList: [],
      publishedList: [{
        itemCode: 'true',
        itemName: '是'
      }, {
        itemCode: 'false',
        itemName: '否'
      }],
      searchForm: {},
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      drawer: false,
      supplyers: [],
      knowledgeAppraiseLists: [],
      timeoutForExport: null,
      regionData: VI_CityAreaTree || [],
      regionOptions: [],
      questionOptions: [],
      insCityCode: []
    };
  },
  created: function created() {
    this.getQuestionTypeLists();
    this.getKnowledgeAppraiseLists();
    this.getExamineStatusList();
    this.loadData();
    this.supplyers = getSupplyers();
  },
  methods: {
    // 获取省市编码
    getCityCode: function getCityCode(val) {
      if (val) {
        this.searchForm.provinceCode = val && val[0]; //省
        this.searchForm.cityCode = val && val[1]; //市
      }
    },
    // 获取问题出现原因
    getQuestionTypeLists: function getQuestionTypeLists() {
      var _this = this;
      knowledgeApi.getQuestionTypeList().then(function (res) {
        if (res.code == 200) {
          _this.questionOptions = res.data || [];
        }
        ;
      });
    },
    getKnowledgeAppraiseLists: function getKnowledgeAppraiseLists() {
      var _this2 = this;
      dictionary.getDictItemsByCode('KNOWLEDGEAPPRAISELISTS').then(function (res) {
        if (res.code === 200) {
          _this2.knowledgeAppraiseLists = res.data || [];
        }
        ;
      });
    },
    getExamineStatusList: function getExamineStatusList() {
      var _this3 = this;
      dictionary.getDictItemsByCode('EXAMINESTATUSLIST').then(function (res) {
        if (res.code === 200) {
          _this3.examineStatusList = res.data || [];
        }
        ;
      });
    },
    getMaterialGroup: function getMaterialGroup() {
      var _this4 = this;
      dictionary.getDictItemsByCode('MATERIALTYPES').then(function (res) {
        if (res.code === 200) {
          _this4.materialGroup = res.data || [];
        }
        ;
      });
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this5 = this;
      this.loading = true;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this5.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        fileName: "车险知识审核列表",
        exportUrl: '/biz-cms-service/knowledge/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    addClicked: function addClicked() {
      this.$router.push({
        name: 'MaterialAutoInsuranceAdd',
        query: {
          tabName: '新增车险知识',
          type: 'add'
        }
      });
    },
    // 编辑
    editDetail: function editDetail(row) {
      this.$router.push({
        name: 'MaterialAutoInsuranceEdit',
        query: {
          materialId: row.materialId,
          tabName: "\u8F66\u9669\u77E5\u8BC6\u7F16\u8F91-".concat(row.materialGroupDisplay, "-").concat(row.materialTitle),
          type: 'edit'
        }
      });
    },
    // 查看
    viewDetail: function viewDetail(row) {
      var newUrl = window.location.href.split('material')[0];
      var toUrl = "".concat(newUrl, "autoInsurance/detail?guid=").concat(row.guid, "&audit='6D8F'");
      window.open(toUrl);
    },
    // 下架
    undercarriage: function undercarriage(row) {
      var newUrl = window.location.href.split('material')[0];
      var toUrl = "".concat(newUrl, "autoInsurance/detail?guid=").concat(row.guid, "&audit='2A3C'");
      window.open(toUrl);
    },
    delArticle: function delArticle(row) {
      var _this6 = this;
      this.$confirm('是否删除该条素材?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        artilceApi.delMaterial(row.materialId).then(function (res) {
          if (res.code === 200) {
            _this6.$message.success(res.msg);
            _this6.loadData();
          }
          ;
        });
      }).catch(function () {});
    },
    refreshClicked: function refreshClicked() {
      this.loadData();
    },
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this7 = this;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      this.drawer = false;
      this.loading = true;
      knowledgeApi.getKnowledgeList(data).then(function (res) {
        _this7.loading = false;
        if (res.code == 200) {
          var resList = res.data && res.data.list;
          _this7.tableData = resList;
          _this7.total = res.data && res.data.page && res.data.page.recordsTotal || 0;
        }
        ;
      }).catch(function (err) {
        _this7.loading = false;
      });
    }
  }
};