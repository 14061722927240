import _objectSpread from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_@babel_runtime@7.23.1@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import groupChatLabelApi from '@/api/groupChatLabel';
import sysManageApi from '@/api/sysManage';
import AddGroupTagDialog from './dialog/addGroupTagDialog';
export default {
  name: 'CustomerLabel',
  components: {
    Pagination: Pagination,
    AddGroupTagDialog: AddGroupTagDialog
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      multipleSelection: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      drawer: false,
      searchForm: {
        groupChatTagId: ''
      },
      tableData: [],
      loading: false,
      vehicleForm: {},
      vehicleFormLoading: false,
      netUserVehicleId: null,
      typeList: [],
      dialogType: '',
      labelId: '',
      showAddGroupLabelDialog: false,
      showLabelDialog: false,
      corpList: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      labelData: []
    };
  },
  created: function created() {
    this.getGroupChatTagClassificationList();
    this.getCorpList();
    this.loadData();
  },
  methods: {
    //   获取所有标签
    getGroupChatTagClassificationList: function getGroupChatTagClassificationList() {
      var _this = this;
      groupChatLabelApi.getGroupChatTagClassificationsList().then(function (res) {
        if (res.code == 200 && res.data) {
          var resData = res.data || [];
          var tagData = [];
          var _iterator = _createForOfIteratorHelper(resData),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              if (i.groupChatTagVOList && i.groupChatTagVOList.length != 0) {
                var _iterator2 = _createForOfIteratorHelper(i.groupChatTagVOList),
                  _step2;
                try {
                  for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                    var VOItem = _step2.value;
                    VOItem.id = VOItem.id;
                    VOItem.label = VOItem.tagName || '';
                  }
                } catch (err) {
                  _iterator2.e(err);
                } finally {
                  _iterator2.f();
                }
                ;
              }
              ;
              tagData.push({
                id: i.id,
                label: i.tagClassificationName || '',
                children: i.groupChatTagVOList || []
              });
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          ;
          _this.labelData = tagData || [];
        }
      });
    },
    // 点击标签树筛选群
    handleNodeClick: function handleNodeClick(data) {
      if (!data.children) {
        this.loading = true;
        this.searchForm.groupChatTagId = data.id;
        this.loadData();
      }
    },
    // 获取企业列表
    getCorpList: function getCorpList() {
      var _this2 = this;
      sysManageApi.getAllWecomCorpConfigList().then(function (res) {
        if (res.code == 200) {
          _this2.corpList = res.data || [];
        }
      });
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      // this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 新增
    add: function add(type) {
      this.dialogType = type;
      this.showAddGroupLabelDialog = true;
    },
    // 编辑
    edit: function edit(type, item) {
      this.dialogType = type;
      this.labelId = item.id.toString();
      this.showLabelDialog = true;
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showAddGroupLabelDialog = false;
      this.showLabelDialog = false;
      if (isDialogNoRefresh) return;
      this.drawer = false;
      this.loading = true;
      setTimeout(function () {
        var data = _objectSpread(_objectSpread({}, _this3.listQuery), {}, {
          groupChatTagId: _this3.searchForm.groupChatTagId
        });
        groupChatLabelApi.getGroupByChatTagSearch(data).then(function (res) {
          _this3.loading = false;
          if (res.code == 200 && res.data) {
            _this3.tableData = res.data && res.data.list || [];
            _this3.total = res.data.page.recordsTotal || 0;
          }
        });
      }, 600);
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 全选群
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 删除
    batchDelete: function batchDelete() {
      var _this4 = this;
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var isBatch = arguments.length > 1 ? arguments[1] : undefined;
      // isBatch 是否批量删除
      var ids = [];
      if (!_.isEmpty(row) && !isBatch) {
        ids.push(row.id);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'id');
        }
        ;
      }
      ;
      // return
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        groupChatLabelApi.batchDeleteGroupChatTag(ids).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.msg);
            setTimeout(function () {
              _this4.loadData();
            }, 500);
          }
          ;
        });
      });
    }
  }
};