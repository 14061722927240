var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-m-cls follow-up-dialog",
      staticStyle: { "max-height": "500px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "服务产品：",
                            prop: "marketingProductCode",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择服务产品" },
                              model: {
                                value: _vm.form.marketingProductCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "marketingProductCode",
                                    $$v
                                  )
                                },
                                expression: "form.marketingProductCode",
                              },
                            },
                            _vm._l(_vm.productList, function (item) {
                              return _c("el-option", {
                                key: item.productId,
                                attrs: {
                                  label: item.productName,
                                  value: item.productCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "跟进分类：", prop: "trackStatus" } },
                        _vm._l(
                          _vm.followclassifications,
                          function (item, index) {
                            return _c(
                              "el-radio-group",
                              {
                                key: index,
                                on: { change: _vm.handleTrack },
                                model: {
                                  value: _vm.form.trackStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "trackStatus", $$v)
                                  },
                                  expression: "form.trackStatus",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { margin: "0 5px" },
                                    attrs: { label: item.itemCode },
                                  },
                                  [_vm._v(_vm._s(item.itemName))]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _vm.form.trackStatus
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "跟进状态：",
                                prop: "trackClassifyCode",
                              },
                            },
                            _vm._l(_vm.trackList, function (item, index) {
                              return _c(
                                "el-radio-group",
                                {
                                  key: index,
                                  model: {
                                    value: _vm.form.trackClassifyCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "trackClassifyCode",
                                        $$v
                                      )
                                    },
                                    expression: "form.trackClassifyCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticStyle: { margin: "0 5px" },
                                      attrs: { label: item.itemCode },
                                    },
                                    [_vm._v(_vm._s(item.itemName))]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "跟进附件：" } },
                        [
                          _vm.form.trackPictureStorageUrl
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "110px",
                                    height: "100px",
                                    position: "relative",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "coverImg",
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      src: _vm.form.trackPictureStorageUrl,
                                      alt: "",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "deleteIcon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.delFileList()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-circle-close",
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "16px",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                limit: 1,
                                action: "",
                                multiple: "",
                                "show-file-list": false,
                                "http-request": function (val) {
                                  return _vm.uploadFile(val)
                                },
                                "before-upload": function (val) {
                                  return _vm.beforeUpload(val)
                                },
                                "file-list": _vm.fileList,
                                accept: _vm.uploadType,
                                "list-type": "text",
                              },
                            },
                            [
                              _c("el-button", { staticClass: "el-icon-plus" }, [
                                _vm._v("上传文件"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.trackStatus == "TRACKING" || !_vm.form.trackStatus
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "下次跟进日期：",
                                prop: "trackSubscribeTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "picker-options": _vm.pickerOptions,
                                  placeholder: "请选择下次跟进日期",
                                  format: "yyyy-MM-dd HH:mm",
                                  "value-format": "yyyy-MM-dd HH:mm",
                                  type: "datetime",
                                  clearable: "",
                                },
                                on: { change: _vm.timeChange },
                                model: {
                                  value: _vm.form.trackSubscribeTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "trackSubscribeTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.trackSubscribeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _vm.form.trackStatus == "SUCCEED"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销售价格：",
                                prop: "sellingPrice",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入销售价格",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.sellingPrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sellingPrice", $$v)
                                  },
                                  expression: "form.sellingPrice",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.form.trackStatus == "TRACKING" || !_vm.form.trackStatus
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.handldTrackTime },
                                  model: {
                                    value: _vm.trackTimeRadio,
                                    callback: function ($$v) {
                                      _vm.trackTimeRadio = $$v
                                    },
                                    expression: "trackTimeRadio",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "明天" } }, [
                                    _vm._v("明天"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "后天" } }, [
                                    _vm._v("后天"),
                                  ]),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "三天后" } },
                                    [_vm._v("三天后")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "五天后" } },
                                    [_vm._v("五天后")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.trackStatus !== "SUCCEED"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "本次跟进内容：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "250",
                                  "show-word-limit": "",
                                  placeholder: "请输入本次跟进内容",
                                  clearable: "",
                                  type: "textarea",
                                  rows: 3,
                                },
                                model: {
                                  value: _vm.form.trackContent,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "trackContent", $$v)
                                  },
                                  expression: "form.trackContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", {
                staticStyle: {
                  "margin-top": "18px",
                  "border-top": "1px solid #ccc",
                },
              }),
              _c(
                "div",
                { staticClass: "history-info" },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "font-weight": "bold",
                        padding: "10px 0",
                        "margin-top": "10px",
                      },
                    },
                    [_vm._v("上次跟进信息：")]
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _vm._v(
                          " 跟进状态：" +
                            _vm._s(
                              (_vm.lastTrackDetails &&
                                _vm.lastTrackDetails.trackClassifyName) ||
                                ""
                            ) +
                            " "
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _vm._v(
                          " 下次跟进时间：" +
                            _vm._s(
                              _vm.lastTrackDetails &&
                                _vm.lastTrackDetails.trackSubscribeTime
                                ? _vm.lastTrackDetails.trackSubscribeTime.slice(
                                    0,
                                    10
                                  )
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _vm._v(
                          " 跟进内容：" +
                            _vm._s(
                              (_vm.lastTrackDetails &&
                                _vm.lastTrackDetails.trackContent) ||
                                ""
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                disabled: _vm.isDisable,
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }