var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "80px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "部门：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple: "",
                                    clearable: "",
                                    placeholder: "请选择部门",
                                  },
                                  on: { change: _vm.getDepartmentList },
                                  model: {
                                    value: _vm.form.departmentId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "departmentId", $$v)
                                    },
                                    expression: "form.departmentId",
                                  },
                                },
                                _vm._l(_vm.department, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "员工：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple:
                                      _vm.formTaskType == "GROUP"
                                        ? false
                                        : true,
                                    clearable: "",
                                    placeholder: "请选择员工",
                                  },
                                  on: { change: _vm.getEmployee },
                                  model: {
                                    value: _vm.form.employeeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "employeeId", $$v)
                                    },
                                    expression: "form.employeeId",
                                  },
                                },
                                _vm._l(
                                  _vm.employeeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.memberName,
                                        value: item.memberId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "line-height": "46px",
            height: "46px",
            padding: "8px 0px",
            "margin-top": "5px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.saveClicked },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage()
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }