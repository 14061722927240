var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "verifyBox" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "mask",
        },
        [
          _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: true,
                expression: "true",
              },
            ],
            staticStyle: { width: "100%", height: "100%" },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "rowBox" },
        [
          _c(
            "el-row",
            { staticStyle: { width: "100%" } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "headerTitle box-card" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.insCorpName) +
                      ": " +
                      _vm._s(_vm.docTitle) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { xs: 23, sm: 16, md: 14, lg: 12, xl: 10 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box-card",
                      staticStyle: { padding: "0 20px" },
                    },
                    [
                      _c("div", {
                        staticClass: "conetnt",
                        attrs: { id: "content_div" },
                        domProps: { innerHTML: _vm._s(_vm.knowledgeBody) },
                      }),
                    ]
                  ),
                ]
              ),
              _vm.audit
                ? _c(
                    "el-col",
                    {
                      staticStyle: { border: "0" },
                      attrs: { xs: 23, sm: 16, md: 14, lg: 12, xl: 10 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "box-card",
                          staticStyle: { padding: "0 20px" },
                        },
                        [
                          _vm.keywordsList && _vm.keywordsList.length != 0
                            ? _c("div", { staticClass: "keywordsBox" }, [
                                _c(
                                  "div",
                                  { staticClass: "keywordsItemTitle" },
                                  [_vm._v("关键字：")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "keywordsItemList" },
                                  _vm._l(
                                    _vm.keywordsList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "keywordsItem",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.keywordsContent) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                          _vm.knowledgeAppraiseDisplay
                            ? _c("div", { staticClass: "keywordsBox" }, [
                                _c(
                                  "div",
                                  { staticClass: "keywordsItemTitle" },
                                  [_vm._v("文章质量：")]
                                ),
                                _c("div", { staticClass: "keywordsItemList" }, [
                                  _c("div", { staticClass: "keywordsItem" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.knowledgeAppraiseDisplay) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.audit
                ? _c("el-col", {
                    staticStyle: { height: "200px" },
                    attrs: { xs: 23, sm: 16, md: 14, lg: 12, xl: 10 },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "fixedBox" },
                [
                  _vm.audit
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { border: "0" },
                          attrs: { xs: 23, sm: 16, md: 14, lg: 12, xl: 10 },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "itemText_header",
                              staticStyle: { "padding-bottom": "0" },
                            },
                            [
                              _c("div", { staticClass: "itemTitle" }, [
                                _vm._v("审核状态："),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "auditorStatus",
                                  style: {
                                    color:
                                      _vm.examineStatus == "KEE03"
                                        ? "red"
                                        : _vm.examineStatus == "KEE02"
                                        ? "green"
                                        : "blue",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.examineStatusDisplay))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "auditBox",
                              staticStyle: { "padding-top": "0" },
                            },
                            [
                              _vm.queryAudit && _vm.queryAudit == "'6D8F'"
                                ? _c(
                                    "div",
                                    { staticClass: "auditChose" },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: _vm.auditStatus,
                                            callback: function ($$v) {
                                              _vm.auditStatus = $$v
                                            },
                                            expression: "auditStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "KEE02" } },
                                            [_vm._v("通过")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "KEE03" } },
                                            [_vm._v("不通过")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.queryAudit &&
                              _vm.queryAudit == "'2A3C'" &&
                              _vm.examineStatus == "KEE02"
                                ? _c(
                                    "div",
                                    { staticClass: "auditChose" },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: _vm.auditStatus,
                                            callback: function ($$v) {
                                              _vm.auditStatus = $$v
                                            },
                                            expression: "auditStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "KEE03" } },
                                            [_vm._v("下架")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.auditStatus == "KEE03"
                                ? _c(
                                    "div",
                                    { staticClass: "noPass" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          rows: 2,
                                          placeholder: "请输入审核备注",
                                        },
                                        model: {
                                          value: _vm.examineRemark,
                                          callback: function ($$v) {
                                            _vm.examineRemark = $$v
                                          },
                                          expression: "examineRemark",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "subAuditBox" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "subAudit",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.submitAudit },
                                    },
                                    [_vm._v(" 提交 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "subAudit",
                                      on: { click: _vm.submitBack },
                                    },
                                    [_vm._v(" 返回 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "el-col",
                        {
                          staticStyle: { border: "0" },
                          attrs: { xs: 23, sm: 16, md: 14, lg: 12, xl: 10 },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "itemText_header",
                              staticStyle: { "padding-bottom": "0" },
                            },
                            [
                              _c("div", { staticClass: "itemTitle" }, [
                                _vm._v("审核状态："),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "auditorStatus",
                                  style: {
                                    color:
                                      _vm.examineStatus == "KEE03"
                                        ? "red"
                                        : _vm.examineStatus == "KEE02"
                                        ? "green"
                                        : "blue",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.examineStatusDisplay))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "auditBox",
                              staticStyle: { "padding-top": "0" },
                            },
                            [
                              _vm.auditStatus == "KEE03"
                                ? _c(
                                    "div",
                                    { staticClass: "noPass" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          rows: 2,
                                          placeholder: "请输入审核备注",
                                        },
                                        model: {
                                          value: _vm.examineRemark,
                                          callback: function ($$v) {
                                            _vm.examineRemark = $$v
                                          },
                                          expression: "examineRemark",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "subAuditBox" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "subAudit",
                                      on: { click: _vm.submitBack },
                                    },
                                    [_vm._v(" 返回 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                ],
                1
              ),
              !_vm.audit
                ? _c(
                    "el-col",
                    {
                      staticStyle: { border: "0" },
                      attrs: { xs: 23, sm: 16, md: 14, lg: 12, xl: 10 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "box-card",
                          staticStyle: { padding: "0 20px" },
                        },
                        [
                          _vm.keywordsList && _vm.keywordsList.length != 0
                            ? _c("div", { staticClass: "keywordsBox" }, [
                                _c(
                                  "div",
                                  { staticClass: "keywordsItemTitle" },
                                  [_vm._v("关键字：")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "keywordsItemList" },
                                  _vm._l(
                                    _vm.keywordsList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "keywordsItem",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.keywordsContent) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "isHelpful" }, [
                            _c("div", { staticClass: "itemTitle" }, [
                              _vm._v("文章是否对你有帮助？"),
                            ]),
                            _c("div", { staticClass: "chose" }, [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.giveALike(true)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "class-name": "zan",
                                      "icon-class": "zan",
                                    },
                                  }),
                                  _c("div", [
                                    _vm._v(
                                      "有用（" + _vm._s(_vm.usefulNum) + "）"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.giveALike(false)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "class-name": "cai",
                                      "icon-class": "cai",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-left": "5px" } },
                                    [
                                      _vm._v(
                                        "没用（" + _vm._s(_vm.uselessNum) + "）"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.audit
                ? _c("el-col", {
                    staticStyle: { height: "200px" },
                    attrs: { xs: 23, sm: 16, md: 14, lg: 12, xl: 10 },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("viewer", { attrs: { images: _vm.images } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }