import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=546e7f48&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&id=546e7f48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546e7f48",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/prod-scrm-web_10.253.3.224/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('546e7f48')) {
      api.createRecord('546e7f48', component.options)
    } else {
      api.reload('546e7f48', component.options)
    }
    module.hot.accept("./detail.vue?vue&type=template&id=546e7f48&scoped=true&", function () {
      api.rerender('546e7f48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/material/article/detail.vue"
export default component.exports